import { IEditCustomPromotion } from "./IEditCustomPromotion";
import { IProductsRepository } from "../../../repositories/ProductsRepository/IProductsRepository";
import { IPromotion } from "../../../entities/PencilSelling/IPromotion";

export class EditCustomPromotionUseCase implements IEditCustomPromotion {
  constructor(private readonly repository: IProductsRepository) {}

  async execute(customPromotion: IPromotion): Promise<IPromotion> {
    const result = await this.repository.editCustomPromotion(customPromotion);
    return result;
  }
}
