import React, { ElementType, ReactNode } from "react";
import { CopyText, Heading, TextLink } from "@dtpk-cc/components";

import { trackClick } from "helpers/reactTracking";
import { PAGE_HOME } from "routes/extern-url";
import MobilRechnerIcon from "../../../../../assets/images/icons/pencil-selling-tool/navigation/tools/magenta-mobil-rechner.svg";
import PencilSellingIcon from "../../../../../assets/images/icons/pencil-selling-tool/navigation/tools/pencil-selling-tool.svg";
import SpeedRechnerIcon from "../../../../../assets/images/icons/pencil-selling-tool/navigation/tools/speed-rechner.svg";
import StreamingRechnerIcon from "../../../../../assets/images/icons/pencil-selling-tool/navigation/tools/streaming-rechner.svg";
import CustomerExpIcon from "../../../../../assets/images/icons/pencil-selling-tool/navigation/tools/customer-experience.svg";
// TODO temporary hidden for MVC
// import PersonalIcon from "../../../../../assets/images/icons/pencil-selling-tool/navigation/tools/personal-recommendation.svg";
import MagentaInfoIcons from "../../../../../assets/images/icons/pencil-selling-tool/navigation/tools/magenta-infos.svg";
import ArrowRightIcon from "../../../../../assets/images/icons/pencil-selling-tool/navigation/tools/navigation-right-arrow.svg";
import DevicesIcon from "../../../../../assets/images/icons/pencil-selling-tool/navigation/tools/devices.svg";

import * as styles from "./tools-menu.module.scss";
// import classes from "./tools-menu.module.scss";
import * as tileStyles from "../navigation-flyout-tile-item.module.scss";

const TRACKING_CONTEXT = "profilmenü";

interface IIconMap {
  [key: string]: {
    title: string;
    items: {
      title: string;
      Icon: ElementType | string;
      link: string;
    }[];
  };
}

type ToolsMenuProps = {
  onClick: () => void;
};

const iconsMap: IIconMap = {
  main: {
    title: "Privatkunden Tools",
    items: [
      {
        title: "Angebotstool PK",
        Icon: PencilSellingIcon,
        link: `${window.location.origin}/angebote-erstellen/angebotstool`,
      },
      {
        title: "Bestandsrechner",
        Icon: MobilRechnerIcon,
        link: `${window.location.origin}/bestandsrechner`,
      },
      {
        title: "Speed Rechner",
        Icon: SpeedRechnerIcon,
        link: "https://ebs07.telekom.de/cet/front/speed-check",
      },
      {
        title: "Streaming Rechner",
        Icon: StreamingRechnerIcon,
        link: "https://ebs07.telekom.de/cet/front/streaming-calculator",
      },
      {
        title: "Customer Experience",
        Icon: CustomerExpIcon,
        link: "https://ebs07.telekom.de/cet/front/",
      },
      // TODO temporary hidden for MVC
      // {
      //   title: "Persönliche Empfehlung",
      //   Icon: PersonalIcon,
      //   link: "",
      // },
      {
        title: "Geräte Vergleich",
        Icon: DevicesIcon,
        link: "https://magentainfos.telekom.de/MTFinder/devices",
      },
      {
        title: "Magenta Infos",
        Icon: MagentaInfoIcons,
        link: "https://magentainfos.telekom.de/overview/393720",
      },
    ],
  },
  business: {
    title: "Geschäftskunden Tools",
    items: [
      {
        title: "Angebotstool GK",
        Icon: PencilSellingIcon,
        link: `${window.location.origin}/angebote-erstellen/angebotstool/geschaeftskunden`,
      },
      // TODO temporary hidden for MVC
      // {
      //   title: "Persönliche Empfehlung",
      //   Icon: PersonalIcon,
      //   link: "",
      // },
    ],
  },
};

const ToolsMenu = ({ onClick }: ToolsMenuProps) => {
  const getToolsListTemplate = (type: string): ReactNode => (
    <>
      <Heading
        customClass={styles.subtitle}
        tag="p"
        variants={[Heading.Variant.quaternary]}
      >
        {iconsMap[type].title}
      </Heading>
      <div
        className={`${styles.content} ${
          type === "main" ? styles.mainToolsWrapper : ""
        }`}
      >
        {iconsMap[type].items.map(({ title, Icon, link }) => (
          <TextLink
            key={title}
            tabIndex={window.location.href === link ? -1 : 0}
            target="_blank"
            rel="noopener noreferrer"
            href={link}
            className={`m-12 py-18 px-20 ${
              tileStyles.navigationFlyoutTileItem
            } ${window.location.href === link ? tileStyles.selected : ""}`}
            onClick={() => {
              trackClick(title, TRACKING_CONTEXT);
              onClick();
            }}
          >
            <div
              className={`m-b-12 ${tileStyles.navigationFlyoutTileItemContainer}`}
            >
              <Icon />
            </div>
            <Heading
              customClass={`${tileStyles.navigationFlyoutTileItemText} textEllipsis`}
              tag="p"
              variants={[Heading.Variant.quaternary, Heading.Variant.highlight]}
            >
              {title}
            </Heading>
          </TextLink>
        ))}
      </div>
    </>
  );

  return (
    <div className={styles.toolsMenu}>
      <div className={styles.header}>
        <Heading
          customClass={styles.title}
          tag="h3"
          variants={[Heading.Variant.tertiary, Heading.Variant.display]}
        >
          Alle Tools
        </Heading>
        <a
          tabIndex={0}
          href={`${window.location.origin}${PAGE_HOME}`}
          className={styles.homePageLink}
        >
          <CopyText>Zur Startseite</CopyText>
          <ArrowRightIcon className={styles.arrowIcon} />
        </a>
      </div>
      {getToolsListTemplate("main")}
      {getToolsListTemplate("business")}
    </div>
  );
};

export default ToolsMenu;
