import React, { useEffect, useState, useCallback } from "react";
import { observer } from "mobx-react";
import {
  Input,
  Container,
  Grid,
  Button,
  Heading,
  CopyText,
  Dropdown,
  Price,
} from "@dtpk-cc/components";
import ActionRemoveDefault from "@dtpk-cc/components/dist/icons/Action/Remove/Default";

import PromotionList from "components/PromotionList";
import IncrementalButtonGroup from "elements/IncrementalButtonGroup";
import { onlyNumber } from "helpers/NumericCalculation";
import { PersonalDataPresenter } from "core/presenter/PencilSelling/PersonalDataPresenter";
import { trackClick } from "helpers/reactTracking";

import "./cards-portfolio.scss";
import { Group, PortfolioKeys } from "core/entities/Product/IProduct";
import { useStores } from "../../stores";
import {
  CardSection,
  CardsPresenter,
} from "../../core/presenter/PencilSelling/CardsPresenter";
import {
  IAdditionalDevices,
  ICartItemData,
} from "../../core/entities/PencilSelling/CartItem/ICartItem";
import { IPromotion } from "../../core/entities/PencilSelling/IPromotion";
import { ICard } from "../../core/entities/Product/Card/ICard";
import { useAddTariffPromotionToOffer } from "../../hooks/PencilSelling/useAddTariffPromotionToOffer";
import { PromotionPriceType } from "../../core/entities/Product/IDiscount";

export const MULTISIM_PRICE = "4,95";

interface SubmitHandlerArgs {
  card: ICartItemData | ICard;
  additionalDevices: IAdditionalDevices[];
  promotions: IPromotion[];
  note: string;
  multiSim: { price: number; quantity: number };
  businessCaseKey: string;
}

interface CardsPortfolioProps {
  title: string;
  cardSectionName: string;
  cardSection: CardSection;
  cardsPresenter: CardsPresenter;
  cardInCart: ICartItemData | null;
  onSubmit: ({
    card,
    additionalDevices,
    promotions,
    note,
    multiSim,
    businessCaseKey,
  }: SubmitHandlerArgs) => void;
  onClose: () => void;
  onRemove: (card: ICartItemData | ICard) => void;
  trackingContext: string;
}

const CardsPortfolio = ({
  title,
  cardSectionName,
  cardSection,
  cardsPresenter,
  cardInCart = null,
  onSubmit,
  onClose,
  onRemove,
  trackingContext = "zusatzkarten",
}: CardsPortfolioProps) => {
  const context = `${trackingContext}.modal`;
  const { offerStore } = useStores();
  const { addPromotion } = useAddTariffPromotionToOffer(offerStore);
  const personalDataPresenter = new PersonalDataPresenter(false);
  const distinctLevelNames = cardsPresenter
    .distinctLevels(cardSection.cards as ICard[])
    .map((entry) => entry.name);
  const businessCases = personalDataPresenter.mobileBusinessCase.items;

  const [activeCard, setActiveCard] = useState(
    cardInCart as ICartItemData | ICard
  );
  const [businessCaseKey, setBusinessCaseKey] = useState(
    cardInCart?.cardData?.businessCaseKey || businessCases[0].key
  );
  const [selectedLevelName, setSelectedLevelName] = useState(
    cardInCart?.cardData?.level?.name || distinctLevelNames[0]
  );
  const [promotions, setPromotions] = useState([]);
  const [highlightedPromotions, setHighlightedPromotions] = useState(
    cardInCart?.promotions || []
  );

  const [note, setNote] = useState(cardInCart?.cardData?.note || "");
  const [smartphoneInfos, setSmartphoneInfos] = useState(
    cardInCart
      ? cardInCart?.additionalDevices
      : [
          {
            name: "",
            price: "",
            isAlternative: false,
            manufacturer: "",
            suffix: "",
          },
        ]
  );
  const [multiSim, setMultiSim] = useState({
    // TODO check validity of MULTISIM_PRICE constant. Is it necessary?
    price:
      cardInCart?.cardData?.multiSim?.price ||
      parseFloat(MULTISIM_PRICE.replace(",", ".")),
    quantity: cardInCart?.cardData?.multiSim?.quantity || 0,
  });

  const customCardPromotions = offerStore.getCustomPromotions()[Group.card];
  const allPromotions = [...promotions, ...customCardPromotions];

  const onDeviceInputChange = (type, value) => {
    setSmartphoneInfos((prev) => [{ ...prev[0], [type]: value }]);
  };

  const handlePromotionSelection = useCallback(
    (promotion: IPromotion) => {
      setHighlightedPromotions((prevPromotions: IPromotion[]) => {
        const promotionExists = prevPromotions.some(
          (prevPromotion) => prevPromotion.key === promotion.key
        );
        if (promotionExists) {
          return prevPromotions.filter(
            (prevPromotion) => prevPromotion.key !== promotion.key
          );
        }

        // NOTE: customPromotion does not have a condition and shouldn't be filtered
        const filteredPrevPromotions = prevPromotions.filter(
          (promotionItem) => {
            if ("conditions" in promotionItem && !promotionItem.isCustom) {
              return (
                promotionItem?.conditions?.includes(businessCaseKey) ||
                promotionItem?.conditions?.length === 0
              );
            }
            return true;
          }
        );

        return [...filteredPrevPromotions, promotion];
      });
    },
    [businessCaseKey]
  );

  const addCustomPromotion = useCallback(
    (promotion) => {
      addPromotion(promotion, PortfolioKeys.MOBILE);
    },
    [addPromotion]
  );

  useEffect(() => {
    if (cardSection?.cards) {
      const selectedCard = (cardSection?.cards as ICard[])?.find(
        (card) => card.level.name === selectedLevelName
      );

      setPromotions(() =>
        selectedCard.promotions.filter((promotion) =>
          promotion.conditions.includes(businessCaseKey)
        )
      );

      setActiveCard((prevState) =>
        prevState ? { ...selectedCard, key: prevState.key } : selectedCard
      );
    }
  }, [selectedLevelName, cardSection, businessCaseKey]);

  useEffect(() => {
    if (cardInCart?.cardData?.level?.name === selectedLevelName) return;

    setHighlightedPromotions([]);
  }, [selectedLevelName, cardInCart?.cardData?.level?.name]);

  return (
    <>
      <Container customClass="cards-portfolio__container">
        <Grid>
          <Heading
            tag="h2"
            variants={[Heading.Variant.secondary, Heading.Variant.hero]}
          >
            {title}
          </Heading>
          <Grid.Row
            gutter
            customClass="cards-portfolio__row cards-portfolio__row--spacer"
          >
            <Grid.Col
              s={3}
              m={6}
              l={6}
              xl={12}
              style={{ display: "flex", alignItems: "center" }}
            >
              <Heading
                tag="h3"
                variants={[Heading.Variant.quaternary]}
                customClass={"cards-portfolio__card-heading"}
              >
                {cardSectionName}
              </Heading>
            </Grid.Col>

            <Grid.Col s={3} m={6} l={6} xl={12}>
              <Input
                value={note}
                customClass="cards-portfolio__input"
                onChange={(e) => setNote(e.target.value)}
                onClick={() => {
                  trackClick(`${cardSectionName}.ergänzungen`, context);
                }}
              >
                Ergänzungen
              </Input>
            </Grid.Col>
          </Grid.Row>

          {distinctLevelNames.length > 1 && (
            <Grid.Row gutter customClass="cards-portfolio__row">
              <Grid.Col s={3} m={6} l={6} xl={12}>
                <Input
                  value={smartphoneInfos[0].name}
                  customClass="cards-portfolio__input"
                  onChange={(e) => onDeviceInputChange("name", e.target.value)}
                  onClick={() => {
                    trackClick(`${cardSectionName}.smartphone`, context);
                  }}
                  type="text"
                >
                  Smartphone
                </Input>
              </Grid.Col>

              <Grid.Col s={1} m={2} l={2} xl={4}>
                <Input
                  value={smartphoneInfos[0].price}
                  customClass="cards-portfolio__input"
                  onChange={(e) => onDeviceInputChange("price", e.target.value)}
                  onClick={() => {
                    trackClick(`${cardSectionName}.kaufpreis`, context);
                  }}
                  type="number"
                  onKeyPress={(e) => onlyNumber(e)}
                >
                  Kaufpreis
                </Input>
              </Grid.Col>
              <Grid.Col s={2} m={4} l={4} xl={8}>
                <Dropdown
                  value={
                    (activeCard as ICard)?.level?.name || distinctLevelNames[0]
                  }
                  label="Substufe"
                  items={distinctLevelNames}
                  onSelection={(value) => {
                    setSelectedLevelName(value);
                    trackClick(`${cardSectionName}.${value}`, context);
                  }}
                  customClass={"cards-portfolio__dropdown"}
                />
              </Grid.Col>
            </Grid.Row>
          )}
          <Grid.Row gutter customClass="cards-portfolio__row">
            <Grid.Col
              s={6}
              m={12}
              l={12}
              xl={24}
              customClass="cards-portfolio__col-heading"
            >
              <CopyText customClass="cards-portfolio__subheading">
                Geschätsfall
              </CopyText>
            </Grid.Col>

            <Grid.Col s={6} m={12} l={12} xl={24}>
              {businessCases.map((entry, index) => (
                <Button
                  key={entry.key}
                  onClick={() => {
                    setBusinessCaseKey(entry.key);
                    trackClick(
                      `${cardSectionName}.geschäftsfall-${entry.name}`,
                      context
                    );
                  }}
                  variants={Button.Variant.outline}
                  customClass={
                    businessCaseKey === entry.key
                      ? `cards-portfolio__button cards-portfolio__button--active cards-portfolio__button-${index}`
                      : `cards-portfolio__button cards-portfolio__button-${index}`
                  }
                >
                  {entry.name}
                </Button>
              ))}
            </Grid.Col>
          </Grid.Row>

          {activeCard && (
            <Grid.Row gutter customClass="cards-portfolio__row">
              <Grid.Col
                s={6}
                m={12}
                l={12}
                xl={24}
                customClass="cards-portfolio__col-heading"
              >
                <CopyText
                  variants={CopyText.Variant.magenta}
                  customClass="cards-portfolio__subheading"
                >
                  Mobilfunk Aktionen
                </CopyText>
              </Grid.Col>

              <Grid.Col s={6} m={12} l={12} xl={24}>
                <PromotionList
                  trackingContext={context}
                  promotions={allPromotions}
                  selectedPromotions={highlightedPromotions}
                  productPrice={activeCard.price.monthly}
                  onSelect={handlePromotionSelection}
                  flexStart
                  onAdd={addCustomPromotion}
                  showPromotionText={false}
                  portfolio={Group.card}
                />
              </Grid.Col>
            </Grid.Row>
          )}

          {(activeCard as ICard)?.hasMultisim === true && (
            <Grid.Row gutter customClass="cards-portfolio__row">
              <Grid.Col
                s={6}
                m={12}
                l={12}
                xl={24}
                customClass="cards-portfolio__col-heading"
              >
                <CopyText customClass="cards-portfolio__subheading">
                  MultiSIM
                </CopyText>
              </Grid.Col>

              <Grid.Col
                s={6}
                m={12}
                l={12}
                xl={24}
                style={{ display: "flex", alignItems: "center" }}
              >
                <IncrementalButtonGroup
                  customClass="cards-portfolio__quantity"
                  handleMinusClick={(quantity) => {
                    setMultiSim((prev) => ({ ...prev, quantity }));
                    trackClick("multisim.plus", context);
                  }}
                  handlePlusClick={(quantity) => {
                    setMultiSim((prev) => ({ ...prev, quantity }));
                    trackClick("multisim.minus", context);
                  }}
                  value={multiSim.quantity}
                  minQuantity={0}
                  maxQuantity={7}
                />
                <Price.Text style={{ fontSize: "1.1rem" }}>
                  <strong>{MULTISIM_PRICE} </strong>
                  mtl.
                </Price.Text>
              </Grid.Col>
            </Grid.Row>
          )}
        </Grid>

        <Container customClass="cards-portfolio__footer-wrapper">
          {cardInCart && (
            <Container customClass="cards-portfolio__footer">
              <Button
                customClass="cards-portfolio__delete-button"
                variants={[Button.Variant.secondary]}
                onClick={() => {
                  onRemove(activeCard);
                  trackClick(`${activeCard.name}.zusatzkarte-löschen`, context);
                  onClose();
                }}
              >
                <Button.Icon icon={ActionRemoveDefault} />
                Zusatzkarte löschen
              </Button>
            </Container>
          )}

          <Container
            customClass="cards-portfolio__footer"
            style={!cardInCart ? { marginLeft: "auto" } : {}}
          >
            <Button
              customClass="cards-portfolio__cancel-button"
              variants={[Button.Variant.secondary]}
              onClick={() => {
                onClose();
                trackClick(`${cardSectionName}.abbrechen`, context);
              }}
            >
              Abbrechen
            </Button>
            <Button
              onClick={() => {
                onSubmit({
                  card: activeCard,
                  promotions: highlightedPromotions,
                  additionalDevices: smartphoneInfos,
                  note,
                  multiSim,
                  businessCaseKey,
                });
                trackClick(`${cardSectionName}.auswahl-übernehmen`, context);
              }}
            >
              Auswahl übernehmen
            </Button>
          </Container>
        </Container>
      </Container>
    </>
  );
};

export default observer(CardsPortfolio);
