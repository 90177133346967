import { IReplaceCustomPromotion } from "./IReplaceCustomPromotion";
import { IOffer } from "../../../entities/PencilSelling/Offer_legacy/IOffer";
import { PortfolioKeys } from "../../../entities/Product/IProduct";
import { IPromotion } from "../../../entities/PencilSelling/IPromotion";

export class ReplaceCustomPromotionUseCase implements IReplaceCustomPromotion {
  constructor(private entity: IOffer) {}

  execute(promotionSection: PortfolioKeys, promotion: IPromotion) {
    this.entity.replaceCustomPromotion(promotionSection, promotion);
  }
}
