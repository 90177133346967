import { hot } from "react-hot-loader/root";
import { setConfig } from "react-hot-loader";
import React, { useEffect, useRef, useState } from "react";

import { useQrCodeModalData } from "hooks/useQrCodeModalData";
import {
  Container,
  Heading,
  Grid,
  ModalLegal,
  CopyText,
} from "@dtpk-cc/components";
import "views/resets.scss";
import "@dtpk-cc/components/dist/fonts-local.css";
import "@dtpk-cc/components/dist/components/Accordion/Accordion.css";
import "@dtpk-cc/components/dist/components/HintBox/HintBox.css";
import "@dtpk-cc/components/dist/components/Asterisk/Asterisk.css";
import "@dtpk-cc/components/dist/components/Button/Button.css";
import "@dtpk-cc/components/dist/components/CopyText/CopyText.css";
import "@dtpk-cc/components/dist/components/EqualHeight/EqualHeight.css";
import "@dtpk-cc/components/dist/components/Container/Container.css";
import "@dtpk-cc/components/dist/components/RadioButton/RadioButton.css";
import "@dtpk-cc/components/dist/components/Grid/Grid.css";
import "@dtpk-cc/components/dist/components/Flex/Flex.css";
import "@dtpk-cc/components/dist/components/Heading/Heading.css";
import "@dtpk-cc/components/dist/components/Input/Input.css";
import "@dtpk-cc/components/dist/components/Icon/Icon.css";
import "@dtpk-cc/components/dist/components/A11yText/A11yText.css";
import "@dtpk-cc/components/dist/components/List/List.css";
import "@dtpk-cc/components/dist/components/Modal/Modal.css";
import "@dtpk-cc/components/dist/components/Price/Price.css";
import "@dtpk-cc/components/dist/components/Tabs/Tabs.css";
import "@dtpk-cc/components/dist/components/Badge/Badge.css";
import "@dtpk-cc/components/dist/components/Tile/Tile.css";
import "@dtpk-cc/components/dist/components/Checkbox/Checkbox.css";
import "@dtpk-cc/components/dist/components/Dropdown/Dropdown.css";
import "@dtpk-cc/components/dist/components/TextLink/TextLink.css";
import "@dtpk-cc/components/dist/components/ToggleButton/ToggleButton.css";
import "@dtpk-cc/components/dist/components/Indicator/Indicator.css";
import "@dtpk-cc/components/dist/components/Tooltip/Tooltip.css";
import "@dtpk-cc/components/dist/components/StepIndicator/StepIndicator.css";

import LoadingSpinner from "elements/LoadingSpinner";
import QrCodeModal from "components/QrCodeModal";
import { CardSections } from "core/entities/Product/Card/ICard";
import { getFreeMonths } from "helpers/GetFreeMonths";
import { useMagentaNextCalculatorStores } from "stores/MagentaNext";
import { useFetchCards } from "hooks/MagentaNext/useFetchCards";
import { observer } from "mobx-react";
import { usePdfFunctions } from "hooks/MagentaNext/usePdfFunctions";
import * as styles from "views/MagentaNext/magenta-next.module.scss";
import { LegacyTariffCheckbox } from "components/MagentaNext/LegacyTariff/LegacyTariffCheckbox";
import TariffConfiguratorSectionWrapper from "components/MagentaNext/TariffConfiguratorSectionWrapper";
import { ISettings } from "core/entities/MagentaNext/Tariff/ITariff";
import LegacyTariffConfigurator from "components/MagentaNext/LegacyTariff/LegacyTariffConfigurator";
import TariffConfigurator from "components/MagentaNext/TariffConfigurator";
import InnerToolbar from "./InnerToolbar";

// Adds reloading of useEffect, useCallback, useMemo on every injection https://github.com/gaearon/react-hot-loader
setConfig({
  reloadHooks: true,
});

const MagentaNextLegacy = () => {
  const { qrCodeModalData, setQrCodeModalData } = useQrCodeModalData();

  const { tariffStore, legacyTariffStore } = useMagentaNextCalculatorStores();

  const { fetchCards } = useFetchCards();

  const activeMainCard =
    tariffStore.getActiveCards()[CardSections.MAGENTA_MOBILE_CARD][0];
  const totalQuantity = tariffStore.getTotalQuantity();
  const settings = tariffStore.getSettings();
  const totalPrice = tariffStore.getTotalPrice();
  const totalCredit = tariffStore.getPromotionsTotalPrice();
  const legacyCustomTotalPrice = legacyTariffStore.getCustomTotalPrice();
  const legacyCustomTotalQuantity = legacyTariffStore.getCustomTotalQuantity();

  const [isLoading, setIsLoading] = useState(true);
  const [isLegalModalOpen, setIsLegalModalOpen] = useState(false);
  const [pdfButtonIsDisabled, setPdfButtonIsDisabled] = useState(true);
  const [transferButtonIsEnabled, setTransferButtonIsEnabled] = useState(false);
  const [transferConfigModalIsOpen, setTransferConfigModalIsOpen] =
    useState(false);
  const [isLegacyTariffVisible, setIsLegacyTariffVisible] = useState(false);
  const [isLegacyTariffDetailViewVisible, setIsLegacyTariffDetailViewVisible] =
    useState(false);
  const [legacyTariffSummaryHeight, setLegacyTariffSummaryHeight] =
    useState(null);
  const priceSummaryRef = useRef<HTMLDivElement | null>(null);

  const pdfFunctions = usePdfFunctions(
    isLegacyTariffDetailViewVisible,
    setIsLoading,
    setQrCodeModalData
  );

  const freeMonths = getFreeMonths(activeMainCard, settings.loyaltyBonusValue);

  const resetCalculator = () => {
    tariffStore.reset();
    legacyTariffStore.reset();
  };

  const updateSettings = (newSettings: Partial<ISettings>) => {
    tariffStore.setSettings({
      ...tariffStore.getSettings(),
      ...newSettings,
    });
  };

  const changeSettingsHandler = (
    key: keyof ISettings,
    value: number | boolean
  ) => {
    if (key === "hasBenefit" && value === false) {
      return updateSettings({
        [key]: value,
        loyaltyBonusValue: 0,
        hasProvisionFee: false,
      });
    }

    if (key === "loyaltyBonusValue" && typeof value === "number") {
      return updateSettings({ [key]: value, hasProvisionFee: value > 0 });
    }

    return updateSettings({ [key]: value });
  };

  const toggleLegacyTariffDetailedView = () => {
    setIsLegacyTariffDetailViewVisible((isVisible) => !isVisible);
  };

  useEffect(() => {
    fetchCards().finally(() => {
      setIsLoading(false);
    });
  }, [fetchCards]);

  useEffect(() => {
    if (
      (legacyCustomTotalQuantity > 0 &&
        legacyCustomTotalPrice > 0 &&
        isLegacyTariffVisible) ||
      (totalPrice > 0 && totalQuantity > 0)
    ) {
      setPdfButtonIsDisabled(false);
    } else {
      setPdfButtonIsDisabled(true);
    }

    if (isLegacyTariffVisible) {
      setLegacyTariffSummaryHeight(
        priceSummaryRef?.current?.offsetHeight || null
      );
    }
  }, [
    legacyCustomTotalQuantity,
    legacyCustomTotalPrice,
    totalPrice,
    totalQuantity,
    totalCredit,
    isLegacyTariffVisible,
  ]);

  useEffect(() => {
    setTransferButtonIsEnabled(!!activeMainCard);
  }, [activeMainCard]);

  return (
    <LoadingSpinner isLoading={isLoading}>
      <div className={`${styles.magentaNext} ${styles.top}`}>
        <Container
          customClass={`${styles.magentaNext} ${styles.mainContainer}`}
          variants={[Container.Variant.centered, Container.Variant.fixedLarge]}
        >
          <Grid padded customClass={`${styles.magentaNext} ${styles.header}`}>
            <Grid.Row
              gutter
              customClass={`${styles.magentaNext} ${styles.row}`}
            >
              <Grid.Col s={6} m={12} l={12} xl={24}>
                <Heading
                  tag="h1"
                  variants={[Heading.Variant.primary, Heading.Variant.display]}
                >
                  MagentaMobil Rechner
                </Heading>
                <LegacyTariffCheckbox
                  isLegacyTariffVisible={isLegacyTariffVisible}
                  setIsLegacyTariffVisible={setIsLegacyTariffVisible}
                />
              </Grid.Col>
            </Grid.Row>
          </Grid>
          {!isLoading && (
            <>
              <Grid padded customClass={`${styles.magentaNext} ${styles.row}`}>
                <Grid.Row
                  gutter
                  customClass={`${styles.magentaNext} ${styles.printRow}`}
                >
                  <TariffConfiguratorSectionWrapper
                    isLegacyTariff
                    configuratorWrapperTitle="Mobilfunk-Tarife im Bestand"
                    gridColClasses={`${styles.magentaNext} ${styles.legacy}`}
                    colDivClasses={`${styles.magentaNext} ${styles.stretch} ${styles.stretchLegacy}`}
                    isLegacyTariffVisible={isLegacyTariffVisible}
                    priceSummaryHeight={legacyTariffSummaryHeight}
                  >
                    <LegacyTariffConfigurator
                      legacyTariffDetailViewIsVisible={
                        isLegacyTariffDetailViewVisible
                      }
                      toggleLegacyTariffDetailedView={
                        toggleLegacyTariffDetailedView
                      }
                    />
                  </TariffConfiguratorSectionWrapper>
                  <TariffConfiguratorSectionWrapper
                    isLegacyTariff={false}
                    configuratorWrapperTitle="Hauptkarte MagentaMobil"
                    gridColClasses={`${styles.magentaNext} ${styles.col} ${styles.centered}`}
                    colDivClasses={`${styles.magentaNext} ${styles.stretch}`}
                    isLegacyTariffVisible={isLegacyTariffVisible}
                    ref={priceSummaryRef}
                  >
                    <TariffConfigurator
                      isLegacyTariffVisible={isLegacyTariffVisible}
                      hasProvisionFee={settings.hasProvisionFee}
                      handleSettingsChange={(key, value) =>
                        changeSettingsHandler(key, value)
                      }
                      settings={settings}
                      onAsteriskClick={() => setIsLegalModalOpen(true)}
                      setTransferButtonIsEnabled={setTransferButtonIsEnabled}
                    />
                  </TariffConfiguratorSectionWrapper>
                </Grid.Row>
              </Grid>
              <InnerToolbar
                pdfFunctions={pdfFunctions}
                isLegacyTariffVisible={isLegacyTariffVisible}
                pdfButtonIsDisabled={pdfButtonIsDisabled}
                transferButtonIsEnabled={transferButtonIsEnabled}
                resetCalculator={resetCalculator}
                setTransferConfigModalIsOpen={setTransferConfigModalIsOpen}
              />
            </>
          )}
        </Container>
      </div>
      <ModalLegal
        isOpen={transferConfigModalIsOpen}
        afterClose={() => setTransferConfigModalIsOpen(false)}
        zIndex={100}
      >
        <CopyText>
          Mit diesem neuen Feature können die im MagentaRechner eingegebenen
          Daten ins Angebotstool übernommen werden.
        </CopyText>
      </ModalLegal>
      <ModalLegal
        isOpen={isLegalModalOpen}
        afterClose={() => setIsLegalModalOpen(false)}
        zIndex={100}
        style={{ padding: "1rem" }}
      >
        <ModalLegal.Heading>
          Der rechnerische Durchschnittspreis erscheint nicht auf der
          Kundenrechnung. Der gesamte Treuebonus wird als Gutschrift angezeigt,
          der Bereitstellungspreis wird damit verrechnet und der Grundpreis
          entfällt für{" "}
          <span style={{ whiteSpace: "nowrap" }}>
            bis zu <span className="brand-color">{freeMonths}</span>
            {freeMonths === 1 ? " Monat" : " Monate"}.
          </span>
        </ModalLegal.Heading>
        <br />
        <CopyText>
          (weitere Leistungen / Optionen des Hauptsvertrages oder Zusatzkosten
          wie Auslandstelefonie reduzieren zusätzlich den Treuebonus und können
          zu Abweichungen bei der Berechnung der Monate führen)
        </CopyText>
      </ModalLegal>

      {!isLoading && qrCodeModalData.isOpen && (
        <QrCodeModal
          magentaNext
          isOpen={qrCodeModalData.isOpen}
          urlLink={qrCodeModalData.urlLink}
          imageSrc={qrCodeModalData.imageSrc}
          closeChangeHandler={() =>
            setQrCodeModalData((prev) => ({ ...prev, isOpen: !prev.isOpen }))
          }
        />
      )}
    </LoadingSpinner>
  );
};

export default hot(observer(MagentaNextLegacy));
