// extracted by mini-css-extract-plugin
var _1 = "young-tariff-module-active-MieFl";
var _2 = "young-tariff-module-checked-PsHFF";
var _3 = "young-tariff-module-label-v8RS9";
var _4 = "young-tariff-module-logoIcon-niZXn";
var _5 = "young-tariff-module-rollIn-FGDZl";
var _6 = "young-tariff-module-rollOut-vUvbL";
var _7 = "young-tariff-module-text-iBLpi";
var _8 = "young-tariff-module-toggleBtn-idTmO";
var _9 = "young-tariff-module-toggleButton-wXSSS";
var _a = "young-tariff-module-toggleButtonSwitcher-FAanb";
var _b = "young-tariff-module-withLoyaltyBonus-Nnalu";
var _c = "young-tariff-module-youngTariff-TfB4Q";
export { _1 as "active", _2 as "checked", _3 as "label", _4 as "logoIcon", _5 as "rollIn", _6 as "rollOut", _7 as "text", _8 as "toggleBtn", _9 as "toggleButton", _a as "toggleButtonSwitcher", _b as "withLoyaltyBonus", _c as "youngTariff" }
