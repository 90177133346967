import React, { FunctionComponent, SVGAttributes } from "react";
import { CopyText, CopyTextVariants } from "@dtpk-cc/components";
import * as styles from "./checkbox-button.module.scss";
import CheckboxInput from "../CheckboxInput";

type CheckBoxBtnProps = {
  title?: string;
  Icon?: FunctionComponent<SVGAttributes<SVGElement>>;
  checked: boolean;
  onChange: (value: boolean) => void;
  customCheckboxClass?: string;
  customTileClass?: string;
  id: string;
  name: string;
  inputTestId: string;
  customTitleClass?: string;
  disabled?: boolean;
  iconWrapperClass?: string;
  customIconClass?: string;
  copyTextVariant?: CopyTextVariants;
};

const CheckBoxBtn = ({
  title = "",
  Icon,
  checked = false,
  onChange = () => {},
  customCheckboxClass = "",
  id,
  name,
  inputTestId,
  disabled = false,
  customTileClass = "",
  iconWrapperClass = "",
  customIconClass = "",
  copyTextVariant = CopyText.Variant.highlight,
}: CheckBoxBtnProps) => (
  <label
    htmlFor={id}
    id={`${id}-label`}
    className={`${customTileClass} ${disabled ? styles.disabled : ""} ${
      styles.checkBoxBtn
    } ${checked ? styles.active : ""}`}
  >
    <div className={styles.iconWithText}>
      {Icon && (
        <div className={iconWrapperClass} id={`${id}-iconWrapper`}>
          <Icon
            className={`${customIconClass} ${styles.icon}`}
            id={`${id}-icon`}
          />
        </div>
      )}
      {title && <CopyText variants={copyTextVariant}>{title}</CopyText>}
    </div>
    <CheckboxInput
      name={name}
      id={id}
      inputTestId={inputTestId}
      checked={checked}
      disabled={disabled}
      onChange={onChange}
      customClass={customCheckboxClass}
    />
  </label>
);

export default CheckBoxBtn;
