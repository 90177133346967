import { makeAutoObservable } from "mobx";
import { Bandwidths } from "core/entities/Product/Tariff/ITariff";
import { filterNonNumericCharactersAndParseToInt } from "helpers/NumberHelpers";
import {
  BandwidthValues,
  DevicesAmounts,
  ICustomerRequirementsBase,
  InternationalTariffVariants,
} from "./ICustomerRequirementsBase";
import { ConfigurationsEntity } from "../Configurations/Configurations";
import {
  ICartConfigurationType,
  ICartConfiguration,
  ICartConfigurationData,
  ILandlineSettings,
} from "../Configurations/IConfigurations";

export const initialRequirementsBase: ICustomerRequirementsBase = {
  bandwidth: {
    current: null,
    max: null,
  },
  fiber: null,
  internationalTariffs: [],
  devices: {
    smartphones: null,
    tablets: null,
    laptops: null,
    smartwatches: null,
    gaming: null,
    mobility: null,
    smartHome: null,
  },
  isBenefitActive: null,
  recommendations: null,
};

export class CustomerRequirementsBase {
  configurationsStore: ConfigurationsEntity;

  customerRequirements: ICustomerRequirementsBase = initialRequirementsBase;

  private initialCustomerRequirements: ICustomerRequirementsBase;

  constructor(configurationsStore: ConfigurationsEntity) {
    this.configurationsStore = configurationsStore;
    makeAutoObservable(this);
  }

  get requirements(): ICustomerRequirementsBase {
    return {
      ...this.customerRequirements,
      isBenefitActive:
        this.configurationsStore.getActiveConfiguration()?.data.isBenefitActive,
      fiber:
        (
          this.configurationsStore.getConfigurationsByType(
            ICartConfigurationType.LANDLINE
          )[0]?.data.settings as ILandlineSettings
        )?.fiber || this.customerRequirements.fiber,
    };
  }

  get recommendations() {
    return {
      bandwidth:
        filterNonNumericCharactersAndParseToInt(
          this.requirements.bandwidth.current || ""
        ) <
        filterNonNumericCharactersAndParseToInt(
          this.requirements.bandwidth.max || ""
        )
          ? "Erhöhung der Geschwindigkeit"
          : "",
    };
  }

  areRequirementsModified(): boolean {
    return (
      JSON.stringify(this.initialCustomerRequirements) !==
      JSON.stringify(this.requirements)
    );
  }

  setup(): void {
    this.initialCustomerRequirements = JSON.parse(
      JSON.stringify(this.requirements)
    ) as ICustomerRequirementsBase;
  }

  setBandwidth(key: keyof BandwidthValues, bandwidth: string): void {
    this.customerRequirements.bandwidth[key] =
      bandwidth === "" ? null : bandwidth;
  }

  toggleBenefitActive(): void {
    const isBenefitActive = !this.requirements.isBenefitActive;

    this.setCustomerRequirementsForConfigurations(
      [ICartConfigurationType.MOBILE, ICartConfigurationType.LANDLINE],
      () => ({
        isBenefitActive,
      })
    );
  }

  toggleFiber(fiber: boolean): void {
    const toggledFiber = fiber === this.requirements.fiber ? null : fiber;

    const fiberIsActive =
      typeof toggledFiber === "boolean" ? toggledFiber : false;

    const bandwidth = Number(fiberIsActive ? Bandwidths.GIGA : Bandwidths.XL);

    this.setCustomerRequirementsForConfigurations(
      [ICartConfigurationType.LANDLINE],
      (config) => ({
        settings: {
          ...config.data.settings,
          fiber: toggledFiber,
          bandwidth,
        },
      })
    );

    this.customerRequirements.fiber = toggledFiber;
  }

  setDevicesValue(key: keyof DevicesAmounts, value: string): void {
    this.customerRequirements.devices[key] = value || null;
  }

  setInternationalTariff(value: InternationalTariffVariants): void {
    this.customerRequirements.internationalTariffs =
      this.customerRequirements.internationalTariffs.includes(value)
        ? this.customerRequirements.internationalTariffs.filter(
            (item) => item !== value
          )
        : [...this.customerRequirements.internationalTariffs, value];
  }

  setCustomerRequirementsForConfigurations(
    configurationTypes: ICartConfigurationType[],
    configChange: (
      config: ICartConfiguration
    ) => Partial<ICartConfigurationData>
  ): void {
    const configurations = configurationTypes
      .map((configType) =>
        this.configurationsStore.getConfigurationsByType(configType)
      )
      .flat();

    configurations.forEach((configuration) => {
      this.configurationsStore.setConfigurationData(
        configuration.key,
        configChange(configuration)
      );
    });
  }
}
