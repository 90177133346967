import React from "react";
import { ToggleBtn } from "elements/new-design/ToggleBtn";
import { observer } from "mobx-react";
import { useStores } from "stores/BusinessClient";
import { SmartphoneChoices } from "core/entities/PencilSelling/BusinessClient/CustomerRequirements/ICustomerRequirementsBC";
import { componentLinks } from "components/new-design/CustomerRequirements/const";
import CustomerRequirementsRecommendation from "components/new-design/CustomerRequirements/CustomerRequirementsRecommendation";
import CustomerRequirementsSectionHeader from "components/new-design/CustomerRequirements/CustomerRequirementsSectionHeader";
import { trackClick } from "helpers/reactTracking";
import * as styles from "./customer-requirements-smartphone-consultation.module.scss";

const smartphoneConsultationContentMap = {
  items: [
    {
      identifier: SmartphoneChoices.SMARTPHONE_CONSULATION,
      title: "Smartphone Beratung",
    },
    {
      identifier: SmartphoneChoices.MOBILE_SECURITY,
      title: "Mobile Datensicherheit",
    },
  ],
};

type CustomerRequirementsSmartphoneProps = {
  trackingContext: string;
};

const CustomerRequirementsSmartphone = ({
  trackingContext,
}: CustomerRequirementsSmartphoneProps) => {
  const { customerRequirementsStore } = useStores();

  return (
    <div className={`${styles.smartphoneConsultationContainer}`}>
      <CustomerRequirementsSectionHeader
        link={componentLinks.smartphoneConsultation}
        sectionTitle="Smartphone"
        customClass={styles.linkTextWidth}
        showInfoIcon={false}
      />
      <div className={styles.smartphoneConsultationItemsContainer}>
        {smartphoneConsultationContentMap.items.map((item) => (
          <ToggleBtn
            id={`smartphoneChoices${item.identifier}`}
            inputTestId={`smartphoneChoices${item.identifier}`}
            checked={customerRequirementsStore.requirements.smartphoneChoices.includes(
              item.identifier
            )}
            onChange={() => {
              trackClick(
                `smartphoneChoices.${item.identifier}`,
                trackingContext
              );
              customerRequirementsStore.setSmartphoneConsultation(
                item.identifier
              );
            }}
            title={item.title}
            key={`smartphoneChoices${item.identifier}`}
            customTileClass={styles.smartphoneConsultationTile}
            customTitleClass={styles.smartphoneConsultationTitle}
            placeItems="start"
          />
        ))}
        <CustomerRequirementsRecommendation
          description={
            customerRequirementsStore.recommendations.smartphoneChoices
          }
          customClass={styles.smartphoneConsultationRecommendationPosition}
        />
      </div>
    </div>
  );
};
export default observer(CustomerRequirementsSmartphone);
