export interface IDiscount {
  kind: PromotionPriceType;
  value: number | null;
  interval: number | null;
  description?: string | null;
  from?: number | null;
  to?: number | null;
}

export interface IDiscountExtended extends IDiscount {
  applyBeforeFrameworkContract: boolean;
}

export enum PromotionPriceType {
  // For monthly type of prices
  MONTHLY = "monthly",
  MONTHLY_DISCOUNT = "monthly_discount",
  MONTHLY_DISCOUNT_IN_PERCENT = "monthly_discount_in_percent",
  PERMANENT_MONTHLY_DISCOUNT = "permanent_monthly_discount", // Currently is not used
  // For provision prices ( relevant for tariffs, and maybe for extras )
  PROVISION_DISCOUNT = "provision_discount",
  PROVISION_DISCOUNT_IN_PERCENT = "provision_discount_in_percent",
  // For once type of prices
  DISCOUNT = "discount",
  DISCOUNT_IN_PERCENT = "discount_in_percent",
  // Others
  JUST_A_NUMBER = "just_a_number",
  CASHBACK = "cashback",
  CUSTOM_PROMOTION = "custom_promotion", // TODO remove or keep for legacy components
}
