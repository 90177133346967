import type { IProductsRepository } from "core/repositories/ProductsRepository/IProductsRepository";
import { IPostCustomPromotion } from "./IPostCustomPromotion";
import { IPromotion } from "../../../entities/PencilSelling/IPromotion";

export class PostCustomPromotionUseCase implements IPostCustomPromotion {
  constructor(private readonly repository: IProductsRepository) {}

  async execute(customPromotion: IPromotion): Promise<IPromotion> {
    const result = await this.repository.postCustomPromotion(customPromotion);
    return result;
  }
}
