// Calculate various monthly prices depending on the active promotions
// As of right now, all tariffs can noly have two monthly promotions
import { IDiscount, IDiscountExtended, PromotionPriceType } from "core/entities/Product/IDiscount";
import type { IPromotion } from "core/entities/PencilSelling/IPromotion";
import { ICartItemData, PaymentTypes } from "../core/entities/PencilSelling/CartItem/ICartItem";
import { formatNumberWithTrimTrailingZeros, getFormattedFloat } from "./NumberHelpers";
import { IYoungDiscount } from "../core/entities/Product/IYoungDiscount";
import { IBusinessCasesTypeValues } from "../core/entities/Product/Tariff/ITariff";

/**
 * Interface representing the start price of a promotion.
 * @property {number} price - The starting value of a promotion
 * @property {number } from - The starting month of a promotion
 * @property {number}    to - The ending month of a promotion
 */
export interface IPromotionStartPrice {
  price: number | null;
  to: number | null;
  from: number | null;
}

export interface ICartItemStepPricesData {
  from: number | null;
  price: number | null;
}

export interface ICartItemStepPricesDescriptions {
  description: string;
}

export type IMonthlyPriceStep = ICartItemStepPricesData &
  ICartItemStepPricesDescriptions;

export interface IMonthlyIntervalItem {
  month: number;
  value: number;
  applyBeforeFrameworkContract?: boolean;
}

/**
 * Interface that describes the ProductMonthlyPrices class.
 * @property {IPromotion} monthlyPromotions - The monthly promotions of a product
 * @property {number } monthlyPrice - The monthly price of a product
 * @property {number} averageMonthlyPrice - The average monthly price of a product
 * @property {number} originalPrice - The original price of a product
 * @property {number} contractPeriod - The contract period of a product
 * @property {ICartItemStepPricesData[]} monthlyPriceSteps - The contract period of a product
 * @property {string[]} monthlyPriceStepsDescriptions - The contract period of a product
 * @property {number} cartItemQuantity - cart item quantity
 * @property {number} isTariffOrCard - defines is current cart item a Card or a Tariff
 */
export interface IProductMonthlyPrices {
  monthlyPromotions: IPromotion[];
  monthlyPrice: number | null;
  originalPrice: number;
  contractPeriod: number;
  monthlyPriceSteps: ICartItemStepPricesData[];
  monthlyPriceStepsDescriptions: string[];
  computeMonthlyExpenses(
    promotions: IPromotion[],
    youngDiscount: IYoungDiscount
  ): void;
  cartItemQuantity: number;
  paymentType: PaymentTypes;
  updatedProductContractPeriod: number;
  baseMonthlyIntervalItems: IMonthlyIntervalItem[];
  frameworkDiscountPercentageValue: number | null;
  monthlyPriceBeforeFrameworkDiscount: number | null;
  monthlyPriceBeforeFrameworkDiscountExist: boolean;
}

export default class ProductMonthlyPrices implements IProductMonthlyPrices {
  monthlyPrice: number;

  originalPrice: number;

  monthlyPromotions: IPromotion[];

  contractPeriod: number;

  cartItemQuantity = 1;

  monthlyPriceSteps: ICartItemStepPricesData[];

  monthlyPriceStepsDescriptions: string[];

  paymentType: PaymentTypes;

  updatedProductContractPeriod: number;

  baseMonthlyIntervalItems: IMonthlyIntervalItem[];

  frameworkDiscountPercentageValue: number | null;

  monthlyPriceBeforeFrameworkDiscount: number | null;

  monthlyPriceBeforeFrameworkDiscountExist: boolean;

  constructor(
    item: ICartItemData,
    businessCase: IBusinessCasesTypeValues | null
  ) {
    this.cartItemQuantity = item.quantity;
    this.monthlyPrice =
      item.paymentType === PaymentTypes.MONTHLY ? item.price.monthly : null;
    this.originalPrice = item.price.originalPrice;
    this.contractPeriod = item.contractPeriod;
    this.paymentType = item.paymentType;
    this.updatedProductContractPeriod = 1;
    this.baseMonthlyIntervalItems = [];
    this.frameworkDiscountPercentageValue =
      typeof item.price.frameworkDiscountPercentageValue === "number"
        ? item.price.frameworkDiscountPercentageValue * 100
        : 0;
    this.monthlyPriceBeforeFrameworkDiscount =
      item.price.monthlyPriceBeforeFrameworkDiscount;
    this.monthlyPriceBeforeFrameworkDiscountExist =
      typeof item.price.monthlyPriceBeforeFrameworkDiscount === "number";
    const youngDiscount =
      typeof item.price.youngDiscount?.price === "number" &&
      item.price.youngDiscount?.availableForBusinessCases.includes(businessCase)
        ? item.price.youngDiscount
        : null;
    this.computeMonthlyExpenses(item.promotions, youngDiscount);
  }

  /**
   * @description - createMonthlyIntervalItemsArr creates monthly instances that are represented by month value and price for each interval monthly step
   */

  static createMonthlyIntervalItemsArr(
    from: number,
    to: number,
    value: number,
    applyBeforeFrameworkContract = false
  ): IMonthlyIntervalItem[] {
    const result: IMonthlyIntervalItem[] = [];
    let counter = from;

    while (counter <= to) {
      result.push({
        value,
        applyBeforeFrameworkContract,
        month: counter,
      });

      counter += 1;
    }

    return result;
  }

  // * 100 operation occurs to prevent following result on sum operation: 0.1 + 0.2 = 0.3000000...04
  static getAverageMonthlyPrice(
    monthlyIntervalItems: IMonthlyIntervalItem[],
    interval: number
  ) {
    const result = monthlyIntervalItems.reduce(
      (acc, monthlyInterval) => (acc * 100 + monthlyInterval.value * 100) / 100,
      0
    );
    return getFormattedFloat(result / interval);
  }

  /**
   * @description - createMonthlyPriceSteps creates monthly price steps data items.
   * Each step represents the price change for certain period specified by 'from' field.
   */

  static createMonthlyPriceSteps(monthlyIntervalItems: IMonthlyIntervalItem[]) {
    return monthlyIntervalItems.reduce((acc, monthlyInterval) => {
      const lastPriceStep = acc[acc.length - 1];

      if (lastPriceStep?.price !== monthlyInterval.value) {
        return [
          ...acc,
          {
            price: monthlyInterval.value,
            from: monthlyInterval.month,
            description: ProductMonthlyPrices.priceIntervalText(
              monthlyInterval.month,
              monthlyInterval.value
            ),
          },
        ];
      }

      return acc;
    }, [] as IMonthlyPriceStep[]);
  }

  static calculateReducedByPercentageValue = (baseValue, percentageValue = 0) =>
    baseValue * (1 - percentageValue / 100);

  getPromotionCalculationData(
    baseMonthlyIntervalValue: number,
    isApplyBeforeFrameworkContract: boolean
  ) {
    const applyMonthlyPriceBeforeFrameworkDiscount =
      isApplyBeforeFrameworkContract &&
      this.monthlyPriceBeforeFrameworkDiscountExist;
    const currentValue = applyMonthlyPriceBeforeFrameworkDiscount
      ? this.monthlyPriceBeforeFrameworkDiscount
      : baseMonthlyIntervalValue;

    return {
      applyMonthlyPriceBeforeFrameworkDiscount,
      currentValue,
    };
  }

  updateProductContractPeriod(
    monthlyPercentagePromotions: IPromotion[],
    monthlyPromotions: IPromotion[],
    monthlyDiscountPromotions: IPromotion[]
  ) {
    this.updatedProductContractPeriod =
      ProductMonthlyPrices.getPromotionsItemsDiscounts([
        ...monthlyPercentagePromotions,
        ...monthlyPromotions,
        ...monthlyDiscountPromotions,
      ]).reduce(
        (acc, discount) => (discount.to > acc ? discount.to : acc),
        this.contractPeriod
      );
  }

  static getPromotionsItemsDiscounts = (
    promotions: IPromotion[]
  ): IDiscount[] => promotions.map((promotion) => promotion.discounts).flat();

  static getMonthlyIntervalsItemsDiscounts = (
    promotions: IPromotion[]
  ): IMonthlyIntervalItem[] => {
    const updatedDiscounts: IDiscountExtended[] = promotions
      .map((promotion) =>
        promotion.discounts.map((discount) => ({
          ...discount,
          applyBeforeFrameworkContract:
            "applyBeforeFrameworkContract" in promotion &&
            promotion.applyBeforeFrameworkContract,
        }))
      )
      .flat();

    return updatedDiscounts
      .map((discount) =>
        ProductMonthlyPrices.createMonthlyIntervalItemsArr(
          discount.from,
          discount.to,
          discount.value,
          discount.applyBeforeFrameworkContract
        )
      )
      .flat();
  };

  static mapMonthlyIntervals = (
    targetMonthlyIntervalsArr: IMonthlyIntervalItem[],
    monthlyIntervalItems: IMonthlyIntervalItem[]
  ) =>
    targetMonthlyIntervalsArr.map((monthlyIntervalItem) => {
      const updatedPrice = monthlyIntervalItems.reduce(
        (acc, { value, month }) =>
          monthlyIntervalItem.month === month && acc > value ? value : acc,
        monthlyIntervalItem.value
      );

      return {
        ...monthlyIntervalItem,
        value: updatedPrice,
      };
    });

  calculateMonthlyPercentagePromotions(
    monthlyPercentagePromotions: IPromotion[]
  ) {
    const monthlyPercentagePromotionsIntervalItems =
      ProductMonthlyPrices.getMonthlyIntervalsItemsDiscounts(
        monthlyPercentagePromotions
      );

    //  Update price
    this.baseMonthlyIntervalItems = this.baseMonthlyIntervalItems.map(
      (monthlyIntervalItem) => {
        const updatedPrice = monthlyPercentagePromotionsIntervalItems.reduce(
          (acc, { value, month, applyBeforeFrameworkContract }) => {
            const { applyMonthlyPriceBeforeFrameworkDiscount, currentValue } =
              this.getPromotionCalculationData(
                acc,
                applyBeforeFrameworkContract
              );
            let priceReducedByPercent =
              ProductMonthlyPrices.calculateReducedByPercentageValue(
                currentValue,
                value
              );

            if (applyMonthlyPriceBeforeFrameworkDiscount) {
              priceReducedByPercent =
                ProductMonthlyPrices.calculateReducedByPercentageValue(
                  priceReducedByPercent,
                  this.frameworkDiscountPercentageValue
                );
            }

            return monthlyIntervalItem.month === month &&
              acc > priceReducedByPercent
              ? priceReducedByPercent
              : acc;
          },
          monthlyIntervalItem.value
        );

        return {
          ...monthlyIntervalItem,
          value: updatedPrice,
        };
      }
    );
  }

  calculateMonthlyPromotions(monthlyPromotions: IPromotion[]) {
    const monthlyPromotionsIntervalItems =
      ProductMonthlyPrices.getMonthlyIntervalsItemsDiscounts(monthlyPromotions);

    this.baseMonthlyIntervalItems = ProductMonthlyPrices.mapMonthlyIntervals(
      this.baseMonthlyIntervalItems,
      monthlyPromotionsIntervalItems
    );
  }

  calculateMonthDiscountPromotions(monthlyDiscountPromotions: IPromotion[]) {
    const monthlyDiscountPromotionsIntervalItems =
      ProductMonthlyPrices.getMonthlyIntervalsItemsDiscounts(
        monthlyDiscountPromotions
      );

    //  Update price
    this.baseMonthlyIntervalItems = this.baseMonthlyIntervalItems.map(
      (monthlyIntervalItem) => {
        const updatedPrice = monthlyDiscountPromotionsIntervalItems.reduce(
          (acc, { value, month, applyBeforeFrameworkContract }) => {
            const { applyMonthlyPriceBeforeFrameworkDiscount, currentValue } =
              this.getPromotionCalculationData(
                acc,
                applyBeforeFrameworkContract
              );
            let reducedValue =
              currentValue - value < 0 ? 0 : currentValue - value;

            if (reducedValue > 0 && applyMonthlyPriceBeforeFrameworkDiscount) {
              reducedValue =
                ProductMonthlyPrices.calculateReducedByPercentageValue(
                  reducedValue,
                  this.frameworkDiscountPercentageValue
                );
            }

            return monthlyIntervalItem.month === month ? reducedValue : acc;
          },
          monthlyIntervalItem.value
        );

        return {
          ...monthlyIntervalItem,
          value: updatedPrice,
        };
      }
    );
  }

  getFinalPriceStep(targetPrice: number): IMonthlyPriceStep {
    const interval = this.updatedProductContractPeriod + 1;
    const price = targetPrice * this.cartItemQuantity;
    return {
      price,
      from: interval,
      description: ProductMonthlyPrices.priceIntervalText(interval, price),
    };
  }

  /**
   * @param promotions - The list of promotions for the product
   * @param youngDiscount - object which contains the information about discount that could be applied to some part of product contract period
   * @description - This method splits current cart item contract period in baseMonthlyIntervalItems. Each item represents once month with specified value and the price that customer should pay in this month.
   * If tariff is young and it has youngDiscount object -> from the business logic perspective we treat is as a MONTHLY promotion ->
   * -> so we just replace regular monthly price in baseMonthlyIntervalItems with the price value of youngDiscount.
   * When baseMonthlyIntervalItems are created. We check whether we have attached promotions ( MONTHLY_DISCOUNT, MONTHLY, MONTHLY_DISCOUNT_PERCENTAGE ).
   * If promotions exists we split their interval into monthlyIntervalItems.
   * Then we apply promotions monthlyIntervalItems to baseMonthlyIntervalItems.
   * After all monthly items price were updated we use cart item quantity multiplier to get correct prices.
   */

  computeMonthlyExpenses(
    promotions: IPromotion[],
    youngDiscount: IYoungDiscount | null
  ) {
    if (this.paymentType !== PaymentTypes.MONTHLY) {
      this.monthlyPriceSteps = [{ from: 1, price: this.monthlyPrice }];
      this.monthlyPriceStepsDescriptions = [];
      return;
    }
    const getPromotionsByType = (type: PromotionPriceType) =>
      promotions?.filter((promotion) => promotion.kind === type) || [];

    const monthlyPercentagePromotions = getPromotionsByType(
      PromotionPriceType.MONTHLY_DISCOUNT_IN_PERCENT
    );
    const monthlyPromotions = getPromotionsByType(PromotionPriceType.MONTHLY);

    const monthlyDiscountPromotions = [
      ...getPromotionsByType(PromotionPriceType.MONTHLY_DISCOUNT),
    ];
    this.updateProductContractPeriod(
      monthlyPercentagePromotions,
      monthlyPromotions,
      monthlyDiscountPromotions
    );

    this.baseMonthlyIntervalItems =
      ProductMonthlyPrices.createMonthlyIntervalItemsArr(
        1,
        this.updatedProductContractPeriod,
        this.monthlyPrice
      );

    if (youngDiscount) {
      const productYoungPriceStep =
        ProductMonthlyPrices.createMonthlyIntervalItemsArr(
          youngDiscount.from,
          youngDiscount.to,
          youngDiscount.price
        );

      this.baseMonthlyIntervalItems = ProductMonthlyPrices.mapMonthlyIntervals(
        this.baseMonthlyIntervalItems,
        productYoungPriceStep
      );
    }

    // Order of applying of promotions is important and should not be changed without discussion with the customer/product manager
    if (monthlyPercentagePromotions.length) {
      this.calculateMonthlyPercentagePromotions(monthlyPercentagePromotions);
    }

    if (monthlyPromotions.length) {
      this.calculateMonthlyPromotions(monthlyPromotions);
    }

    if (monthlyDiscountPromotions.length) {
      this.calculateMonthDiscountPromotions(monthlyDiscountPromotions);
    }

    // Update monthly interval prices with cart item quantity
    if (this.cartItemQuantity > 1) {
      this.baseMonthlyIntervalItems = this.baseMonthlyIntervalItems.map(
        (monthlyIntervalItem) => ({
          ...monthlyIntervalItem,
          value: monthlyIntervalItem.value * this.cartItemQuantity,
        })
      );
    }

    //  Create price steps
    let monthlyPriceStepsData = ProductMonthlyPrices.createMonthlyPriceSteps(
      this.baseMonthlyIntervalItems
    );

    // Display the regular monthly price if whole contract period price was affected by promotions
    if (
      monthlyPriceStepsData[monthlyPriceStepsData.length - 1].price !==
        this.monthlyPrice * this.cartItemQuantity &&
      typeof this.originalPrice !== "number"
    ) {
      monthlyPriceStepsData = [
        ...monthlyPriceStepsData,
        this.getFinalPriceStep(this.monthlyPrice),
      ];
    }

    if (typeof this.originalPrice === "number") {
      monthlyPriceStepsData = [
        ...monthlyPriceStepsData,
        this.getFinalPriceStep(this.originalPrice),
      ];
    }
    this.monthlyPriceSteps = monthlyPriceStepsData.map(
      ({ description, ...priceStepData }) => priceStepData
    );
    this.monthlyPriceStepsDescriptions = monthlyPriceStepsData
      .map(
        (priceStepData) => priceStepData.description
        // We don't need the description of the first step. Because we display it in as a main price in Summary
      )
      .slice(1);
  }

  /**
   * @returns {string} - A string that represents the price after the contract period with formatted text.
   */

  static priceIntervalText(interval: number, price: number) {
    return `Ab dem ${interval}. Monat ${formatNumberWithTrimTrailingZeros(
      price
    )} €`;
  }

  static priceAverageText(averagePriceValue: number) {
    return `rechnerischer 2-Jahres-Preis ${formatNumberWithTrimTrailingZeros(
      averagePriceValue
    )} <sup>2</sup> €`;
  }
}
