import React, { FunctionComponent, SVGAttributes } from "react";
import { Button, CopyText, CopyTextVariants } from "@dtpk-cc/components";
import * as styles from "./toggle-btn.module.scss";

type IconProps = {
  Icon: FunctionComponent<SVGAttributes<SVGElement>> | null;
  iconWrapperClass: string | null;
};

type ToggleBtnProps = {
  title: string;
  checked: boolean;
  iconConfig?: IconProps;
  onChange: (value: boolean) => void;
  customTileClass?: string;
  id: string;
  inputTestId: string;
  customTitleClass?: string;
  disabled?: boolean;
  copyTextVariant?: CopyTextVariants;
  placeItems?: "center" | "start";
};

export const ToggleBtn = ({
  title = "",
  iconConfig = { Icon: null, iconWrapperClass: null },
  checked = false,
  onChange = () => {},
  id,
  inputTestId,
  disabled = false,
  customTitleClass = "",
  customTileClass = "",
  copyTextVariant = CopyText.Variant.highlight,
  placeItems = "center",
}: ToggleBtnProps) => (
  <Button
    variants={Button.Variant.bare}
    onClick={() => onChange(!checked)}
    id={`${id}-label`}
    className={`${customTileClass} ${disabled ? styles.disabled : ""}
     ${styles.toggleBtn} ${checked ? styles.active : ""}`}
    data-testid={inputTestId}
  >
    {iconConfig.Icon && iconConfig.iconWrapperClass ? (
      <div className={`${iconConfig.Icon ? styles.iconWithText : ""}`}>
        <iconConfig.Icon
          className={iconConfig.iconWrapperClass}
          id={`${id}-icon`}
        />
        <CopyText
          customClass={`${customTitleClass} ${styles.title}`}
          variants={copyTextVariant}
        >
          {title}
        </CopyText>
      </div>
    ) : (
      <CopyText
        customClass={`${customTitleClass} ${styles.title} ${styles[placeItems]}`}
        variants={copyTextVariant}
      >
        {title}
      </CopyText>
    )}
  </Button>
);
