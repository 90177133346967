export enum UseCaseVariants {
  HOMEOFFICE = "homeoffice",
  VIDOE_TELEPHONY = "video_telephony",
  GAMING = "gaming",
}

export enum StreamingServiceVariants {
  MAGENTA_HD_TV = "magenta_hd_tv",
  APPLE_TV = "apple_tv",
  NETFLIX = "netflix",
  DISNEY_PLUS = "disney_plus",
  RTL_PLUS = "rtl_plus",
  DAZN = "dazn",
  PRIME_VIDEO = "prime_video",
  YOUTUBE_PREMIUM = "youtube_premium",
  WOW = "wow",
  MAGENTA_SPORT = "magenta_sport",
  MAGENTA_MUSIC_STREAM = "magenta_music_stream",
}

export type HouseholdSize = {
  adults: string;
  children: string;
};

export type StreamingConfig = {
  activeStreamingServices: StreamingServiceVariants[];
  newMagentaTv: boolean;
  resolutionActive: boolean;
  televisionNumber: string;
};

export interface ICustomerRequirementsPC {
  useCases: UseCaseVariants[];
  household: HouseholdSize;
  streaming: StreamingConfig;
  isYoung: boolean;
  newSmartphoneDesired: boolean;
}
