export enum TariffWorldKeys {
  zuhause_sofort = "zuhause_sofort_landline_tariff_world_definition",
  magenta_home = "magenta_home",
  magenta_home_tv = "magenta_home_tv",
  magenta_mobile_one = "magenta_mobile_one",
  magenta_mobile = "magenta_mobile",
  magenta_mobile_special = "magenta_mobile_special",
  magenta_mobile_data = "magenta_mobile_data",
  magenta_mobile_prepaid = "magenta_mobile_prepaid",
  fiber_home_tv = "glasfaser_mit_magentatv_landline_tariff_world_definition",
  smart_connect = "smart_connect",
  business_mobil_3 = "business_mobil_3_generation_mobile_tariff_world_definition",
  business_mobil_4 = "business_mobil_4_generation_mobile_tariff_world_definition",
  business_mobil_5 = "business_mobil_5_generation_mobile_tariff_world_definition",
  business_card_3 = "business_card_3_generation_mobile_tariff_world_definition",
  business_card_5 = "business_card_5_generation_mobile_tariff_world_definition",
  business_mobil_eins = "business_mobil_eins_mobile_tariff_world_definition",
  company_start_mobile = "business_mobil_eins_mobile_tariff_world_definition",
  company_start_landline = "company_start_landline_tariff_world_definition",
  business_card_gen_4 = "business_card_50_mobile_tariff_world_definition",
}

// TODO: This should be fetched dynamically from backend
export enum CardWorldKeys {
  // ------ // legacy, exists only in MagentaRechner
  magenta_mobile_family_card_kids_cards = "magenta_mobile_family_card_kids_cards",
  magenta_mobile_family_card_teens_cards = "magenta_mobile_family_card_teens_cards",
  magenta_mobile_one_community_teens_card = "magenta_mobile_one_community_teens_card",
  magenta_mobile_one_community_card = "magenta_mobile_one_community_card",
  magenta_mobile_combi_card_data = "magenta_mobile_combi_card_data",
  magenta_mobile_one_data_card = "magenta_mobile_one_data_card",
  magenta_mobile_one_connect_card = "magenta_mobile_one_connect_card",
  combi_card_data_comfort = "combi_card_data_comfort",
  combi_card_mobile_data = "combi_card_mobile_data",
  magenta_mobile_multi_sim = "magenta_mobile_multi_sim",
  magenta_mobile_family_card_cards = "magenta_mobile_family_card_cards",
  // ------ //
  magenta_mobile_next_card_main = "magenta_mobile_next_card_main",
  magenta_mobile_next_card_secondary = "magenta_mobile_next_card_secondary",
  magenta_mobile_next_card_kids_cards = "magenta_mobile_next_card_kids_cards",
  magenta_mobile_next_card_data_cards = "magenta_mobile_next_card_data_cards",
  magenta_mobile_data_sim = "magenta_mobile_data_sim",
  magenta_mobile_combi_card_smart_connect = "magenta_mobile_combi_card_smart_connect",
}

export enum ProductGroupKeys {
  data_product_group = "data_product_group",
  digital_home_services_group = "digital_home_services_group",
  magenta_cloud_landline = "magenta_cloud_landline",
  magenta_cloud_mobile = "magenta_cloud_mobile",
  magenta_smart_home_product_group = "magenta_smart_home_product_group",
  magenta_tv_flex = "magenta_tv_flex",
  magenta_tv_ott = "magenta_tv_ott",
  mail_landline = "mail_landline",
  mail_mobile = "mail_mobile",
  netflix_mobile = "netflix_mobile",
  security_package_landline = "security_package_landline",
  security_package_mobile = "security_package_mobile",
  speed_home_wlan = "speed_home_wlan",
  tv_options_dazn = "tv_options_dazn",
  tv_options_dazn_mobile = "tv_options_dazn_mobile",
  tv_options_disney_landline = "tv_options_disney_landline",
  tv_options_netflix_landline = "tv_options_netflix_landline",
  disney_mobile = "disney_mobile",
}

export enum LandlinePromotionConditions {
  broadband_prospect = "Breitband-Neukunde",
  landline_prospect = "Festnetz-Neukunde",
  triple_play_client = "MZ mit MagentaTV (TP)",
  double_play_client = "MagentaZuhause (DP)",
  complete_changer = "Wechsel vom Wettbewerber",
}

export enum MobilePromotionConditions {
  new_mobile_contract = "Neuvertrag",
  mobile_contract_extension = "Vertragsverlängerung",
}
