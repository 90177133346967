import React, { ChangeEvent, FormEvent, Fragment, useCallback } from "react";
import { observer } from "mobx-react";
import {
  Grid,
  GridColProps,
  Tile,
  Heading,
  Button,
  Icon,
} from "@dtpk-cc/components";
import ActionRemoveDefault from "@dtpk-cc/components/dist/icons/Action/Remove/Default";
import { ICustomPromotionsForm } from "../IPromotionSelection";
import { PortfolioKeys } from "../../../../core/entities/Product/IProduct";
import Input from "../../../../elements/new-design/Input";
import {
  onlyNumber,
  preventDecimal,
} from "../../../../helpers/NumericCalculation";
import CustomPromotionsActionBlock from "../CustomPromotionsActionBlock";
import DropdownMain from "../../../../elements/new-design/DropdownMain";
import { trackClick } from "../../../../helpers/reactTracking";

import * as styles from "./custom-promotions-form.module.scss";
import { convertPriceStringToNumber } from "../../../../helpers/NumberHelpers";
import {
  CUSTOM_PROMOTION_INTERVAL_RANGE,
  CUSTOM_PROMOTION_PRICE_RANGE,
} from "../../../../constants/const";
import { IPromotion } from "../../../../core/entities/PencilSelling/IPromotion";
import { PromotionPriceType } from "../../../../core/entities/Product/IDiscount";

type CustomPromotionsFormProps = {
  formState: ICustomPromotionsForm;
  defaultFormConfig: ICustomPromotionsForm;
  portfolioKey: PortfolioKeys;
  setFormState: React.Dispatch<React.SetStateAction<ICustomPromotionsForm>>;
  availablePromotions: IPromotion[];
  isAddMode: boolean;
  setIsFormVisible: React.Dispatch<React.SetStateAction<boolean>>;
  setDeleteConfirmModalIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  trackingContext: string;
  onAdd: (customPromotion: IPromotion) => void;
  onEdit: (customPromotion: IPromotion) => void;
};

type CustomPromotionRange = {
  min: number;
  max: number;
};

type FormTypeConfigMapProps = {
  [key in PromotionPriceType]?: {
    components: {
      [key: string]: React.JSX.Element;
    };
    formConfigData: {
      [key: string]: string | null;
    };
    validationHandler: () => boolean;
  };
};

const promotionTypesMap: {
  [key in PromotionPriceType]?: string;
} = {
  [PromotionPriceType.MONTHLY_DISCOUNT]: "Monatliche Preisreduktion",
  [PromotionPriceType.PROVISION_DISCOUNT]: "Einmalige Preisreduktion",
  [PromotionPriceType.JUST_A_NUMBER]: "Aktion ohne Preis",
  [PromotionPriceType.CASHBACK]: "Cashback Aktion",
};

const CustomPromotionsForm = ({
  formState,
  setFormState,
  portfolioKey,
  availablePromotions,
  isAddMode,
  setIsFormVisible,
  setDeleteConfirmModalIsOpen,
  trackingContext,
  onAdd,
  onEdit,
  defaultFormConfig,
}: CustomPromotionsFormProps) => {
  const inputChangeHandler = (event: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setFormState((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const numberIsInRange = (num: number, range: CustomPromotionRange) =>
    num > range.min && num <= range.max;

  const getInvalidRangeMessage = (range: CustomPromotionRange) => `
    Muss größer als ${range.min} und kleiner als ${range.max} sein
  `;

  const getInputComponentTemplate = ({
    customClass = "",
    placeholder = "",
    name = "",
    value = "",
    isNumberType = false,
    isValid = true,
    invalidMessage = "",
    required = false,
    width = {
      m: 12,
      l: 12,
      xl: 24,
    },
    decimalIsPrevent = false,
  }) => (
    <Grid.Col
      customClass={customClass}
      m={width.m as GridColProps["m"]}
      l={width.l as GridColProps["l"]}
      xl={width.xl as GridColProps["xl"]}
    >
      <Input
        id={name}
        placeholder={placeholder}
        invalidMessage={invalidMessage}
        requiredMessage={isValid ? invalidMessage : null}
        isValid={isValid}
        name={name}
        value={value}
        required={required}
        onChange={(event) => inputChangeHandler(event)}
        onKeyPress={(e) => {
          if (isNumberType) {
            onlyNumber(e);
          }
          if (decimalIsPrevent) {
            preventDecimal(e);
          }
        }}
      />
    </Grid.Col>
  );

  const convertPromotionItem = (): IPromotion => {
    const from = formState.from ? parseInt(formState.from, 10) : null;
    const to = formState.to ? parseInt(formState.to, 10) : null;
    const interval = from && to ? to - from + 1 : null;

    return {
      key: formState.key,
      portfolio: portfolioKey,
      name: formState.name,
      description: formState.name,
      kind: formState.kind,
      isEditable: true,
      isCustom: true,
      conditions: [],
      discounts: [
        {
          interval,
          from,
          to,
          value: formState.value
            ? convertPriceStringToNumber(formState.value)
            : null,
          kind: formState.kind,
        },
      ],
    };
  };

  const validateNameForUnique = () =>
    !availablePromotions.some(
      // key !== formState.key check statement for Edit mode
      ({ name, key }) => name === formState.name && key !== formState.key
    );

  const priceInputDefaultPayload = {
    placeholder: "Preis",
    name: "value",
    value: formState.value || "",
    isNumberType: true,
    required: true,
    isValid:
      formState.value === null ||
      numberIsInRange(
        convertPriceStringToNumber(formState.value),
        CUSTOM_PROMOTION_PRICE_RANGE
      ),
    invalidMessage: getInvalidRangeMessage(CUSTOM_PROMOTION_PRICE_RANGE),
  };

  const formTypeConfigMap: FormTypeConfigMapProps = {
    [PromotionPriceType.MONTHLY_DISCOUNT]: {
      components: {
        value: getInputComponentTemplate({
          ...priceInputDefaultPayload,
          customClass: "m-b-12",
        }),
        from: getInputComponentTemplate({
          customClass: "p-r-12",
          placeholder: "Gültig ab",
          name: "from",
          value: formState.from || "",
          isNumberType: true,
          decimalIsPrevent: true,
          required: true,
          isValid:
            formState.from === null ||
            numberIsInRange(
              parseInt(formState?.from, 10),
              CUSTOM_PROMOTION_INTERVAL_RANGE
            ),
          invalidMessage: getInvalidRangeMessage(
            CUSTOM_PROMOTION_INTERVAL_RANGE
          ),
          width: {
            m: 6,
            l: 6,
            xl: 12,
          },
        }),
        to: getInputComponentTemplate({
          placeholder: "Gültig bis",
          name: "to",
          value: formState.to || "",
          isNumberType: true,
          decimalIsPrevent: true,
          required: true,
          isValid:
            formState.to === null ||
            (numberIsInRange(
              parseInt(formState.to, 10),
              CUSTOM_PROMOTION_INTERVAL_RANGE
            ) &&
              parseInt(formState.to, 10) >= parseInt(formState.from, 10)),
          invalidMessage:
            parseInt(formState.to, 10) < parseInt(formState.from, 10)
              ? "Muss größer oder gleich „Gültig ab“ sein"
              : getInvalidRangeMessage(CUSTOM_PROMOTION_INTERVAL_RANGE),
          width: {
            m: 6,
            l: 6,
            xl: 12,
          },
        }),
      },
      formConfigData: {
        value: null,
        interval: null,
        from: null,
        to: null,
      },
      validationHandler: () =>
        formState.name &&
        formState.kind &&
        numberIsInRange(
          convertPriceStringToNumber(formState.value),
          CUSTOM_PROMOTION_PRICE_RANGE
        ) &&
        numberIsInRange(
          parseInt(formState.from, 10),
          CUSTOM_PROMOTION_INTERVAL_RANGE
        ) &&
        numberIsInRange(
          parseInt(formState.to, 10),
          CUSTOM_PROMOTION_INTERVAL_RANGE
        ) &&
        validateNameForUnique(),
    },
    [PromotionPriceType.PROVISION_DISCOUNT]: {
      components: {
        value: getInputComponentTemplate({
          ...priceInputDefaultPayload,
        }),
      },
      formConfigData: {
        value: null,
        interval: null,
        from: null,
        to: null,
      },
      validationHandler: () =>
        formState.name &&
        formState.kind &&
        numberIsInRange(
          convertPriceStringToNumber(formState.value),
          CUSTOM_PROMOTION_PRICE_RANGE
        ) &&
        validateNameForUnique(),
    },
    [PromotionPriceType.JUST_A_NUMBER]: {
      components: {},
      formConfigData: {
        value: null,
        interval: null,
        from: null,
        to: null,
      },
      validationHandler: () =>
        formState.name && formState.kind && validateNameForUnique(),
    },
    [PromotionPriceType.CASHBACK]: {
      components: {
        value: getInputComponentTemplate({
          customClass: "m-b-12",
          ...priceInputDefaultPayload,
        }),
      },
      formConfigData: {
        value: null,
        interval: null,
        from: null,
        to: null,
      },
      validationHandler: () =>
        formState.name &&
        formState.kind &&
        numberIsInRange(
          convertPriceStringToNumber(formState.value),
          CUSTOM_PROMOTION_PRICE_RANGE
        ) &&
        validateNameForUnique(),
    },
  };

  const actionBtnIsDisabled = useCallback(
    (customValidationHandler: () => boolean) => {
      if (customValidationHandler) {
        const formIsValid = customValidationHandler();
        return !formIsValid;
      }
      return true;
    },
    []
  );

  const promotionTypeChangeHandler = (displayName) => {
    // Search for custom promotion kind by selected dropdown value
    const promotionKindKey = Object.keys(promotionTypesMap).find(
      (key) => promotionTypesMap[key] === displayName
    ) as PromotionPriceType;

    setFormState((prev) => ({
      ...prev,
      ...(formTypeConfigMap[promotionKindKey]
        .formConfigData as Partial<ICustomPromotionsForm>),
      name: prev.name,
      key: prev.key,
      kind: promotionKindKey,
    }));
  };

  const submitHandler = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const promotionItemToSubmit = convertPromotionItem();
    if (isAddMode) {
      onAdd(promotionItemToSubmit);
    } else {
      onEdit(promotionItemToSubmit);
    }
  };

  const formComponents = formTypeConfigMap[formState.kind]?.components || {};

  return (
    <Tile variant="shadow">
      <form onSubmit={submitHandler}>
        <div className={`m-b-24 ${styles.header}`}>
          <Heading
            customClass="m-b-0"
            variants={[Heading.Variant.quaternary, Heading.Variant.highlight]}
          >
            {isAddMode
              ? "Eigene Aktion hinzufügen"
              : "Eigene Aktion bearbeiten"}
          </Heading>
          {!isAddMode && (
            <Button
              variants={Button.Variant.bare}
              onClick={() => {
                setIsFormVisible(false);
                setDeleteConfirmModalIsOpen(true);
                trackClick(
                  "öffnen-sie-den-löschbestätigungsdialog",
                  trackingContext
                );
              }}
            >
              <Icon size={Icon.Size.small} icon={ActionRemoveDefault} />
            </Button>
          )}
        </div>
        <Grid>
          <Grid.Row customClass="m-l-0 m-r-0 m-b-12">
            <Grid.Col m={12} l={12} xl={24}>
              <Input
                id="name"
                name="name"
                placeholder="Aktionsname"
                invalidMessage={`${formState.name} existiert bereits`}
                value={formState.name}
                isValid={validateNameForUnique()}
                onChange={inputChangeHandler}
                requiredMessage="Dieses Feld ist erforderlich"
                required
              />
            </Grid.Col>
          </Grid.Row>
          <Grid.Row customClass="m-l-0 m-r-0 m-b-12">
            <Grid.Col m={12} l={12} xl={24}>
              <DropdownMain
                label="Wähle die Aktionsart"
                items={Object.values(promotionTypesMap)}
                onSelection={promotionTypeChangeHandler}
                value={promotionTypesMap[formState.kind]}
                invalidMessage="Dieses Feld ist erforderlich"
                required
              />
            </Grid.Col>
          </Grid.Row>
        </Grid>
        <Grid.Row
          customClass={`m-l-0 m-r-0 ${
            Object.entries(formComponents).length ? "m-b-26" : "m-b-12"
          }`}
        >
          {Object.entries(formComponents).map(([key, formComponent], index) => (
            <Fragment key={`${key}${index}`}>{formComponent}</Fragment>
          ))}
        </Grid.Row>
        <CustomPromotionsActionBlock
          isAddMode={isAddMode}
          submitBtnIsDisabled={actionBtnIsDisabled(
            formTypeConfigMap[formState.kind]?.validationHandler
          )}
          cancelBtnHandler={() => {
            setIsFormVisible(false);
            setFormState(defaultFormConfig);
            trackClick("benutzerdefiniertes-werbeformular-schließen");
          }}
        />
      </form>
    </Tile>
  );
};

export default observer(CustomPromotionsForm);
