import { PortfolioKeys } from "../../Product/IProduct";
import { ICartConfigurationType } from "../Configurations/IConfigurations";
import {
  BusinessCasesLandline,
  BusinessCasesMobile,
} from "../../Product/Tariff/ITariff";
import { IBusinessCasesTypes } from "./IBaseOffer";
import { ICustomPromotionSections } from "../ICustomPromotion";

export const initialCustomPromotions: ICustomPromotionSections = {
  [PortfolioKeys.MOBILE]: [],
  [PortfolioKeys.LANDLINE]: [],
};

export const initialBusinessCases: IBusinessCasesTypes = {
  [ICartConfigurationType.MOBILE]: BusinessCasesMobile.NEUVERTRAG,
  [ICartConfigurationType.LANDLINE]: BusinessCasesLandline.NEUKUNDE,
  [ICartConfigurationType.CARD]: BusinessCasesMobile.NEUVERTRAG,
};
