import React from "react";
import NextMagentaHeart from "../../../../../../../assets/images/icons/pencil-selling-tool/tariff-selection/next-magenta-heart.png";
import * as styles from "./tariff-selection-next-magenta-headline.module.scss";

type TariffSelectionNextMagentaHeadlineProps = {
  isActive: boolean;
};

const TariffSelectionNextMagentaHeadline = ({
  isActive,
}: TariffSelectionNextMagentaHeadlineProps) => (
  <div
    className={`${styles.nextMagentaHeadlineContainer} ${
      isActive ? styles.active : ""
    }`}
  >
    <h4 className={styles.nextMagentaLabel}>PlusKarte</h4>
    <img
      src={NextMagentaHeart as string}
      alt="NextMagentaHerz"
      className={`${styles.nextMagentaHeart} m-l-8 m-r-6`}
    />
    <h4 className={styles.nextMagentaLabel}>Unlimited</h4>
  </div>
);

export default TariffSelectionNextMagentaHeadline;
