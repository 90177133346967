import React, { ChangeEvent } from "react";
import { Heading } from "@dtpk-cc/components";
import { observer } from "mobx-react";
import TextArea from "../../../../elements/new-design/TextArea";
import { trackClick } from "../../../../helpers/reactTracking";
import * as styles from "./user-note.module.scss";
import { BaseOffer } from "../../../../core/entities/PencilSelling/BaseOffer/BaseOffer";

type UserNoteProps = {
  offerStore: BaseOffer;
  trackingContext: string;
  customClass?: string;
};

const UserNote = ({
  trackingContext,
  offerStore,
  customClass = "",
}: UserNoteProps) => {
  const customer = offerStore.customer.get();

  const handleCustomerChange = (value: string, key: string) => {
    offerStore.customer.set({
      ...customer,
      [key]: value,
    });
  };

  return (
    <div className={`${styles.textAreaWrapper} ${customClass}`}>
      <Heading
        customClass="m-b-24"
        tag="h3"
        variants={[Heading.Variant.tertiary, Heading.Variant.highlight]}
        data-qa="quality"
      >
        Anmerkungen
      </Heading>
      <TextArea
        customClass={styles.textAreaContent}
        text={customer.notice}
        handleChange={(event: ChangeEvent<HTMLTextAreaElement>) =>
          handleCustomerChange(event.currentTarget.value, "notice")
        }
        onClick={() => trackClick("anmerkungen", trackingContext)}
        label="Notizen"
      />
    </div>
  );
};

export default observer(UserNote);
