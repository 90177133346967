import React, { useEffect, useState, useRef, ChangeEvent } from "react";
import * as styles from "./text-area.module.scss";
import { getHtmlFontSize } from "../../../helpers/GetHtmlFontSize";

type TextAreaProps = {
  text: string;
  placeholder?: string;
  label: string;
  customClass?: string;
  handleChange: (event: ChangeEvent<HTMLTextAreaElement>) => void;
  id?: string;
  labelId?: string;
  onClick: () => void;
};

const TextArea = ({
  customClass = "",
  handleChange = () => {},
  id = "wish-area",
  labelId = "area-label",
  placeholder = "",
  text,
  label,
  onClick,
}: TextAreaProps) => {
  const textArea = useRef<HTMLTextAreaElement>(null);
  const [hasFocus, setHasFocus] = useState(false);
  const [isLabelTop, setIsLabelTop] = useState(!!text);
  const autoGrow = () => {
    const htmlCurrentFontSize = getHtmlFontSize();
    textArea.current.style.height = `${60 / htmlCurrentFontSize}rem`;
    textArea.current.style.height = `${
      textArea.current.scrollHeight / htmlCurrentFontSize
    }rem`;
  };

  const handleOnchange = (event: ChangeEvent<HTMLTextAreaElement>) => {
    handleChange(event);
  };

  useEffect(() => {
    autoGrow();
    setIsLabelTop(!!text || hasFocus);
  }, [text, hasFocus]);

  return (
    <div className={styles.container}>
      <label
        className={`
          ${styles.label}
          ${isLabelTop ? styles.top : ""}
        `}
        htmlFor={id}
        id={labelId}
      >
        {label}
      </label>
      <textarea
        ref={textArea}
        id={id}
        data-role="textbox"
        value={text}
        placeholder={placeholder}
        className={`
          ${styles.content}
          ${customClass}
        `}
        onChange={(event) => handleOnchange(event)}
        onFocus={() => setHasFocus(true)}
        onBlur={() => setHasFocus(false)}
        aria-labelledby={labelId}
        onClick={onClick}
      />
    </div>
  );
};

export default TextArea;
