import { ITariffWorld, ILevel } from "core/entities/Product/IProduct";
import { CardLimits, ICard } from "core/entities/Product/Card/ICard";
import { ICartItemData } from "core/entities/PencilSelling/CartItem/ICartItem";

type SettingKeys = "tariffWorld" | "cardWorld" | "level";
type ISettings = Record<SettingKeys, string>;
type CardSectionKeys =
  | "name"
  | "key"
  | "cards"
  | "size"
  | "dataVolume"
  | "dataVolumeMagentaOne"
  | "limit";
export type CardSection = Record<CardSectionKeys, string | ICard[] | number>;

const UNLIMITED_TEXT = "unbegrenztes";
const DOUBLE_TEXT = "doppeltes";
export class CardsPresenter {
  private filterSettings: ISettings = {
    tariffWorld: "",
    cardWorld: "",
    level: "",
  };

  tariffWorld = "";

  cardsInViewport = 4;

  constructor(private cards: ICard[], mobileTariffWorld?: string) {
    this.tariffWorld = mobileTariffWorld;
  }

  get(): ICard[] {
    return this.cards.filter((card) =>
      card.availableFor.some((key) => key === this.tariffWorld)
    );
  }

  getInheritingCardSection(inheritingCard: boolean): CardSection[] {
    const cards = this.get();
    const filteredCards = cards.filter(
      (card) => card.inheritingCard === inheritingCard
    );

    return CardsPresenter.getCardSectionWithSizing(filteredCards);
  }

  getSettings(): ISettings {
    return this.filterSettings;
  }

  static isLimited(
    cardSection: CardSection,
    cardsInCart: ICartItemData[]
  ): boolean {
    const cardsByTariffWorld = cardsInCart.filter(
      (card) => card?.tariffWorld?.key === cardSection.key
    );

    const cardLimitation =
      cardsByTariffWorld.length >= (cardSection.limit as number);

    const totalCardLimitation = cardsInCart.length >= CardLimits.TOTAL;

    return cardLimitation || totalCardLimitation;
  }

  static getCardSummary(
    summaryText: string,
    dataVolume: string,
    dataVolumeMagentaOne: string,
    dataVolumeNextMagenta: string,
    benefitIsActive: boolean,
    nextMagentaIsActive?: boolean
  ): string {
    // NOTE: We have cards that have dynamic text and cards without dynamic text
    let dataVolumeSummary = benefitIsActive
      ? `mit ${dataVolumeMagentaOne} statt ${dataVolume} Datenvolumen`
      : `mit ${dataVolumeMagentaOne} Datenvolumen`;

    if (nextMagentaIsActive && dataVolumeNextMagenta) {
      dataVolumeSummary = `mit ${dataVolumeNextMagenta} Datenvolumen`;
    }

    return CardsPresenter.interpolateDescriptionSummaryWithParams(
      summaryText,
      dataVolumeSummary
    );
  }

  static getCardDescription(
    descriptionText: string,
    size: string,
    dataVolume: string,
    dataVolumeMagentaOne: string,
    dataVolumeNextMagenta: string,
    benefitIsActive: boolean,
    nextMagentaIsActive?: boolean
  ): string {
    let dataVolumeText = benefitIsActive
      ? `mit ${dataVolumeMagentaOne} statt ${dataVolume} Datenvolumen (MagentaEINS Vorteil)`
      : `mit ${dataVolume} Datenvolumen (MagentaEINS Kunden erhalten ${
          dataVolumeMagentaOne || dataVolume
        })`;

    if (!dataVolumeMagentaOne) {
      dataVolumeText = `mit ${dataVolume} Datenvolumen`;
    }

    if (nextMagentaIsActive && dataVolumeNextMagenta) {
      dataVolumeText = `mit ${dataVolumeNextMagenta} Datenvolumen`;
    }

    return CardsPresenter.interpolateDescriptionTextWithParams(
      descriptionText,
      size,
      dataVolumeText
    );
  }

  static getStaticCardSummary(
    summaryText: string,
    dataVolume: string,
    dataVolumeMagentaOne: string,
    benefitIsActive: boolean
  ): string {
    const dataVolumeText =
      benefitIsActive && dataVolumeMagentaOne
        ? `mit ${dataVolumeMagentaOne} statt ${dataVolume} Datenvolumen (MagentaEINS Vorteil)`
        : `mit ${dataVolume} Datenvolumen (MagentaEINS Kunden erhalten ${dataVolume})`;

    return CardsPresenter.interpolateDescriptionSummaryWithParams(
      summaryText,
      dataVolumeText
    );
  }

  static getStaticCardDescription(
    descriptionText: string,
    dataVolume: string,
    dataVolumeMagentaOne: string,
    benefitIsActive: boolean
  ): string {
    const dataVolumeText =
      benefitIsActive && dataVolumeMagentaOne
        ? `mit ${dataVolumeMagentaOne} statt ${dataVolume} Datenvolumen (MagentaEINS Vorteil)`
        : `mit ${dataVolume} Datenvolumen (MagentaEINS Kunden erhalten ${
            dataVolumeMagentaOne || dataVolume
          })`;

    return CardsPresenter.interpolateDescriptionTextWithParams(
      descriptionText,
      null,
      dataVolumeText
    );
  }

  /* eslint-disable @typescript-eslint/no-implied-eval */
  /* eslint-disable @typescript-eslint/no-unsafe-return */
  private static interpolateDescriptionSummaryWithParams(
    descriptionText: string,
    dataVolumeSummary: string
  ): string {
    return new Function(
      "descriptionText",
      "dataVolumeSummary",
      `return \`${descriptionText}\`;`
    )(descriptionText, dataVolumeSummary);
  }

  private static interpolateDescriptionTextWithParams(
    descriptionText: string,
    size: string,
    dataVolumeText: string
  ): string {
    return new Function(
      "descriptionText",
      "size",
      "dataVolumeText",
      `return \`${descriptionText}\`;`
    )(descriptionText, size, dataVolumeText);
  }

  private static getCardSectionWithSizing(cards: ICard[]): CardSection[] {
    return cards.reduce(
      (acc, { tariffWorld, size, dataVolume, dataVolumeMagentaOne }) => {
        const tariffWorldWithSizingExists = acc.some(
          (accEntry) =>
            tariffWorld.key === accEntry.key && size === accEntry.size
        );

        if (tariffWorldWithSizingExists) return acc;

        const filteredCardsByTariffWorldAndSize = cards.filter(
          (card) =>
            card.tariffWorld.key === tariffWorld.key && card.size === size
        );
        const cardSection = {
          name: tariffWorld.name,
          key: tariffWorld.key,
          size,
          limit: tariffWorld.limit,
          dataVolume,
          dataVolumeMagentaOne,
          cards: filteredCardsByTariffWorldAndSize,
        };

        return [...acc, cardSection];
      },
      [] as CardSection[]
    );
  }

  // TODO check for possible remove
  get cardWorlds(): ITariffWorld[] {
    return this.cards.reduce((acc, { tariffWorld, availableFor }) => {
      const cardHasSelectedTariffWorld = availableFor.some(
        (key) => this.filterSettings.tariffWorld === key
      );

      const skipCard =
        (this.filterSettings.tariffWorld !== "" &&
          !cardHasSelectedTariffWorld) ||
        acc.find(({ key }) => key === tariffWorld.key);

      return skipCard ? acc : [...acc, tariffWorld];
    }, [] as ITariffWorld[]);
  }

  // eslint-disable-next-line class-methods-use-this
  distinctLevels(cards: ICard[]): ILevel[] {
    return cards.reduce((acc: ILevel[], { level }) => {
      const skipCard = acc.find(({ key }) => key === level.key);

      return skipCard ? acc : [...acc, level];
    }, [] as ILevel[]);
  }

  get maximalScrollValue() {
    return this.get().length - this.cardsInViewport + 1;
  }
}
