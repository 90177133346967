import React from "react";
import { observer } from "mobx-react";
import { ICartItemData } from "../../../../../core/entities/PencilSelling/CartItem/ICartItem";
import {
  IBusinessCasesTypeValues,
  ITariff,
} from "../../../../../core/entities/Product/Tariff/ITariff";
import { IStepTrackingContext } from "../../../../../views/PencilSelling/IPencilSelling";
import {
  ILevel,
  ITariffWorld,
  PortfolioKeys,
} from "../../../../../core/entities/Product/IProduct";
import TariffConfigurationWrapper from "../TariffConfigurationWrapper";
import TariffConfigurationWorldsLevels from "../TariffConfigurationWorldsLevels";
import TariffConfigurationPromotions from "../TariffConfigurationPromotions";
import { ADDONS_DISPLAY_AMOUNT } from "../const";
import TariffConfigurationNote from "../TariffConfigurationNote";
import { ILandlineSettings } from "../../../../../core/entities/PencilSelling/Configurations/IConfigurations";
import LandlineTariffFilter from "../LandlineTariffFilter";
import { ConfigurationsEntity } from "../../../../../core/entities/PencilSelling/Configurations/Configurations";
import { ProductsRepository } from "../../../../../core/repositories/ProductsRepository/ProductsRepository";
import { BaseOffer } from "../../../../../core/entities/PencilSelling/BaseOffer/BaseOffer";
import TariffConfigurationMonthlyPriceSteps from "../TariffConfigurationMonthlyPriceSteps";
import { CustomerType } from "../../../../../core/entities/PencilSelling/Customer/ICustomer";
import TariffConfigurationLink from "../TariffConfigurationLink";
import { IPromotion } from "../../../../../core/entities/PencilSelling/IPromotion";

type LandlineTariffConfigurationProps = {
  activeTariffInCart: ICartItemData | undefined;
  activeTariff: ITariff | null;
  trackingContext: IStepTrackingContext;
  tariffWorlds: ITariffWorld[];
  levels: ILevel[];
  onSettingsChange: (
    key: keyof ILandlineSettings | null,
    value: ILandlineSettings[keyof ILandlineSettings] | null,
    settingsObj?: ILandlineSettings
  ) => void;
  setActiveLevelIndex: (value: number) => void;
  activeLevelIndex: number;
  activeLevelExists: boolean;
  settings: ILandlineSettings;
  selectedPromotions: IPromotion[];
  addPromotionsHandler: (promotions: IPromotion[]) => void;
  businessCase: IBusinessCasesTypeValues;
  businessCaseName: string;
  configurationsStore: ConfigurationsEntity;
  customPromotions?: IPromotion[];
  withCustomPromotions?: boolean;
  productsRepositoryStore: ProductsRepository;
  offerStore: BaseOffer;
  applyLandlineFilter: (filterSettings: Partial<ILandlineSettings>) => void;
  withMultipleOnceMonthlyPromotions: boolean;
};

const LandlineTariffConfiguration = ({
  trackingContext,
  tariffWorlds,
  levels,
  onSettingsChange,
  settings,
  setActiveLevelIndex,
  selectedPromotions,
  addPromotionsHandler,
  activeTariffInCart,
  activeTariff,
  businessCase,
  businessCaseName,
  activeLevelIndex,
  activeLevelExists,
  configurationsStore,
  offerStore,
  productsRepositoryStore,
  applyLandlineFilter,
  customPromotions = [],
  withCustomPromotions = false,
  withMultipleOnceMonthlyPromotions = true,
}: LandlineTariffConfigurationProps) => {
  const { customerType } = offerStore.customer.get();

  const levelBtnTextMap = {
    [CustomerType.PRIVATE]: "MAGENTA TV-TARIF AUSWÄHLEN",
    [CustomerType.BUSINESS]: "Substufe auswählen",
    [CustomerType.EMPLOYEE]: "Substufe auswählen",
  };

  return (
    <TariffConfigurationWrapper>
      <TariffConfigurationWorldsLevels
        onChange={onSettingsChange}
        trackingContext={trackingContext}
        tariffWorlds={tariffWorlds}
        levels={levels}
        activeLevelExists={activeLevelExists}
        settings={settings}
        activeLevelIndex={activeLevelIndex}
        setActiveLevelIndex={setActiveLevelIndex}
        levelBtnText={levelBtnTextMap[customerType]}
      />
      <TariffConfigurationPromotions
        customPromotions={customPromotions}
        addPromotionsHandler={addPromotionsHandler}
        selectedPromotions={selectedPromotions}
        maxItemsDisplayAmount={ADDONS_DISPLAY_AMOUNT}
        businessCaseName={businessCaseName}
        businessCase={businessCase}
        tariffInCart={activeTariffInCart}
        activeTariff={activeTariff}
        trackingContext={trackingContext}
        withCustomPromotions={withCustomPromotions}
        offerStore={offerStore}
        productsRepositoryStore={productsRepositoryStore}
        portfolioKey={PortfolioKeys.LANDLINE}
        withMultipleOnceMonthlyPromotions={withMultipleOnceMonthlyPromotions}
      />
      <LandlineTariffFilter
        settings={settings}
        applyLandlineFilter={applyLandlineFilter}
      />
      <TariffConfigurationLink
        linkValue="https://ebs07.telekom.de/cet/front/slideseleven/ausbaukarte"
        linkTitle="Ausbaukarte"
      />
      <TariffConfigurationNote
        configurationsStore={configurationsStore}
        trackingContext={trackingContext}
      />
      {activeTariffInCart && (
        <TariffConfigurationMonthlyPriceSteps
          businessCase={businessCase}
          activeTariffInCart={activeTariffInCart}
          settings={settings}
          configurationsStore={configurationsStore}
        />
      )}
    </TariffConfigurationWrapper>
  );
};

export default observer(LandlineTariffConfiguration);
