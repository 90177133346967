import { useCallback, useMemo, useState } from "react";
import { IAdditionalDevices } from "../../core/entities/PencilSelling/CartItem/ICartItem";
import { PortfolioKeys } from "../../core/entities/Product/IProduct";
import { ITariff } from "../../core/entities/Product/Tariff/ITariff";
import {
  getTariffDataForCart,
  tariffProductDescriptionMap,
} from "../../helpers/TariffSelection";
import { ICartConfigurationSettings } from "../../core/entities/PencilSelling/Configurations/IConfigurations";
import { ConfigurationsEntity } from "../../core/entities/PencilSelling/Configurations/Configurations";
import { ProductsEntity } from "../../core/entities/PencilSelling/Products/ProductsEntity";
import { BaseOffer } from "../../core/entities/PencilSelling/BaseOffer/BaseOffer";
import { TARIFF_ADDONS_RESET_DIALOG_DATA } from "../../components/new-design/Tariff/const";
import { getRelatedAddonsAreSelected } from "../../helpers/GetRelatedAddonsAreSelected";
import { IPromotion } from "../../core/entities/PencilSelling/IPromotion";

interface IWarningModalText {
  mainText: string;
  title: string;
}

export const useTariffSelection = <T extends ICartConfigurationSettings>(
  portfolio: PortfolioKeys,
  tariffs: ITariff[],
  configurationsStore: ConfigurationsEntity,
  productsStore: ProductsEntity,
  offerStore: BaseOffer
) => {
  const settings = configurationsStore.getActiveConfigurationSettings<T>();
  const isBenefitActive =
    configurationsStore.getActiveConfigurationBenefitIsActive();
  const tariffInCart = configurationsStore
    .getActiveConfigurationChildren()
    .getTariff(portfolio);
  const customPromotions = offerStore.getCustomPromotions();
  const [activeTariff, setActiveTariff] = useState(
    tariffs.find(({ key }) => tariffInCart?.key === key) || null
  );
  const [smartphoneInfos, setSmartphoneInfos] = useState(
    tariffInCart?.additionalDevices || []
  );
  const selectedPromotions = useMemo(
    () => tariffInCart?.promotions || [],
    [tariffInCart?.promotions]
  );
  const [warningModalCallback, setWarningModalCallback] = useState<() => void>(
    () => {}
  );
  const [warningModalIsOpen, setWarningModalIsOpen] = useState(false);
  const [warningModalText, setWarningModalText] = useState<IWarningModalText>({
    title: "",
    mainText: "",
  });

  const addToCart = useCallback(
    (
      tariff: ITariff,
      promotions: IPromotion[],
      additionalDevices: IAdditionalDevices[]
    ) => {
      if (tariff) {
        // TODO temporary hidden
        // const selectedTariffExtension =
        //   portfolio === PortfolioKeys.LANDLINE ? currentLandlineExtension : null;
        const benefitIsActive =
          configurationsStore.getActiveConfigurationBenefitIsActive();
        const isNextMagentaActive =
          configurationsStore.applyNextMagentaForConfiguration(
            configurationsStore.getActiveConfiguration().key,
            tariff
          );
        const tariffCartData = getTariffDataForCart(
          tariff,
          promotions,
          additionalDevices,
          portfolio,
          tariffProductDescriptionMap[portfolio],
          settings,
          benefitIsActive,
          isNextMagentaActive,
          getRelatedAddonsAreSelected(tariff, configurationsStore)
        );
        // tariffCartData.extension = selectedTariffExtension;
        configurationsStore
          .getActiveConfigurationChildren()
          .addToCart(tariffCartData);
      }
    },
    [portfolio, settings, configurationsStore]
  );

  const addPromotionsHandler = useCallback(
    (promotions: IPromotion[]) => {
      addToCart(activeTariff, promotions, smartphoneInfos);
    },
    [addToCart, activeTariff, smartphoneInfos]
  );

  const addSmartphonesHandler = useCallback(
    (smartphoneItems: IAdditionalDevices[]) => {
      addToCart(activeTariff, selectedPromotions, smartphoneItems);
    },
    [addToCart, activeTariff, selectedPromotions]
  );

  const dialogHandler = (
    callback: () => void,
    checkCallback: () => boolean,
    actionCallback: () => void,
    modalText: IWarningModalText
  ) => {
    if (checkCallback()) {
      callback();
      return;
    }
    setWarningModalCallback(() => () => {
      actionCallback();
      callback();
    });
    setWarningModalText(modalText);
    setWarningModalIsOpen(true);
  };

  const removeTariffAddonsDialogHandler = (
    callback: () => void,
    checkCallback: () => boolean = () => true,
    actionCallback: () => void = () => {}
  ) => {
    const tariffAddonsAreAdded = !configurationsStore
      .getActiveConfigurationChildren()
      .getOptions(portfolio).length;

    dialogHandler(
      callback,
      () => tariffAddonsAreAdded && checkCallback(),
      () => {
        configurationsStore
          .getActiveConfigurationChildren()
          .resetOptions(portfolio);
        actionCallback();
      },
      TARIFF_ADDONS_RESET_DIALOG_DATA
    );
  };

  return {
    productsStore,
    tariffs,
    settings,
    isBenefitActive,
    tariffInCart,
    activeTariff,
    setActiveTariff,
    smartphoneInfos,
    setSmartphoneInfos,
    selectedPromotions,
    addToCart,
    addPromotionsHandler,
    addSmartphonesHandler,
    warningModalCallback,
    setWarningModalCallback,
    warningModalIsOpen,
    setWarningModalIsOpen,
    removeTariffAddonsDialogHandler,
    customPromotions,
    warningModalText,
    setWarningModalText,
    dialogHandler,
  };
};
