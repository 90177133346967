import React from "react";
import { CopyText, Heading, List } from "@dtpk-cc/components";
import TariffSelectionNextMagentaHeadline from "components/new-design/Tariff/TariffSelection/TariffSelectionNextMagenta/TariffSelectionNextMagentaHeadline";
import { IDescriptionItemNextMagenta } from "core/entities/Product/IDescription";
import SummarySectionItemWrapper from "../SummarySectionItemWrapper";
import AdditionalCardsIcon from "../../../../../assets/images/icons/pencil-selling-tool/summary/additional-cards.svg";
import * as styles from "./summary-configuration-next-magenta.module.scss";

type SummaryConfigurationNextMagentaProps = {
  nextMagentaDescriptionItem: IDescriptionItemNextMagenta;
};

const SummaryConfigurationNextMagenta = ({
  nextMagentaDescriptionItem,
}: SummaryConfigurationNextMagentaProps) => {
  const sectionTitle = nextMagentaDescriptionItem.title ? (
    <Heading
      customClass={styles.optionsTitleText}
      variants={[Heading.Variant.quaternary, Heading.Variant.highlight]}
    >
      {nextMagentaDescriptionItem.title}
    </Heading>
  ) : (
    <TariffSelectionNextMagentaHeadline isActive />
  );

  return (
    <SummarySectionItemWrapper variant="borderedInSection">
      <SummarySectionItemWrapper.Main variant="paddedLeftInSection">
        <div className={`${styles.optionsTitle} m-b-6`}>
          <AdditionalCardsIcon className={styles.icon} />
          {sectionTitle}
        </div>
      </SummarySectionItemWrapper.Main>
      <SummarySectionItemWrapper.Monthly />
      <SummarySectionItemWrapper.Once />
      <SummarySectionItemWrapper.Main variant="paddedLeftInSection">
        <div className="m-l-36">
          {nextMagentaDescriptionItem.title && (
            <div className="m-b-6">
              <TariffSelectionNextMagentaHeadline isActive />
            </div>
          )}
          <CopyText customClass="m-b-6">
            <b>{nextMagentaDescriptionItem.subtitle}</b>
          </CopyText>
          <List>
            {nextMagentaDescriptionItem.usps.map((usp) => (
              <List.Item
                key={usp}
                dangerouslySetInnerHTML={{
                  __html: usp,
                }}
              />
            ))}
          </List>
        </div>
      </SummarySectionItemWrapper.Main>
      <SummarySectionItemWrapper.Monthly />
      <SummarySectionItemWrapper.Once />
    </SummarySectionItemWrapper>
  );
};

export default SummaryConfigurationNextMagenta;
