import React, { useEffect, useState } from "react";
import { observer } from "mobx-react";

import { trackClick, trackLink } from "helpers/reactTracking";
import {
  A11yText,
  Input,
  Container,
  Flex,
  Grid,
  RadioButton,
  Modal as LPGModal,
  Button,
  Heading,
  CopyText,
  Icon,
  EqualHeightElement,
  TextLink,
  Checkbox,
  Price,
} from "@dtpk-cc/components";
import ActionRemoveDefault from "@dtpk-cc/components/dist/icons/Action/Remove/Default";
import TariffEntry from "elements/TariffEntry";
import RangeSlider from "elements/RangeSlider";
import Modal from "components/Modal";

import "./tariff-portfolio.scss";
import { Bandwidths, ITariff } from "core/entities/Product/Tariff/ITariff";
import {
  MobilePromotionConditions,
  LandlinePromotionConditions,
} from "core/repositories/ProductsRepository/DefinitionKeys";
import { BENEFIT_VALUE } from "core/entities/MagentaNext/Tariff/Tariff";
import LoyaltyBonusPriceBadge from "components/LoyaltyBonusPriceBadge";
import { onlyNumber } from "helpers/NumericCalculation";
import {
  ILandlineSettings,
  IMobileSettings,
} from "core/entities/PencilSelling/Offer_legacy/IOffer";
import { IPromotion } from "core/entities/PencilSelling/IPromotion";
import {
  IAdditionalDevices,
  ICartItemData,
} from "core/entities/PencilSelling/CartItem/ICartItem";
import { TariffPresenter } from "core/presenter/PencilSelling/TariffPresenter";
import { CartItemSummaryPresenter } from "core/presenter/PencilSelling/CartItemSummaryPresenter";
import CustomToggleButton from "../../elements/CustomToggleButton";
import CartItem from "../../elements/CartItem";
import { useStores } from "../../stores";
import { IPrice } from "../../core/entities/Product/IPrice";
import PromotionsSection from "../PromotionList/PromotionsSection";
import { PortfolioKeys } from "../../core/entities/Product/IProduct";

export interface TariffPortfolioProps {
  activeTariff: ITariff;
  activeTariffInCart: ICartItemData | null;
  title: string;
  settings: ILandlineSettings | IMobileSettings;
  promotions: IPromotion[];
  selectedPromotions: IPromotion[];
  onSelectPromotions: (promotions: IPromotion[]) => void;
  onChangeSettings: (key: any, value: any, callBack?: () => void) => void;
  onChangeTariff: (tariff: ITariff) => void;
  onChangeAdditionalInfo: (value: string) => void;
  onChangeSmartphone?: (index: number, key: string, value: string) => void;
  onSmartphoneRemove?: (phoneItemIndex: number) => void;
  onSmartphoneAdd?: () => void;
  smartphoneInfos?: IAdditionalDevices[];
  showSmartphoneInfo?: boolean;
  additionalInfo: string;
  isLandline?: boolean;
  tariffPresenter: TariffPresenter;
  isBenefitActive: boolean;
  showTelefonflat?: boolean;
  showLoyaltyBonus?: boolean;
  onChangeStaticSettings?: (
    key: string,
    value: IMobileSettings[keyof IMobileSettings]
  ) => void;
}

const TariffPortfolio = observer(
  ({
    activeTariff,
    activeTariffInCart,
    title,
    settings,
    promotions,
    selectedPromotions,
    onSelectPromotions,
    onChangeSettings,
    onChangeTariff,
    onChangeAdditionalInfo,
    onChangeSmartphone = () => {},
    onSmartphoneRemove = () => {},
    onSmartphoneAdd = () => {},
    smartphoneInfos,
    showSmartphoneInfo = false,
    additionalInfo,
    isLandline = false,
    tariffPresenter,
    isBenefitActive,
    showTelefonflat = true,
    showLoyaltyBonus,
    onChangeStaticSettings = () => {},
  }: TariffPortfolioProps) => {
    const { cartStore, offerStore } = useStores();
    const { landlineSettings, mobileSettings } =
      offerStore.export().cartSummary;
    const trackingContext = "wunschtarif";
    const portfolio = isLandline ? "festnetz" : "mobilfunk";
    const filteredTariffs = tariffPresenter.get();

    const priceStepsDescriptions = activeTariffInCart
      ? new CartItemSummaryPresenter(
          activeTariffInCart,
          isLandline ? landlineSettings : mobileSettings,
          isLandline
            ? landlineSettings.businessCase
            : mobileSettings.businessCase
        ).monthlyPrice().monthlyPriceStepsDescriptions
      : null;

    const scrollPosition =
      tariffPresenter.isTariffExist(Bandwidths.Start) &&
      filteredTariffs.length > 4
        ? 2
        : 1;
    const { tariffWorlds, levels, activeLevelExists } = tariffPresenter;

    const [tariffScrollPosition, setTariffScrollPosition] =
      useState(scrollPosition);

    useEffect(() => {
      setTariffScrollPosition(scrollPosition);
    }, [scrollPosition, settings]);

    // store levelIndex so that on young change the selection stays the same
    const [activeLevelIndex, setActiveLevelIndex] = useState(
      tariffPresenter.getLevelIndex(settings?.level)
    );
    const [modalOpen, setModalOpen] = useState(false);
    const [highlightedPromotions, setHighlightedPromotions] =
      useState(selectedPromotions);

    const open = () => {
      setHighlightedPromotions(selectedPromotions);
      setModalOpen(true);
    };

    const [activeTariffWorldKey, setActiveWorldKey] = useState(
      settings.tariffWorld
    );

    // Usecase: If tariffWorld x does not exist within the current settings, switch to the first tariffWorld
    useEffect(() => {
      // Guard clause if zero products/tariffworlds are found
      if (tariffWorlds.length === 0) return;

      const tariffWorldExists = tariffWorlds.some(
        ({ key }) => key === settings.tariffWorld
      );
      if (!tariffWorldExists) {
        const defaultTariffWorldKey = tariffWorlds[0].key;
        onChangeSettings("tariffWorld", defaultTariffWorldKey);
      }
    }, [settings, tariffWorlds, onChangeSettings]);

    useEffect(() => {
      setActiveWorldKey(settings.tariffWorld);
    }, [settings.tariffWorld]);

    const sanitizedPrice = (price: number) => parseFloat(`${price}`);

    const tariffWorld = tariffWorlds.find(
      ({ key }) => settings.tariffWorld === key
    );

    const handleTariffClick = (tariff: ITariff) => {
      onChangeTariff(tariff);
    };

    const handlePromotionSubmit = () => {
      onSelectPromotions(highlightedPromotions);
      setModalOpen(false);
    };

    const handlePromotionSelection = (promotion: IPromotion) => {
      setHighlightedPromotions((prevPromotions) => {
        const promotionExists = prevPromotions.some(
          (prevPromotion) => prevPromotion.key === promotion.key
        );
        if (promotionExists) {
          // Update existing promotion by filtering it out from current state and adding new one down below
          return prevPromotions.filter(
            (prevPromotion) => prevPromotion.key !== promotion.key
          );
        }

        return [...prevPromotions, promotion];
      });
    };

    useEffect(() => {
      if (activeLevelIndex > 0) {
        setActiveLevelIndex((prev) => (levels[activeLevelIndex] ? prev : 0));
      }
    }, [levels, activeLevelIndex, isLandline]);

    useEffect(() => {
      if (!activeLevelExists && levels.length) {
        const defaultLevelKey = levels[0].key;
        onChangeSettings(
          "level",
          levels[activeLevelIndex]?.key || defaultLevelKey
        );
      }
    }, [
      onChangeSettings,
      settings,
      levels,
      activeLevelIndex,
      activeLevelExists,
    ]);

    const tariffLink = settings.isYoung
      ? tariffWorld?.links?.young
      : tariffWorld?.links?.default;

    const filteredTariffPromotionsByCurrentBusinessCase =
      activeTariff?.promotions?.filter((item) =>
        item.conditions.includes(settings.businessCase)
      ) || [];
    const allPromotions = [
      ...promotions,
      ...filteredTariffPromotionsByCurrentBusinessCase,
    ];

    const conditionName = isLandline
      ? LandlinePromotionConditions[settings.businessCase]
      : MobilePromotionConditions[settings.businessCase];

    const getTariffFeature = (tariff: ITariff) => {
      if (!isLandline) {
        const dataVolume = isBenefitActive
          ? tariff.dataVolumeMagentaOne || tariff.dataVolume
          : tariff.dataVolume;

        return {
          tariffFeature: dataVolume,
          isHighlighted: isBenefitActive && !!tariff.dataVolumeMagentaOne,
        };
      }

      const downloadSpeed = `${
        // probably better to rely on 'isLandline' since 'fiber' exists only in landline tariff ?
        "fiber" in settings && settings.fiber
          ? tariff.speedFiber?.download
          : tariff.speed?.download
      } MBit/s`;

      return { tariffFeature: downloadSpeed, isHighlighted: false };
    };

    const getTariffDescription = (tariff: ITariff) => {
      if (isLandline) {
        // Remove product description for specific product
        if (tariff.key === "magenta_home_xs") {
          return "";
        }
        if (Number(Bandwidths[tariff.size]) > 3) {
          return "Telefonie-Flat";
        }
        if (showTelefonflat && isBenefitActive) {
          return "Telefonie-Flat";
        }

        return "Festnetzflat";
      }
      return "";
    };

    const getTariffLinkDescription = () => tariffWorld.links.link_title;

    const tariffPrice = (tariff: ITariff) => {
      const rebateOption = tariff.benefits[0]?.options.find(
        (option) => option?.key === "discount"
      );

      const rebate = rebateOption?.value || 0;

      const price: number = isBenefitActive
        ? tariff.price.monthly - rebate
        : tariff.price.monthly;

      return sanitizedPrice(price);
    };

    const getTariffEntryPrice = (productPrice: IPrice) => {
      if (!productPrice) return 0;

      // seems like previously here might be an error
      // since we relied on 'settings.loyaltyBonusValue' every time but
      // it exists only in mobile settings
      const loyaltyBonusValue =
        "loyaltyBonusValue" in settings ? settings.loyaltyBonusValue : 0;

      const price =
        productPrice.monthly - (BENEFIT_VALUE + loyaltyBonusValue / 24);

      const provisionFee =
        "hasProvisionFee" in settings && settings.hasProvisionFee
          ? productPrice.provision / 24
          : 0;
      const priceWithFee = price + provisionFee;

      return priceWithFee || 0;
    };

    return (
      <div
        data-testid={`${
          isLandline ? "tariff-landline-section" : "tariff-mobile-section"
        }`}
        className="tariff-portfolio"
      >
        <Container customClass="tariff-portfolio__container">
          <Container customClass="tariff-portfolio__header-container">
            <Icon
              customClass="tariff-portfolio__header-container--icon"
              icon={tariffPresenter.icon}
              size={Icon.Size.xlarge}
              wrapperProps={{ style: { width: "auto" } }}
            />
            <Heading
              data-testid="tariff-section-heading"
              tag="h2"
              customClass="tariff-portfolio__header-container--title"
              variants={[
                Heading.Variant.tertiary,
                Heading.Variant.display,
                Heading.Variant.uppercase,
              ]}
            >
              {title}
            </Heading>
          </Container>
          <Container customClass="tariff-portfolio__toggle-section">
            <CopyText
              variants={settings.isYoung ? null : CopyText.Variant.highlight}
              customClass="tariff-portfolio__toggle-text tariff-portfolio__toggle-text--mr"
            >
              Standard Tarife
            </CopyText>
            <CustomToggleButton
              checked={settings.isYoung}
              onClick={() => {
                onChangeSettings("isYoung", !settings.isYoung);
                trackClick(`${portfolio}.young-toggle`, trackingContext);
              }}
            />
            <CopyText
              variants={settings.isYoung ? CopyText.Variant.highlight : null}
              customClass="tariff-portfolio__toggle-text tariff-portfolio__toggle-text--ml"
            >
              Young Tarife
            </CopyText>
          </Container>
          <EqualHeightElement name="tariffChoice">
            <EqualHeightElement name="tariffWorld">
              {tariffWorlds.length >= 1 && (
                <>
                  <CopyText
                    variants={[CopyText.Variant.highlight]}
                    customClass="tariff-portfolio__mb"
                  >
                    Tarifwelt auswählen
                  </CopyText>
                  <RadioButton.Group
                    customClass="high-contrast-support"
                    style={{ flexDirection: "row", flexWrap: "wrap" }}
                    variants={RadioButton.Group.Variant.block}
                    key={settings.tariffWorld}
                  >
                    <RadioButton.Legend a11y>Tarifwelt</RadioButton.Legend>
                    {tariffWorlds.map((world) => (
                      <Container
                        key={world.key}
                        customClass="tariff-portfolio__radio-field tariff-portfolio__mb"
                      >
                        <RadioButton
                          onChange={(event) => {
                            event.stopPropagation();
                            event.preventDefault();
                            onChangeSettings("tariffWorld", event.target.value);
                          }}
                          checked={activeTariffWorldKey === world.key}
                          key={world.name}
                          value={world.key}
                          onClick={() =>
                            trackClick(
                              `${portfolio}.${world.name}`,
                              trackingContext
                            )
                          }
                        >
                          {world.name}
                        </RadioButton>
                      </Container>
                    ))}
                  </RadioButton.Group>
                </>
              )}
            </EqualHeightElement>
            <EqualHeightElement name="tariff">
              {levels.length > 1 && (
                <>
                  <CopyText
                    variants={[CopyText.Variant.highlight]}
                    customClass="tariff-portfolio__mb tariff-portfolio__mt"
                  >
                    {tariffPresenter.tariffLevelDisplayText}
                  </CopyText>
                  <RadioButton.Group
                    style={{
                      alignItems: "center",
                      flexDirection: "row",
                      flexWrap: "wrap",
                      display: "flex",
                    }}
                    key={settings.tariffWorld + settings.level}
                    customClass="high-contrast-support tariff-portfolio__levels-radio-container"
                  >
                    <RadioButton.Legend a11y>
                      {tariffPresenter.tariffLevelDisplayText}
                    </RadioButton.Legend>
                    {levels.map((level, index) => (
                      <Grid.Col
                        key={`${level.key}${index}`}
                        style={{ width: "50%", whiteSpace: "nowrap" }}
                      >
                        <RadioButton
                          checked={level.key === settings.level}
                          value={level.key}
                          onChange={(event) => {
                            event.stopPropagation();
                            event.preventDefault();
                            onChangeSettings("level", event.target.value, () =>
                              setActiveLevelIndex(index)
                            );
                          }}
                          onClick={() =>
                            trackClick(
                              `${portfolio}.${level.name}`,
                              trackingContext
                            )
                          }
                        >
                          {level.name}
                        </RadioButton>
                      </Grid.Col>
                    ))}
                  </RadioButton.Group>
                </>
              )}
            </EqualHeightElement>
            <Grid.Row customClass="tariff-portfolio__text-link-row tariff-portfolio__mt">
              {tariffWorld && tariffLink && (
                <TextLink
                  customClass="tariff-portfolio__mt"
                  target="_blank"
                  href={tariffLink}
                  onClick={() => trackLink(tariffLink, trackingContext)}
                >
                  {getTariffLinkDescription()}
                </TextLink>
              )}
            </Grid.Row>
          </EqualHeightElement>
        </Container>

        <Grid.Row customClass="tariff-portfolio__tariff-entry-group">
          <Container
            customClass={`tariff-portfolio__tariff-entry-container tariff-portfolio__tariff-entry-container--${tariffScrollPosition}`}
          >
            {filteredTariffs.map((tariff, index) => (
              <Grid.Col
                customClass="tariff-portfolio__tariff-entry-col"
                key={tariff.key}
                s={3}
                m={3}
                l={3}
                xl={6}
              >
                <TariffEntry
                  isHybrid={tariff.key === "magenta_home_hybrid"} // special treatment for magenta_home_hybrid #1080
                  toggleActive={() => {
                    handleTariffClick(tariff);
                    trackClick(
                      `${portfolio}.tariff.${tariff.name}`,
                      trackingContext
                    );
                  }}
                  active={activeTariff?.key === tariff.key}
                  price={tariffPrice(tariff)}
                  legacyPrice={
                    isBenefitActive ? tariff.price.monthly : undefined
                  }
                  subtitle={getTariffFeature(tariff)}
                  description={getTariffDescription(tariff)}
                  highlightDescription={showTelefonflat}
                  displaySize={tariff.displaySize}
                  young={tariff.isYoung}
                  size={Bandwidths[tariff.size]}
                  name={tariff.name}
                  isBenefitActive={isBenefitActive}
                  trackingContext={trackingContext}
                  isLandline={isLandline}
                  index={index}
                  loyaltyBonusPriceBadgeComp={
                    <LoyaltyBonusPriceBadge
                      id="loyalty-bonus-price-badge"
                      loyaltyBonusPrice={getTariffEntryPrice(tariff?.price)}
                      disabled={
                        !(
                          ("loyaltyBonusValue" in settings &&
                            settings.loyaltyBonusValue) > 0 &&
                          isBenefitActive &&
                          showLoyaltyBonus
                        )
                      }
                    />
                  }
                />
              </Grid.Col>
            ))}
          </Container>
        </Grid.Row>
        <EqualHeightElement
          className="tariff-portfolio__slider"
          name="tariff-slider"
        >
          {filteredTariffs.length > tariffPresenter.tariffsInViewport && (
            <Grid.Row>
              <RangeSlider
                min={1}
                max={tariffPresenter.maximalTariffScrollValue}
                value={tariffScrollPosition}
                onChange={(value: number) => setTariffScrollPosition(value)}
              />
            </Grid.Row>
          )}
        </EqualHeightElement>

        <EqualHeightElement name="portfolio-inputs">
          {!!activeTariff && !!activeTariff.extensions.length && (
            <>
              <CopyText
                variants={[CopyText.Variant.highlight]}
                customClass="tariff-portfolio__mb"
              >
                Tariferweiterungen
              </CopyText>
              <RadioButton.Group
                style={{
                  alignItems: "center",
                  flexDirection: "row",
                  flexWrap: "wrap",
                  display: "flex",
                }}
                key={`${settings.tariffWorld} ${
                  cartStore.getCurrentLandlineTariffExtension()?.key
                }`}
                customClass="high-contrast-support tariff-portfolio__extensions"
              >
                <RadioButton.Legend a11y>Tariferweiterungen</RadioButton.Legend>
                {activeTariff.extensions.map((extension, index) => (
                  <Grid.Col
                    key={`${extension.key}${index}`}
                    style={{ width: "50%", whiteSpace: "nowrap" }}
                  >
                    <RadioButton
                      customClass="tariff-portfolio__extensions-item"
                      checked={
                        extension.key ===
                        cartStore.getCurrentLandlineTariffExtension()?.key
                      }
                      value={extension.key}
                      onChange={(event) => {
                        event.stopPropagation();
                        event.preventDefault();
                        cartStore.setLandlineTariffExtension(extension);
                      }}
                      onClick={() =>
                        trackClick(
                          `${portfolio}.${activeTariff.name}-${extension.name}`,
                          trackingContext
                        )
                      }
                    >
                      <span className="tariff-portfolio__extensions-item-name">
                        {extension.name}
                      </span>
                      {["monthly", "once"]
                        .filter(
                          (paymentType) =>
                            typeof extension.price[paymentType] === "number"
                        )
                        .map((paymentType) => (
                          <Price
                            key={paymentType}
                            variants={[Price.Variant.brand]}
                            value={extension.price[paymentType]}
                            suffix={
                              paymentType === "monthly" ? "mtl." : "einmalig"
                            }
                          />
                        ))}
                    </RadioButton>
                  </Grid.Col>
                ))}
              </RadioButton.Group>
            </>
          )}
          {/* Temporary hidden */}
          {/* {!isLandline && showLoyaltyBonus && "loyaltyBonusValue" in settings && ( */}
          {/*  <Grid.Row> */}
          {/*    <LoyaltyBonus */}
          {/*      trackingContext={trackingContext} */}
          {/*      value={settings.loyaltyBonusValue} */}
          {/*      disabled={!isBenefitActive} */}
          {/*      onChange={(value) => */}
          {/*        onChangeStaticSettings("loyaltyBonusValue", value) */}
          {/*      } */}
          {/*    /> */}
          {/*  </Grid.Row> */}
          {/* )} */}

          {showSmartphoneInfo && (
            <>
              {smartphoneInfos.map((phoneItem, index) => (
                <Grid.Row key={`phoneItem${index}`}>
                  <Flex
                    alignItems="center"
                    customClass="tariff-portfolio__input-wrapper"
                  >
                    <Input
                      id={`phone-name-${index}`}
                      customClass="tariff-portfolio__input tariff-portfolio__input--75 tariff-portfolio__input--medium"
                      value={phoneItem.name}
                      onChange={(e) =>
                        onChangeSmartphone(index, "name", e.target.value)
                      }
                    >
                      Smartphone
                    </Input>

                    <Input
                      id={`phone-price-${index}`}
                      customClass="tariff-portfolio__input tariff-portfolio__input--small tariff-portfolio__input--20"
                      value={phoneItem.price}
                      onChange={(e) =>
                        onChangeSmartphone(index, "price", e.target.value)
                      }
                      onKeyPress={(e) => onlyNumber(e)}
                    >
                      Kaufpreis
                    </Input>
                    <Button
                      data-testid={`remove-smartphone-inputs-block-${index}`}
                      customClass="tariff-portfolio__input--5 tariff-portfolio__input-remove-btn"
                      onClick={() => {
                        onSmartphoneRemove(index);
                      }}
                      variants={[Button.Variant.bare, Button.Variant.icon]}
                    >
                      <Button.Icon icon={ActionRemoveDefault} />
                      <A11yText>Smartphone-Element entfernen</A11yText>
                    </Button>
                  </Flex>
                </Grid.Row>
              ))}
              <Grid.Row>
                <Button
                  data-testid="add-smartphone-inputs-block"
                  customClass="tariff-portfolio__add-input-btn"
                  onClick={onSmartphoneAdd}
                  variants={Button.Variant.outline}
                >
                  Smartphone hinzufügen
                </Button>
              </Grid.Row>
            </>
          )}
        </EqualHeightElement>

        <Grid.Row>
          <Input
            id={`input-extras-${isLandline ? "-lanline" : "-mobile"}`}
            customClass="tariff-portfolio__input"
            value={additionalInfo}
            onChange={(e) => onChangeAdditionalInfo(e.target.value)}
            onClick={() =>
              trackClick(`${portfolio}.ergänzungen`, trackingContext)
            }
          >
            Ergänzungen
          </Input>
        </Grid.Row>

        <Grid.Row>
          <Button
            data-testid="open-promotions-modal"
            customClass="tariff-portfolio__button"
            // @ts-ignore
            variants={[Button.Variant.onlyIconUntilS]}
            onClick={open}
            disabled={!activeTariff}
          >
            {tariffPresenter.promotionText}
          </Button>
        </Grid.Row>

        {!isLandline && (
          <Grid.Row>
            <Checkbox
              onChange={(isActive) =>
                onChangeStaticSettings("hasProvisionFee", isActive)
              }
              checked={
                "hasProvisionFee" in settings && settings.hasProvisionFee
              }
              disabled={
                "loyaltyBonusValue" in settings &&
                settings.loyaltyBonusValue > 0
              }
            >
              Bereitstellungspreis berücksichtigen
            </Checkbox>
          </Grid.Row>
        )}

        {!!activeTariffInCart && (
          <>
            <div className="tariff-portfolio__cart-items-wrapper">
              <CartItem
                activeTariffInCart={activeTariffInCart}
                tariffSettings={settings}
                title="Haupttarif"
                selectedPromotions={selectedPromotions}
              />
            </div>
            {priceStepsDescriptions?.map((priceDescription) => (
              <Price.Text key={priceDescription}>{priceDescription}</Price.Text>
            ))}
          </>
        )}

        <Modal
          active={modalOpen}
          showSubmitButton
          close={() => setModalOpen(false)}
          handleSubmitButton={() => {
            handlePromotionSubmit();
            trackClick("aktions-modal-auswahl-übernehmen", trackingContext);
          }}
        >
          {conditionName && (
            <CopyText customClass="modal__copy">
              <b>Geschäftsfall:</b> {conditionName}
            </CopyText>
          )}
          <LPGModal.Heading variant={LPGModal.Heading.Variant.hint}>
            Aktionsangebote
          </LPGModal.Heading>

          <CopyText
            data-testid="promotion-modal-tariff-product-name"
            customClass="modal__copy"
          >
            Für <b>{activeTariff?.name}</b>
          </CopyText>
          <PromotionsSection
            portfolioKey={
              isLandline ? PortfolioKeys.LANDLINE : PortfolioKeys.MOBILE
            }
            promotions={allPromotions}
            productPrice={activeTariff?.price.monthly}
            highlightedPromotions={highlightedPromotions}
            setHighlightedPromotions={setHighlightedPromotions}
            setActivePromotions={onSelectPromotions}
            trackingContext={trackingContext}
            onSelect={handlePromotionSelection}
          />
        </Modal>
      </div>
    );
  }
);

export default TariffPortfolio;
