import React, { useCallback } from "react";
import {
  BusinessCasesLandline,
  ITariff,
} from "../../core/entities/Product/Tariff/ITariff";
import { ILandlineSettings } from "../../core/entities/PencilSelling/Configurations/IConfigurations";
import {
  getFilteredTariff,
  getTariffBySize,
} from "../../helpers/TariffSelection";
import { ConfigurationsEntity } from "../../core/entities/PencilSelling/Configurations/Configurations";
import { IAdditionalDevices } from "../../core/entities/PencilSelling/CartItem/ICartItem";
import { IPromotion } from "../../core/entities/PencilSelling/IPromotion";

type UseLandlineTariffSelectionProps = {
  landlineSettings: ILandlineSettings;
  businessCase: BusinessCasesLandline;
  configurationsStore: ConfigurationsEntity;
  landlineTariff: ITariff;
  setLandlineTariff: React.Dispatch<React.SetStateAction<ITariff>>;
  tariffs: ITariff[];
  isBenefitActive: boolean;
  addToCart: (
    tariff: ITariff,
    promotions: IPromotion[],
    additionalDevices: IAdditionalDevices[]
  ) => void;
  selectedLandlinePromotions: IPromotion[];
  removeTariffAddonsDialogHandler: (
    callback: () => void,
    checkCallback?: () => boolean,
    actionCallback?: () => void
  ) => void;
};

export const useLandlineTariffSelection = ({
  landlineSettings,
  businessCase,
  configurationsStore,
  landlineTariff,
  setLandlineTariff,
  tariffs,
  isBenefitActive,
  addToCart,
  selectedLandlinePromotions,
  removeTariffAddonsDialogHandler,
}: UseLandlineTariffSelectionProps) => {
  const setLandlineBenefitSettings = useCallback(
    (currentLandlineTariff: ITariff | null) => {
      configurationsStore.setActiveConfigurationBenefit(
        currentLandlineTariff?.benefits[0] || null
      );
    },
    [configurationsStore]
  );

  const toggleLandlineBenefit = useCallback(
    (tariff: ITariff | null) => {
      configurationsStore.setActiveConfigurationBenefitIsActive(
        !isBenefitActive
      );

      const IsBenefitActive =
        configurationsStore.getActiveConfigurationBenefitIsActive();
      if (IsBenefitActive) {
        setLandlineBenefitSettings(tariff);
      } else {
        setLandlineBenefitSettings(null);
      }
      addToCart(tariff, selectedLandlinePromotions, null);
    },
    [
      addToCart,
      selectedLandlinePromotions,
      configurationsStore,
      isBenefitActive,
      setLandlineBenefitSettings,
    ]
  );

  const handleLandlineTariffChange = useCallback(
    (tariff: ITariff | null, activeTariff: ITariff) => {
      const existsInCart = activeTariff?.key === tariff?.key;
      const [setterArg, effectFn] = existsInCart
        ? [
            null,
            () => {
              configurationsStore
                .getActiveConfigurationChildren()
                .removeTariffFromCart(true);
            },
          ]
        : [
            tariff,
            () => {
              addToCart(tariff, [], null);
            },
          ];
      effectFn();
      setLandlineTariff(setterArg);
      setLandlineBenefitSettings(setterArg);
    },
    [
      configurationsStore,
      addToCart,
      setLandlineTariff,
      setLandlineBenefitSettings,
    ]
  );

  const handleLandlineSettingsChange = useCallback(
    (
      key: keyof ILandlineSettings | null,
      value: ILandlineSettings[keyof ILandlineSettings] | null,
      settingsObj?: ILandlineSettings
    ) => {
      let tariff: ITariff = getFilteredTariff(
        tariffs,
        landlineTariff,
        {
          ...landlineSettings,
          ...(settingsObj || {}),
          ...(key && value ? { [key]: value } : {}),
        },
        true,
        businessCase
      );

      configurationsStore.setActiveConfigurationSettings<ILandlineSettings>({
        key,
        value,
        settingsObj,
      });

      if (!tariff) {
        configurationsStore
          .getActiveConfigurationChildren()
          .removeTariffFromCart(true);
        setLandlineTariff(null);
        setLandlineBenefitSettings(null);
      }

      if (key === "level" && landlineTariff) {
        tariff = getTariffBySize(
          value,
          tariffs,
          landlineTariff,
          landlineSettings.isYoung,
          landlineSettings.isVario
        );
        handleLandlineTariffChange(tariff, landlineTariff);
        return;
      }
      setLandlineTariff(tariff);
      setLandlineBenefitSettings(tariff);
    },
    [
      businessCase,
      configurationsStore,
      handleLandlineTariffChange,
      landlineSettings,
      landlineTariff,
      setLandlineBenefitSettings,
      setLandlineTariff,
      tariffs,
    ]
  );

  const getLandlineTariffFeature = (tariff: ITariff) => {
    const downloadSpeed = `${
      landlineSettings.fiber
        ? tariff.speedFiber?.download
        : tariff.speed?.download
    }`;

    return { tariffFeature: downloadSpeed, tariffFeatureUnit: "MBit/s" };
  };

  const applyLandlineFilter = (filterSettings: Partial<ILandlineSettings>) => {
    const updatedSettings = {
      ...landlineSettings,
      ...filterSettings,
    };

    if (!landlineTariff) {
      removeTariffAddonsDialogHandler(() => {
        handleLandlineSettingsChange(null, null, updatedSettings);
      });
      return;
    }

    const tariffIsExist = getFilteredTariff(
      tariffs,
      landlineTariff,
      updatedSettings,
      true,
      businessCase
    );

    if (!tariffIsExist) {
      removeTariffAddonsDialogHandler(() => {
        handleLandlineSettingsChange(null, null, updatedSettings);
      });
      return;
    }
    handleLandlineSettingsChange(null, null, updatedSettings);
  };

  return {
    toggleLandlineBenefit,
    handleLandlineTariffChange,
    handleLandlineSettingsChange,
    getLandlineTariffFeature,
    applyLandlineFilter,
  };
};
