import { useCallback } from "react";
import { useAddTariffPromotionToOffer } from "../useAddTariffPromotionToOffer";
import { MAGENTA_CALCULATOR_CONFIG_TRANSFER_LOCAL_STORAGE_KEY } from "../../../constants/const";
import { PortfolioKeys } from "../../../core/entities/Product/IProduct";
import { IAdditionalDevices } from "../../../core/entities/PencilSelling/CartItem/ICartItem";
import { TariffWorldKeys } from "../../../core/repositories/ProductsRepository/DefinitionKeys";
import { IPromotion } from "../../../core/entities/PencilSelling/IPromotion";
import { useStores } from "../../../stores/PrivateClient";
import { ITariff as ITariffProduct } from "../../../core/entities/Product/Tariff/ITariff";
import { IBenefitsData } from "../../../core/entities/MagentaNext/Tariff/ITariff";
import { ICard } from "../../../core/entities/Product/Card/ICard";
import {
  ICartConfigurationType,
  IMobileSettings,
} from "../../../core/entities/PencilSelling/Configurations/IConfigurations";
import {
  getTariffDataForCart,
  tariffProductDescriptionMap,
} from "../../../helpers/TariffSelection";
import { getMobileBenefitSettings } from "../../../helpers/GetMobileBenefitSettings";
import { getSecondaryCardDataForCart } from "../../../helpers/GetSecondaryCardDataForCart";
import { createSecondaryCardKey } from "../../../helpers/CreateSeconaryCardKey";

interface ISecondaryCardsItem extends ICard {
  additionalDevices: IAdditionalDevices[];
}

interface IMagentaCalculatorConfig {
  loyaltyBonus: number;
  mainCard: ITariffProduct;
  secondaryCards: ISecondaryCardsItem[];
  benefits: IBenefitsData;
  smartphoneName: string;
  smartphonePrice: string;
  promotions: IPromotion[];
}

export const useApplyMagentaCalculatorConfig = () => {
  const { offerStore, configurationsStore, productsStore } = useStores();
  const { addPromotion } = useAddTariffPromotionToOffer(offerStore.baseOffer);

  const applyMagentaCalculatorConfig = useCallback(() => {
    const magentaCalculatorConfig = localStorage.getItem(
      MAGENTA_CALCULATOR_CONFIG_TRANSFER_LOCAL_STORAGE_KEY
    );
    // TODO adjust smartphone validation
    if (!magentaCalculatorConfig) return;

    const mobileConfiguration = configurationsStore.getConfigurationsByType(
      ICartConfigurationType.MOBILE
    )[0];
    const secondaryCardsConfiguration =
      configurationsStore.getConfigurationByKey(
        mobileConfiguration.linkedConfigurationKey
      );

    const {
      mainCard,
      secondaryCards,
      benefits,
      smartphoneName,
      smartphonePrice,
      promotions,
    } = JSON.parse(magentaCalculatorConfig) as IMagentaCalculatorConfig;

    const mobileTariff = productsStore.tariffs[PortfolioKeys.MOBILE].find(
      (tariff) => tariff.key === mainCard.key
    );
    // Gather useCases to apply all part of configuration
    const tariffSectionConfigApplyHandler = () => {
      const currentMobileSettings = mobileConfiguration.data
        .settings as IMobileSettings;
      mobileConfiguration.data.settings = {
        ...currentMobileSettings,
        tariffWorld: TariffWorldKeys.magenta_mobile,
        level: mainCard.level.key,
      };

      const cartItemData = getTariffDataForCart(
        mobileTariff,
        [],
        smartphoneName
          ? [
              {
                name: smartphoneName,
                price: smartphonePrice ?? "",
                manufacturer: "",
                isAlternative: false,
                suffix: "",
              },
            ]
          : [],
        PortfolioKeys.MOBILE,
        tariffProductDescriptionMap[PortfolioKeys.MOBILE],
        mobileConfiguration.data.settings,
        benefits.isActive,
        false,
        false
      );
      // Convert product from calculator to ICartItemData type.
      mobileConfiguration.children.addToCart(cartItemData);
      mobileConfiguration.data.isBenefitActive = benefits.isActive;
      mobileConfiguration.data.benefit = getMobileBenefitSettings(
        benefits.isActive,
        mobileTariff
      );
    };

    const secondaryCardsConfigApplyHandler = () => {
      const inheritedDataVolume = benefits.isActive
        ? mobileTariff.dataVolumeMagentaOne
        : mobileTariff.dataVolume;
      secondaryCards.forEach((card) => {
        const cartItemData = getSecondaryCardDataForCart({
          card,
          additionalDevices: (card.additionalDevices || []).reduce(
            (acc, additionalDevice) =>
              additionalDevice.name
                ? [
                    ...acc,
                    {
                      ...additionalDevice,
                      manufacturer: "",
                      isAlternative: false,
                      suffix: "",
                    },
                  ]
                : acc,
            [] as IAdditionalDevices[]
          ),
          promotions: [],
          note: "",
          cardSettings: {
            inheritedDataVolume,
            benefitIsActive: benefits.isActive,
            tariff: mobileTariff,
          },
        });
        cartItemData.key = createSecondaryCardKey(card.key);

        secondaryCardsConfiguration.children.addToCart(cartItemData);
      });
    };

    const tariffPromotionsApplyHandler = () => {
      const mobileTariffInCart = mobileConfiguration.children.getMobileTariff();
      promotions.forEach((promotion) => {
        const promotionItem = {
          isEditable: false,
          isCustom: true,
          name: promotion.name,
          description: promotion.name,
          key: `${promotion.key}`,
          portfolio: PortfolioKeys.MOBILE,
          totalPriceDescription: null,
          kind: promotion.kind,
          conditions: [],
          discounts: [...promotion.discounts],
          // discounts: [
          //   {
          //     value: promotion.discount.value,
          //     interval: promotion.discounts[0].interval,
          //     kind: promotion.kind,
          //     from: 1,
          //     to: promotion.contractPeriod,
          //   },
          // ],
        };
        addPromotion(promotionItem, PortfolioKeys.MOBILE);
        mobileTariffInCart.promotions.push(promotionItem);
      });
    };

    tariffSectionConfigApplyHandler();
    secondaryCardsConfigApplyHandler();
    tariffPromotionsApplyHandler();

    //  Remove config from localStorage
    localStorage.removeItem(
      MAGENTA_CALCULATOR_CONFIG_TRANSFER_LOCAL_STORAGE_KEY
    );
  }, [configurationsStore, productsStore, addPromotion]);

  return { applyMagentaCalculatorConfig };
};
