import {
  CustomPromotionFetchResponse,
  IProductBase,
  LandlineResponse,
  MobileResponse,
  ProductsResponse,
  StreamingAnalysisResponse,
} from "core/repositories/ProductsRepository/ResponseInterface";
import { CalculatorProductsResponse } from "core/repositories/ProductsRepository/CalculatorResponseInterface";
import { ITariff } from "core/entities/Product/Tariff/ITariff";
import {
  CUSTOM_PROMOTIONS_RESPONSE_DEFAULT,
  IProductsSummary,
  ProductsMapper,
  ProductsMapperConstructorArgs,
} from "./ProductsMapper";

export class ProductsMapperBC extends ProductsMapper {
  protected productResponse: ProductsResponse;

  protected calculatorResponse: CalculatorProductsResponse;

  protected streamingAnalysisResponse: StreamingAnalysisResponse;

  protected customPromotionsResponse: CustomPromotionFetchResponse;

  constructor({
    productResponse = null,
    calculatorResponse = null,
    streamingAnalysisResponse = null,
    customPromotionsResponse = CUSTOM_PROMOTIONS_RESPONSE_DEFAULT,
  }: Partial<ProductsMapperConstructorArgs>) {
    super({
      productResponse,
      calculatorResponse,
      streamingAnalysisResponse,
      customPromotionsResponse,
    });
  }

  static applyGen3Discount(card: ITariff, product: IProductBase): ITariff {
    return {
      ...card,
      price: {
        ...card.price,
        gen3Discount: {
          value: product.pricing.quantity_discount?.price ?? null,
          required_quantity:
            product.pricing.quantity_discount?.required_quantity ?? null,
        },
      },
    };
  }

  getSummaryCommands: Array<
    (
      source: { landline: LandlineResponse; mobile: MobileResponse },
      destination: IProductsSummary
    ) => void
  > = [
    (source, destination) => {
      // eslint-disable-next-line no-param-reassign
      destination.mobile.cards = ProductsMapper.sortAndMap(
        source.mobile.cards,
        (product) =>
          ProductsMapperBC.applyGen3Discount(
            ProductsMapper.mapTariff({ ...product }),
            product
          )
      );
    },
  ];
}
