import React from "react";
import { A11yText, Button, Icon } from "@dtpk-cc/components";
import TariffSelectionBenefit from "components/new-design/Tariff/TariffSelection/TariffSelectionBenefit";
import { useStores } from "stores/BusinessClient";
import { observer } from "mobx-react";
import { CustomerType } from "core/entities/PencilSelling/Customer/ICustomer";
import DropdownMain from "elements/new-design/DropdownMain";
import { AlertInformationDefault } from "@dtpk-cc/components/dist/icons";
import { InfoModalVariants } from "components/new-design/CustomerRequirements/CustomerRequirementsInfoModal";
import { trackClick } from "helpers/reactTracking";
import * as styles from "./customer-requirements-header-extra-elements.module.scss";
import PdfIcon from "../../../../../../assets/images/icons/pencil-selling-tool/summary/pdf.svg";

const branchenDropdownItems = [
  "",
  "Handwerk",
  "Einzelhandel",
  "Gastronomie",
  "Dienstleistungen",
  "Produktion und Fertigung",
  "Bauwesen",
  "Transport und Logistik",
  "IT und Technologie",
  "Gesundheitswesen",
  "Kreativwirtschaft",
  "Finanz- und Beratungsbranche",
];

type CustomerRequirementsHeaderExtraElementsProps = {
  trackingContext: string;
  openInfoModal: (variant: InfoModalVariants) => void;
};

const CustomerRequirementsHeaderExtraElements = ({
  trackingContext,
  openInfoModal,
}: CustomerRequirementsHeaderExtraElementsProps) => {
  const { customerRequirementsStore, offerStore, productsRepositoryStore } =
    useStores();
  const { customerRequirementsBase } = customerRequirementsStore;
  const { setIsLoading, customer } = offerStore.baseOffer;
  const { domainType, customerType } = customer.get();
  const pdfPayload = {
    tool_domain: domainType,
    bge_context: customerType === CustomerType.EMPLOYEE,
    customer_requirements: customerRequirementsStore.export(),
  };
  const pdfState = customerRequirementsStore.areRequirementsModified()
    ? "modified"
    : "not_modified";
  const getCustomerRequirementsPDF = async () => {
    try {
      setIsLoading(true);
      const pdfResponse =
        await productsRepositoryStore.getCustomerRequirementsPDF(pdfPayload);
      if (pdfResponse)
        window.open(pdfResponse, "_blank", "noopener,noreferrer");
    } catch (e) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, no-console
      console.log(e?.message);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className={styles.headerExtraElements}>
      <div className={styles.extraButtonsWrapper}>
        <TariffSelectionBenefit
          isActive={customerRequirementsBase.requirements.isBenefitActive}
          onClick={() => customerRequirementsBase.toggleBenefitActive()}
          withLoyaltyBonus={false}
          customText="MagentaEINS"
          customClass={styles.magentaBenefit}
          trackingContext={trackingContext}
        />
        <div className={styles.branchDropdownContainer}>
          <DropdownMain
            items={branchenDropdownItems}
            value={customerRequirementsStore.requirements.selectedBranche || ""}
            onSelection={(value) => {
              trackClick(`branche.${value}`, trackingContext);
              customerRequirementsStore.setBranche(value);
            }}
            label="Branche"
            customClass={styles.branchDropdown}
          />
          <Button
            data-testid="landline-business-case-info"
            customClass={styles.brancheInfoButton}
            tabIndex={0}
            variants={Button.Variant.bare}
            onClick={() => openInfoModal(InfoModalVariants.BRANCHE)}
          >
            <Icon
              icon={AlertInformationDefault}
              wrapperProps={{ className: styles.brancheInfoIcon }}
              size={Icon.Size.xsmall}
            />
            <A11yText>Branchen-Infos</A11yText>
          </Button>
        </div>
      </div>
      <div className={styles.buttonsWrapper}>
        <Button
          tabIndex={0}
          onClick={() => {
            trackClick(`pdf_drucken.${pdfState}`, trackingContext);
            getCustomerRequirementsPDF();
          }}
          variants={Button.Variant.icon}
          customClass={`${styles.btn}`}
        >
          <Icon icon={PdfIcon} wrapperProps={{ className: styles.btnIcon }} />
          PDF drucken
        </Button>
      </div>
    </div>
  );
};

export default observer(CustomerRequirementsHeaderExtraElements);
