export enum InternationalTariffVariants {
  EU_CH_UK = "eu_ch_uk",
  USA_CANADA_TURKEY = "usa_canada_turkey",
  TURKEY = "turkey",
  USA_CANADA = "usa_canada",
}

export type BandwidthValues = {
  current: string;
  max: string;
};

// START OF CURRENTLY NOT IN USE
export enum DevicesAmountVariants {
  IN_STOCK = "inStock",
  NEEDED = "needed",
}

export type DevicesVariants = {
  [key in DevicesAmountVariants]: DevicesAmounts;
};
// END OF CURRENTLY NOT IN USE

export type DevicesAmounts = {
  smartphones: string;
  tablets: string;
  laptops: string;
  smartwatches: string;
  gaming: string;
  mobility: string;
  smartHome: string;
};

export interface ICustomerRequirementsBase {
  bandwidth: BandwidthValues;
  fiber: boolean;
  internationalTariffs: InternationalTariffVariants[];
  devices: DevicesAmounts;
  isBenefitActive: boolean;
  recommendations: Partial<Record<keyof ICustomerRequirementsBase, string>>;
}
