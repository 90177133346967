import React, { ChangeEvent } from "react";
import { observer } from "mobx-react";
import { Checkbox, Heading } from "@dtpk-cc/components";
import { BaseOffer } from "../../../../core/entities/PencilSelling/BaseOffer/BaseOffer";
import { CustomerType } from "../../../../core/entities/PencilSelling/Customer/ICustomer";
import Input from "../../../../elements/new-design/Input";
import { trackClick } from "../../../../helpers/reactTracking";
import * as styles from "./customer-data-form.module.scss";
import TextArea from "../../../../elements/new-design/TextArea";
import { TileContainerVariants } from "../../TileContainer/ITileContainer";
import TileContainer from "../../TileContainer";
import TileContainerTitle from "../../TileContainer/TileContainerTitle";
import {
  onlyNumber,
  preventDecimal,
} from "../../../../helpers/NumericCalculation";

type CustomerDataFormWrapperProps = {
  offerStore: BaseOffer;
};

type InputFieldData = {
  name: string;
  placeHolder: string;
  type: "input" | "textarea";
  customClass?: string;
  isNumberType?: boolean;
};

const CustomerDataForm = ({ offerStore }: CustomerDataFormWrapperProps) => {
  const customerType =
    offerStore.customer.get().customerType === CustomerType.PRIVATE
      ? CustomerType.PRIVATE
      : CustomerType.BUSINESS;
  const { customerData } = offerStore.customer.get();

  const inputChangeHandler = (key: string, value: string | boolean) => {
    offerStore.customer.set({
      ...offerStore.customer.get(),
      customerData: {
        ...customerData,
        [key]: value,
      },
    });
  };

  const inputClickHandler = (name: string) => {
    trackClick(`${name}.customer.data`, offerStore.customer.get().customerType);
  };

  const getCheckboxTemplate = () => (
    <Checkbox
      key="customer-data-kek"
      checked={customerData.kek}
      onClick={() => inputClickHandler}
      onChange={(checked) => inputChangeHandler("kek", checked)}
      role="checkbox"
      aria-checked="true"
    >
      {customerData.kek ? "KEK" : "Noch keine KEK erteilt, bitte einholen"}
    </Checkbox>
  );

  const getInputTemplate = ({
    customClass = "",
    name,
    placeHolder,
    type,
    isNumberType,
  }: InputFieldData) =>
    type === "input" ? (
      <Input
        key={`customer-data-${name}`}
        id={`customer-data-${name}`}
        value={customerData[name] as string}
        onClick={() => inputClickHandler(name)}
        onChange={({ target: { value } }) => {
          inputChangeHandler(name, value);
        }}
        placeholder={placeHolder}
        wrapperCustomClass={customClass}
        onKeyPress={(event) => {
          if (isNumberType) {
            onlyNumber(event);
            preventDecimal(event);
          }
        }}
      />
    ) : (
      <TextArea
        key={`customer-data-${name}`}
        id={`customer-data-${name}`}
        labelId={`customer-data-label-${name}`}
        data-role="textbox"
        text={customerData[name] as string}
        handleChange={(event: ChangeEvent<HTMLTextAreaElement>) =>
          inputChangeHandler(name, event.currentTarget.value)
        }
        onClick={() => inputClickHandler(name)}
        label={placeHolder}
        customClass={`${styles.textArea} ${customClass}`}
      />
    );

  const customerDataFormMap = {
    [CustomerType.PRIVATE]: [
      getInputTemplate({
        name: "contactPerson",
        placeHolder: "Name",
        type: "input",
        customClass: "m-b-24",
      }),
      getInputTemplate({
        name: "address",
        placeHolder: "Anschrift",
        type: "textarea",
        customClass: "m-b-24",
      }),
      <div className={`${styles.inputWrapper} m-b-24`} key="phone">
        {getInputTemplate({
          name: "landlineNumber",
          placeHolder: "Festnetz Rufnummer",
          type: "input",
          isNumberType: true,
        })}
        {getInputTemplate({
          name: "mobileNumber",
          placeHolder: "Mobilfunk Rufnummer",
          type: "input",
          isNumberType: true,
        })}
      </div>,
      getCheckboxTemplate(),
      getInputTemplate({
        name: "prevContract",
        placeHolder: "Bisherige Verträge",
        type: "textarea",
      }),
    ],
    [CustomerType.BUSINESS]: [
      getInputTemplate({
        name: "companyName",
        placeHolder: "Name / Firma",
        type: "input",
        customClass: "m-b-24",
      }),
      getInputTemplate({
        name: "contactPerson",
        placeHolder: "Ansprechpartner",
        type: "input",
        customClass: "m-b-24",
      }),
      getInputTemplate({
        name: "address",
        placeHolder: "Anschrift",
        type: "textarea",
        customClass: "m-b-24",
      }),
      <div className={`${styles.inputWrapper} m-b-24`} key="phone">
        {getInputTemplate({
          name: "landlineNumber",
          placeHolder: "Festnetz Rufnummer",
          type: "input",
          isNumberType: true,
        })}
        {getInputTemplate({
          name: "mobileNumber",
          placeHolder: "Mobilfunk Rufnummer",
          type: "input",
          isNumberType: true,
        })}
      </div>,
      getInputTemplate({
        name: "frameworkContract",
        placeHolder: "Rahmenvertragsnummer",
        type: "input",
        customClass: "m-b-24",
      }),
      getCheckboxTemplate(),
      getInputTemplate({
        name: "prevContract",
        placeHolder: "Bisherige Verträge",
        type: "textarea",
      }),
    ],
  };

  return (
    <TileContainer
      customClass={`dataContainer ${styles.wrapper}`}
      variant={TileContainerVariants.outline}
    >
      <TileContainerTitle
        variants={[Heading.Variant.tertiary, Heading.Variant.uppercase]}
        title="Kundenangaben"
      />
      {customerDataFormMap[customerType]}
    </TileContainer>
  );
};

export default observer(CustomerDataForm);
