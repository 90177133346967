import React from "react";
import * as styles from "./checkbox-input.module.scss";

type CheckboxInputProps = {
  value?: string;
  name: string;
  id: string;
  inputTestId?: string;
  checked: boolean;
  onChange?: (value) => void;
  customClass?: string;
  customInputClass?: string;
  disabled?: boolean;
  inactive?: boolean;
  variant?: "primary" | "secondary";
};

const CheckboxInput = ({
  customClass = "",
  customInputClass = "",
  disabled = false,
  inactive = false,
  value = "",
  name,
  id,
  inputTestId = null,
  checked,
  onChange = () => {},
  variant = "primary",
}: CheckboxInputProps) => {
  const variants = {
    primary: {
      input: styles.input,
      checkmark: styles.checkMark,
    },
    secondary: {
      input: styles.inputSecondary,
      checkmark: styles.checkMarkSecondary,
    },
  };
  return (
    <>
      <input
        tabIndex={disabled ? -1 : 0}
        type="checkbox"
        value={value}
        name={name}
        onKeyPress={(event) => {
          onChange(event);
        }}
        onChange={(event) => {
          onChange(event);
        }}
        data-testid={inputTestId}
        id={id}
        checked={checked}
        className={`${customInputClass} ${variants[variant].input}`}
      />
      <span
        className={`${customClass} ${variants[variant].checkmark} 
        ${inactive ? styles.inactive : ""}`}
      />
    </>
  );
};

export default CheckboxInput;
