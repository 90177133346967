import React, { useEffect, useState } from "react";
import { observer } from "mobx-react";
import { Button, Heading } from "@dtpk-cc/components";
import { IStepTrackingContext } from "views/PencilSelling/IPencilSelling";
import { ILevel, ITariffWorld } from "core/entities/Product/IProduct";
import RadioButtonMain from "elements/new-design/RadioButtonMain";
import { trackClick } from "helpers/reactTracking";

import * as styles from "./tariff-configuration-worlds-levels.module.scss";
import { ICartConfigurationSettings } from "../../../../../core/entities/PencilSelling/Configurations/IConfigurations";

type TariffConfigurationWorldsLevelsProps = {
  trackingContext: IStepTrackingContext;
  tariffWorlds: ITariffWorld[];
  levels: ILevel[];
  onChange: (
    key: keyof ICartConfigurationSettings,
    value: ICartConfigurationSettings[keyof ICartConfigurationSettings],
    settingsObj?: ICartConfigurationSettings
  ) => void;
  setActiveLevelIndex: (value: number) => void;
  settings: ICartConfigurationSettings;
  activeLevelExists: boolean;
  activeLevelIndex: number;
  levelBtnText?: string;
};

const TariffConfigurationWorldsLevels = ({
  trackingContext,
  tariffWorlds,
  levels,
  onChange,
  setActiveLevelIndex,
  settings,
  activeLevelExists,
  activeLevelIndex,
  levelBtnText = "Substufe auswählen",
}: TariffConfigurationWorldsLevelsProps) => {
  const [tariffWorldIsDisplayed, setTariffWorldIsDisplayed] = useState(true);

  const toggleTariffWorldIsDisplayed = () =>
    setTariffWorldIsDisplayed((prev) => !prev);

  const activeTariffWorld = tariffWorlds.find(
    (tariffWorld) => tariffWorld.key === settings.tariffWorld
  );
  const activeLevel = levels.find((level) => level.key === settings.level);

  useEffect(() => {
    if (!activeLevelExists && levels.length) {
      const defaultLevelKey = levels[0].key;
      onChange("level", levels[activeLevelIndex]?.key || defaultLevelKey);
    }
  }, [activeLevelExists, activeLevelIndex, levels, onChange]);

  useEffect(() => {
    if (tariffWorlds.length !== 0) {
      const tariffWorldExists = tariffWorlds.some(
        ({ key }) => key === settings.tariffWorld
      );
      if (!tariffWorldExists) {
        const defaultTariffWorldKey = tariffWorlds[0].key;
        onChange("tariffWorld", defaultTariffWorldKey);
      }
    }
  }, [onChange, settings.tariffWorld, tariffWorlds]);

  return (
    <div className={styles.wrapper}>
      <div className={styles.header}>
        {activeTariffWorld && (
          <Button
            tabIndex={0}
            onClick={toggleTariffWorldIsDisplayed}
            variants={Button.Variant.bare}
            customClass={`${styles.toggleBtn} ${
              tariffWorldIsDisplayed ? styles.active : ""
            }`}
          >
            <Heading
              tag="p"
              variants={Heading.Variant.quaternary}
              customClass={styles.toggleBtnHeader}
            >
              Tarifwelt
            </Heading>
            <Heading
              tag="p"
              variants={[Heading.Variant.quaternary, Heading.Variant.display]}
              customClass={styles.toggleBtnValue}
            >
              {activeTariffWorld.name}
            </Heading>
          </Button>
        )}
        {activeLevel && levels.length > 1 && (
          <Button
            tabIndex={0}
            onClick={toggleTariffWorldIsDisplayed}
            variants={Button.Variant.bare}
            customClass={`${styles.toggleBtn} ${
              !tariffWorldIsDisplayed ? styles.active : ""
            }`}
          >
            <Heading
              tag="p"
              variants={Heading.Variant.quaternary}
              customClass={styles.toggleBtnHeader}
            >
              {levelBtnText}
            </Heading>
            <Heading
              tag="p"
              variants={[Heading.Variant.quaternary, Heading.Variant.display]}
              customClass={styles.toggleBtnValue}
            >
              {activeLevel.name}
              <span className="m-l-6">ausgewählt</span>
            </Heading>
          </Button>
        )}
      </div>
      <div className={styles.list}>
        {tariffWorldIsDisplayed &&
          tariffWorlds.map((tariffWorld, index) => (
            <RadioButtonMain
              customClass={styles.radioBtn}
              key={tariffWorld.key}
              inputTestId={`tarifweltauswahl-${index}`}
              title={tariffWorld.name}
              onChange={() => {
                onChange("tariffWorld", tariffWorld.key);
                trackClick(
                  `${trackingContext.portfolio}.${tariffWorld.name}`,
                  trackingContext.main
                );
              }}
              checked={tariffWorld.key === settings.tariffWorld}
              value={tariffWorld.key}
              name="tarifweltauswahl"
              id={`tarifweltauswahl-${tariffWorld.key}`}
            />
          ))}
        {!tariffWorldIsDisplayed &&
          levels.map((level, index) => (
            <RadioButtonMain
              customClass={styles.radioBtn}
              key={level.key}
              inputTestId={`substufe-${index}`}
              title={level.name}
              onChange={() => {
                setActiveLevelIndex(index);
                onChange("level", level.key);
                trackClick(
                  `${trackingContext.portfolio}.${level.name}`,
                  trackingContext.main
                );
              }}
              checked={level.key === settings.level}
              value={level.key}
              name="substufe"
              id={`substufe-${level.key}`}
            />
          ))}
      </div>
    </div>
  );
};

export default observer(TariffConfigurationWorldsLevels);
