import { useState } from "react";
import { TariffPresenter } from "../../core/presenter/PencilSelling/TariffPresenter";
import { getBusinessCaseName } from "../../helpers/GetBusinessCaseName";
import { IBusinessCaseDataItems } from "../../core/entities/Product/BusinessClient/IBaseData";
import { ICartConfigurationSettings } from "../../core/entities/PencilSelling/Configurations/IConfigurations";
import {
  businessCaseOption,
  IBusinessCasesTypeValues,
  ITariff,
} from "../../core/entities/Product/Tariff/ITariff";

type UseMobileTariffSelectionDataProps = {
  businessCase: IBusinessCasesTypeValues;
  isLandline: boolean;
  settings: ICartConfigurationSettings;
  tariffs: ITariff[];
  businessCaseData: businessCaseOption[] | IBusinessCaseDataItems[];
};

export const useTariffSelectionData = ({
  isLandline,
  settings,
  tariffs,
  businessCaseData,
  businessCase,
}: UseMobileTariffSelectionDataProps) => {
  const tariffPresenter = new TariffPresenter(
    tariffs,
    settings,
    isLandline,
    businessCase
  );
  const { tariffWorlds, levels, activeLevelExists } = tariffPresenter;
  const filteredTariffs = tariffPresenter.get();
  const [activeLevelIndex, setActiveLevelIndex] = useState(
    tariffPresenter.getLevelIndex(settings?.level)
  );
  const businessCaseName = getBusinessCaseName(businessCase, businessCaseData);
  const activeTariffWorld = tariffWorlds.find(
    ({ key }) => settings.tariffWorld === key
  );

  return {
    tariffWorlds,
    levels,
    activeLevelExists,
    filteredTariffs,
    activeLevelIndex,
    setActiveLevelIndex,
    businessCaseName,
    activeTariffWorld,
  };
};
