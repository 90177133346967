// extracted by mini-css-extract-plugin
var _1 = "customer-requirements-header-extra-elements-module-branchDropdown-C4w9S";
var _2 = "customer-requirements-header-extra-elements-module-branchDropdownContainer-yzrPV";
var _3 = "customer-requirements-header-extra-elements-module-brancheInfoButton-x2EJR";
var _4 = "customer-requirements-header-extra-elements-module-brancheInfoIcon-vZmmq";
var _5 = "customer-requirements-header-extra-elements-module-btn-uO7sq";
var _6 = "customer-requirements-header-extra-elements-module-btnIcon-ELk4U";
var _7 = "customer-requirements-header-extra-elements-module-buttonsWrapper-hxQw6";
var _8 = "customer-requirements-header-extra-elements-module-extraButtonsWrapper-G23FC";
var _9 = "customer-requirements-header-extra-elements-module-headerExtraElements-c06re";
var _a = "customer-requirements-header-extra-elements-module-magentaBenefit-PDAXE";
export { _1 as "branchDropdown", _2 as "branchDropdownContainer", _3 as "brancheInfoButton", _4 as "brancheInfoIcon", _5 as "btn", _6 as "btnIcon", _7 as "buttonsWrapper", _8 as "extraButtonsWrapper", _9 as "headerExtraElements", _a as "magentaBenefit" }
