import React, { useMemo, useState } from "react";
import { observer } from "mobx-react";
import { Grid, Icon, Button, Heading, Price } from "@dtpk-cc/components";
import ActionRemoveDefault from "@dtpk-cc/components/dist/icons/Action/Remove/Default";
import AddDeviceIcon from "../../../../../../../assets/images/icons/pencil-selling-tool/secondary-cards/add-device.svg";
import AddPromotionIcon from "../../../../../../../assets/images/icons/pencil-selling-tool/secondary-cards/add-promotion.svg";
import LinkBtn from "../../../../../../elements/new-design/LinkBtn";
import DropdownMain from "../../../../../../elements/new-design/DropdownMain";
import { useStores } from "../../../../../../stores/PrivateClient";
import { ICard } from "../../../../../../core/entities/Product/Card/ICard";
import { getPromotionsFilteredByBusinessCase } from "../../../../../../helpers/GetPromotionsFilteredByBusinessCase";
import {
  IAdditionalDevices,
  ICartItemData,
} from "../../../../../../core/entities/PencilSelling/CartItem/ICartItem";
import { IBusinessCasesTypeValues } from "../../../../../../core/entities/Product/Tariff/ITariff";
import { getBusinessCaseName } from "../../../../../../helpers/GetBusinessCaseName";
import { secondaryCardsBusinessCase } from "../../../../../../core/entities/Product/BusinessClient/IBaseData";
import PromotionSelection from "../../../../../../components/new-design/PromotionSelection";
import { PortfolioKeys } from "../../../../../../core/entities/Product/IProduct";
import TariffConfigurationPhonesModal from "../../../../../../components/new-design/Tariff/TariffConfiguration/TariffConfigurationPhones/TariffConfigurationPhonesModal";
import TextInputModal from "../../../../../../elements/new-design/TextInputModal";
import { trackClick } from "../../../../../../helpers/reactTracking";
import { getSecondaryCardNote } from "../../../../../../helpers/GetSecondaryCardNote";

import * as styles from "./secondary-cards-section-card-item.module.scss";
import { getSmartphoneName } from "../../../../../../helpers/GetSmartphoneName";
import TariffConfigurationAddons from "../../../../../../components/new-design/Tariff/TariffConfiguration/TariffConfigurationAddons";
import { getPromotionsName } from "../../../../../../helpers/GetPromotionsName";
import { IPromotion } from "../../../../../../core/entities/PencilSelling/IPromotion";

type SecondaryCardsSectionCardItemProps = {
  distinctLevelNames: string[];
  cardInCart: ICartItemData;
  card: ICard;
  customPromotions: IPromotion[];
  businessCase: IBusinessCasesTypeValues;
  trackingContext: string;
  editSecondaryCardHandler: ({
    levelName,
    promotions,
    additionalDevices,
    note,
    cardKey,
  }: {
    levelName: string;
    promotions: IPromotion[];
    additionalDevices: IAdditionalDevices[];
    note: string;
    cardKey: string;
  }) => void;
  deleteCardHandler: (card: ICartItemData) => void;
  cardSectionName: string;
  index: number;
};

const ADDONS_MAX_DISPLAY_AMOUNT = 1;

const SecondaryCardsSectionCardItem = ({
  distinctLevelNames,
  card,
  cardInCart,
  customPromotions,
  businessCase,
  trackingContext,
  editSecondaryCardHandler,
  deleteCardHandler,
  cardSectionName,
  index,
}: SecondaryCardsSectionCardItemProps) => {
  const { offerStore, productsRepositoryStore } = useStores();
  const [promotionsModalIsOpen, setPromotionsModalIsOpen] = useState(false);
  const [phonesModalIsOpen, setPhonesModalIsOpen] = useState(false);
  const [cardNoteModalIsOpen, setCardNoteModalIsOpen] = useState(false);
  const [smartphoneInfos, setSmartphoneInfos] = useState(
    cardInCart?.additionalDevices || []
  );

  const trackingContextData = {
    main: trackingContext,
    portfolio: PortfolioKeys.MOBILE,
  };
  const businessCaseName = getBusinessCaseName(
    businessCase,
    secondaryCardsBusinessCase.items
  );
  const selectedPromotions = useMemo(
    () => cardInCart.promotions || [],
    [cardInCart]
  );
  const filteredTariffPromotionsByCurrentBusinessCase =
    getPromotionsFilteredByBusinessCase(card, businessCase);

  const addPromotionsHandler = (promotions: IPromotion[]) => {
    editSecondaryCardHandler({
      promotions,
      additionalDevices: smartphoneInfos,
      levelName: card.level.name,
      note: cardInCart.cardData.note,
      cardKey: cardInCart.key,
    });
  };

  const addSmartphonesHandler = (additionalDevices: IAdditionalDevices[]) => {
    editSecondaryCardHandler({
      additionalDevices,
      levelName: card.level.name,
      promotions: selectedPromotions,
      note: cardInCart.cardData.note,
      cardKey: cardInCart.key,
    });
  };

  const levelChangeHandler = (levelName: string) => {
    editSecondaryCardHandler({
      levelName,
      additionalDevices: smartphoneInfos,
      promotions: selectedPromotions,
      note: cardInCart.cardData.note,
      cardKey: cardInCart.key,
    });
  };

  const setCardNoteHandler = (note: string) => {
    setCardNoteModalIsOpen(false);
    editSecondaryCardHandler({
      note: note.trim(),
      levelName: card.level.name,
      additionalDevices: smartphoneInfos,
      promotions: selectedPromotions,
      cardKey: cardInCart.key,
    });
  };

  const smartphoneNamesArr = smartphoneInfos.map(getSmartphoneName);

  const promotionsNamesArr = getPromotionsName(selectedPromotions);

  return (
    <Grid>
      <Grid.Row customClass="p-t-24 m-0">
        <Grid.Col
          customClass={`p-r-0 ${styles.titleWrapper}`}
          m={3}
          l={3}
          xl={7}
        >
          <div>
            <LinkBtn
              customClass={styles.noteBtn}
              onClick={() => setCardNoteModalIsOpen(true)}
              text={getSecondaryCardNote(cardInCart.cardData.note, index)}
            />
            <TextInputModal
              title="Ergänzungen"
              inputPlaceholder="Ergänzungen"
              onSubmit={setCardNoteHandler}
              onCancel={() => setCardNoteModalIsOpen(false)}
              onInputClick={() => {
                trackClick(`${trackingContext}${cardSectionName}.ergänzungen`);
              }}
              defaultInputValue={cardInCart.cardData.note}
              isOpen={cardNoteModalIsOpen}
            />
            <Heading
              tag="p"
              variants={[Heading.Variant.quaternary, Heading.Variant.highlight]}
            >
              {cardInCart.name}
            </Heading>
          </div>
        </Grid.Col>
        <Grid.Col customClass={styles.rowCell} m={3} l={3} xl={5}>
          <DropdownMain
            label="Substufe"
            items={distinctLevelNames}
            onSelection={levelChangeHandler}
            value={card.level.name}
            customClass={styles.levelDropdown}
          />
        </Grid.Col>
        <Grid.Col customClass={styles.rowCell} m={2} l={2} xl={4}>
          <div>
            <TariffConfigurationAddons
              maxItemsDisplayAmount={ADDONS_MAX_DISPLAY_AMOUNT}
              addons={smartphoneNamesArr}
            />
            <LinkBtn
              onClick={() => setPhonesModalIsOpen(true)}
              text="Endgeräte verwalten"
              icon={AddDeviceIcon}
              iconWrapperCustomClass={styles.linkBtnIcon}
            />
          </div>
          {phonesModalIsOpen && (
            <TariffConfigurationPhonesModal
              isOpen={phonesModalIsOpen}
              setIsOpen={setPhonesModalIsOpen}
              businessCaseName={businessCaseName}
              productInCart={cardInCart}
              smartphoneInfos={smartphoneInfos}
              trackingContext={trackingContextData}
              setSmartphoneInfos={setSmartphoneInfos}
              addSmartphonesHandler={addSmartphonesHandler}
            />
          )}
        </Grid.Col>
        <Grid.Col customClass={styles.rowCell} m={2} l={2} xl={4}>
          <div>
            <TariffConfigurationAddons
              maxItemsDisplayAmount={ADDONS_MAX_DISPLAY_AMOUNT}
              addons={promotionsNamesArr}
            />
            <LinkBtn
              onClick={() => setPromotionsModalIsOpen(true)}
              text="Aktionen verwalten"
              icon={AddPromotionIcon}
              iconWrapperCustomClass={styles.linkBtnIcon}
            />
          </div>
          {promotionsModalIsOpen && (
            <PromotionSelection
              withCustomPromotions
              trackingContext={trackingContextData}
              businessCaseName={businessCaseName}
              productInCartName={cardInCart.name}
              activeProductItem={card}
              selectedPromotions={selectedPromotions}
              customPromotions={customPromotions}
              promotions={filteredTariffPromotionsByCurrentBusinessCase}
              setPromotionsModalIsOpen={setPromotionsModalIsOpen}
              promotionsModalIsOpen={promotionsModalIsOpen}
              addPromotionsHandler={addPromotionsHandler}
              offerStore={offerStore.getBaseOffer()}
              productsRepositoryStore={productsRepositoryStore}
              portfolioKey={PortfolioKeys.MOBILE}
            />
          )}
        </Grid.Col>
        <Grid.Col customClass={styles.rowCell} m={1} l={1} xl={3}>
          <Price
            suffix={"mtl."}
            variants={[Price.Variant.brand, Price.Variant.large]}
            value={cardInCart.price.monthly}
          />
        </Grid.Col>
        <Grid.Col customClass={styles.rowCell} m={1} l={1} xl={1}>
          <Button
            customClass={styles.removeBtn}
            variants={Button.Variant.outline}
            onClick={() => deleteCardHandler(cardInCart)}
          >
            <Icon customClass={styles.removeIcon} icon={ActionRemoveDefault} />
          </Button>
        </Grid.Col>
      </Grid.Row>
    </Grid>
  );
};

export default observer(SecondaryCardsSectionCardItem);
