// always round up the value by the specified floating point value
export const roundUpValue = (value: number, exponent: number): number =>
  Math.ceil(value * 10 ** exponent) / 10 ** exponent;

export const formatNumberWithTrimTrailingZeros = (price: number): string => {
  const result = price.toFixed(2);
  return result.match(/\.[0]{2}/)
    ? parseInt(result, 10).toString()
    : result.replace(".", ",");
};

export const getFormattedFloat = (value) => parseFloat(value.toFixed(2));

export const humandReadableNumber = (
  value: number,
  suffix = "",
  roundUp = "roundDown"
): string => {
  if (Number.isNaN(Number(value))) return "";

  const result = roundUp === "roundUp" ? roundUpValue(value, 2) : value;

  return `${result.toFixed(2).replace(".", ",")} ${suffix}`;
};

export const convertPriceStringToNumber = (value: string) =>
  value ? parseFloat(value.toString().replace(",", ".")) : null;

export const filterNonNumericCharactersAndParseToInt = (
  value: string
): number => parseInt(value.replace(/\D/g, ""), 10);
