import React from "react";
import { ToggleBtn } from "elements/new-design/ToggleBtn";
import { CopyText } from "@dtpk-cc/components";
import { UseCaseVariantsBusiness } from "core/entities/PencilSelling/BusinessClient/CustomerRequirements/ICustomerRequirementsBC";
import { observer } from "mobx-react";
import { useStores } from "stores/BusinessClient";
import { trackClick } from "helpers/reactTracking";
import CustomerRequirementsRecommendation from "components/new-design/CustomerRequirements/CustomerRequirementsRecommendation";
import * as styles from "./customer-requirements-use-case.module.scss";
import UsvIcon from "../../../../../../assets/images/icons/pencil-selling-tool/customer-requirements/usv.svg";
import TkSystemIcon from "../../../../../../assets/images/icons/pencil-selling-tool/customer-requirements/tk_systems.svg";
import RouterIcon from "../../../../../../assets/images/icons/pencil-selling-tool/customer-requirements/router_outline.svg";
import CashDeskIcon from "../../../../../../assets/images/icons/pencil-selling-tool/customer-requirements/cash_desk.svg";

const useCaseContentArray = [
  {
    identifier: UseCaseVariantsBusiness.USV,
    title: "Ausfallsicherheit",
    icon: UsvIcon,
  },
  {
    identifier: UseCaseVariantsBusiness.TK_SYSTEMS,
    title: "TK Anlage",
    icon: TkSystemIcon,
  },
  {
    identifier: UseCaseVariantsBusiness.ROUTER,
    title: "Router",
    icon: RouterIcon,
  },
  {
    identifier: UseCaseVariantsBusiness.CASH_DESK,
    title: "Kasse / EC Cash",
    icon: CashDeskIcon,
  },
];

type CustomerRequirementsUseCasesProps = {
  trackingContext: string;
};

const CustomerRequirementsUseCases = ({
  trackingContext,
}: CustomerRequirementsUseCasesProps) => {
  const { customerRequirementsStore } = useStores();

  return (
    <div className={styles.useCaseContainer}>
      <div className={styles.useCaseTileContainer}>
        {useCaseContentArray.map((item) => (
          <ToggleBtn
            key={`personal_use_case_${item.identifier}`}
            iconConfig={{ Icon: item.icon, iconWrapperClass: styles.iconWrapper }}
            title={item.title}
            onChange={() => {
              trackClick(`usecases.${item.identifier}`, trackingContext);
              customerRequirementsStore.setUseCases(item.identifier);
            }}
            checked={customerRequirementsStore.requirements.useCases.includes(
              item.identifier
            )}
            inputTestId={`personal_use_case_${item.identifier}`}
            id={`personal_use_case_${item.identifier}`}
            customTileClass={styles.useCaseTile}
            copyTextVariant={CopyText.Variant.additional}
          />
        ))}
      </div>
      <CustomerRequirementsRecommendation
        description={customerRequirementsStore.recommendations.useCases}
        customClass={styles.recommendationPosition}
      />
    </div>
  );
};

export default observer(CustomerRequirementsUseCases);
