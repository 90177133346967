import { useCallback } from "react";

import { useUseCases } from "../useUseCases";
import { PortfolioKeys } from "../../core/entities/Product/IProduct";
import { IPromotion } from "../../core/entities/PencilSelling/IPromotion";
import { BaseOffer } from "../../core/entities/PencilSelling/BaseOffer/BaseOffer";
import { OfferEntity } from "../../core/entities/PencilSelling/Offer_legacy/Offer";

export const useAddTariffPromotionToOffer = (
  offerStore: BaseOffer | OfferEntity
) => {
  const { getCustomPromotionsUseCase, setCustomPromotionsUseCase } =
    useUseCases();

  const addPromotion = useCallback(
    (promotion: IPromotion, portfolioKey: PortfolioKeys) => {
      const previousPromotions = offerStore.getCustomPromotions();
      offerStore.setCustomPromotions({
        ...previousPromotions,
        [portfolioKey]: [...previousPromotions[portfolioKey], promotion],
      });
    },
    [offerStore]
  );

  // TODO: remove later when no component will use it
  const addLandlinePromotionHandler = useCallback(
    (promotion) => {
      const previousPromotions = getCustomPromotionsUseCase.execute();
      setCustomPromotionsUseCase.execute({
        ...previousPromotions,
        [PortfolioKeys.LANDLINE]: [
          ...previousPromotions[PortfolioKeys.LANDLINE],
          promotion,
        ],
      });
    },
    [getCustomPromotionsUseCase, setCustomPromotionsUseCase]
  );

  const addMobilePromotionHandler = useCallback(
    (promotion) => {
      const previousPromotions = getCustomPromotionsUseCase.execute();
      setCustomPromotionsUseCase.execute({
        ...previousPromotions,
        [PortfolioKeys.MOBILE]: [
          ...previousPromotions[PortfolioKeys.MOBILE],
          promotion,
        ],
      });
    },
    [getCustomPromotionsUseCase, setCustomPromotionsUseCase]
  );

  return {
    addPromotion,
    addLandlinePromotionHandler,
    addMobilePromotionHandler,
  };
};
