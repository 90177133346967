import React, { useCallback, useEffect, useState } from "react";
import { observer } from "mobx-react";
import { CSSTransition } from "react-transition-group";

import "views/resets.scss";
import "views/PencilSelling/pencil-selling-global.scss";
import SketchBoardWrapper from "../../SketchBoard/SketchBoardWrapper";
import { INavigationMap } from "../Navigation/INavigation";
import Navigation from "../Navigation";
import { IUseNavigationStateProps } from "../../../hooks/PencilSelling/Navigation/useNavigationState";
import * as styles from "./pencil-selling-container.module.scss";
import { ConfigurationsEntity } from "../../../core/entities/PencilSelling/Configurations/Configurations";
import { ProductsRepository } from "../../../core/repositories/ProductsRepository/ProductsRepository";
import { BaseOffer } from "../../../core/entities/PencilSelling/BaseOffer/BaseOffer";
import { FactModalEntity } from "../../../core/entities/PencilSelling/FactModal/FactModal";
import FactModal from "../FactModal";
import UserGroupWarningModal from "../UserGroupWarningModal";
import { IUserGroups } from "../../../core/entities/PencilSelling/Customer/ICustomer";

type PencilSellingContainerProps = {
  mainContainerAriaIsHidden: boolean;
  navigationMenuMap: INavigationMap;
  navigationState: IUseNavigationStateProps;
  appResetHandler: () => void;
  adjustNavigationMap?: (navMap: INavigationMap) => void;
  userName: string;
  configurationsStore: ConfigurationsEntity;
  offerStore: BaseOffer;
  productsRepositoryStore: ProductsRepository;
  factModalStore: FactModalEntity;
};

const PencilSellingContainer: React.FC<PencilSellingContainerProps> = ({
  mainContainerAriaIsHidden,
  navigationMenuMap,
  navigationState,
  appResetHandler,
  adjustNavigationMap,
  userName,
  configurationsStore,
  offerStore,
  productsRepositoryStore,
  children,
  factModalStore,
}) => {
  const { userGroup } = offerStore.customer.get();
  const [sketchboardIsActive, setSketchboardIsActive] = useState(false);
  const [userGroupModalIsClosed, setUserGroupModalIsClosed] = useState(false);
  const factModalItem = factModalStore.getActiveFactModal();

  const toggleSketchboard = useCallback(() => {
    const bodyElem = document.body;
    /*
     * "fullscreen" class is a flag, that gives an understanding of is fullscreen mode is turned on.
     * If it is, than we add "sketchboard--fullscreen" class to remove footer, adjust styling for body and sketchboard container
     * */
    // TODO Refactor
    if (bodyElem?.classList.contains("fullscreen")) {
      bodyElem.classList.toggle("sketchboard--fullscreen");
    }

    setSketchboardIsActive((prev) => !prev);
  }, []);

  useEffect(() => {
    document.body.classList.add("pst-body-container");
  }, []);

  const userGroupModalIsOpen =
    userGroup === IUserGroups.DEFAULT && !userGroupModalIsClosed;

  return (
    <>
      {sketchboardIsActive && (
        <SketchBoardWrapper
          toggleSketchboard={toggleSketchboard}
          sketchboardIsActive={sketchboardIsActive}
        />
      )}
      <CSSTransition
        timeout={0}
        in={sketchboardIsActive || mainContainerAriaIsHidden}
        classNames={{
          enter: styles.pencilSellingTransitionEnter,
          enterActive: styles.pencilSellingTransitionEnterActive,
          enterDone: styles.pencilSellingTransitionEnterDone,
          exit: styles.pencilSellingTransitionExit,
          exitActive: styles.pencilSellingTransitionExitActive,
          exitDone: styles.pencilSellingTransitionExitDone,
        }}
      >
        <div
          aria-hidden={mainContainerAriaIsHidden || sketchboardIsActive}
          className={`${styles.pencilSellingTool} ${
            mainContainerAriaIsHidden ? styles.hidden : ""
          }`}
        >
          <Navigation
            {...navigationState}
            userName={userName}
            navigationMenuMap={navigationMenuMap}
            toggleSketchboard={toggleSketchboard}
            appResetHandler={appResetHandler}
            adjustNavigationMap={adjustNavigationMap}
            productsRepositoryStore={productsRepositoryStore}
            offerStore={offerStore}
          />
          <div
            key={configurationsStore.getActiveConfiguration()?.key}
            className={styles.pencilSellingToolContent}
          >
            {children}
          </div>
          {!userGroupModalIsOpen && (
            <FactModal
              customerType={
                factModalStore.getBaseOffer().customer.get().customerType
              }
              factModalItem={factModalItem}
              factModalMapItem={factModalStore.getActiveFactModalMapItem()}
              factModalKey={factModalStore.getFactModalActiveKey()}
              factModalStore={factModalStore}
            />
          )}
        </div>
      </CSSTransition>
      <UserGroupWarningModal
        isOpen={userGroupModalIsOpen}
        onClose={() => setUserGroupModalIsClosed(true)}
      />
    </>
  );
};

export default observer(PencilSellingContainer);
