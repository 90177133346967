import { CustomerType } from "core/entities/PencilSelling/Customer/ICustomer";

export const componentLinks = {
  household: {
    href: "https://ebs07.telekom.de/cet/front/slidesthirteen/tarife",
    label: "Tarifberatung",
  },
  fiberCheck: {
    [CustomerType.PRIVATE]: {
      href: "https://ebs07.telekom.de/cet/front/slideseleven/geschwindigkeitsvergleich",
      label: "Geschwindigkeitsvergleich",
    },
    [CustomerType.BUSINESS]: {
      href: "https://ebs07.telekom.de/cet/front/slideseleven/ausbaukarte",
      label: "Ausbaukarte",
    },
  },
  streaming: {
    href: "https://ebs07.telekom.de/cet/front/streaming-calculator",
    label: "Streaming Rechner",
  },
  smartphoneConsultation: {
    href: "https://ebs07.telekom.de/cet/front/slidesthirteen/smartphone-vergleich",
    label: "Beratung",
  },
  specialistQuestions: {
    href: "",
    label: "",
  },
  businessSize: {
    href: "https://ebs07.telekom.de/cet/front/slides-gk/geschaftskunden",
    label: "Tarifberatung",
  },
};
