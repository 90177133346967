// extracted by mini-css-extract-plugin
var _1 = "customer-requirements-specialist-questions-module-bgTransparent-qyYSy";
var _2 = "customer-requirements-specialist-questions-module-bgWhite-qcTKC";
var _3 = "customer-requirements-specialist-questions-module-colorMagenta-HPpRa";
var _4 = "customer-requirements-specialist-questions-module-colorWhite-R7cxm";
var _5 = "customer-requirements-specialist-questions-module-connectedDevicesDropdownOpenTop-JdU0M";
var _6 = "customer-requirements-specialist-questions-module-copyTextSpecialistQuestions-psBSg";
var _7 = "customer-requirements-specialist-questions-module-icon-WqYWf";
var _8 = "customer-requirements-specialist-questions-module-networkDropdownContainer-aGmzK";
var _9 = "customer-requirements-specialist-questions-module-networkSectionContainer-xvHrI";
var _a = "customer-requirements-specialist-questions-module-officeAreaDropdownOpenTop-pDkhR";
var _b = "customer-requirements-specialist-questions-module-specialistQuestionsCheckbox-XX92k";
var _c = "customer-requirements-specialist-questions-module-specialistQuestionsContainer-IFhjd";
var _d = "customer-requirements-specialist-questions-module-specialistQuestionsContent-M9NYl";
var _e = "customer-requirements-specialist-questions-module-specialistQuestionsDropdown-EOZ5J";
var _f = "customer-requirements-specialist-questions-module-streamingRecommendationPosition-NIZ0D";
var _10 = "customer-requirements-specialist-questions-module-streamingServiceHeader-IIkrY";
var _11 = "customer-requirements-specialist-questions-module-telephonyFirstRow-qMvW1";
var _12 = "customer-requirements-specialist-questions-module-telephonySectionContainer-WkgRh";
var _13 = "customer-requirements-specialist-questions-module-titleRowWithIcon-mDmFV";
var _14 = "customer-requirements-specialist-questions-module-toggleBtnContainer-rNyO0";
var _15 = "customer-requirements-specialist-questions-module-toggleBtnTile-TRzYl";
export { _1 as "bgTransparent", _2 as "bgWhite", _3 as "colorMagenta", _4 as "colorWhite", _5 as "connectedDevicesDropdownOpenTop", _6 as "copyTextSpecialistQuestions", _7 as "icon", _8 as "networkDropdownContainer", _9 as "networkSectionContainer", _a as "officeAreaDropdownOpenTop", _b as "specialistQuestionsCheckbox", _c as "specialistQuestionsContainer", _d as "specialistQuestionsContent", _e as "specialistQuestionsDropdown", _f as "streamingRecommendationPosition", _10 as "streamingServiceHeader", _11 as "telephonyFirstRow", _12 as "telephonySectionContainer", _13 as "titleRowWithIcon", _14 as "toggleBtnContainer", _15 as "toggleBtnTile" }
