import React, { useEffect } from "react";
import { Checkbox, CopyText } from "@dtpk-cc/components";
import CheckBoxBtn from "elements/new-design/CheckBoxBtn";
import { ToggleBtn } from "elements/new-design/ToggleBtn";
import { useStores } from "stores/PrivateClient";
import { StreamingServiceVariants } from "core/entities/PencilSelling/PrivateClient/CustomerRequirements/ICustomerRequirementsPC";
import { observer } from "mobx-react";
import { trackClick } from "helpers/reactTracking";
import { componentLinks } from "components/new-design/CustomerRequirements/const";
import { InfoModalVariants } from "components/new-design/CustomerRequirements/CustomerRequirementsInfoModal";
import CustomerRequirementsRecommendation from "components/new-design/CustomerRequirements/CustomerRequirementsRecommendation";
import CustomerRequirementsSectionHeader from "components/new-design/CustomerRequirements/CustomerRequirementsSectionHeader";
import { getCustomerRequirementsOptionsInterval } from "helpers/CustomerRequirements";
import MagentaHDTvLogo from "../../../../../../assets/images/icons/pencil-selling-tool/customer-requirements/Logos/magenta_hd_tv.svg";
import AppleTvPlusLogo from "../../../../../../assets/images/icons/pencil-selling-tool/customer-requirements/Logos/apple_tv_plus.svg";
import NetflixLogo from "../../../../../../assets/images/icons/pencil-selling-tool/customer-requirements/Logos/netflix.svg";
import DisneyPlusLogo from "../../../../../../assets/images/icons/pencil-selling-tool/customer-requirements/Logos/disney_plus.svg";
import RtlPlusLogo from "../../../../../../assets/images/icons/pencil-selling-tool/customer-requirements/Logos/rtl_plus.svg";
import DaznLogo from "../../../../../../assets/images/icons/pencil-selling-tool/customer-requirements/Logos/dazn.svg";
import PrimeVideoLogo from "../../../../../../assets/images/icons/pencil-selling-tool/customer-requirements/Logos/prime_video.svg";
import YoutubeLogo from "../../../../../../assets/images/icons/pencil-selling-tool/customer-requirements/Logos/youtube.svg";
import WowLogo from "../../../../../../assets/images/icons/pencil-selling-tool/customer-requirements/Logos/wow.svg";
import MagentaSportLogo from "../../../../../../assets/images/icons/pencil-selling-tool/customer-requirements/Logos/magenta_sport.svg";
import MagentaMusicStreamLogo from "../../../../../../assets/images/icons/pencil-selling-tool/customer-requirements/Logos/magenta_music_stream.svg";
import * as styles from "./customer-requirements-streaming.module.scss";
import MonitorIcon from "../../../../../../assets/images/icons/pencil-selling-tool/customer-requirements/monitor.svg";

const streamingContentMap = {
  magentaTvCheckboxes: [
    { value: false, title: "MagentaTV alt" },
    { value: true, title: "MagentaTV neu" },
  ],
  televisionTiles: getCustomerRequirementsOptionsInterval(2, [3], null),
  streamingServices: {
    col1: [
      {
        identifier: StreamingServiceVariants.MAGENTA_HD_TV,
        icon: MagentaHDTvLogo,
      },
      {
        identifier: StreamingServiceVariants.APPLE_TV,
        icon: AppleTvPlusLogo,
        customTileColor: styles.bgAppleTv,
        customCheckBoxClass: styles.checkBoxNoneWhiteBg,
      },
      {
        identifier: StreamingServiceVariants.NETFLIX,
        icon: NetflixLogo,
      },
      {
        identifier: StreamingServiceVariants.DISNEY_PLUS,
        icon: DisneyPlusLogo,
        customTileColor: styles.bgDisneyPlus,
        customCheckBoxClass: styles.checkBoxNoneWhiteBg,
      },
      {
        identifier: StreamingServiceVariants.RTL_PLUS,
        icon: RtlPlusLogo,
      },
    ],
    col2: [
      {
        identifier: StreamingServiceVariants.DAZN,
        icon: DaznLogo,
      },
      {
        identifier: StreamingServiceVariants.PRIME_VIDEO,
        icon: PrimeVideoLogo,
      },
      {
        identifier: StreamingServiceVariants.YOUTUBE_PREMIUM,
        icon: YoutubeLogo,
      },
      {
        identifier: StreamingServiceVariants.WOW,
        icon: WowLogo,
      },
      {
        identifier: StreamingServiceVariants.MAGENTA_SPORT,
        icon: MagentaSportLogo,
      },
      {
        identifier: StreamingServiceVariants.MAGENTA_MUSIC_STREAM,
        icon: MagentaMusicStreamLogo,
        customIconClass: styles.magentaMusicIcon,
      },
    ],
  },
};

type CustomerRequirementsStreamingProps = {
  openInfoModal: (variant: InfoModalVariants) => void;
  trackingContext: string;
};

const CustomerRequirementsStreaming = ({
  openInfoModal,
  trackingContext,
}: CustomerRequirementsStreamingProps) => {
  const { customerRequirementsStore } = useStores();

  // Workaround for svg gradient id issue
  // Webpack gives svgs an id of `a` which makes the uniqueness obsolete
  useEffect(() => {
    const wowIconSvg = document.getElementById(
      "streaming_service_wow-icon"
    ).children;
    wowIconSvg[1].children[0].id = "wow-gradient";
    wowIconSvg[0].setAttribute("style", "fill: url('#wow-gradient')");
  }, []);

  return (
    <div className={styles.streamingServiceContainer}>
      <CustomerRequirementsSectionHeader
        link={componentLinks.streaming}
        sectionTitle="Streaming"
        customClass={styles.streamingServiceHeader}
        titleCustomClass={styles.colorWhite}
        onInfoClick={() => openInfoModal(InfoModalVariants.STREAMING)}
      />
      <div className={styles.streamingContainer}>
        <div className={styles.streamingDevicesContainer}>
          <div className={styles.streamingDevicesWrapper}>
            <MonitorIcon className={styles.icon} />
            <div className={styles.streamingDevicesItemsContainer}>
              {streamingContentMap.televisionTiles.map((tile) => (
                <ToggleBtn
                  key={tile}
                  title={tile}
                  id={`streaming_devices_${tile}`}
                  inputTestId={`streaming_devices_${tile}`}
                  onChange={() => {
                    trackClick(`streaming.devices.${tile}`, trackingContext);
                    customerRequirementsStore.handleChangeTelevision(tile);
                  }}
                  customTitleClass={`${
                    customerRequirementsStore.requirements.streaming
                      .televisionNumber === tile
                      ? styles.colorMagenta
                      : styles.colorWhite
                  }`}
                  customTileClass={`${styles.streamingDeviceItem}
                  ${
                    customerRequirementsStore.requirements.streaming
                      .televisionNumber === tile
                      ? styles.bgWhite
                      : styles.bgTransparent
                  }`}
                  checked={
                    customerRequirementsStore.requirements.streaming
                      .televisionNumber === tile
                  }
                />
              ))}
            </div>
          </div>
          <Checkbox
            checked={
              customerRequirementsStore.requirements.streaming
                .resolutionActive || false
            }
            onChange={() => {
              trackClick("streaming.4k/8k", trackingContext);
              customerRequirementsStore.toggleResolution();
            }}
            wrapperProps={{ customClass: styles.streamingCheckbox }}
          >
            <CopyText customClass={styles.copyTextStreaming}>4k/8k</CopyText>
          </Checkbox>
          <CustomerRequirementsRecommendation
            description={customerRequirementsStore.recommendations.streaming}
            customClass={styles.streamingRecommendationPosition}
          />
        </div>
        <div className={styles.streamingServiceTileFlex}>
          <div className={styles.streamingServiceContentLeft}>
            <div className={styles.magentaTvCheckboxesContainer}>
              {streamingContentMap.magentaTvCheckboxes.map((item) => (
                <Checkbox
                  checked={
                    customerRequirementsStore.requirements.streaming
                      .newMagentaTv === item.value
                  }
                  onChange={() => {
                    trackClick(
                      `streaming.magentatv.${item.value ? "neu" : "alt"}`,
                      trackingContext
                    );
                    customerRequirementsStore.setNewMagentaTvWithToggle(
                      item.value
                    );
                  }}
                  key={item.title}
                  wrapperProps={{ customClass: styles.streamingCheckbox }}
                >
                  <CopyText customClass={styles.copyTextStreaming}>
                    {item.title}
                  </CopyText>
                </Checkbox>
              ))}
            </div>
            <div className={styles.streamingServiceTilesContainer}>
              {streamingContentMap.streamingServices.col1.map((item) => (
                <CheckBoxBtn
                  key={item.identifier}
                  id={`streaming_service_${item.identifier}`}
                  name={`streaming_service_${item.identifier}`}
                  inputTestId={`streaming_service_${item.identifier}`}
                  Icon={item.icon}
                  checked={customerRequirementsStore.requirements.streaming.activeStreamingServices.includes(
                    item.identifier
                  )}
                  onChange={() => {
                    trackClick(
                      `streaming.activeStreamingServices.${item.identifier}`,
                      trackingContext
                    );
                    customerRequirementsStore.handleStreamingServiceChange(
                      item.identifier
                    );
                  }}
                  customTileClass={`${item.customTileColor ?? styles.bgWhite} ${
                    styles.streamingServiceTiles
                  }`}
                  customTitleClass={styles.streamingServiceLogo}
                  customCheckboxClass={item.customCheckBoxClass}
                  iconWrapperClass={styles.iconWrapper}
                />
              ))}
            </div>
          </div>
          <div className={styles.streamingServiceTilesContainer}>
            {streamingContentMap.streamingServices.col2.map((item) => (
              <CheckBoxBtn
                key={item.identifier}
                id={`streaming_service_${item.identifier}`}
                name={`streaming_service_${item.identifier}`}
                inputTestId={`streaming_service_${item.identifier}`}
                Icon={item.icon}
                checked={customerRequirementsStore.requirements.streaming.activeStreamingServices.includes(
                  item.identifier
                )}
                onChange={() =>
                  customerRequirementsStore.handleStreamingServiceChange(
                    item.identifier
                  )
                }
                customTileClass={styles.streamingServiceTilesSecondary}
                customTitleClass={styles.streamingServiceLogo}
                iconWrapperClass={styles.iconWrapper}
                customIconClass={item.customIconClass}
              />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default observer(CustomerRequirementsStreaming);
