import { ICartItemData } from "../core/entities/PencilSelling/CartItem/ICartItem";
import { PromotionPriceType } from "../core/entities/Product/IDiscount";
import { IPromotion } from "../core/entities/PencilSelling/IPromotion";

export const getCashbackPromotions = (
  cartItems: ICartItemData[]
): IPromotion[] =>
  cartItems
    .map((cartItem) => cartItem.promotions)
    .flat()
    .filter((promotion) => promotion.kind === PromotionPriceType.CASHBACK);
