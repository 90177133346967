import {
  ICustomPromotionRequest,
  ICustomPromotionResponse,
} from "../core/repositories/ProductsRepository/ResponseInterface";
import { CustomPromotionTypes } from "../core/entities/PencilSelling/ICustomPromotion";
import { IPromotion } from "../core/entities/PencilSelling/IPromotion";
import { PromotionPriceType } from "../core/entities/Product/IDiscount";

const customPromotionTypesMapToRequest: Partial<
  Record<PromotionPriceType, CustomPromotionTypes>
> = {
  [PromotionPriceType.MONTHLY_DISCOUNT]:
    CustomPromotionTypes.PROMOTION_TYPE_MONTHLY_REDUCE,
  [PromotionPriceType.PROVISION_DISCOUNT]:
    CustomPromotionTypes.PROMOTION_TYPE_ONCE_REDUCE,
  [PromotionPriceType.JUST_A_NUMBER]:
    CustomPromotionTypes.PROMOTION_TYPE_WITHOUT_PRICE,
  [PromotionPriceType.CASHBACK]: CustomPromotionTypes.PROMOTION_TYPE_CASHBACK,
};

const customPromotionTypesMapFromRequest: Partial<
  Record<CustomPromotionTypes, PromotionPriceType>
> = {
  [CustomPromotionTypes.PROMOTION_TYPE_MONTHLY_REDUCE]:
    PromotionPriceType.MONTHLY_DISCOUNT,
  [CustomPromotionTypes.PROMOTION_TYPE_ONCE_REDUCE]:
    PromotionPriceType.PROVISION_DISCOUNT,
  [CustomPromotionTypes.PROMOTION_TYPE_WITHOUT_PRICE]:
    PromotionPriceType.JUST_A_NUMBER,
  [CustomPromotionTypes.PROMOTION_TYPE_CASHBACK]: PromotionPriceType.CASHBACK,
};

// TODO discuss with the backend clean-up flow when there will be a capacity for him
export const mapCustomPromotionToRequest = (
  promotion: IPromotion
): ICustomPromotionRequest => ({
  custom_promotion: {
    key: promotion.key,
    name: promotion.name,
    portfolio: promotion.portfolio,
    description: promotion.description,
    type: customPromotionTypesMapToRequest[promotion.kind],
    pricing: {
      value: promotion.discounts[0].value,
      kind: customPromotionTypesMapToRequest[promotion.kind],
      quantity: promotion.discounts[0].interval,
      from: promotion.discounts[0].from,
      to: promotion.discounts[0].to,
    },
  },
});

export const mapCustomPromotionFromRequest = (
  promotion: ICustomPromotionResponse | null
): IPromotion | null =>
  promotion
    ? {
        isEditable: true,
        isCustom: true,
        key: promotion.key,
        name: promotion.name,
        description: promotion.description,
        portfolio: promotion.portfolio,
        kind: customPromotionTypesMapFromRequest[promotion.pricing.kind],
        conditions: [],
        summaryDescription: {
          summary: "",
          text: "",
        },
        discounts: [
          {
            value: promotion.pricing.value,
            kind: customPromotionTypesMapFromRequest[promotion.pricing.kind],
            interval: promotion.pricing.quantity,
            from: promotion.pricing.from,
            to: promotion.pricing.to,
          },
        ],
      }
    : null;
