export const GROSS_PRICE_PREFIX = "brutto";
export const NET_PRICE_PREFIX = "netto";

export const CUSTOMER_DATA_DEFAULT = {
  companyName: "",
  contactPerson: "",
  address: "",
  landlineNumber: "",
  mobileNumber: "",
  frameworkContract: "",
  kek: false,
  prevContract: "",
};
