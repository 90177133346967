import { ITariff } from "core/entities/Product/Tariff/ITariff";

type GetTariffDataVolumeProps = {
  isBenefitActive: boolean;
  isNextMagentaActive?: boolean;
  tariff: ITariff;
};

export const getTariffDataVolume = ({
  isBenefitActive,
  tariff,
  isNextMagentaActive,
}: GetTariffDataVolumeProps): string => {
  const { dataVolume, dataVolumeNextMagenta, dataVolumeMagentaOne } = tariff;

  if (isNextMagentaActive && dataVolumeNextMagenta) {
    return dataVolumeNextMagenta;
  }

  if (isBenefitActive && dataVolumeMagentaOne) {
    return dataVolumeMagentaOne;
  }

  return dataVolume;
};
