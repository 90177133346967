import React, { useState } from "react";
import BackDrop from "elements/new-design/ModalWrapper/BackDrop";
import { trackClick } from "helpers/reactTracking";
import HomeInternetAtHomeDefault from "@dtpk-cc/components/dist/icons/Home/InternetAtHome/Default";
import Logo from "../../../../assets/images/icons/pencil-selling-tool/navigation/logo.svg";
import NavCustomerRequirementsIcon from "../../../../assets/images/icons/pencil-selling-tool/navigation/customer-requirements.svg";
import NavIconProfile from "../../../../assets/images/icons/pencil-selling-tool/navigation/profile.svg";
import NavBaseDataIcon from "../../../../assets/images/icons/pencil-selling-tool/navigation/base-data.svg";
import NavMobileTariffIcon from "../../../../assets/images/icons/pencil-selling-tool/navigation/mobile-tariff.svg";
import NavSecondaryCardsIcon from "../../../../assets/images/icons/pencil-selling-tool/navigation/secondary-cards.svg";
import NavLandlineTariffIcon from "../../../../assets/images/icons/pencil-selling-tool/navigation/landline-tariff.svg";
import NavDevicesIcon from "../../../../assets/images/icons/pencil-selling-tool/navigation/devices.svg";
import NavOfferFinalisationIcon from "../../../../assets/images/icons/pencil-selling-tool/navigation/offer-finalisation.svg";
import NavSummaryIcon from "../../../../assets/images/icons/pencil-selling-tool/navigation/summary.svg";
import NavSketchBoardIcon from "../../../../assets/images/icons/pencil-selling-tool/navigation/sketchboard.svg";
import NavToolsIcon from "../../../../assets/images/icons/pencil-selling-tool/navigation/tools.svg";
import NavResetIcon from "../../../../assets/images/icons/pencil-selling-tool/navigation/reset.svg";
import NavFullScreenIcon from "../../../../assets/images/icons/pencil-selling-tool/navigation/fullscreen.svg";

import * as styles from "./navigation.module.scss";
import {
  NAV_TITLE_BASE_DATA,
  NAV_TITLE_DEVICES,
  NAV_TITLE_FULLSCREEN,
  NAV_TITLE_HEIMNETZ,
  NAV_TITLE_LANDLINE_TARIFF,
  NAV_TITLE_MOBILE_TARIFF,
  NAV_TITLE_CUSTOMER_REQUIREMENTS,
  NAV_TITLE_OFFER_FINALISATION,
  NAV_TITLE_RESET,
  NAV_TITLE_SECONDARY_CARDS,
  NAV_TITLE_SKETCHBOARD,
  NAV_TITLE_SUMMARY,
  NAV_TITLE_TOOLS,
} from "./const";
import { INavigationMap, INavigationMapSection } from "./INavigation";
import NavigationSection from "./NavigationSection";
import ProfileMenu from "./ProfileMenu";
import ToolsMenu from "./ToolsMenu";
import WarningModal from "../WarningModal";
import { BaseOffer } from "../../../core/entities/PencilSelling/BaseOffer/BaseOffer";
import { ProductsRepository } from "../../../core/repositories/ProductsRepository/ProductsRepository";
import { PAGE_HOME } from "../../../routes/extern-url";

export const TRACKING_CONTEXT = "Navigationselement klicken";

type NavigationProps = {
  userName: string;
  initialActiveMenuItem?: string;
  navigationMenuMap: INavigationMap;
  toggleSketchboard: () => void;
  appResetHandler: () => void;
  adjustNavigationMap?: (navMap: INavigationMap) => void;
  activeFlyoutItemKey: string | null;
  setActiveFlyoutItemKey: (value: string | null) => void;
  activeTabItemKey: string;
  setActiveTabItemKey: (value: string | null) => void;
  activeFlyoutKey: string | null;
  setActiveFlyoutKey: (value: string | null) => void;
  backDropIsActive: boolean;
  setBackDropIsActive: (value: boolean) => void;
  offerStore: BaseOffer;
  productsRepositoryStore: ProductsRepository;
};

type FlyoutNavItemClickHandlerProps = {
  isActive: boolean;
  key: string;
};

const Navigation = ({
  userName,
  navigationMenuMap,
  toggleSketchboard,
  appResetHandler,
  adjustNavigationMap = () => {},
  activeFlyoutItemKey,
  setActiveFlyoutItemKey,
  activeTabItemKey,
  setActiveTabItemKey,
  activeFlyoutKey,
  setActiveFlyoutKey,
  backDropIsActive,
  setBackDropIsActive,
  offerStore,
  productsRepositoryStore,
}: NavigationProps) => {
  const [resetModalIsActive, setResetModalIsActive] = useState(false);
  const iconsMap = {
    [userName]: NavIconProfile,
    [NAV_TITLE_CUSTOMER_REQUIREMENTS]: NavCustomerRequirementsIcon,
    [NAV_TITLE_BASE_DATA]: NavBaseDataIcon,
    [NAV_TITLE_MOBILE_TARIFF]: NavMobileTariffIcon,
    [NAV_TITLE_SECONDARY_CARDS]: NavSecondaryCardsIcon,
    [NAV_TITLE_LANDLINE_TARIFF]: NavLandlineTariffIcon,
    [NAV_TITLE_DEVICES]: NavDevicesIcon,
    [NAV_TITLE_OFFER_FINALISATION]: NavOfferFinalisationIcon,
    [NAV_TITLE_SUMMARY]: NavSummaryIcon,
    [NAV_TITLE_SKETCHBOARD]: NavSketchBoardIcon,
    [NAV_TITLE_TOOLS]: NavToolsIcon,
    [NAV_TITLE_RESET]: NavResetIcon,
    [NAV_TITLE_FULLSCREEN]: NavFullScreenIcon,
    [NAV_TITLE_HEIMNETZ]: HomeInternetAtHomeDefault,
  };

  const fullscreenToggleHandler = async () => {
    const elem = document.documentElement;

    if (
      document.fullscreenElement ||
      document.webkitFullscreenElement ||
      document.msFullscreenElement
    ) {
      if (document.exitFullscreen) {
        await document.exitFullscreen();
      } else if (document?.webkitExitFullscreen) {
        /* Chrome, Safari and Opera */
        await document.webkitExitFullscreen();
      } else if (document.msExitFullscreen) {
        /* IE/Edge */
        await document.msExitFullscreen();
      } else if (document.mozCancelFullScreen) {
        /* Firefox */
        await document.mozCancelFullScreen();
      }
    } else if (elem.requestFullscreen) {
      await elem.requestFullscreen();
    } else if (elem?.webkitRequestFullscreen) {
      /* Chrome, Safari and Opera */
      await elem.webkitRequestFullscreen();
    } else if (elem.msRequestFullscreen) {
      /* IE/Edge */
      await elem.msRequestFullscreen();
    } else if (elem.mozRequestFullScreen) {
      /* Firefox */
      await elem.mozRequestFullScreen();
    }
  };

  const inactivateFlyout = () => {
    setActiveFlyoutItemKey(null);
    setActiveFlyoutKey(null);
    setBackDropIsActive(false);
  };

  const flyoutNavItemClickHandler = ({
    isActive,
    key,
  }: FlyoutNavItemClickHandlerProps) => {
    if (!isActive) {
      setActiveFlyoutItemKey(key);
      setActiveFlyoutKey(key);
      setBackDropIsActive(true);
    } else {
      inactivateFlyout();
    }
  };

  const mainNavigationMap = {
    profile: {
      title: null,
      items: [
        {
          title: userName,
          onClick: ({ isActive, key }: FlyoutNavItemClickHandlerProps) => {
            flyoutNavItemClickHandler({ isActive, key });
            trackClick(userName, TRACKING_CONTEXT);
          },
          getChildren: ({ key }) =>
            key === activeFlyoutKey ? (
              <ProfileMenu
                offerStore={offerStore}
                productsRepositoryStore={productsRepositoryStore}
                onClick={inactivateFlyout}
              />
            ) : null,
          withArrow: true,
        },
      ],
    },
    tools: {
      title: "weitere tools",
      items: [
        {
          title: NAV_TITLE_SKETCHBOARD,
          onClick: () => {
            toggleSketchboard();
            trackClick(NAV_TITLE_SKETCHBOARD, TRACKING_CONTEXT);
          },
          getChildren: () => null,
          withArrow: true,
        },
        {
          title: NAV_TITLE_TOOLS,
          onClick: ({ isActive, key }: FlyoutNavItemClickHandlerProps) => {
            flyoutNavItemClickHandler({ isActive, key });
            trackClick(NAV_TITLE_TOOLS, TRACKING_CONTEXT);
          },
          getChildren: ({ key }) =>
            key === activeFlyoutKey ? (
              <ToolsMenu onClick={inactivateFlyout} />
            ) : null,
          withArrow: true,
        },
      ],
    },
    options: {
      title: "optionen",
      items: [
        {
          title: NAV_TITLE_RESET,
          onClick: () => {
            setActiveFlyoutItemKey(null);
            setActiveFlyoutKey(null);
            setBackDropIsActive(false);
            setResetModalIsActive(true);
            trackClick(NAV_TITLE_RESET, TRACKING_CONTEXT);
          },
          getChildren: () => null,
          withArrow: false,
        },
        {
          title: NAV_TITLE_FULLSCREEN,
          onClick: async () => {
            await fullscreenToggleHandler();
            trackClick(NAV_TITLE_FULLSCREEN, TRACKING_CONTEXT);
          },
          getChildren: () => null,
          withArrow: false,
        },
      ],
    },
  };

  adjustNavigationMap(mainNavigationMap);

  const getNavigationSection = ({
    navigationMapSection,
    activeItemKey,
    activeFlyoutKeyValue,
    setActiveItem,
  }: {
    navigationMapSection: INavigationMapSection;
    activeItemKey: string | null;
    activeFlyoutKeyValue: string | null;
    setActiveItem: (value: string | null) => void;
  }) => (
    <NavigationSection
      navigationMapSection={navigationMapSection}
      activeItemKey={activeItemKey}
      setActiveItem={setActiveItem}
      setActiveFlyoutKey={setActiveFlyoutKey}
      activeFlyoutKey={activeFlyoutKeyValue}
      iconsMap={iconsMap}
      setBackDropIsActive={setBackDropIsActive}
      userName={userName}
    />
  );

  return (
    <>
      <div
        role="navigation"
        className={`${styles.navigation} ${
          backDropIsActive ? styles.withFlyout : ""
        }`}
      >
        <div className={styles.inner}>
          <div className={`m-b-24 m-l-12 ${styles.logoLinkWrapper}`}>
            {/* TODO replace with NavLink when HomePage will be applied */}
            <a href={PAGE_HOME} tabIndex={0} className={`${styles.logoLink}`}>
              <Logo />
            </a>
          </div>
          {getNavigationSection({
            navigationMapSection: mainNavigationMap.profile,
            activeItemKey: activeFlyoutItemKey,
            activeFlyoutKeyValue: activeFlyoutKey,
            setActiveItem: setActiveFlyoutItemKey,
          })}
          {getNavigationSection({
            navigationMapSection: navigationMenuMap.menu,
            activeItemKey: activeTabItemKey,
            activeFlyoutKeyValue: activeFlyoutKey,
            setActiveItem: (key) => {
              // Deselect all nav item with flyout
              setActiveFlyoutItemKey(null);
              setActiveTabItemKey(key);
            },
          })}

          <div className={styles.bottom}>
            <hr className={`mx-12 m-t-0 ${styles.delimiter}`} />
            {getNavigationSection({
              navigationMapSection: mainNavigationMap.tools,
              activeItemKey: activeFlyoutItemKey,
              activeFlyoutKeyValue: activeFlyoutKey,
              setActiveItem: setActiveFlyoutItemKey,
            })}
            {getNavigationSection({
              navigationMapSection: mainNavigationMap.options,
              activeItemKey: null,
              activeFlyoutKeyValue: null,
              setActiveItem: setActiveFlyoutItemKey,
            })}
          </div>
        </div>
      </div>
      {backDropIsActive && (
        <BackDrop
          withPortal={false}
          onClick={() => {
            trackClick("flyout.schließen", TRACKING_CONTEXT);
            setActiveFlyoutItemKey(null);
            setActiveFlyoutKey(null);
            setBackDropIsActive(false);
          }}
        />
      )}
      <WarningModal
        mainText="Bei Erstellung eines neuen Angebots werden alle zuvor getätigten Eingaben gelöscht. Willst du wirklich fortfahren?"
        titleText="Zurücksetzen"
        cancelBtnText="Abbrechen"
        submitBtnText="Ja, zurücksetzen"
        onSubmit={() => {
          appResetHandler();
          trackClick("neues-angebot");
          setResetModalIsActive(false);
          setActiveFlyoutItemKey(null);
          setActiveTabItemKey(NAV_TITLE_BASE_DATA);
          setActiveFlyoutKey(null);
        }}
        onCancel={() => {
          setResetModalIsActive(false);
        }}
        isOpen={resetModalIsActive}
      />
    </>
  );
};

export default Navigation;
