import React from "react";
import { useStores } from "stores/BusinessClient";
import { observer } from "mobx-react";
import DropdownMain from "elements/new-design/DropdownMain";
import { BusinessSize } from "core/entities/PencilSelling/BusinessClient/CustomerRequirements/ICustomerRequirementsBC";
import { componentLinks } from "components/new-design/CustomerRequirements/const";
import { InfoModalVariants } from "components/new-design/CustomerRequirements/CustomerRequirementsInfoModal";
import CustomerRequirementsRecommendation from "components/new-design/CustomerRequirements/CustomerRequirementsRecommendation";
import CustomerRequirementsSectionHeader from "components/new-design/CustomerRequirements/CustomerRequirementsSectionHeader";
import { trackClick } from "helpers/reactTracking";
import { getCustomerRequirementsOptionsInterval } from "helpers/CustomerRequirements";
import * as styles from "./customer-requirements-business-size.module.scss";
import CeoIcon from "../../../../../../assets/images/icons/pencil-selling-tool/customer-requirements/ceo.svg";
import OfficeChairIcon from "../../../../../../assets/images/icons/pencil-selling-tool/customer-requirements/office_chair.svg";
import OfficeBuildingIcon from "../../../../../../assets/images/icons/pencil-selling-tool/customer-requirements/office_building.svg";
import EmployeeIcon from "../../../../../../assets/images/icons/pencil-selling-tool/customer-requirements/employee.svg";

type BusinessSizeItem = {
  icon: React.FunctionComponent<React.SVGAttributes<SVGElement>>;
  identifier: keyof BusinessSize;
  dropDownValues: string[];
  dropDownLabel: string;
};

const dropdownValues = {
  default: getCustomerRequirementsOptionsInterval(4, [5]),
  employee: getCustomerRequirementsOptionsInterval(9, [10, 20]),
};

const businessSizeContentItems: BusinessSizeItem[] = [
  {
    icon: CeoIcon,
    identifier: "ceos",
    dropDownValues: dropdownValues.default,
    dropDownLabel: "Anzahl Geschäftsführer",
  },
  {
    icon: OfficeChairIcon,
    identifier: "employeesInOffice",
    dropDownValues: dropdownValues.employee,
    dropDownLabel: "Mitarbeiter im Büro",
  },
  {
    icon: EmployeeIcon,
    identifier: "employees",
    dropDownValues: dropdownValues.employee,
    dropDownLabel: "Anzahl Mitarbeiter",
  },
  {
    icon: OfficeBuildingIcon,
    identifier: "locations",
    dropDownValues: dropdownValues.default,
    dropDownLabel: "Anzahl Standorte",
  },
];

type CustomerRequirementsBusinessSizeProps = {
  openInfoModal: (variant: InfoModalVariants) => void;
  trackingContext: string;
};

const CustomerRequirementsBusinessSize = ({
  openInfoModal,
  trackingContext,
}: CustomerRequirementsBusinessSizeProps) => {
  const { customerRequirementsStore } = useStores();
  return (
    <div className={styles.businessSizeContainer}>
      <CustomerRequirementsSectionHeader
        sectionTitle="firma | mitarbeiter"
        link={componentLinks.businessSize}
        sectionSubtitle="Unbegrenztes Datenvolumen für alle Mitarbeiter"
        onInfoClick={() => openInfoModal(InfoModalVariants.BUSINESS_SIZE)}
      />
      <div className={styles.businessSizeItemsContainer}>
        {businessSizeContentItems.map((item) => (
          <div className={styles.businessSizeItemsRow} key={item.identifier}>
            <item.icon className={styles.icon} />
            <DropdownMain
              label={item.dropDownLabel}
              items={item.dropDownValues}
              value={
                customerRequirementsStore.requirements.businessSize[
                  item.identifier
                ] || ""
              }
              onSelection={(value) => {
                trackClick(
                  `businesssize.${item.identifier}.${value || "0"}`,
                  trackingContext
                );
                customerRequirementsStore.setBusinessSize(
                  item.identifier,
                  value
                );
              }}
              customClass={styles.dropdown}
            />
          </div>
        ))}
      </div>
      <CustomerRequirementsRecommendation
        description={""}
        customClass={styles.businessSizeRecommendationPosition}
      />
    </div>
  );
};

export default observer(CustomerRequirementsBusinessSize);
