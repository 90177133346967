import React, { useState } from "react";
import { observer } from "mobx-react";
import { CopyText } from "@dtpk-cc/components";
import TariffConfigurationExtraSelectionModal from "../Tariff/TariffConfiguration/TariffConfigurationExtraSelectionModal";
import { trackClick } from "../../../helpers/reactTracking";
import PromotionSelectionItem from "../../../elements/new-design/ExtraSelection/PromotionSelectionItem";
import { IPromotion } from "../../../core/entities/PencilSelling/IPromotion";
import { IStepTrackingContext } from "../../../views/PencilSelling/IPencilSelling";
import { ITariff } from "../../../core/entities/Product/Tariff/ITariff";
import { PaymentTypes } from "../../../core/entities/PencilSelling/CartItem/ICartItem";
import { ICard } from "../../../core/entities/Product/Card/ICard";
import { CustomPromotionTypes } from "../../../core/entities/PencilSelling/ICustomPromotion";
import CustomPromotionsSection from "./CustomPromotionsSection";
import { PromotionPriceType } from "../../../core/entities/Product/IDiscount";

import * as styles from "./promotion-selection.module.scss";
import { BaseOffer } from "../../../core/entities/PencilSelling/BaseOffer/BaseOffer";
import { ProductsRepository } from "../../../core/repositories/ProductsRepository/ProductsRepository";
import { PortfolioKeys } from "../../../core/entities/Product/IProduct";
import { ICustomPromotionsForm } from "./IPromotionSelection";

type PromotionSelectionProps = {
  trackingContext: IStepTrackingContext;
  businessCaseName: string;
  productInCartName: string | null;
  activeProductItem: ITariff | ICard | null;
  customClass?: string;
  selectedPromotions: IPromotion[];
  customPromotions?: IPromotion[];
  promotions: IPromotion[];
  setPromotionsModalIsOpen: (value: boolean) => void;
  promotionsModalIsOpen: boolean;
  addPromotionsHandler: (promotions: IPromotion[]) => void;
  withCustomPromotions?: boolean;
  offerStore: BaseOffer;
  productsRepositoryStore: ProductsRepository;
  portfolioKey: PortfolioKeys;
  withMultipleOnceMonthlyPromotions?: boolean;
};

export const DEFAULT_FORM_CONFIG = {
  name: "",
  kind: null,
  value: null,
  key: null,
  from: null,
  to: null,
  interval: null,
};

const promotionsTypeMap = {
  [PaymentTypes.MONTHLY]: [
    PromotionPriceType.MONTHLY,
    PromotionPriceType.MONTHLY_DISCOUNT,
    PromotionPriceType.MONTHLY_DISCOUNT_IN_PERCENT,
    CustomPromotionTypes.PROMOTION_TYPE_MONTHLY_REDUCE,
  ],
  [PaymentTypes.ONCE]: [
    PromotionPriceType.DISCOUNT,
    PromotionPriceType.PROVISION_DISCOUNT,
    PromotionPriceType.PROVISION_DISCOUNT_IN_PERCENT,
    CustomPromotionTypes.PROMOTION_TYPE_ONCE_REDUCE,
  ],
};

const PromotionSelection = ({
  trackingContext,
  businessCaseName,
  productInCartName,
  activeProductItem,
  selectedPromotions,
  promotions,
  addPromotionsHandler,
  setPromotionsModalIsOpen,
  promotionsModalIsOpen,
  offerStore,
  productsRepositoryStore,
  portfolioKey,
  customPromotions = [],
  withCustomPromotions = false,
  withMultipleOnceMonthlyPromotions = true,
}: PromotionSelectionProps) => {
  const [modalPromotionsState, setModalPromotionsState] =
    useState(selectedPromotions);
  const [isAddMode, setIsAddMode] = useState(false);
  const [isFormVisible, setIsFormVisible] = useState(false);
  const [formState, setFormState] =
    useState<ICustomPromotionsForm>(DEFAULT_FORM_CONFIG);

  const availablePromotions = withCustomPromotions
    ? [...promotions, ...customPromotions]
    : promotions;

  /**
   * Checks if a promotion matches a certain payment type.
   *
   * @param promotion - The promotion object to check.
   * @param type - The payment type to match against.
   * @returns A boolean indicating whether the promotion has a matching type.
   */
  const hasMatchingPromotionType = (
    promotion: IPromotion,
    type: PaymentTypes
  ) => promotionsTypeMap[type].includes(promotion.kind);

  // Determine if a promotion should be disabled
  const getPromotionIsDisabled = (promotion: IPromotion) => {
    // Check for selected and current promotions with specified type ( monthly or once -> list is in promotionsTypeMap ).
    const isSecondPromotionOfType = (paymentType: PaymentTypes) =>
      modalPromotionsState.some(
        (selectedPromotion) =>
          hasMatchingPromotionType(selectedPromotion, paymentType) &&
          hasMatchingPromotionType(promotion, paymentType)
      );
    // If we can find current promotion is selected state -> don't disable it, to be able to deselect. Other wise -> disable.
    const promotionIsSelected = !!(
      modalPromotionsState.find(
        (selectedPromotion) => selectedPromotion.key === promotion.key
      ) || null
    );

    // don't disable if multiple promotions are allowed or if item is already selected
    if (withMultipleOnceMonthlyPromotions || promotionIsSelected) {
      return false;
    }

    // disable if another promotion of the same type is already selected
    return (
      isSecondPromotionOfType(PaymentTypes.MONTHLY) ||
      isSecondPromotionOfType(PaymentTypes.ONCE)
    );
  };

  const handlePromotionSelection = (promotion: IPromotion) => {
    setModalPromotionsState((prevPromotions) => {
      const promotionExists = prevPromotions.some(
        (prevPromotion) => prevPromotion.key === promotion.key
      );
      if (promotionExists) {
        // Update existing promotion by filtering it out from current state and adding new one down below
        return prevPromotions.filter(
          (prevPromotion) => prevPromotion.key !== promotion.key
        );
      }

      return [...prevPromotions, promotion];
    });
  };

  const submitModalHandler = () => {
    addPromotionsHandler(modalPromotionsState);
    setPromotionsModalIsOpen(false);
    trackClick("aktions-modal-auswahl-übernehmen", trackingContext.main);
  };

  const getPromotionEditHandler = (promotion: IPromotion) =>
    promotion.isCustom && promotion.isEditable
      ? () => {
          const promotionDiscount = promotion.discounts[0];
          setIsFormVisible(true);
          setIsAddMode(false);
          setFormState({
            name: promotion.name,
            kind: promotion.kind,
            key: promotion.key,
            value:
              promotionDiscount.value || promotionDiscount.value === 0
                ? promotionDiscount.value.toString()
                : null,
            interval: promotionDiscount.interval
              ? promotionDiscount.interval.toString()
              : null,
            from: promotionDiscount.from
              ? promotionDiscount.from.toString()
              : null,
            to: promotionDiscount.to ? promotionDiscount.to.toString() : null,
          });
        }
      : null;

  return (
    <TariffConfigurationExtraSelectionModal
      customClass={`${styles.mainWrapper} ${
        offerStore.isLoading ? styles.isLoading : ""
      } ${withCustomPromotions ? styles.withCustomPromotions : ""}`}
      title="Aktionsangebote"
      isOpen={promotionsModalIsOpen}
      setIsOpen={setPromotionsModalIsOpen}
      businessCaseName={businessCaseName}
      tariffName={productInCartName}
      onSubmit={() => {
        submitModalHandler();
        trackClick(
          `${trackingContext.portfolio}.modales.aktionen.fenster`,
          trackingContext.main
        );
      }}
      onCancel={() => {
        setPromotionsModalIsOpen(false);
        trackClick(
          `${trackingContext.portfolio}.modales.aktionen.schließen`,
          trackingContext.main
        );
      }}
    >
      <div
        className={`${styles.content} ${
          withCustomPromotions ? styles.withCustomPromotions : ""
        }`}
      >
        <div
          className={`${styles.promotionsWrapper} ${
            withCustomPromotions ? styles.withCustomPromotions : ""
          }`}
        >
          {availablePromotions.length > 0 ? (
            availablePromotions.map((promotion) => (
              <PromotionSelectionItem
                key={promotion.key}
                id={promotion.key}
                disabled={getPromotionIsDisabled(promotion)}
                discounts={promotion.discounts}
                productPrice={
                  "applyBeforeFrameworkContract" in promotion &&
                  promotion.applyBeforeFrameworkContract &&
                  typeof activeProductItem.price
                    .monthlyPriceBeforeFrameworkDiscount === "number"
                    ? activeProductItem.price
                        .monthlyPriceBeforeFrameworkDiscount
                    : activeProductItem?.price.monthly
                }
                name={promotion?.description || promotion.name}
                checked={
                  !!modalPromotionsState.find(
                    (promotionItem) => promotionItem.key === promotion.key
                  )
                }
                onChange={() => {
                  handlePromotionSelection(promotion);
                  trackClick(
                    promotion?.description || promotion.name,
                    trackingContext.main
                  );
                }}
                title={promotion?.description || promotion.name}
                subtitle={promotion?.additionalDescription || null}
                onEdit={getPromotionEditHandler(promotion)}
                kind={promotion.kind}
                isCustom={promotion.isCustom}
              />
            ))
          ) : (
            <CopyText>
              In der Kombination aus Geschäftsfall und Tarif sind aktuell keine
              Aktionsangebote verfügbar.
            </CopyText>
          )}
        </div>
        {withCustomPromotions && (
          <CustomPromotionsSection
            portfolioKey={portfolioKey}
            offerStore={offerStore}
            productsRepositoryStore={productsRepositoryStore}
            isAddMode={isAddMode}
            setIsAddMode={setIsAddMode}
            isFormVisible={isFormVisible}
            setIsFormVisible={setIsFormVisible}
            formState={formState}
            setFormState={setFormState}
            modalPromotionsState={modalPromotionsState}
            setModalPromotionsState={setModalPromotionsState}
            addPromotionsHandler={addPromotionsHandler}
            availablePromotions={availablePromotions}
            selectedPromotions={selectedPromotions}
            defaultFormConfig={DEFAULT_FORM_CONFIG}
          />
        )}
      </div>
    </TariffConfigurationExtraSelectionModal>
  );
};

export default observer(PromotionSelection);
