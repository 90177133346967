import {
  DeviceDeviceTvDefault,
  DeviceDeviceRouterDefault,
  DeviceDevicePhoneDefault,
} from "@dtpk-cc/components/dist/icons";
import {
  Bandwidths,
  BusinessCasesLandline,
  BusinessCasesMobile,
  IBusinessCasesTypeValues,
  ITariff,
} from "core/entities/Product/Tariff/ITariff";
import { ITariffWorld, ILevel } from "core/entities/Product/IProduct";
import { TariffWorldKeys } from "core/repositories/ProductsRepository/DefinitionKeys";
import { ExpansionArea } from "core/entities/Applications";
import {
  ILandlineSettings,
  IMobileSettings,
  ICartConfigurationSettings,
} from "../../entities/PencilSelling/Configurations/IConfigurations";
import { ICardSettings } from "../../entities/PencilSelling/Offer_legacy/IOffer";
import { getTariffLevels } from "../../../helpers/GetTariffLevels";

export class TariffPresenter {
  private tariffs: ITariff[];

  tariffsInViewport = 4;

  constructor(
    tariffs: ITariff[],
    private settings: ICartConfigurationSettings | ICardSettings,
    private isLandline: boolean,
    private businessCase: IBusinessCasesTypeValues | null // null is for getSecondaryCardsTariffs method
  ) {
    this.tariffs = tariffs;
  }

  isTariffExist(bandwidth: Bandwidths): boolean {
    return this.get().some((tariff) => Bandwidths[tariff.size] === bandwidth);
  }

  getSecondaryCardsTariffs(): ITariff[] {
    const { isYoung, level, tariffWorld } = this.settings;

    return this.tariffs.filter(
      (tariff) =>
        tariff.tariffWorld.key === tariffWorld &&
        tariff.isYoung === isYoung &&
        tariff.level.key === level
    );
  }

  // TODO: Use Strategy Pattern
  private filterMobile(
    shouldFilterLevel = true,
    tariffWorldParam = ""
  ): ITariff[] {
    const { isYoung, level, tariffWorld, isVario } = this
      .settings as IMobileSettings;

    const tariffWorldKey = tariffWorldParam || tariffWorld;

    return this.tariffs.filter((tariff) => {
      const dontFilterLevel = !shouldFilterLevel || this.levels.length < 2;
      const tariffIsAvailableForAllBusinessCases =
        tariff.availableForBusinessCases.length === 0;

      return (
        tariff.tariffWorld.key === tariffWorldKey &&
        tariff.isYoung === isYoung &&
        tariff.isVario === isVario &&
        (tariffIsAvailableForAllBusinessCases ||
          (tariff.availableForBusinessCases as BusinessCasesMobile[]).includes(
            this.businessCase as BusinessCasesMobile
          )) &&
        (dontFilterLevel || tariff.level.key === level)
      );
    });
  }

  // TODO: Use Strategy Pattern
  private filterLandline(
    shouldFilterLevel = true,
    tariffWorldParam = ""
  ): ITariff[] {
    const {
      isYoung,
      level,
      tariffWorld,
      fiber,
      bandwidth,
      expansionArea,
      isVario,
    } = this.settings as ILandlineSettings;

    const tariffWorldKey = tariffWorldParam || tariffWorld;
    const filteredTariffs = this.tariffs.filter((tariff) => {
      const dontFilterFiber = !fiber;
      const dontFilterLevel = !shouldFilterLevel || this.levels.length < 2;
      const tariffIsAvailableForAllBusinessCases =
        tariff.availableForBusinessCases.length === 0;

      return (
        tariff.tariffWorld.key === tariffWorldKey &&
        (tariff.expansionArea.includes(expansionArea) ||
          tariff.expansionArea.length === 0) &&
        tariff.isYoung === isYoung &&
        tariff.isVario === isVario &&
        Bandwidths[tariff.size] <= bandwidth &&
        (dontFilterFiber || tariff.isFiber === fiber) &&
        (tariffIsAvailableForAllBusinessCases ||
          (
            tariff.availableForBusinessCases as BusinessCasesLandline[]
          ).includes(this.businessCase as BusinessCasesLandline)) &&
        (dontFilterLevel || tariff.level.key === level)
      );
    });

    return (bandwidth === 1 && expansionArea === ExpansionArea.telekom) ||
      expansionArea === ExpansionArea.regio
      ? this.filterHybridTariffByConditions(filteredTariffs)
      : TariffPresenter.filterHybridTariff(filteredTariffs);
  }

  // Special bandwith/size condition handling for magenta_home_hybrid  #1080
  private static filterHybridTariff(filteredTariffs: ITariff[]): ITariff[] {
    return filteredTariffs.filter(
      (tariff) => tariff.key !== "magenta_home_hybrid"
    );
  }

  private filterHybridTariffByConditions(
    filteredTariffs: ITariff[]
  ): ITariff[] {
    let validBusinessCase: boolean;
    if (this.businessCase) {
      validBusinessCase =
        this.businessCase === BusinessCasesLandline.NEUKUNDE ||
        this.businessCase === BusinessCasesLandline.BESTANDSKUNDE_DP ||
        this.businessCase === BusinessCasesLandline.KOMPLETTWECHSLER ||
        this.businessCase === BusinessCasesLandline.BREITBAND_NEUKUNDE;
    }

    return validBusinessCase
      ? filteredTariffs
      : TariffPresenter.filterHybridTariff(filteredTariffs);
  }

  get(): ITariff[] {
    return this.isLandline ? this.filterLandline() : this.filterMobile();
  }

  get tariffWorlds(): ITariffWorld[] {
    let uniqueTariffWorlds = this.tariffs.reduce((acc, { tariffWorld }) => {
      const tariffWorldExists = acc.some(({ key }) => tariffWorld.key === key);

      return tariffWorldExists ? acc : [...acc, tariffWorld];
    }, [] as ITariffWorld[]);

    // remove tariff worlds without tariffs
    uniqueTariffWorlds = this.isLandline
      ? uniqueTariffWorlds.filter(
          (tariffWorld) => this.filterLandline(false, tariffWorld.key).length
        )
      : uniqueTariffWorlds.filter(
          (tariffWorld) => this.filterMobile(false, tariffWorld.key).length
        );

    return uniqueTariffWorlds.sort(
      (current, next) => current.valency - next.valency
    );
  }

  get levels(): ILevel[] {
    const filteredTariffs = this.isLandline
      ? this.filterLandline(false)
      : this.filterMobile(false);
    return getTariffLevels(filteredTariffs);
  }

  get maximalTariffScrollValue() {
    return this.get().length - this.tariffsInViewport + 1;
  }

  // Check if the current selected level in setting is still available in #this.levels
  get activeLevelExists(): boolean {
    return this.levels.some(({ key }) => this.settings.level === key);
  }

  get tariffLevelDisplayText(): string {
    if (this.settings.tariffWorld === TariffWorldKeys.magenta_home_tv) {
      return "MagentaTV-Variante auswählen";
    }

    if (this.settings.tariffWorld === TariffWorldKeys.zuhause_sofort) {
      return "Variante auswählen";
    }
    return "Substufe auswählen";
  }

  get icon() {
    switch (this.settings.tariffWorld) {
      case "magenta_home":
        return DeviceDeviceRouterDefault;
      case "magenta_home_regio":
        return DeviceDeviceRouterDefault;
      case "magenta_home_tv":
        return DeviceDeviceTvDefault;
      default:
        return DeviceDevicePhoneDefault;
    }
  }

  get promotionText() {
    return this.isLandline
      ? "Festnetz Aktionen hinzufügen"
      : "Mobilfunk Aktionen hinzufügen";
  }

  getLevelIndex(key: string) {
    const index = this.levels.findIndex((level) => level.key === key);

    return index === -1 ? 0 : index;
  }

  // dynamic product description
  static getMobileTariffSummary(
    summaryText: string,
    dataVolume: string,
    dataVolumeMagentaOne: string,
    dataVolumeNextMagenta: string,
    benefitIsActive: boolean,
    dataVolumeExtra?: string,
    streamingServiceIsActive?: boolean,
    applyNextMagenta?: boolean
  ): string {
    let dataVolumeSummary = benefitIsActive
      ? `${dataVolumeMagentaOne} statt ${dataVolume} Datenvolumen im besten`
      : `${dataVolume} Datenvolumen im besten`;

    // Covering the case for MagentaMobil Young S & M, which don't have dataVolumeMagentaOne
    if (!dataVolumeMagentaOne && !streamingServiceIsActive) {
      dataVolumeSummary = `${dataVolume} Datenvolumen im besten`;
    }

    if (
      (!dataVolumeMagentaOne && streamingServiceIsActive) ||
      (dataVolumeMagentaOne && !benefitIsActive && streamingServiceIsActive)
    ) {
      dataVolumeSummary = `${dataVolume} + ${dataVolumeExtra} statt ${dataVolume} Datenvolumen im besten`;
    }

    if (dataVolumeNextMagenta && applyNextMagenta) {
      dataVolumeSummary = `${dataVolumeNextMagenta} Datenvolumen im besten`;
    }

    return TariffPresenter.interpolateDescriptionSummaryWithParams(
      summaryText,
      dataVolumeSummary
    );
  }

  static getMobileTariffDescription(
    descriptionText: string,
    dataVolume: string,
    dataVolumeMagentaOne: string,
    dataVolumeNextMagenta: string,
    benefitIsActive: boolean,
    dataVolumeExtra?: string,
    streamingServiceIsActive?: boolean,
    applyNextMagenta?: boolean
  ): string {
    // NOTE: We have cards that have dynamic text and cards without dynamic text
    let dataVolumeText = benefitIsActive
      ? `mit ${dataVolumeMagentaOne} statt ${dataVolume} Datenvolumen (MagentaEINS Vorteil)`
      : `mit ${dataVolume} Datenvolumen (MagentaEINS Kunden erhalten ${dataVolumeMagentaOne})`;

    if (!dataVolumeMagentaOne && !streamingServiceIsActive) {
      dataVolumeText = `mit ${dataVolume} Datenvolumen`;
    }

    if (
      (!dataVolumeMagentaOne && streamingServiceIsActive) ||
      (dataVolumeMagentaOne && !benefitIsActive && streamingServiceIsActive)
    ) {
      dataVolumeText = `mit ${dataVolume} + ${dataVolumeExtra} statt ${dataVolume} Datenvolumen`;
    }

    if (dataVolumeMagentaOne && !benefitIsActive && streamingServiceIsActive) {
      dataVolumeText = `mit ${dataVolume} + ${dataVolumeExtra} statt ${dataVolume} Datenvolumen (MagentaEINS Kunden erhalten ${dataVolumeMagentaOne})`;
    }

    if (dataVolumeNextMagenta && applyNextMagenta) {
      dataVolumeText = `mit ${dataVolumeNextMagenta} Datenvolumen`;
    }

    return TariffPresenter.interpolateMobileDescriptionTextWithParams(
      descriptionText,
      dataVolumeText
    );
  }

  static getLandlineTariffDescription(
    descriptionText: string,
    benefitIsActive: boolean
  ): string {
    // NOTE: We have cards that have dynamic text and cards without dynamic text
    const flatrateText = benefitIsActive
      ? "ins dt. Festnetz und alle dt. Mobilfunknetze (MagentaEINS Vorteil)"
      : "ins dt. Festnetz (MagentaEINS Kunden erhalten eine Festnetz zu Mobilfunk Flat)";

    return TariffPresenter.interpolateLandlineDescriptionTextWithParams(
      descriptionText,
      flatrateText
    );
  }

  /* eslint-disable @typescript-eslint/no-implied-eval */
  /* eslint-disable @typescript-eslint/no-unsafe-return */
  private static interpolateDescriptionSummaryWithParams(
    descriptionText: string,
    dataVolumeSummary: string
  ): string {
    return new Function(
      "descriptionText",
      "dataVolumeSummary",
      `return \`${descriptionText}\`;`
    )(descriptionText, dataVolumeSummary);
  }

  private static interpolateLandlineDescriptionTextWithParams(
    descriptionText: string,
    flatrateText: string
  ): string {
    return new Function(
      "descriptionText",
      "flatrateText",
      `return \`${descriptionText}\`;`
    )(descriptionText, flatrateText);
  }

  private static interpolateMobileDescriptionTextWithParams(
    descriptionText: string,
    dataVolumeText: string
  ): string {
    return new Function(
      "descriptionText",
      "dataVolumeText",
      `return \`${descriptionText}\`;`
    )(descriptionText, dataVolumeText);
  }
}
