import { makeAutoObservable, runInAction } from "mobx";
import { v4 as uuidv4 } from "uuid";
import { ITariff } from "core/entities/Product/Tariff/ITariff";
import { nextMagentaIsApplied } from "helpers/GetNextMagenta";
import { Cart as ChildrenCart } from "../Cart/Cart";
import {
  ICartConfiguration,
  ICartConfigurationData,
  ICartConfigurationSettings,
  ICartConfigurationType,
} from "./IConfigurations";
import { IBenefit } from "../../Product/IBenefit";
import { ICartItemData } from "../CartItem/ICartItem";

export class ConfigurationsEntity {
  configurations: ICartConfiguration[] = [];

  activeConfiguration: ICartConfiguration | null = null;

  constructor() {
    makeAutoObservable(this);
  }

  getConfigurations(): ICartConfiguration[] {
    return this.configurations;
  }

  getConfigurationByKey(key: string): ICartConfiguration | null {
    return (
      this.configurations.find((configuration) => configuration.key === key) ||
      null
    );
  }

  getConfigurationsByType(type: ICartConfigurationType): ICartConfiguration[] {
    return this.configurations.filter(
      (configuration) => configuration.type === type
    );
  }

  setConfigurationName(configurationKey: string, name: string | null): void {
    const targetConfiguration = this.getConfigurationByKey(configurationKey);

    targetConfiguration.name = name;
  }

  addConfiguration(configuration: ICartConfiguration): void {
    this.configurations.push({
      ...configuration,
      key: uuidv4(),
    });
    this.activeConfiguration =
      this.configurations[this.configurations.length - 1];
  }

  // Should be used for configurations and children
  replaceConfiguration(key: string, configuration: ICartConfiguration): void {
    this.configurations = this.configurations.map((configurationItem) =>
      configurationItem.key === key ? configuration : configurationItem
    );
  }

  removeConfiguration(keysArr: string[]): void {
    this.configurations = this.configurations.filter(
      (configurationItem) => !keysArr.includes(configurationItem.key)
    );
  }

  getActiveConfiguration(): ICartConfiguration {
    return this.activeConfiguration;
  }

  setActiveConfiguration(configuration: ICartConfiguration): void {
    this.activeConfiguration = configuration;
  }

  // Check does active configuration has selected tariff
  getActiveConfigurationChildren(): ChildrenCart {
    return this.activeConfiguration.children;
  }

  getActiveConfigurationAmount(): number | null {
    return this.activeConfiguration?.amount || null;
  }

  setActiveConfigurationAmount(amount: number): void {
    runInAction(() => {
      this.activeConfiguration.amount = amount;
    });
  }

  setConfigurationData(
    targetConfigurationKey: string,
    data: Partial<ICartConfigurationData>
  ) {
    const configuration = this.getConfigurationByKey(targetConfigurationKey);
    if (configuration) {
      configuration.data = {
        ...configuration.data,
        ...data,
      };
    }
  }

  getActiveConfigurationData() {
    return this.activeConfiguration.data;
  }

  getActiveConfigurationSettings<
    T extends ICartConfigurationSettings
  >(): T | null {
    return this.activeConfiguration
      ? (this.activeConfiguration.data.settings as T)
      : null;
  }

  setActiveConfigurationSettings<T extends ICartConfigurationSettings>({
    key,
    value,
    settingsObj,
  }: {
    key: keyof T;
    value: T[keyof T];
    settingsObj?: T;
  }): void {
    runInAction(() => {
      const settings = this.getActiveConfigurationSettings<T>();
      if (settingsObj) {
        Object.keys(settingsObj).forEach((keyValue) => {
          settings[keyValue] = settingsObj[keyValue] as T[keyof T];
        });
        return;
      }

      settings[key] = value;
    });
  }

  setActiveConfigurationNote(value: string): void {
    this.activeConfiguration.data.note = value;
  }

  setActiveConfigurationBenefit(benefit: IBenefit | null): void {
    runInAction(() => {
      this.activeConfiguration.data.benefit = benefit;
    });
  }

  getActiveConfigurationBenefitIsActive(): boolean {
    return this.activeConfiguration.data.isBenefitActive;
  }

  setActiveConfigurationBenefitIsActive(isActive: boolean): void {
    this.activeConfiguration.data.isBenefitActive = isActive;
  }

  setActiveConfigurationNextMagentaIsActive(isActive: boolean): void {
    this.activeConfiguration.data.isNextMagentaActive = isActive;
  }

  applyNextMagentaForConfiguration(
    configurationKey: string,
    mobileTariff: ITariff | ICartItemData | null
  ): boolean {
    const configuration = this.getConfigurationByKey(configurationKey);

    const { isBenefitActive, isNextMagentaActive } = configuration.data;

    return nextMagentaIsApplied(
      isBenefitActive,
      isNextMagentaActive,
      mobileTariff?.key
    );
  }

  getActiveConfigurationBenefit(): IBenefit | null {
    return this.activeConfiguration.data.benefit;
  }

  getConfigurationWithAppliedLoyaltyBonus(): ICartConfiguration | null {
    return (
      this.configurations.find((configuration) => {
        if (
          configuration.data.settings &&
          "loyaltyBonusValue" in configuration.data.settings
        ) {
          return configuration.data.settings?.loyaltyBonusValue > 0;
        }

        return false;
      }) || null
    );
  }

  activeConfigurationIsWithActiveBenefit(
    type: ICartConfigurationType
  ): boolean {
    const configurationWithBenefit =
      this.getConfigurationsByType(type).find(
        (configuration) => configuration.data.isBenefitActive
      ) || null;
    if (!configurationWithBenefit) return true;

    return (
      !!configurationWithBenefit &&
      this.activeConfiguration.key === configurationWithBenefit.key
    );
  }

  activeConfigurationIsWithLoyaltyBonus(): boolean {
    const configurationWithLoyaltyBonus =
      this.getConfigurationWithAppliedLoyaltyBonus();

    if (configurationWithLoyaltyBonus) {
      return this.activeConfiguration.key === configurationWithLoyaltyBonus.key;
    }
    return true;
  }

  resetConfigurationsByType(type: ICartConfigurationType): void {
    if (this.activeConfiguration && this.activeConfiguration.type === type) {
      this.activeConfiguration = null;
    }

    this.configurations = this.configurations.filter(
      (configuration) => configuration.type !== type
    );
  }

  getActiveConfigurationLinkedConfigurationKey(): string | null {
    return this.activeConfiguration.linkedConfigurationKey;
  }

  linkConfigurations(targetConfigKey: string, linkConfigKey: string): void {
    const targetConfiguration = this.getConfigurationByKey(targetConfigKey);
    const linkConfiguration = this.getConfigurationByKey(linkConfigKey);

    targetConfiguration.linkedConfigurationKey = linkConfiguration.key;
    linkConfiguration.linkedConfigurationKey = targetConfiguration.key;
  }

  setConfigurationLinkedConfigurationKey(
    targetConfigurationKey: string,
    linkedConfigurationKey: string
  ): void {
    const configuration = this.getConfigurationByKey(targetConfigurationKey);
    configuration.linkedConfigurationKey = linkedConfigurationKey;
  }

  getConfigurationLinkedConfigurationKey(
    targetConfigurationKey: string
  ): string | null {
    const configuration = this.getConfigurationByKey(targetConfigurationKey);

    return configuration?.linkedConfigurationKey || null;
  }

  reset(): void {
    this.activeConfiguration = null;
    this.configurations = [];
  }
}
