import type { IPromotion } from "core/entities/PencilSelling/IPromotion";
import type { ITariff as ITariffProduct } from "core/entities/Product/Tariff/ITariff";
import {
  ICard,
  ICardDetailSection,
  ICardDetail,
  CardExport,
  CardLimits,
  CardSections,
} from "core/entities/Product/Card/ICard";
import {
  CardWorldKeys,
  TariffWorldKeys,
} from "core/repositories/ProductsRepository/DefinitionKeys";
import { PromotionPriceType } from "core/entities/Product/IDiscount";
import { TariffLevelsKeys } from "core/entities/Product/Tariff/ITariff";
import { makeAutoObservable } from "mobx";
import {
  IMaxAmount,
  ITariff,
  AdditionalCardModes,
  IBenefitsData,
  TariffDataExport,
  ISettings,
} from "./ITariff";
import { BenefitKeys, ILevel } from "../../Product/IProduct";
import { getTariffLevels } from "../../../../helpers/GetTariffLevels";
import { getHappyHourBenefitOptionName } from "../../../../helpers/GetBenefitOptions";
import { HAPPY_HOUR_BENEFIT_OPTION_DESCRIPTION } from "../../../../constants/const";
import { IBenefitOption } from "../../Product/IBenefit";

export const BENEFIT_VALUE = 5;

const INITIAL_SETTINGS = {
  level: TariffLevelsKeys.PHONE_NONE,
  hasBenefit: false,
  hasProvisionFee: false,
  loyaltyBonusValue: 0,
  smartphoneName: "",
  smartphonePrice: null,
};

const INITIAL_CARD_DETAIL_SECTION = {
  [CardSections.MAGENTA_NEXT]: [],
  [CardSections.MAGENTA_NEXT_SECONDARY]: [],
  [CardSections.MAGENTA_MOBILE_KIDS_CARD]: [],
  [CardSections.MAGENTA_MOBILE_DATA_CARD]: [],
  [CardSections.COMBI_CARD_S]: [],
  [CardSections.COMBI_CARD_M]: [],
  [CardSections.ADDITIONAL_CARD]: [],
};

export class Tariff implements ITariff {
  private tariffs: ITariffProduct[] = [];

  private cards: ICard[] = [];

  private promotions: IPromotion[] = [];

  private benefitsData: IBenefitsData = {
    isActive: false,
    price: null,
    title: "",
    happyHourBenefitOptionDescription: null,
    list: [],
  };

  private cardDetailSection: ICardDetailSection = INITIAL_CARD_DETAIL_SECTION;

  private defaultPeriod = 24;

  private settings: ISettings = INITIAL_SETTINGS;

  constructor(private maxAmount: IMaxAmount) {
    makeAutoObservable(this);
  }

  // Extend interface, mocks.

  getSettings(): ISettings {
    return this.settings;
  }

  setSettings(settings: ISettings): void {
    this.settings = settings;
  }

  getLoyaltyBonus(): number {
    return this.settings.loyaltyBonusValue;
  }

  setLoyaltyBonus(value: number) {
    this.settings.loyaltyBonusValue = value;
  }

  getValueCalculatedByPeriod(value: number, period = this.defaultPeriod) {
    return value / period;
  }

  getBenefitsData(): IBenefitsData {
    const magentaMobilTariff = this.getMagentaMobileTariffs(true)[0];

    if (!magentaMobilTariff || !magentaMobilTariff.benefits?.length)
      return this.benefitsData;

    const benefitsOptions = magentaMobilTariff.benefits[0].options.filter(
      (option) => !!option.name
    );

    const indexOfDiscountBenefit = benefitsOptions.findIndex(
      (option) => option.key === BenefitKeys.discount
    );

    let discountBenefit: IBenefitOption = null;

    if (indexOfDiscountBenefit !== -1) {
      [discountBenefit] = benefitsOptions.splice(indexOfDiscountBenefit, 1);
    }

    if (discountBenefit) {
      benefitsOptions.unshift(discountBenefit);
    }

    return {
      ...this.benefitsData,
      title: magentaMobilTariff.benefits[0].name,
      price: discountBenefit ? discountBenefit.value : null,
      happyHourBenefitOptionDescription: getHappyHourBenefitOptionName(
        magentaMobilTariff.benefits[0]
      )
        ? HAPPY_HOUR_BENEFIT_OPTION_DESCRIPTION
        : null,
      list: benefitsOptions.map((option) => ({
        name: option.name,
        value: option.value,
        key: option.key,
      })),
    };
  }

  get additionalCardMode(): AdditionalCardModes {
    if (this.getMagentaMobileTariffs(true).length)
      return AdditionalCardModes.MAGENTA_MOBILE_PLUS;
    if (this.getFamilyCards(true).length)
      return AdditionalCardModes.FAMILY_CARD;

    return null;
  }

  get isCardLimitReached(): boolean {
    const additionalCards = [
      ...this.getMagentaNextCard(true),
      ...this.getFamilyCards(true),
      ...this.getMagentaNextSecondaryCard(true),
      ...this.getMagentaMobileKidsCards(true),
      ...this.getMagentaMobileDataCards(true),
      ...this.getCombiCards(true),
      ...this.getMultiSimCards(true),
    ];

    const cardAmount = additionalCards.reduce(
      (acc, { quantity }) => acc + quantity,
      0
    );

    return cardAmount >= CardLimits.TOTAL;
  }

  setPromotions(promotions: IPromotion[]): void {
    this.promotions = promotions;
  }

  getPromotions(): IPromotion[] {
    return this.promotions;
  }

  getSavingsTotalPrice = () => {
    const benefitPromotionSummary = this.settings.hasBenefit
      ? this.getBenefitsData().price * this.defaultPeriod +
        Number(this.settings.loyaltyBonusValue)
      : 0;

    const result =
      Number(benefitPromotionSummary) +
      this.getPromotionsTotalPrice() * this.defaultPeriod;

    return result;
  };

  getPromotionsTotalPrice(): number {
    return this.promotions.reduce((acc, item) => {
      const value = item.discounts[0]?.value || 0;
      if (typeof value !== "number" || value === 0) return acc;

      const contractPeriod = item.discounts[0]?.interval ?? this.defaultPeriod;

      const averageItemCosts =
        (value * 100 * contractPeriod) / this.defaultPeriod;

      return (acc * 100 + averageItemCosts) / 100;
    }, 0);
  }

  setCards(cards: ICard[]): void {
    this.cards = cards;
  }

  getCard(key: string): ICard {
    return this.cards.find((card) => card.key === key);
  }

  setCardSectionItem(
    sectionKey: keyof ICardDetailSection,
    cardDetails: ICardDetail[]
  ): void {
    this.cardDetailSection[sectionKey] = cardDetails;
  }

  getCardDetailSection(): ICardDetailSection {
    return this.cardDetailSection;
  }

  setTariffs(tariffs: ITariffProduct[]) {
    this.tariffs = tariffs;
  }

  getCards(): CardExport {
    return {
      // Hauptkarten
      [CardSections.MAGENTA_MOBILE_CARD]: this.getMagentaMobileTariffs(),
      // Zusatzkarten
      [CardSections.MAGENTA_NEXT]: this.getMagentaNextCard(),
      [CardSections.FAMILY_CARD]: this.getFamilyCards(),
      // Weitere MagentaNext Zusatzkarten (?)
      [CardSections.MAGENTA_NEXT_SECONDARY]: this.getMagentaNextSecondaryCard(),
      [CardSections.MAGENTA_MOBILE_KIDS_CARD]: this.getMagentaMobileKidsCards(),
      [CardSections.MAGENTA_MOBILE_DATA_CARD]: this.getMagentaMobileDataCards(),
      // Weitere Zusatzkarten
      [CardSections.COMBI_CARD]: this.getCombiCards(),
      [CardSections.ADDITIONAL_CARD]: this.getMultiSimCards(),
    };
  }

  getActiveCards(): CardExport {
    return {
      [CardSections.MAGENTA_MOBILE_CARD]: this.getMagentaMobileTariffs(true),
      [CardSections.MAGENTA_NEXT]: this.getMagentaNextCard(true),
      [CardSections.FAMILY_CARD]: this.getFamilyCards(true),
      [CardSections.MAGENTA_NEXT_SECONDARY]:
        this.getMagentaNextSecondaryCard(true),
      [CardSections.MAGENTA_MOBILE_KIDS_CARD]:
        this.getMagentaMobileKidsCards(true),
      [CardSections.MAGENTA_MOBILE_DATA_CARD]:
        this.getMagentaMobileDataCards(true),
      [CardSections.COMBI_CARD]: this.getCombiCards(true),
      [CardSections.ADDITIONAL_CARD]: this.getMultiSimCards(true),
    };
  }

  setQuantity(key: string, quantity: number): void {
    const productToChange = [...this.cards, ...this.tariffs].find(
      (product) => key === product.key
    );
    // if (cardToChange && this.maxAmount[key] >= quantity && quantity > -1)
    if (productToChange && quantity > -1) productToChange.quantity = quantity;

    // use this line for testing without maximal amounts
    // if (cardToChange && quantity > -1) cardToChange.quantity = quantity;
  }

  getTotalQuantity(): number {
    return (
      this.tariffs.reduce((acc, { quantity }) => acc + (quantity || 0), 0) +
      this.cards.reduce((acc, { quantity }) => acc + (quantity || 0), 0)
    );
  }

  getHasBenefit(): boolean {
    return this.settings.hasBenefit;
  }

  setHasBenefit(hasBenefit: boolean): void {
    this.settings.hasBenefit = hasBenefit;
  }

  getHasProvisionFee(): boolean {
    return this.settings.hasProvisionFee;
  }

  setHasProvisionFee(hasProvisionFee: boolean): void {
    this.settings.hasProvisionFee = hasProvisionFee;
  }

  getMobileTariffsLevels(): ILevel[] {
    return getTariffLevels(this.tariffs);
  }

  getMagentaMobileTariffs(onlyActive = false): ITariffProduct[] {
    return this.tariffs.filter(
      ({ tariffWorld, quantity }) =>
        tariffWorld.key === TariffWorldKeys.magenta_mobile &&
        (!onlyActive || quantity)
    );
  }

  getMagentaNextCard(onlyActive?: boolean): ICard[] {
    return this.cards.filter(
      ({ tariffWorld, quantity }) =>
        tariffWorld.key === CardWorldKeys.magenta_mobile_next_card_main &&
        (!onlyActive || quantity)
    );
  }

  getMagentaNextSecondaryCard(onlyActive?: boolean): ICard[] {
    return this.cards.filter(
      ({ tariffWorld, quantity }) =>
        tariffWorld.key === CardWorldKeys.magenta_mobile_next_card_secondary &&
        (!onlyActive || quantity)
    );
  }

  getMagentaMobileKidsCards(onlyActive?: boolean): ICard[] {
    return this.cards.filter(
      ({ tariffWorld, quantity }) =>
        tariffWorld.key === CardWorldKeys.magenta_mobile_next_card_kids_cards &&
        (!onlyActive || quantity)
    );
  }

  getMagentaMobileDataCards(onlyActive?: boolean): ICard[] {
    return this.cards.filter(
      ({ tariffWorld, quantity }) =>
        tariffWorld.key === CardWorldKeys.magenta_mobile_next_card_data_cards &&
        (!onlyActive || quantity)
    );
  }

  getCombiCards(onlyActive?: boolean): ICard[] {
    return this.cards.filter(
      ({ tariffWorld, quantity }) =>
        tariffWorld.key ===
          CardWorldKeys.magenta_mobile_combi_card_smart_connect &&
        (!onlyActive || quantity)
    );
  }

  getMultiSimCards(onlyActive?: boolean): ICard[] {
    return this.cards.filter(
      ({ tariffWorld, quantity }) =>
        tariffWorld.key === CardWorldKeys.magenta_mobile_multi_sim &&
        (!onlyActive || quantity)
    );
  }

  getFamilyCards(onlyActive?: boolean): ICard[] {
    return this.cards.filter(
      ({ tariffWorld, quantity, isLegacy }) =>
        tariffWorld.key === CardWorldKeys.magenta_mobile_family_card_cards &&
        (!onlyActive || quantity) &&
        isLegacy
    );
  }

  getNextCardPrice(): number {
    return this.getMagentaNextCard(true).reduce(
      (acc, item) =>
        (acc * 100 + item.price.monthly * 100 * item.quantity) / 100,
      0
    );
  }

  getNextSecondaryCardPrice(): number {
    return this.getMagentaNextSecondaryCard(true).reduce(
      (acc, item) =>
        (acc * 100 + item.price.monthly * 100 * item.quantity) / 100,
      0
    );
  }

  getMagentaMobileCardPrice(): number {
    return this.getMagentaMobileTariffs(true).reduce(
      (acc, item) =>
        (acc * 100 + item.price.monthly * 100 * item.quantity) / 100,
      0
    );
  }

  getMagentaMobileKidsCardPrice(): number {
    return this.getMagentaMobileKidsCards(true).reduce(
      (acc, item) =>
        (acc * 100 + item.price.monthly * 100 * item.quantity) / 100,
      0
    );
  }

  getMagentaMobileDataCardPrice(): number {
    return this.getMagentaMobileDataCards(true).reduce(
      (acc, item) =>
        (acc * 100 + item.price.monthly * 100 * item.quantity) / 100,
      0
    );
  }

  getCombiCardsPrice(): number {
    return this.getCombiCards(true).reduce(
      (acc, item) =>
        (acc * 100 + item.price.monthly * 100 * item.quantity) / 100,
      0
    );
  }

  getMultiSimCardsPrice(): number {
    return this.getMultiSimCards(true).reduce(
      // (acc, item) => this.calculateActiveCardPriceWithProvision(acc, item),
      (acc, item) =>
        (acc * 100 + item.price.monthly * 100 * item.quantity) / 100,
      0
    );
  }

  getFamilyCardsPrice(): number {
    return this.getFamilyCards(true).reduce(
      (acc, item) =>
        (acc * 100 + item.price.monthly * 100 * item.quantity) / 100,
      0
    );
  }

  getTotalPrice(): number {
    const benefitsData = this.getBenefitsData();
    const benefitPrice =
      this.getHasBenefit() && typeof benefitsData.price === "number"
        ? -benefitsData.price
        : 0;
    const result =
      this.getMagentaMobileCardPrice() +
      this.getMagentaMobileKidsCardPrice() +
      this.getMagentaMobileDataCardPrice() +
      this.getCombiCardsPrice() +
      this.getMultiSimCardsPrice() +
      this.getFamilyCardsPrice() +
      this.getNextCardPrice() +
      this.getNextSecondaryCardPrice() +
      benefitPrice;

    return result;
  }

  mapCardsDetailsToCards() {
    // Deep clone the object without using lodash cloneDeep
    const activeCards = JSON.parse(
      JSON.stringify(this.getActiveCards())
    ) as CardExport;

    const mapCardDetailsToCards = (
      cards: ICard[],
      cardDetailsArr: ICardDetail[]
    ): ICard[] => {
      const currentCards = [...cards];

      cardDetailsArr.forEach((cardDetails) => {
        const currentCard = currentCards.find(
          (card) => card.key === cardDetails.cardKey
        );

        const smartphoneData = {
          name: cardDetails.smartphoneName,
          price: cardDetails.smartphonePrice,
        };

        if (currentCard?.cardDetails) {
          const currentSmartphoneData = [
            ...(currentCard.cardDetails.smartphoneData || []),
            smartphoneData,
          ];

          currentCard.cardDetails.smartphoneData = currentSmartphoneData;
        } else {
          currentCard.cardDetails = {
            smartphoneData: [smartphoneData],
          };
        }
      });
      return currentCards;
    };

    return {
      ...activeCards,
      [CardSections.MAGENTA_NEXT]: mapCardDetailsToCards(
        activeCards[CardSections.MAGENTA_NEXT],
        this.getCardDetailSection()[CardSections.MAGENTA_NEXT]
      ),
      [CardSections.MAGENTA_NEXT_SECONDARY]: mapCardDetailsToCards(
        activeCards[CardSections.MAGENTA_NEXT_SECONDARY],
        this.getCardDetailSection()[CardSections.MAGENTA_NEXT_SECONDARY]
      ),
      [CardSections.MAGENTA_MOBILE_KIDS_CARD]: mapCardDetailsToCards(
        activeCards[CardSections.MAGENTA_MOBILE_KIDS_CARD],
        this.getCardDetailSection()[CardSections.MAGENTA_MOBILE_KIDS_CARD]
      ),
      [CardSections.MAGENTA_MOBILE_DATA_CARD]: mapCardDetailsToCards(
        activeCards[CardSections.MAGENTA_MOBILE_DATA_CARD],
        this.getCardDetailSection()[CardSections.MAGENTA_MOBILE_DATA_CARD]
      ),
      [CardSections.COMBI_CARD]: mapCardDetailsToCards(
        activeCards[CardSections.COMBI_CARD],
        [
          ...this.getCardDetailSection()[CardSections.COMBI_CARD_S],
          ...this.getCardDetailSection()[CardSections.COMBI_CARD_M],
        ]
      ),
    };
  }

  export(): TariffDataExport {
    return {
      cards: this.mapCardsDetailsToCards(),
      totalPrice: this.getTotalPrice(),
      credit: this.getPromotionsTotalPrice(),
      quantity: this.getTotalQuantity(),
      promotions: this.getPromotions().filter(
        (promotionItem) =>
          promotionItem.discounts[0]?.value &&
          promotionItem.discounts[0]?.interval
      ),
      benefits: {
        ...this.getBenefitsData(),
        isActive: this.getHasBenefit(),
      },
      loyaltyBonus: this.getLoyaltyBonus(),
      hasProvisionFee: this.settings.hasProvisionFee,
      smartphoneName: this.settings.smartphoneName,
      smartphonePrice: this.settings.smartphonePrice,
    };
  }

  reset(): void {
    this.tariffs = this.tariffs.map((tariff) => ({
      ...tariff,
      quantity: 0,
    }));
    this.cards = this.cards.map((card) => ({
      ...card,
      cardDetails: null,
      quantity: 0,
    }));
    this.cardDetailSection = {
      [CardSections.MAGENTA_NEXT]: [],
      [CardSections.MAGENTA_NEXT_SECONDARY]: [],
      [CardSections.MAGENTA_MOBILE_KIDS_CARD]: [],
      [CardSections.MAGENTA_MOBILE_DATA_CARD]: [],
      [CardSections.COMBI_CARD_S]: [],
      [CardSections.COMBI_CARD_M]: [],
      [CardSections.ADDITIONAL_CARD]: [],
    };
    this.promotions = [];
    this.settings = INITIAL_SETTINGS;
  }
}
