export enum UseCaseVariantsBusiness {
  USV = "usv",
  TK_SYSTEMS = "tk_systems",
  ROUTER = "router",
  CASH_DESK = "cash_desk",
}

export enum CallLocationVariants {
  HOMEOFFICE = "homeoffice",
  INTERNATIONAL = "international",
  MOBILE = "mobile",
}

export enum ITSupportVariants {
  ON_SITE = "on_site",
  INHOUSE_IT_SUPPORT = "inhouse_it_support",
  CUSTOMER_WLAN = "customer_wlan",
}

export type BusinessSize = {
  ceos: string;
  employees: string;
  employeesInOffice: string;
  locations: string;
};

export type TelephonyConfig = {
  maxParallelCalls: string;
  callLocations: CallLocationVariants[];
};

export type NetworkConfig = {
  internetVisibility: boolean;
  crm: boolean;
  itSupport: ITSupportVariants[];
  connectedDevices: string;
  sizeOfOffice: string;
};

export type SpecialistQuestionsConfig = {
  telephony: TelephonyConfig;
  network: NetworkConfig;
};

export enum SmartphoneChoices {
  SMARTPHONE_CONSULATION = "smartphone-consultation",
  MOBILE_SECURITY = "mobile-security",
}

export interface ICustomerRequirementsBC {
  useCases: UseCaseVariantsBusiness[];
  businessSize: BusinessSize;
  smartphoneChoices: SmartphoneChoices[];
  specialistQuestions: SpecialistQuestionsConfig;
  selectedBranche: string;
  recommendations: Partial<Record<keyof ICustomerRequirementsBC, string>>;
}
