import React from "react";
import {
  A11yText,
  Button,
  CopyText,
  Heading,
  Icon,
  TextLink,
} from "@dtpk-cc/components";
import {
  AlertInformationDefault,
  LpgContinueOutline,
} from "@dtpk-cc/components/dist/icons";
import * as styles from "./customer-requirements-section-header.module.scss";

type LinkObject = {
  href: string;
  label: string;
};

type CustomerRequirementsSectionHeaderProps = {
  sectionTitle: string;
  sectionSubtitle?: string;
  link?: LinkObject;
  onInfoClick?: () => void;
  showInfoIcon?: boolean;
  titleCustomClass?: string;
  customClass?: string;
};

const CustomerRequirementsSectionHeader = ({
  sectionTitle,
  sectionSubtitle,
  link = undefined,
  onInfoClick,
  showInfoIcon = true,
  titleCustomClass = "",
  customClass = "",
}: CustomerRequirementsSectionHeaderProps) => (
  <div className={`${customClass} ${styles.titleSubtitleContainer}`}>
    <div className={styles.titleLinkContainer}>
      <div className={styles.titleInfoIconContainer}>
        <Heading
          tag="h3"
          variants={[Heading.Variant.tertiary, Heading.Variant.uppercase]}
          customClass={`${styles.sectionTitle} ${titleCustomClass}`}
        >
          {sectionTitle}
        </Heading>
        {showInfoIcon && (
          <Button
            data-testid="landline-business-case-info"
            customClass={styles.sectionTitleButton}
            tabIndex={0}
            variants={Button.Variant.bare}
            onClick={onInfoClick}
          >
            <Icon
              icon={AlertInformationDefault}
              size={Icon.Size.xsmall}
              wrapperProps={{
                className: titleCustomClass,
              }}
            />
            <A11yText>{sectionTitle}-Info</A11yText>
          </Button>
        )}
      </div>
      {link && link.href !== "" && (
        <TextLink
          href={link.href}
          target="_blank"
          iconRight={LpgContinueOutline}
          customClass={`${styles.sectionHeaderTitleLink} ${titleCustomClass}`}
        >
          {link.label}
        </TextLink>
      )}
    </div>
    {sectionSubtitle && (
      <CopyText variants={CopyText.Variant.additional}>
        {sectionSubtitle}
      </CopyText>
    )}
  </div>
);

export default CustomerRequirementsSectionHeader;
