import { PortfolioKeys } from "../Product/IProduct";
import { IPromotion } from "./IPromotion";

export interface ICustomPromotionSections {
  [PortfolioKeys.LANDLINE]: IPromotion[];
  [PortfolioKeys.MOBILE]: IPromotion[];
}

export enum CustomPromotionTypes {
  PROMOTION_TYPE_MONTHLY_REDUCE = "monthly",
  PROMOTION_TYPE_ONCE_REDUCE = "once",
  PROMOTION_TYPE_WITHOUT_PRICE = "without_price",
  PROMOTION_TYPE_CASHBACK = "cashback",
}
