import { observer } from "mobx-react";
import React from "react";
import StepHeader from "components/new-design/StepHeader";

// TODO: add duplicated code from pk und gk header extra elements here
const CustomerRequirementsHeader: React.FC = ({ children }) => (
  <StepHeader title="Umfeldanalyse" subtitle="Umfeld- und Bedarf des Kunden">
    {children}
  </StepHeader>
);

export default observer(CustomerRequirementsHeader);
