import { IConsultingTime } from "../IConsultingTime";

export interface ICustomer {
  consultingTime: IConsultingTime;
  notice: string;
  customerType: CustomerType;
  customerPricePrefix: string;
  domainType: DomainType;
  customerData: ICustomerData;
  userGroup: IUserGroups;
}

export interface ICustomerEntity {
  get(): ICustomer;
  set(customer: ICustomer): void;
  reset(): void;
}

export enum CustomerType {
  EMPLOYEE = "employee",
  BUSINESS = "business",
  PRIVATE = "private",
}

export enum DomainType {
  PRIVATE = "personal",
  BUSINESS = "business",
  EMPLOYEE = "employee",
}

export interface ICustomerData {
  companyName: string | null;
  contactPerson: string | null;
  address: string | null;
  landlineNumber: string | null;
  mobileNumber: string | null;
  frameworkContract: string | null;
  kek: boolean;
  prevContract: string | null;
}


export enum IUserGroups {
  DEFAULT = "default",
}