import React from "react";
import { CopyText } from "@dtpk-cc/components";
import * as styles from "./customer-requirements-recommendation.module.scss";

type CustomerRequirementsRecommendationProps = {
  description?: string;
  customClass?: string;
};

const CustomerRequirementsRecommendation = ({
  description,
  customClass = "",
}: CustomerRequirementsRecommendationProps) => (
  <div
    className={`${customClass} ${styles.recommendationTileContainer} ${
      !description ? styles.hide : ""
    }`}
  >
    <CopyText
      customClass={styles.description}
      variants={CopyText.Variant.highlight}
    >
      Empfehlung!
    </CopyText>
    <CopyText customClass={styles.description}>{description}</CopyText>
  </div>
);

export default CustomerRequirementsRecommendation;
