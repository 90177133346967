import React from "react";
import { Button, CopyText } from "@dtpk-cc/components";
import { IStepTrackingContext } from "views/PencilSelling/IPencilSelling";
import SecondaryCardsIcon from "../../../../../../assets/images/icons/pencil-selling-tool/navigation/secondary-cards.svg";

import * as stylesToggleBtn from "../../../../../elements/new-design/ToggleMainBtn/toggle-main-btn.module.scss";
import * as styles from "./tariff-selection-next-magenta.module.scss";
import { trackClick } from "../../../../../helpers/reactTracking";
import TariffSelectionNextMagentaHeadline from "./TariffSelectionNextMagentaHeadline";

type TariffSelectionMagentaNextCardProps = {
  customClass?: string;
  isActive: boolean;
  onClick: () => void;
  trackingContext: IStepTrackingContext;
};

const TariffSelectionMagentaNextCard = ({
  isActive,
  onClick,
  customClass = "",
  trackingContext,
}: TariffSelectionMagentaNextCardProps) => (
  <Button
    tabIndex={0}
    onClick={() => {
      onClick();
      trackClick("pluskarte-hinzufuegen", trackingContext.portfolio);
    }}
    variants={Button.Variant.bare}
    customClass={`${styles.nextMagenta} ${customClass} ${
      isActive ? styles.active : ""
    }`}
  >
    <SecondaryCardsIcon className={styles.logoIcon} />
    <div className={styles.nextMagentaTextContainer}>
      <TariffSelectionNextMagentaHeadline isActive={isActive} />
      <CopyText
        variants={[CopyText.Variant.additional]}
        customClass={styles.nextMagentaSubLabel}
      >
        Die Zusatzkarte kostet 19,95 € mtl.
      </CopyText>
    </div>
    <div
      className={`${stylesToggleBtn.toggleButton} ${
        isActive ? stylesToggleBtn.checked : ""
      } ${styles.toggleBtn}`}
    >
      <span className={stylesToggleBtn.toggleButtonSwitcher} />
    </div>
  </Button>
);

export default TariffSelectionMagentaNextCard;
