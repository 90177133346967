import React, { useCallback } from "react";
import { getTariffDataVolume } from "helpers/GetTariffDataVolume";
import { ITariff } from "../../core/entities/Product/Tariff/ITariff";
import { IMobileSettings } from "../../core/entities/PencilSelling/Configurations/IConfigurations";
import { getTariffBySize } from "../../helpers/TariffSelection";
import { ConfigurationsEntity } from "../../core/entities/PencilSelling/Configurations/Configurations";
import { IAdditionalDevices } from "../../core/entities/PencilSelling/CartItem/ICartItem";
import { getMobileBenefitSettings } from "../../helpers/GetMobileBenefitSettings";
import { Volume } from "../../core/entities/Product/Card/ICard";
import { getRelatedAddonsAreSelected } from "../../helpers/GetRelatedAddonsAreSelected";
import { IPromotion } from "../../core/entities/PencilSelling/IPromotion";
import NextMagentaUnlimited from "../../../assets/images/icons/pencil-selling-tool/tariff-selection/next-magenta-unlimited.png";

type UseMobileTariffSelectionProps = {
  isBenefitActive: boolean;
  configurationsStore: ConfigurationsEntity;
  mobileTariff: ITariff;
  tariffs: ITariff[];
  mobileSettings: IMobileSettings;
  setMobileTariff: React.Dispatch<React.SetStateAction<ITariff>>;
  addToCart: (
    tariff: ITariff,
    promotions: IPromotion[],
    additionalDevices: IAdditionalDevices[]
  ) => void;
  smartphoneInfos: IAdditionalDevices[];
  handleNextMagentaCard?: (tariff: ITariff | null) => void;
};

export const useMobileTariffSelection = ({
  isBenefitActive,
  configurationsStore,
  mobileTariff,
  tariffs,
  mobileSettings,
  setMobileTariff,
  addToCart,
  smartphoneInfos,
  handleNextMagentaCard,
}: UseMobileTariffSelectionProps) => {
  const getTariffFeature = useCallback(
    (tariff: ITariff) => {
      const isNextMagentaActive =
        configurationsStore.applyNextMagentaForConfiguration(
          configurationsStore.getActiveConfiguration().key,
          tariff
        );
      const dataVolume = getTariffDataVolume({
        isBenefitActive,
        tariff,
        isNextMagentaActive,
      });

      const getParsedDataVolume = (targetDataVolume: string) =>
        targetDataVolume === Volume.INFINITY_RAW
          ? ["", targetDataVolume]
          : targetDataVolume.split(" ");
      const relatedAddonsAreSelected = getRelatedAddonsAreSelected(
        tariff,
        configurationsStore
      );

      const parsedDataVolume = getParsedDataVolume(dataVolume);
      const parsedDataVolumeExtra = getParsedDataVolume(
        tariff.dataVolumeExtra || ""
      );

      return {
        ...(relatedAddonsAreSelected && dataVolume !== Volume.INFINITY_VALUE
          ? {
              tariffExtraFeature: parsedDataVolumeExtra[0],
              tariffExtraFeatureUnit: parsedDataVolumeExtra[1] || "",
            }
          : {}),
        tariffFeature: parsedDataVolume[0],
        tariffFeatureUnit: parsedDataVolume[1],
        ...(isNextMagentaActive &&
          tariff.dataVolumeNextMagenta && {
            tariffFeatureIconSrc: NextMagentaUnlimited as string,
          }),
      };
    },
    [isBenefitActive, configurationsStore]
  );

  const setBenefitSettings = useCallback(
    (BenefitIsActive: boolean, currentMobileTariff: ITariff | null) => {
      const benefitSettings = getMobileBenefitSettings(
        BenefitIsActive,
        currentMobileTariff
      );
      configurationsStore.setActiveConfigurationBenefit(benefitSettings);
    },
    [configurationsStore]
  );

  const handleMobileTariffChange = useCallback(
    (tariff: ITariff | null, activeTariff: ITariff) => {
      const existsInCart = activeTariff?.key === tariff?.key;
      if (existsInCart) {
        configurationsStore
          .getActiveConfigurationChildren()
          .removeTariffFromCart(false);
        setMobileTariff(null);
        setBenefitSettings(isBenefitActive, null);
      } else {
        addToCart(tariff, [], smartphoneInfos);
        setMobileTariff(tariff);
        setBenefitSettings(isBenefitActive, tariff);
        handleNextMagentaCard?.(tariff);
      }
    },
    [
      handleNextMagentaCard,
      configurationsStore,
      setMobileTariff,
      setBenefitSettings,
      isBenefitActive,
      addToCart,
      smartphoneInfos,
    ]
  );

  const handleMobileSettingsChange = useCallback(
    (
      key: keyof IMobileSettings,
      value: IMobileSettings[keyof IMobileSettings]
    ) => {
      let tariff: ITariff = null;
      configurationsStore.setActiveConfigurationSettings<IMobileSettings>({
        key,
        value,
      });

      configurationsStore
        .getActiveConfigurationChildren()
        .removeTariffFromCart(false);
      if (key === "level" && mobileTariff) {
        tariff = getTariffBySize(
          value,
          tariffs,
          mobileTariff,
          mobileSettings.isYoung,
          mobileSettings.isVario
        );
        handleMobileTariffChange(tariff, mobileTariff);
        // Prevents double setMobileTariff and setBenefitSettings state update
        return;
      }
      setMobileTariff(tariff);
      setBenefitSettings(isBenefitActive, tariff);
    },
    [
      handleMobileTariffChange,
      configurationsStore,
      mobileSettings.isYoung,
      mobileSettings.isVario,
      tariffs,
      mobileTariff,
      setBenefitSettings,
      isBenefitActive,
      setMobileTariff,
    ]
  );

  return {
    getTariffFeature,
    setBenefitSettings,
    handleMobileTariffChange,
    handleMobileSettingsChange,
  };
};
