import React from "react";
import { observer } from "mobx-react";
import { useStores } from "stores/BusinessClient";
import type { IDistributionData } from "core/presenter/PencilSelling/OfferSummaryBasePresenter";

import * as styles from "./offer-finalization.module.scss";
import OfferFinalizationWrapper from "../../../../components/new-design/OfferFinalization/OfferFinalizationWrapper";
import OfferFinalizationDateTime from "../../../../components/new-design/OfferFinalization/OfferFinalizationDateTime";
import OfferFinalizationShopData from "../../../../components/new-design/OfferFinalization/OfferFinalizationShopData";
import TotalPriceToggle from "../../../../components/new-design/OfferFinalization/TotalPriceDisplayToggle";

interface OfferFinalizationProps {
  distributionData: IDistributionData;
}

const OfferFinalization = observer(
  ({ distributionData }: OfferFinalizationProps) => {
    const { offerStore } = useStores();

    return (
      <OfferFinalizationWrapper
        customClass={styles.offerFinalization}
        title="Fertigstellen"
        subtitle="Optionale Angaben für Ihr Angebot"
      >
        <OfferFinalizationDateTime
          offerStore={offerStore.getBaseOffer()}
          trackingContext="geschaeftskunden.angebot-fertigstellen"
        />
        <OfferFinalizationShopData
          customClass={styles.shopDataContainer}
          offerStore={offerStore.getBaseOffer()}
          distributionData={distributionData}
        />
        <TotalPriceToggle baseOffer={offerStore.baseOffer} />
      </OfferFinalizationWrapper>
    );
  }
);

export default OfferFinalization;
