import { makeAutoObservable } from "mobx";
import { TariffWorldKeys } from "core/repositories/ProductsRepository/DefinitionKeys";
import { ConfigurationsEntity } from "../../Configurations/Configurations";
import {
  ICartConfiguration,
  ICartConfigurationType,
} from "../../Configurations/IConfigurations";
import { IFrameWorkContractItem } from "./IOffer";
import { BaseOffer } from "../../BaseOffer/BaseOffer";
import { IBaseOfferExport } from "../../BaseOffer/IBaseOffer";

export interface BusinessGen3CardsData {
  modalWasOpened: boolean;
  amount: {
    configurations: number;
    modal: number;
  };
}

const initialBusinessGen3CardsData = {
  modalWasOpened: false,
  amount: {
    modal: 0,
    // since the default open config is the Business3Gen TariffWorld
    configurations: 1,
  },
};

export class Offer {
  configurationsEntity: ConfigurationsEntity;

  baseOffer: BaseOffer;

  constructor(configurationsEntity: ConfigurationsEntity) {
    this.configurationsEntity = configurationsEntity;
    this.baseOffer = new BaseOffer(configurationsEntity);
    makeAutoObservable(this);
  }

  frameworkContractsList: IFrameWorkContractItem[] = [];

  frameworkContract: IFrameWorkContractItem | null = null;

  private businessCardLimitHintIsDisplayed = true;

  /*
    amount of business card 3 generation, they could be set:
     - either from modal on zusatkarten page
     - or when configuration somehow changes
  */
  private businessGen3CardsData: BusinessGen3CardsData =
    initialBusinessGen3CardsData;

  getBaseOffer(): BaseOffer {
    return this.baseOffer;
  }

  getBusinessCardLimitHintIsDisplayed() {
    return this.businessCardLimitHintIsDisplayed;
  }

  setBusinessCardLimitHintIsDisplayed(isDisplayed: boolean) {
    this.businessCardLimitHintIsDisplayed = isDisplayed;
  }

  setFrameworkContractsList(
    frameworkContractsList: IFrameWorkContractItem[]
  ): void {
    this.frameworkContractsList = frameworkContractsList;
  }

  getFrameworkContractsList(): IFrameWorkContractItem[] {
    return this.frameworkContractsList;
  }

  setFrameworkContract(frameworkContract: IFrameWorkContractItem | null): void {
    this.frameworkContract = frameworkContract;
  }

  getFrameworkContract(): IFrameWorkContractItem {
    return this.frameworkContract;
  }

  setBusinessCardsGen3Amount(amount: Partial<BusinessGen3CardsData["amount"]>) {
    this.businessGen3CardsData.amount = {
      ...this.businessGen3CardsData.amount,
      ...amount,
    };
  }

  setBusinessCards3ModalWasOpened(isOpen: boolean) {
    this.businessGen3CardsData.modalWasOpened = isOpen;
  }

  resetBusinessGen3CardsData(): void {
    this.businessGen3CardsData = initialBusinessGen3CardsData;
  }

  getBusinessCards3GenConfigurations(): ICartConfiguration[] {
    return this.configurationsEntity
      .getConfigurations()
      .filter(({ type, data, children }) => {
        const cardTariffIsSelected = !!children.getMobileTariff();
        return (
          type === ICartConfigurationType.CARD &&
          data.settings.tariffWorld === TariffWorldKeys.business_card_3 &&
          cardTariffIsSelected
        );
      });
  }

  getBusinessCards3GenAmountFromConfigurations(): number {
    return this.getBusinessCards3GenConfigurations().reduce<number>(
      (acc, { amount: cardAmount }) => {
        // eslint-disable-next-line no-param-reassign
        acc += cardAmount;
        return acc;
      },
      0
    );
  }

  get businessCards3GenAmountTotal(): number {
    const { modal, configurations } = this.businessGen3CardsData.amount;
    return modal + configurations;
  }

  get businessCards3ModalWasOpened(): boolean {
    return this.businessGen3CardsData.modalWasOpened;
  }

  export(): IBaseOfferExport {
    return this.getBaseOffer().export();
  }
}
