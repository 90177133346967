import React from "react";
import { observer } from "mobx-react";
import { useAddonSelection } from "components/new-design/AddonsSelection/hooks/useAddonSelection";
import AddonsSelection from "../../../../../components/new-design/AddonsSelection";
import {
  Group,
  PortfolioKeys,
} from "../../../../../core/entities/Product/IProduct";
import { getBusinessCaseName } from "../../../../../helpers/GetBusinessCaseName";
import { ICartConfigurationType } from "../../../../../core/entities/PencilSelling/Configurations/IConfigurations";
import { businessCaseOptions } from "../../../../../core/entities/Product/Tariff/ITariff";
import { PropsWithBusinessCase } from "../../../IPencilSelling";
import { useStores } from "../../../../../stores/BusinessClient";
import { useGetCustomAddonContractPeriod } from "../../../../../hooks/PencilSelling/useGetCustomAddonContractPeriod";
import { CUSTOM_EXTRA_FORM_DATA } from "../../../../../components/new-design/AddonsSelection/const";

interface ILandlineTariffAddons extends PropsWithBusinessCase {
  trackingContext?: string;
}

const LandlineTariffAddons = ({
  businessCase,
  trackingContext = "Extras Festnetz",
}: ILandlineTariffAddons) => {
  const { configurationsStore, offerStore } = useStores();
  const { addons, addonsInCart, activeTariffKey } = useAddonSelection(
    ICartConfigurationType.LANDLINE
  );
  const customAddonContractPeriod = useGetCustomAddonContractPeriod(
    offerStore.getBaseOffer()
  );

  return (
    <AddonsSelection
      key={addons.length}
      products={addons}
      activeProducts={addonsInCart}
      isFungible={true}
      allowCustomProducts={false}
      condition={activeTariffKey}
      portfolio={PortfolioKeys.LANDLINE}
      addonGroup={Group.addon}
      trackingContext={trackingContext}
      businessCaseName={getBusinessCaseName(businessCase, businessCaseOptions)}
      configurationsStore={configurationsStore}
      customAddonContractPeriod={customAddonContractPeriod}
      customAddonFormData={CUSTOM_EXTRA_FORM_DATA}
    />
  );
};

export default observer(LandlineTariffAddons);
