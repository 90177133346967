import React from "react";
import { observer } from "mobx-react";
import { Dropdown } from "@dtpk-cc/components";

import * as styles from "./dropdown-main.module.scss";

type DropdownMainProps = {
  label: string;
  items: string[];
  onSelection: (
    value: string,
    event: React.ChangeEvent<HTMLSelectElement>
  ) => void;
  value: string;
  required?: boolean;
  invalidMessage?: string;
  customClass?: string;
};

const DropdownMain = ({
  label,
  items,
  onSelection,
  value,
  required = false,
  invalidMessage = "",
  customClass = "",
}: DropdownMainProps) => (
  <Dropdown
    label={label}
    items={items}
    value={value}
    onSelection={onSelection}
    customClass={`${customClass} ${styles.dropdownMain}`}
    required={required}
    invalidMessage={invalidMessage}
  />
);

export default observer(DropdownMain);
