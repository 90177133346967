import React from "react";
import { useStores } from "stores/PrivateClient";
import { ToggleBtn } from "elements/new-design/ToggleBtn";
import { CopyText } from "@dtpk-cc/components";
import { UseCaseVariants } from "core/entities/PencilSelling/PrivateClient/CustomerRequirements/ICustomerRequirementsPC";
import { observer } from "mobx-react";
import { trackClick } from "helpers/reactTracking";
import * as styles from "./customer-requirements-use-cases.module.scss";
import HomeOfficeIcon from "../../../../../../assets/images/icons/pencil-selling-tool/customer-requirements/homeoffice.svg";
import VideoCallIcon from "../../../../../../assets/images/icons/pencil-selling-tool/customer-requirements/videocall.svg";
import GamingIcon from "../../../../../../assets/images/icons/pencil-selling-tool/customer-requirements/gaming.svg";

const useCaseContentArray = [
  {
    identifier: UseCaseVariants.HOMEOFFICE,
    title: "Homeoffice",
    icon: HomeOfficeIcon,
  },
  {
    identifier: UseCaseVariants.VIDOE_TELEPHONY,
    title: "Videotelefonie",
    icon: VideoCallIcon,
  },
  {
    identifier: UseCaseVariants.GAMING,
    title: "Gaming",
    icon: GamingIcon,
  },
];

type CustomerRequirementsUseCasesProps = {
  trackingContext: string;
};

const CustomerRequirementsUseCases = ({
  trackingContext,
}: CustomerRequirementsUseCasesProps) => {
  const { customerRequirementsStore } = useStores();

  return (
    <div className={styles.useCaseTileContainer}>
      {useCaseContentArray.map((item) => (
        <ToggleBtn
          key={`personal_use_case_${item.identifier}`}
          iconConfig={{ Icon: item.icon, iconWrapperClass: styles.iconWrapper }}
          title={item.title}
          onChange={() => {
            trackClick(`useCases.${item.identifier}`, trackingContext);
            customerRequirementsStore.setUseCases(item.identifier);
          }}
          checked={customerRequirementsStore.requirements.useCases.includes(
            item.identifier
          )}
          inputTestId={`personal_use_case_${item.identifier}`}
          id={`personal_use_case_${item.identifier}`}
          customTileClass={styles.useCaseTile}
          copyTextVariant={CopyText.Variant.additional}
        />
      ))}
    </div>
  );
};

export default observer(CustomerRequirementsUseCases);
