import { makeAutoObservable } from "mobx";
import { handleArrayChange } from "helpers/CustomerRequirements";
import { ConfigurationsEntity } from "../../Configurations/Configurations";
import {
  BusinessSize,
  CallLocationVariants,
  ICustomerRequirementsBC,
  ITSupportVariants,
  SmartphoneChoices,
  UseCaseVariantsBusiness,
} from "./ICustomerRequirementsBC";
import { CustomerRequirementsBase, initialRequirementsBase } from "../../CustomerRequirementsBase/CustomerRequirementsBase";
import { InternationalTariffVariants } from "../../CustomerRequirementsBase/ICustomerRequirementsBase";
import { ICartConfigurationType } from "../../Configurations/IConfigurations";
import { ICustomerRequirementsExport } from "../../ICustomerRequirements";

const initialRequirements: ICustomerRequirementsBC = {
  useCases: [],
  smartphoneChoices: [],
  businessSize: {
    ceos: null,
    employees: null,
    employeesInOffice: null,
    locations: null,
  },
  specialistQuestions: {
    telephony: {
      callLocations: [],
      maxParallelCalls: null,
    },
    network: {
      crm: false,
      itSupport: [],
      internetVisibility: false,
      connectedDevices: null,
      sizeOfOffice: null,
    },
  },
  selectedBranche: null,
  recommendations: null,
};

export class CustomerRequirementsBC {
  private customerRequirements: ICustomerRequirementsBC = initialRequirements;

  customerRequirementsBase: CustomerRequirementsBase;

  private initialCustomerRequirements: ICustomerRequirementsBC;

  constructor(configurationsStore: ConfigurationsEntity) {
    this.customerRequirementsBase = new CustomerRequirementsBase(
      configurationsStore
    );
    makeAutoObservable(this);
  }

  get requirements(): ICustomerRequirementsBC {
    return {
      ...this.customerRequirements,
    };
  }

  get recommendations() {
    return {
      smartphoneChoices: this.requirements.smartphoneChoices.includes(
        SmartphoneChoices.MOBILE_SECURITY
      )
        ? "Business Mobil 5. Gen"
        : "",
      internationalTariffs: this.getInternationalTariffRecommendation(),
      useCases: this.getUseCasesRecommendation(),
    };
  }

  areRequirementsModified(): boolean {
    return (
      JSON.stringify(this.initialCustomerRequirements) !==
        JSON.stringify(this.requirements) ||
      this.customerRequirementsBase.areRequirementsModified()
    );
  }

  export(standalone = true): ICustomerRequirementsExport {
    if (!this.areRequirementsModified() && standalone) {
      return {
        ...initialRequirements,
        ...initialRequirementsBase,
        recommendations: null,
      };
    }

    if (this.areRequirementsModified() || standalone) {
      return {
        ...this.requirements,
        ...this.customerRequirementsBase.requirements,
        recommendations: {
          ...this.recommendations,
          ...this.customerRequirementsBase.recommendations,
        },
      };
    }

    return null;
  }

  setup(addConfigurationHandler: (type: ICartConfigurationType) => void): void {
    addConfigurationHandler(ICartConfigurationType.LANDLINE);
    addConfigurationHandler(ICartConfigurationType.MOBILE);
    this.initialCustomerRequirements = JSON.parse(
      JSON.stringify(this.requirements)
    ) as ICustomerRequirementsBC;
    this.customerRequirementsBase.setup();
  }

  setBranche(value: string) {
    this.customerRequirements.selectedBranche = value;
  }

  setBusinessSize(key: keyof BusinessSize, size: string): void {
    this.customerRequirements.businessSize[key] = size || null;
  }

  setSmartphoneConsultation(value: SmartphoneChoices): void {
    this.customerRequirements.smartphoneChoices = handleArrayChange(
      this.customerRequirements.smartphoneChoices,
      value,
      true
    );
  }

  setMaxParallelCalls(value: string): void {
    this.customerRequirements.specialistQuestions.telephony.maxParallelCalls =
      value === "" ? null : value;
  }

  setCallLocations(value: CallLocationVariants): void {
    this.customerRequirements.specialistQuestions.telephony.callLocations =
      handleArrayChange(
        this.customerRequirements.specialistQuestions.telephony.callLocations,
        value,
        true
      );
  }

  setITSupport(value: ITSupportVariants): void {
    this.customerRequirements.specialistQuestions.network.itSupport =
      handleArrayChange(
        this.customerRequirements.specialistQuestions.network.itSupport,
        value,
        true
      );
  }

  setConnectedDevices(value: string): void {
    this.customerRequirements.specialistQuestions.network.connectedDevices =
      value === "" ? null : value;
  }

  setOfficeArea(value: string): void {
    this.customerRequirements.specialistQuestions.network.sizeOfOffice =
      value === "" ? null : value;
  }

  toggleInternetVisibility(): void {
    this.customerRequirements.specialistQuestions.network.internetVisibility =
      !this.customerRequirements.specialistQuestions.network.internetVisibility;
  }

  toggleCrm(): void {
    this.customerRequirements.specialistQuestions.network.crm =
      !this.customerRequirements.specialistQuestions.network.crm;
  }

  setUseCases(value: UseCaseVariantsBusiness): void {
    this.customerRequirements.useCases = handleArrayChange(
      this.customerRequirements.useCases,
      value,
      true
    );
  }

  private getUseCasesRecommendation(): string {
    const useCasesWithRecommendation = [
      UseCaseVariantsBusiness.USV,
      UseCaseVariantsBusiness.CASH_DESK,
    ];

    if (
      this.customerRequirements.useCases.some((useCase) =>
        useCasesWithRecommendation.includes(useCase)
      )
    )
      return "Business DSL Tarife";

    return "";
  }

  private getInternationalTariffRecommendation(): string {
    if (!this.customerRequirementsBase.requirements.internationalTariffs.length)
      return "";

    if (
      this.customerRequirementsBase.requirements.internationalTariffs.includes(
        InternationalTariffVariants.USA_CANADA_TURKEY
      )
    )
      return "Business Mobil XL";

    return "Business Mobil 5. Gen";
  }
}
