// extracted by mini-css-extract-plugin
var _1 = "magenta-next-module-active-L7U0H";
var _2 = "magenta-next-module-additionalCardsWrapper-n4snW";
var _3 = "magenta-next-module-badge-fJ4uN";
var _4 = "magenta-next-module-benefit-kzlIW";
var _5 = "magenta-next-module-benefitPrice-x8s0c";
var _6 = "magenta-next-module-benefitsButton-OAqtO";
var _7 = "magenta-next-module-brandColor-iA1Ho";
var _8 = "magenta-next-module-centered-sGdmQ";
var _9 = "magenta-next-module-checkbox-bz1Qs";
var _a = "magenta-next-module-col-XPPCP";
var _b = "magenta-next-module-comparisingMode-IEmEo";
var _c = "magenta-next-module-copytext-pF8JU";
var _d = "magenta-next-module-equalHeightSummary-PoiIv";
var _e = "magenta-next-module-header-rzD4I";
var _f = "magenta-next-module-headerHintbox-XmFUW";
var _10 = "magenta-next-module-headline-syRjw";
var _11 = "magenta-next-module-headlineWithDelimiter-ZMiZJ";
var _12 = "magenta-next-module-hideInPrint-fc_1J";
var _13 = "magenta-next-module-hintbox-LmokT";
var _14 = "magenta-next-module-hintboxIcon-zMG62";
var _15 = "magenta-next-module-legacy-v40tn";
var _16 = "magenta-next-module-linkTile-DVEok";
var _17 = "magenta-next-module-list-XhHvt";
var _18 = "magenta-next-module-listElement-tpTVD";
var _19 = "magenta-next-module-loyaltyBonus-yeL2Y";
var _1a = "magenta-next-module-magentaNext-ZalJ7";
var _1b = "magenta-next-module-mainContainer-sG_k7";
var _1c = "magenta-next-module-noFlexShrink-F901Z";
var _1d = "magenta-next-module-priceSummary-KI2IN";
var _1e = "magenta-next-module-priceSummaryLeft-y96Il";
var _1f = "magenta-next-module-priceSummaryRight-WwCAF";
var _20 = "magenta-next-module-primaryText-THZX5";
var _21 = "magenta-next-module-printRow-vwUnn";
var _22 = "magenta-next-module-productTile-kbNdy";
var _23 = "magenta-next-module-reset-jrFZI";
var _24 = "magenta-next-module-row-KIo7M";
var _25 = "magenta-next-module-scrollChild-o8gXP";
var _26 = "magenta-next-module-scrollContainer-mvn2k";
var _27 = "magenta-next-module-separator-WS9Ka";
var _28 = "magenta-next-module-stretch-KzSZX";
var _29 = "magenta-next-module-stretchLegacy-EIlZH";
var _2a = "magenta-next-module-tariffConfigurator-Q5jIN";
var _2b = "magenta-next-module-tile-PfwoX";
var _2c = "magenta-next-module-top-pDy6V";
var _2d = "magenta-next-module-withDelimiter-DMFDy";
export { _1 as "active", _2 as "additionalCardsWrapper", _3 as "badge", _4 as "benefit", _5 as "benefitPrice", _6 as "benefitsButton", _7 as "brandColor", _8 as "centered", _9 as "checkbox", _a as "col", _b as "comparisingMode", _c as "copytext", _d as "equalHeightSummary", _e as "header", _f as "headerHintbox", _10 as "headline", _11 as "headlineWithDelimiter", _12 as "hideInPrint", _13 as "hintbox", _14 as "hintboxIcon", _15 as "legacy", _16 as "linkTile", _17 as "list", _18 as "listElement", _19 as "loyaltyBonus", _1a as "magentaNext", _1b as "mainContainer", _1c as "noFlexShrink", _1d as "priceSummary", _1e as "priceSummaryLeft", _1f as "priceSummaryRight", _20 as "primaryText", _21 as "printRow", _22 as "productTile", _23 as "reset", _24 as "row", _25 as "scrollChild", _26 as "scrollContainer", _27 as "separator", _28 as "stretch", _29 as "stretchLegacy", _2a as "tariffConfigurator", _2b as "tile", _2c as "top", _2d as "withDelimiter" }
