import { Button, A11yText, Asterisk, Icon } from "@dtpk-cc/components";
import {
  ActionPrintSelected,
  ActionRestartDefault,
} from "@dtpk-cc/components/dist/icons";
import {
  MAGENTA_CALCULATOR_CONFIG_TRANSFER_LOCAL_STORAGE_KEY,
  TIMESTAMP_MARK,
} from "constants/const";
import { TariffDataExport } from "core/entities/MagentaNext/Tariff/ITariff";
import { CardSections } from "core/entities/Product/Card/ICard";
import { trackClick } from "helpers/reactTracking";
import { PdfFunctions } from "hooks/MagentaNext/usePdfFunctions";
import React from "react";
import { useMagentaNextCalculatorStores } from "stores/MagentaNext";
import { observer } from "mobx-react";
import QrCodeIcon from "../../../../assets/images/icons/qr_code_icon.svg";
import TransferDataToPstIcon from "../../../../assets/images/icons/transfer-data-to-pst-icon.svg";
import * as styles from "./inner-toolbar.module.scss";

interface InnerToolbarProps {
  pdfFunctions: PdfFunctions;
  isLegacyTariffVisible: boolean;
  transferButtonIsEnabled: boolean;
  pdfButtonIsDisabled: boolean;
  resetCalculator: () => void;
  setTransferConfigModalIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const PENCIL_SELLING_TOOL_URL = "/angebote-erstellen/angebotstool";

const InnerToolbar = ({
  pdfFunctions,
  isLegacyTariffVisible,
  pdfButtonIsDisabled,
  transferButtonIsEnabled,
  resetCalculator,
  setTransferConfigModalIsOpen,
}: InnerToolbarProps) => {
  const { openPdf, getOfferPdfQrCode } = pdfFunctions;

  const { tariffStore } = useMagentaNextCalculatorStores();

  const comparisonClass = "";

  const transformCalculatorDataForPencilSelling = (
    exportData: TariffDataExport
  ) => {
    // eslint-disable-next-line no-unused-vars
    const { cards, quantity, ...mainExportData } = exportData;
    const {
      [CardSections.MAGENTA_MOBILE_CARD]: mobileCard,
      ...secondaryCardsObj
    } = cards;
    const secondaryCardProducts = Object.values(secondaryCardsObj).flat();
    // Add card as a separate item due to quantity value
    const secondaryCards = secondaryCardProducts.reduce((acc, cardItem) => {
      const cardsArr = [];

      for (let i = 0; i < cardItem.quantity; i += 1) {
        cardsArr.push({
          ...cardItem,
          key: `${cardItem.key}${TIMESTAMP_MARK}${i}`,
          quantity: 1,
          // Search for appropriate smartphone data by current index
          additionalDevices: [
            cardItem?.cardDetails?.smartphoneData[i] || { name: "", price: "" },
          ],
        });
      }

      return [...acc, ...cardsArr];
    }, []);

    return {
      ...mainExportData,
      secondaryCards,
      mainCard: mobileCard[0],
    };
  };

  const calculatorConfigTransferHandler = () => {
    // Get current config
    const tariffExport = tariffStore.export();
    const exportData = transformCalculatorDataForPencilSelling(tariffExport);
    //  Add Local Storage item
    localStorage.setItem(
      MAGENTA_CALCULATOR_CONFIG_TRANSFER_LOCAL_STORAGE_KEY,
      JSON.stringify(exportData)
    );
    //  Open new tab with PST
    window.open(
      `${window.location.origin}${PENCIL_SELLING_TOOL_URL}`,
      "_blank",
      "noopener,noreferrer"
    );
  };

  return (
    <div
      className={`${styles.innerToolbar} ${styles.toolbarGenerationWrapper}`}
    >
      <div
        className={`${styles.innerToolbar} ${styles.toolbarGenerationInner} ${comparisonClass}`}
      >
        <Button
          customClass={`${styles.innerToolbar} ${styles.ctaButton} ${comparisonClass}`}
          disabled={pdfButtonIsDisabled}
          onClick={openPdf}
          variants={[Button.Variant.icon]}
        >
          <Icon
            wrapperProps={{
              className: `${styles.innerToolbar} ${styles.ctaButtonIcon}`,
            }}
            icon={ActionPrintSelected}
          />
          <A11yText>Drucken</A11yText>
        </Button>

        <Button
          customClass={`${styles.innerToolbar} ${styles.ctaButton} ${styles.qrCodeButton} ${comparisonClass}`}
          variants={[Button.Variant.icon]}
          onClick={getOfferPdfQrCode}
          disabled={pdfButtonIsDisabled}
        >
          <Icon
            wrapperProps={{
              className: `${styles.innerToolbar} ${styles.ctaButtonIcon}`,
            }}
            icon={QrCodeIcon}
          />
          <A11yText>QR-Code</A11yText>
        </Button>

        <Button
          customClass={`${styles.innerToolbar} ${styles.ctaButton}
          ${styles.resetButton} ${comparisonClass}`}
          variants={[Button.Variant.icon, Button.Variant.outline]}
          onClick={() => {
            resetCalculator();
            trackClick("reset-magentamobil-rechner");
          }}
        >
          <Icon
            wrapperProps={{
              className: `${styles.innerToolbar} ${styles.ctaButtonIcon}`,
            }}
            icon={ActionRestartDefault}
          />
          <A11yText>Rechner zurücksetzen</A11yText>
        </Button>
      </div>
    </div>
  );
};

export default observer(InnerToolbar);
