import { ICalculatorExport } from "core/presenter/MagentaNext/MagentaNextSummaryPresenter";
import { BaseOffer } from "core/entities/PencilSelling/BaseOffer/BaseOffer";
import { ICustomerRequirementsPdfPayload } from "core/entities/PencilSelling/ICustomerRequirements";
import { CalculatorProductsResponse } from "./CalculatorResponseInterface";
import type { IProductsRepository } from "./IProductsRepository";
import {
  CustomAddonsRequestKeys,
  CustomPromotionFetchResponse,
  ICustomAddonItemResponse,
  ICustomPromotionResponse,
  OfferLinkResponse,
  ProductsResponse,
  StreamingAnalysisResponse,
} from "./ResponseInterface";
import {
  mapCustomPromotionFromRequest,
  mapCustomPromotionToRequest,
} from "../../../helpers/MapCustomPromotion";
import { IOfferSummary } from "../../presenter/PencilSelling/OfferSummaryPresenter";
import { IOfferConfigurationsSummary } from "../../../components/new-design/Summary/ISummary";
import {
  CustomerType,
  DomainType,
} from "../../entities/PencilSelling/Customer/ICustomer";
import { Endpoints } from "./IProductsRepository";
import { ICustomAddon } from "../../../components/new-design/AddonsSelection/IAddonsSelection";
import {
  mapCustomAddonFromResponse,
  mapCustomAddonToRequest,
} from "../../../helpers/MapCustomAddon";
import { IPromotion } from "../../entities/PencilSelling/IPromotion";

export enum FrameworkContractRequestParams {
  FRAMEWORK_CONTRACT_ID = "business_discount_report_id",
  TARIFF_KEY = "tariff_key",
}

interface IRequestParams {
  endpoint: string;
  method?: string;
  data?: object;
}

export const businessClientProductsRequestUrlMap: {
  [key in CustomerType]?: Endpoints;
} = {
  [CustomerType.EMPLOYEE]: Endpoints.pencil_selling_employee,
  [CustomerType.BUSINESS]: Endpoints.pencil_selling_business,
};

export class ProductsRepository implements IProductsRepository {
  constructor(
    private httpClient: typeof fetch,
    private baseOffer?: BaseOffer
  ) {}

  private get domainType(): DomainType {
    if (!this.baseOffer) {
      throw new Error(
        "Unable to determine domainType because baseOffer is not set"
      );
    }
    return this.baseOffer?.customer.get().domainType;
  }

  async getProducts(url: Endpoints | string): Promise<ProductsResponse | null> {
    try {
      const response = await this.httpClient(url);
      return await (response.json() as Promise<ProductsResponse>); // NOTE: resolve Promise as Promise again?...
    } catch (error) {
      return null;
    }
  }

  async getSreamingAnalysis(): Promise<StreamingAnalysisResponse | null> {
    try {
      const response = await this.httpClient(Endpoints.streaming_analysis);
      return await (response.json() as Promise<StreamingAnalysisResponse>);
    } catch (error) {
      return null;
    }
  }

  async getCalculatorProducts(): Promise<CalculatorProductsResponse> {
    try {
      const response = await this.httpClient(Endpoints.magenta_calculator);
      return await (response.json() as Promise<CalculatorProductsResponse>);
    } catch (error) {
      return null;
    }
  }

  async sendRequest({
    data = null,
    endpoint,
    method,
  }: IRequestParams): Promise<Response> {
    const csrfToken = document
      .querySelector("meta[name='csrf-token']")
      .getAttribute("content");

    const response = await this.httpClient(endpoint, {
      method,
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-TOKEN": csrfToken,
      },
      credentials: "include",
      ...(data ? { body: JSON.stringify(data) } : {}),
    });

    if (!response.ok) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      const { errors } = await response.json();
      // eslint-disable-next-line no-alert
      window.alert(JSON.stringify(errors));

      return Promise.reject(errors);
    }
    return response;
  }

  async getFile(data: object, endpoint: string): Promise<string> {
    try {
      const response: Response | string = await this.sendRequest({
        data,
        endpoint,
        method: "POST",
      });
      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);
      return url;
    } catch (error: unknown) {
      return ""; // TODO: handle Error to display in FE
    }
  }

  async getCalculatorPDF(data: ICalculatorExport): Promise<string> {
    const result = await this.getFile(data, Endpoints.magenta_calculator_pdf);
    return result;
  }

  async submitOrder(
    data: IOfferSummary | IOfferConfigurationsSummary
  ): Promise<string> {
    const result = await this.getFile(data, Endpoints.pdf);
    return result;
  }

  async getCustomerRequirementsPDF(
    data: ICustomerRequirementsPdfPayload
  ): Promise<string> {
    const result = await this.getFile(
      data,
      Endpoints.customer_requirements_pdf
    );
    return result;
  }

  async submitPdfQrCode(
    data: IOfferSummary | IOfferConfigurationsSummary
  ): Promise<Response> {
    const result = await this.sendRequest({
      data,
      endpoint: Endpoints.pdf_qr,
      method: "POST",
    });
    return result;
  }

  async getOfferPdfLink(
    data: IOfferSummary | IOfferConfigurationsSummary
  ): Promise<string> {
    const result = await this.sendRequest({
      data,
      endpoint: Endpoints.pdf_link,
      method: "POST",
    });
    const offerLink = (await result.json()) as OfferLinkResponse;
    return offerLink.url;
  }

  async submitCalculatorPdfQrCode(data: ICalculatorExport): Promise<Response> {
    const result = await this.sendRequest({
      data,
      endpoint: Endpoints.pdf_qr_calculator,
      method: "POST",
    });
    return result;
  }

  async setCustomPromotion(
    customPromotion: IPromotion,
    method: string,
    endpoint: string
  ): Promise<IPromotion> {
    const data = mapCustomPromotionToRequest(customPromotion);
    const response = await this.sendRequest({
      data,
      method,
      endpoint,
    });

    const result = (await response.json()) as ICustomPromotionResponse;

    return mapCustomPromotionFromRequest(
      result
        ? {
            ...result,
            portfolio: customPromotion.portfolio,
          }
        : null
    );
  }

  async postCustomPromotion(customPromotion: IPromotion): Promise<IPromotion> {
    const result = await this.setCustomPromotion(
      customPromotion,
      "POST",
      `${Endpoints.handle_custom_promotion}?tool_domain=${this.domainType}`
    );
    return result;
  }

  async editCustomPromotion(customPromotion: IPromotion): Promise<IPromotion> {
    const result = await this.setCustomPromotion(
      customPromotion,
      "PUT",
      `${Endpoints.handle_custom_promotion}/${customPromotion.key}?tool_domain=${this.domainType}`
    );
    return result;
  }

  async deleteCustomPromotion(promotionKey: string): Promise<Response> {
    const result = await this.sendRequest({
      method: "DELETE",
      endpoint: `${Endpoints.handle_custom_promotion}/${promotionKey}?tool_domain=${this.domainType}`,
    });
    return result.json() as Promise<Response>;
  }

  async fetchCustomPromotion(): Promise<CustomPromotionFetchResponse> {
    const endpoint = `${Endpoints.fetch_custom_promotions}${this.domainType}`;
    const result = await this.sendRequest({
      method: "GET",
      endpoint,
    });
    return result.json() as Promise<CustomPromotionFetchResponse>;
  }

  async fetchCustomAddons(endpoint: string, domainType: DomainType) {
    const result = await this.sendRequest({
      method: "GET",
      endpoint: `${endpoint}${domainType}`,
    });
    const customAddons = (await result.json()) as ICustomAddonItemResponse[];
    return customAddons.map(mapCustomAddonFromResponse);
  }

  async createCustomAddon(
    endpoint: string,
    domainType: DomainType,
    data: ICustomAddon,
    customAddonTypeKey: CustomAddonsRequestKeys
  ) {
    const result = await this.sendRequest({
      method: "POST",
      endpoint: `${endpoint}?tool_domain=${domainType}`,
      data: mapCustomAddonToRequest(data, customAddonTypeKey),
    });
    const customAddonItemResponse =
      (await result.json()) as ICustomAddonItemResponse;
    return mapCustomAddonFromResponse(customAddonItemResponse);
  }

  async deleteCustomAddon(
    endpoint: string,
    domainType: DomainType,
    customAddonKey: string
  ) {
    const result = await this.sendRequest({
      method: "DELETE",
      endpoint: `${endpoint}/${customAddonKey}?tool_domain=${domainType}`,
    });
    return (await result.json()) as Promise<Response>;
  }

  async logout(): Promise<Response> {
    return this.sendRequest({ endpoint: Endpoints.logout, method: "DELETE" });
  }
}
