// extracted by mini-css-extract-plugin
var _1 = "tariff-entry-module-active-hyrfx";
var _2 = "tariff-entry-module-bottomWrapper-MWe2i";
var _3 = "tariff-entry-module-dataVolume-GvVwK";
var _4 = "tariff-entry-module-dataVolumeExtra-VtGQq";
var _5 = "tariff-entry-module-dataVolumeIcon-SHSit";
var _6 = "tariff-entry-module-dataVolumeUnit-LXALX";
var _7 = "tariff-entry-module-dataVolumeWrapper-LYrhu";
var _8 = "tariff-entry-module-header-lyIb5";
var _9 = "tariff-entry-module-infoItem-jxMct";
var _a = "tariff-entry-module-infoItemIcon-Ynqso";
var _b = "tariff-entry-module-infoItemIconWrapper-MRSQA";
var _c = "tariff-entry-module-infoItemText-RTytD";
var _d = "tariff-entry-module-infoItemWrapper-S8ZlD";
var _e = "tariff-entry-module-loyaltyBonus-UUFYj";
var _f = "tariff-entry-module-loyaltyBonusIcon-NzesJ";
var _10 = "tariff-entry-module-loyaltyBonusPriceData-DdjUC";
var _11 = "tariff-entry-module-loyaltyBonusPriceTitle-uSMU5";
var _12 = "tariff-entry-module-loyaltyBonusPriceWrapper-Zd7Jp";
var _13 = "tariff-entry-module-magenta-LcFKE";
var _14 = "tariff-entry-module-mainContainer-q8nAh";
var _15 = "tariff-entry-module-mainPriceWrapper-LVODT";
var _16 = "tariff-entry-module-oncePriceWrapper-wQnKl";
var _17 = "tariff-entry-module-priceWrapper-r4aQX";
var _18 = "tariff-entry-module-radioMark-q8rAr";
var _19 = "tariff-entry-module-sellingPoints-HHzbs";
var _1a = "tariff-entry-module-streamingServicesIcon-hhQs0";
var _1b = "tariff-entry-module-subInfoItemText-XNwb8";
var _1c = "tariff-entry-module-tariffEntry-NRu_k";
var _1d = "tariff-entry-module-title-enrpT";
var _1e = "tariff-entry-module-topWrapper-ma_dg";
var _1f = "tariff-entry-module-withLoyaltyBonus-_31h8";
export { _1 as "active", _2 as "bottomWrapper", _3 as "dataVolume", _4 as "dataVolumeExtra", _5 as "dataVolumeIcon", _6 as "dataVolumeUnit", _7 as "dataVolumeWrapper", _8 as "header", _9 as "infoItem", _a as "infoItemIcon", _b as "infoItemIconWrapper", _c as "infoItemText", _d as "infoItemWrapper", _e as "loyaltyBonus", _f as "loyaltyBonusIcon", _10 as "loyaltyBonusPriceData", _11 as "loyaltyBonusPriceTitle", _12 as "loyaltyBonusPriceWrapper", _13 as "magenta", _14 as "mainContainer", _15 as "mainPriceWrapper", _16 as "oncePriceWrapper", _17 as "priceWrapper", _18 as "radioMark", _19 as "sellingPoints", _1a as "streamingServicesIcon", _1b as "subInfoItemText", _1c as "tariffEntry", _1d as "title", _1e as "topWrapper", _1f as "withLoyaltyBonus" }
