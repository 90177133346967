import React from "react";
import { observer } from "mobx-react";
import { useStores } from "stores/BusinessClient";
import StepHeader from "components/new-design/StepHeader";
import PageWrapper from "elements/new-design/PageWrapper";
import BusinessCases from "../../../../components/new-design/Personal/BusinessCases";
import FrameworkContract from "./FrameworkContract";
import { IFrameworkContractSelectState } from "../../IPencilSelling";

import UserNote from "../../../../components/new-design/Personal/UserNote";
import PersonalPageInner from "../../../../components/new-design/Personal/PersonalPageInner";
import { ICartConfigurationType } from "../../../../core/entities/PencilSelling/Configurations/IConfigurations";
import { TileContainerVariants } from "../../../../components/new-design/TileContainer/ITileContainer";
import TileContainer from "../../../../components/new-design/TileContainer";
import CustomerDataForm from "../../../../components/new-design/Personal/CustomerDataForm";

const trackingContext = "basisdaten";

type PersonalProps = {
  frameworkContractSelectState: IFrameworkContractSelectState;
  setFrameworkContractSelectState: React.Dispatch<
    React.SetStateAction<IFrameworkContractSelectState>
  >;
};

const Personal = ({
  setFrameworkContractSelectState,
  frameworkContractSelectState,
}: PersonalProps) => {
  const { offerStore, configurationsStore } = useStores();

  const onConfigurationsReset = (type: ICartConfigurationType) => {
    configurationsStore.resetConfigurationsByType(type);
  };

  return (
    <PageWrapper>
      <StepHeader
        title="Auswahl des Geschäftsfalls"
        subtitle="Für die Erstellung personalisierter Angebote"
      />
      <PersonalPageInner>
        <div className="mainContent">
          <div className="dataContainer">
            <FrameworkContract
              frameworkContractSelectState={frameworkContractSelectState}
              setFrameworkContractSelectState={setFrameworkContractSelectState}
              trackingContext={trackingContext}
              customClass="m-b-36"
            />
            <TileContainer variant={TileContainerVariants.outline}>
              <BusinessCases
                onConfigurationsReset={onConfigurationsReset}
                offerStore={offerStore.getBaseOffer()}
                configurationsStore={configurationsStore}
                trackingContext={trackingContext}
              />
            </TileContainer>
          </div>
          <CustomerDataForm offerStore={offerStore.getBaseOffer()} />
        </div>
        <UserNote
          offerStore={offerStore.getBaseOffer()}
          trackingContext={trackingContext}
        />
      </PersonalPageInner>
    </PageWrapper>
  );
};

export default observer(Personal);
