import React, { useCallback, useEffect, useState } from "react";
import { observer } from "mobx-react";
import {
  Container,
  CopyText,
  Grid,
  Heading,
  RadioButton,
} from "@dtpk-cc/components";

import { trackClick } from "helpers/reactTracking";
import { TariffWorldKeys } from "core/repositories/ProductsRepository/DefinitionKeys";
import { TariffSizes, Bandwidths } from "core/entities/Product/Tariff/ITariff";
import CustomToggleButton from "elements/CustomToggleButton";
import { PortfolioKeys } from "core/entities/Product/IProduct";
import { TariffPresenter } from "core/presenter/PencilSelling/TariffPresenter";
import TariffEntry from "elements/TariffEntry";
import { useStores } from "../../../../stores";
import Cards from "../Cards";
import "./secondary-cards.scss";

const SecondaryCards = () => {
  const { cartStore, offerStore, productsStore } = useStores();
  const trackingContext = "zusatzkarten";
  const cardSettings = offerStore.getCardSettings();
  const [isWarningModalOpen, setIsWarningModalOpen] = useState(false);
  const [toBeSelectedSetting, setToBeSelectedSetting] = useState(null);
  const [hasCardSection, setHasCardSection] = useState(true);

  const mobileTariffPresenter = new TariffPresenter(
    productsStore.tariffs[PortfolioKeys.MOBILE],
    cardSettings,
    false,
    null,
  );

  const filteredTariffs = mobileTariffPresenter?.getSecondaryCardsTariffs();
  const { tariffWorlds } = mobileTariffPresenter;

  const checkIsXLSize = cardSettings.tariff?.size === TariffSizes.XL;

  const handleCardSettingsChange = useCallback(
    (key, value) => {
      const conditionSettings =
        key !== "benefitIsActive" ? { tariff: null } : {};

      offerStore.setCardSettings({
        ...cardSettings,
        ...conditionSettings,
        [key]: value,
      });
    },
    [offerStore, cardSettings]
  );

  const checkMobileSettingsChange = useCallback(
    (key, value) => {
      const coreCardsInCart = cartStore
        .getCards()
        .filter((card) => card.inheritingCard === true);
      if (coreCardsInCart.length) {
        setIsWarningModalOpen(true);
        setToBeSelectedSetting({ key, value });
      } else {
        handleCardSettingsChange(key, value);
      }
    },
    [
      cartStore,
      setIsWarningModalOpen,
      setToBeSelectedSetting,
      handleCardSettingsChange,
    ]
  );

  const magentaMobilBenefitIsActive =
    cardSettings.benefitIsActive &&
    (cardSettings.tariffWorld === TariffWorldKeys.magenta_mobile ||
      cardSettings.tariffWorld === TariffWorldKeys.magenta_mobile_special);

  const tariffSettingChangeHandler = useCallback(
    (tariffSetting) => {
      handleCardSettingsChange(tariffSetting?.key, tariffSetting?.value);
    },
    [handleCardSettingsChange]
  );

  const handleRemoveTariff = useCallback(() => {
    offerStore.setCardSettings({
      ...cardSettings,
      tariff: null,
    });

    tariffSettingChangeHandler(toBeSelectedSetting);
    setToBeSelectedSetting(null);

    setIsWarningModalOpen(false);
  }, [
    offerStore,
    cardSettings,
    setIsWarningModalOpen,
    toBeSelectedSetting,
    tariffSettingChangeHandler,
    setToBeSelectedSetting,
  ]);

  const handleToggleTariff = useCallback(
    (tariff) => {
      const isDeselection = cardSettings.tariff?.key === tariff.key;
      if (isDeselection) {
        if (cartStore.getCards().length > 0) {
          setIsWarningModalOpen(true);
          return;
        }

        handleRemoveTariff();
        return;
      }
      offerStore.setCardSettings({
        ...cardSettings,
        tariff,
      });
    },
    [
      cartStore,
      offerStore,
      cardSettings,
      setIsWarningModalOpen,
      handleRemoveTariff,
    ]
  );

  useEffect(() => {
    if (cardSettings.tariff) {
      // NOTE: this removes multisims which the size is not of the #cardSettings.tariff?.size
      cartStore.deleteMultiSimBySize(cardSettings.tariff?.size);
    }
  }, [cartStore, cardSettings.tariff]);

  return (
    <Grid padded>
      <Grid.Row gutter style={{ margin: "0.75rem 0" }}>
        <Heading
          customClass="secondary-cards__section-container secondary-cards__title"
          tag="h3"
          variants={[Heading.Variant.secondary]}
        >
          Zusatzkarten
        </Heading>
      </Grid.Row>

      <Grid.Row gutter>
        <div className="secondary-cards__tariff-section-container">
          <div className="secondary-cards__tariff-world-wrapper">
            <Heading
              customClass="secondary-cards__tariff-world-wrapper__title"
              tag="h3"
              variants={[Heading.Variant.secondary]}
            >
              Zugrundeliegende Hauptkarte
            </Heading>
            <div className="secondary-cards__tariff-types-wrapper">
              <CopyText
                variants={
                  cardSettings.isYoung ? null : CopyText.Variant.highlight
                }
                customClass="tariff-portfolio__toggle-text tariff-portfolio__toggle-text--mr"
              >
                Standard Tarife
              </CopyText>
              <CustomToggleButton
                checked={cardSettings.isYoung}
                onClick={() => {
                  checkMobileSettingsChange("isYoung", !cardSettings.isYoung);
                  trackClick("tarif-toggle", trackingContext);
                }}
              />
              <CopyText
                variants={
                  cardSettings.isYoung ? CopyText.Variant.highlight : null
                }
                customClass="tariff-portfolio__toggle-text tariff-portfolio__toggle-text--ml"
              >
                Young Tarife
              </CopyText>
            </div>
            <hr className="secondary-cards__tariff-worlds--delimiter" />
            <div className="secondary-cards__tariff-worlds">
              <CopyText
                variants={[CopyText.Variant.highlight]}
                customClass="tariff-portfolio__mb"
              >
                Tarifwelt auswählen
              </CopyText>
              <RadioButton.Group
                style={{ flexDirection: "row", flexWrap: "wrap" }}
                variants={RadioButton.Group.Variant.block}
                key={cardSettings.tariffWorld}
              >
                <RadioButton.Legend a11y>Tarifwelt</RadioButton.Legend>
                {tariffWorlds.map((world) => (
                  <Container
                    key={world.key}
                    customClass="tariff-portfolio__radio-field tariff-portfolio__mb"
                  >
                    <RadioButton
                      onChange={(event) => {
                        event.stopPropagation();
                        event.preventDefault();
                        checkMobileSettingsChange(
                          "tariffWorld",
                          event.target.value
                        );
                        trackClick(`${event.target.value}`, trackingContext);
                      }}
                      checked={cardSettings.tariffWorld === world.key}
                      key={world.name}
                      value={world.key}
                    >
                      {world.name}
                    </RadioButton>
                  </Container>
                ))}
              </RadioButton.Group>
            </div>
          </div>
          {hasCardSection && (
            <div className="secondary-cards__tariffs-wrapper">
              <Grid.Row customClass="card-offer__tariff-entry-group tariff-portfolio__tariff-entry-group">
                {filteredTariffs?.map((tariff) => (
                  <Grid.Col
                    customClass="card-offer__tariff-entry-col"
                    key={tariff.key}
                    s={3}
                    m={3}
                    l={3}
                    xl={6}
                  >
                    <TariffEntry
                      isLandline={false}
                      isHybrid={false}
                      isBenefitActive={false}
                      index={0}
                      isHighlighted={
                        magentaMobilBenefitIsActive &&
                        tariff.size === TariffSizes.L
                      }
                      highlightText="∞ für alle"
                      active={cardSettings.tariff?.key === tariff.key}
                      price={parseFloat(tariff.price?.monthly.toString())}
                      subtitle={{
                        tariffFeature: magentaMobilBenefitIsActive
                          ? tariff?.dataVolumeMagentaOne
                          : tariff.dataVolume,
                        isHighlighted: magentaMobilBenefitIsActive,
                      }}
                      displaySize={tariff.size}
                      young={tariff.isYoung}
                      size={Bandwidths[tariff.size]}
                      toggleActive={() => handleToggleTariff(tariff)}
                      description={""}
                      withPrice={false}
                      customClass="card-offer__tariff-entry"
                      trackingContext={trackingContext}
                      name={tariff.name}
                     />
                  </Grid.Col>
                ))}
              </Grid.Row>
            </div>
          )}
        </div>
      </Grid.Row>

      <Grid.Row gutter>
        <Cards
          trackingContext={trackingContext}
          handleRemoveTariff={handleRemoveTariff}
          isActive={hasCardSection}
          cardSettings={cardSettings}
          setHasCardSection={setHasCardSection}
          setIsWarningModalOpen={setIsWarningModalOpen}
          isWarningModalOpen={isWarningModalOpen}
          setToBeSelectedSetting={setToBeSelectedSetting}
          checkIsXLSize={checkIsXLSize}
          handleCardSettingsChange={handleCardSettingsChange}
        />
        {!hasCardSection && (
          <Heading
            customClass="secondary-cards__title secondary-cards__no-cards-msg"
            tag="h3"
            variants={[Heading.Variant.secondary]}
          >
            Keine Zusatzkarten verfügbar
          </Heading>
        )}
      </Grid.Row>
    </Grid>
  );
};

export default observer(SecondaryCards);
