import { createContext, useContext } from "react";
import {
  configuration,
  offer,
  productsRepository,
  productsEntity,
  factModalEntity,
  customerRequirements,
} from "../../core/entities/PencilSelling/BusinessClient/main";

const rootStoresContext = createContext({
  configurationsStore: configuration,
  offerStore: offer,
  productsStore: productsEntity,
  productsRepositoryStore: productsRepository,
  factModalStore: factModalEntity,
  customerRequirementsStore: customerRequirements,
});

export const useStores = () => useContext(rootStoresContext);
