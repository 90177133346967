import { TariffDataExport } from "core/entities/MagentaNext/Tariff/ITariff";

import { humandReadableNumber, roundUpValue } from "helpers/NumberHelpers";
import { LOYALTY_BONUS_TEXT } from "constants/const";
import { BaseTariffsPresenter } from "./BaseTariffsPresenter";
import {
  CardExport,
  CardSections,
  FilteredCardSections,
  ICard,
  LegacySummaryCard,
  CardDetails,
} from "../../entities/Product/Card/ICard";
import { ITariff } from "../../entities/Product/Tariff/ITariff";
import { IPromotion } from "../../entities/PencilSelling/IPromotion";
import { BENEFIT_VALUE } from "../../entities/MagentaNext/Tariff/Tariff";
import { IDescriptionItem } from "../../entities/Product/IDescription";

interface ILegacyTariffsExport {
  cards: CardExport | LegacySummaryCard;
  quantity: number;
  totalPrice: number;
  averagePrice: number;
}

interface ITariffsExport extends TariffDataExport {
  averagePrice: number;
}

interface ITariffSectionMap {
  title: string;
  cardsKeys: Partial<CardSections>[];
}

interface PhoneDetail {
  name: string;
  price: string;
}

interface ISectionCardItem {
  name: string;
  monthlyPrice: string;
  oncePrice: string;
  dataVolume: string;
  smartphoneData: PhoneDetail;
  contractPeriodText: string;
  provisionPrice: string | null;
}

interface ISectionCards {
  name: string;
  cards: ISectionCardItem[];
}

interface IPromotionItem {
  name: string;
  quantity: number;
  price: string;
}

interface IBenefits {
  name: string;
  description: {
    name: string;
    value: string;
  }[];
}

interface ISummary {
  totalPriceName: string;
  averagePriceName: string;
  totalPrice: string;
  averagePrice: string;
  credit: string;
}

export interface ICalculatorExport {
  calculatorItems: {
    tariffs?: ITariffsSummary;
    legacyTariffs?: ILegacyTariffsSummary;
  };
  disclaimer: {
    headline: string;
    text: string;
  };
}

interface ITariffsSummary {
  title: string;
  mainTariff: {
    name: string;
    dataVolume: string;
    oldPrice: string | null;
    monthlyPrice: string;
    provisionPrice: string | null;
    contractPeriodText: string;
    smartphoneName: string;
    smartphonePrice: string;
  };
  sections: ISectionCards[];
  promotions: {
    name: string;
    cards: IPromotionItem[];
  };
  benefits?: IBenefits;
  happyHourBenefitOptionDescription: IDescriptionItem | null;
  summary: ISummary;
  loyaltyBonus: {
    name: string;
    value: string;
    description: string;
    averagePriceDescription: string;
  };
  provisionFee: {
    name: string;
    value: string;
  };
}

interface ILegacyTariffsSummary {
  title: string;
  sections: ISectionCards[];
  summary: ISummary;
}

export class MagentaNextSummaryPresenter extends BaseTariffsPresenter {
  private readonly tariffsHasBenefit: boolean;

  private legacyTariffsSectionsMap: ITariffSectionMap[] = [
    {
      title: "Zusammenfassung",
      cardsKeys: [CardSections.LEGACY_SUMMARY],
    },
    {
      title: "Hauptkarten",
      cardsKeys: [CardSections.MAGENTA_MOBILE_CARD],
    },
    {
      title: "Zusatzkarten",
      cardsKeys: [CardSections.ADDITIONAL_CARD],
    },
    {
      title: "Bestandskarten",
      cardsKeys: [CardSections.CUSTOM_CARD],
    },
  ];

  private tariffsSectionsMap: ITariffSectionMap[] = [
    {
      title: "Zusatzkarten",
      cardsKeys: [
        CardSections.MAGENTA_NEXT,
        CardSections.FAMILY_CARD,
        CardSections.MAGENTA_NEXT_SECONDARY,
        CardSections.MAGENTA_MOBILE_KIDS_CARD,
        CardSections.MAGENTA_MOBILE_DATA_CARD,
      ],
    },
    {
      title: "Weitere Zusatzkarten",
      cardsKeys: [CardSections.ADDITIONAL_CARD, CardSections.COMBI_CARD],
    },
  ];

  private activeMainCard: ITariff;

  constructor(
    private legacyTariffsExport: ILegacyTariffsExport,
    private tariffsExport: ITariffsExport
  ) {
    super();
    const {
      [CardSections.MAGENTA_MOBILE_CARD]: activeMainCard,
      ...tariffExportCards
    } = this.tariffsExport.cards;
    this.tariffsExport.cards = tariffExportCards;
    this.tariffsHasBenefit = this.tariffsExport.benefits.isActive;
    this.activeMainCard = activeMainCard[0];
    this.updateTariffsCardsDataVolume();
  }

  static getPromotionItem({
    description,
    discounts,
  }: IPromotion): IPromotionItem {
    return {
      name: description,
      quantity: discounts[0].interval,
      price: humandReadableNumber(-discounts[0].value, "€"),
    };
  }

  static getCardItem(
    card: ICard | LegacySummaryCard,
    cardDetailSection: CardDetails,
    index: number
  ): ISectionCardItem {
    let dataVolume = card?.dataVolume;
    const smartphoneName = cardDetailSection?.smartphoneData[index].name;
    const smartphonePrice = cardDetailSection?.smartphoneData[index].price;
    if (card.tariffWorld?.key === CardSections.CUSTOM_CARD && dataVolume) {
      dataVolume = `${dataVolume} GB `;
    }

    return {
      name: card.name,
      monthlyPrice: card?.price?.monthly
        ? humandReadableNumber(card?.price?.monthly, "€")
        : null,
      oncePrice: card?.price?.once
        ? humandReadableNumber(card?.price?.once, "€")
        : null,
      dataVolume: dataVolume ?? "",
      provisionPrice: card?.price?.provision
        ? humandReadableNumber(card?.price?.provision, "€")
        : null,
      contractPeriodText:
        card?.contractPeriod > 0
          ? `Mindestvertragslaufzeit ${card?.contractPeriod} Monate`
          : "",
      smartphoneData: {
        name: smartphoneName,
        price: smartphonePrice
          ? humandReadableNumber(
              Number(cardDetailSection?.smartphoneData[index].price),
              "€"
            )
          : "",
      },
    };
  }

  updateTariffsCardsDataVolume() {
    const cardKeysWithBenefit = [
      FilteredCardSections.MAGENTA_NEXT_SECONDARY,
      FilteredCardSections.MAGENTA_MOBILE_KIDS_CARD,
      FilteredCardSections.MAGENTA_MOBILE_DATA_CARD,
      FilteredCardSections.ADDITIONAL_CARD,
      FilteredCardSections.MAGENTA_NEXT,
    ];

    Object.keys(this.tariffsExport.cards).forEach(
      (key: FilteredCardSections) => {
        if (cardKeysWithBenefit.includes(key)) {
          const cardItems = this.tariffsExport.cards[key];

          this.tariffsExport.cards[key] = cardItems.map(
            (cardItem) =>
              MagentaNextSummaryPresenter.overwriteVolume(
                cardItem,
                this.tariffsHasBenefit,
                this.activeMainCard
              ) as ICard
          );
        }
      }
    );
  }

  private static mapCardDetailsToCardsForEachQuantity(
    cardsArr: ICard[]
  ): ISectionCardItem[] {
    return cardsArr.reduce((acc: ISectionCardItem[], card: ICard) => {
      let result = [] as ISectionCardItem[];
      for (let index = 1; card.quantity >= index; index += 1) {
        const cardItem = MagentaNextSummaryPresenter.getCardItem(
          card,
          card.cardDetails,
          index - 1 // index for array starts at 0
        );

        result = [...result, cardItem];
      }
      return [...acc, result] as ISectionCardItem[];
    }, [] as ISectionCardItem[]);
  }

  private static mapCardsSections(
    cards: CardExport | LegacySummaryCard,
    cardsKeysMap: ITariffSectionMap[]
  ) {
    const cardsSections = [] as ISectionCards[];

    cardsKeysMap.forEach((section) => {
      const sectionItem = { name: section.title } as ISectionCards;

      sectionItem.cards = Object.keys(cards).reduce(
        (acc, key: Partial<CardSections>) => {
          const cardsArr = cards[key] as ICard[];
          // Prevent display cards with 0 quantity and 0 price
          const activeCardsArr = cardsArr.filter(
            (cardItem) => cardItem.quantity > 0 && cardItem.price.monthly > 0
          );

          if (section.cardsKeys.includes(key) && activeCardsArr.length) {
            const cardItems =
              MagentaNextSummaryPresenter.mapCardDetailsToCardsForEachQuantity(
                activeCardsArr
              );

            return [...acc, ...cardItems];
          }
          return acc;
        },
        [] as ISectionCardItem[]
      );
      if (sectionItem.cards.length) {
        cardsSections.push(sectionItem);
      }
    });
    return cardsSections;
  }

  getBenefits(): IBenefits {
    const { benefits } = this.tariffsExport;
    return {
      name: benefits.title,
      description: benefits.list.map(({ name, value }) => ({
        name,
        value:
          typeof value === "number" ? humandReadableNumber(-value, "€") : null,
      })),
    };
  }

  static getSummaryBaseData(
    quantity: number,
    totalPrice: number,
    averagePrice: number,
    hasProvisionFee = false,
    loyaltyBonus = 0,
    isLegacy = true
  ) {
    let computedAveragePrice = averagePrice;
    if (loyaltyBonus > 0 || hasProvisionFee) {
      computedAveragePrice = roundUpValue(computedAveragePrice, 2);
    }

    const averagePriceText = `Rechnerischer Durchschnittspreis pro Karte ${
      !isLegacy && hasProvisionFee ? "inkl. Bereitstellungspreis" : ""
    }`;

    return {
      totalPriceName: `Summe für ${quantity} Mobilfunk-Karte${
        quantity === 1 ? "" : "n"
      }`,
      averagePriceName: averagePriceText,
      averagePrice: `<div><b>&Oslash; ${humandReadableNumber(
        computedAveragePrice,
        "€ mtl.",
        "roundUp"
      )}</b></div>`,
      totalPrice: `<div>${humandReadableNumber(totalPrice, "€ mtl.")}</div>`,
    };
  }

  getTariffsSummary(): ITariffsSummary {
    const tariffs = { title: "MagentaMobil" } as ITariffsSummary;
    const {
      cards,
      totalPrice,
      credit,
      quantity,
      averagePrice,
      promotions,
      benefits,
      loyaltyBonus,
      hasProvisionFee,
    } = this.tariffsExport;

    if (this.activeMainCard) {
      const mainMobileTariff = this.activeMainCard;
      const oldPrice = this.tariffsHasBenefit
        ? mainMobileTariff.price?.monthly
        : null;
      tariffs.mainTariff = {
        monthlyPrice: humandReadableNumber(mainMobileTariff.price.monthly, "€"),
        provisionPrice: humandReadableNumber(
          mainMobileTariff.price.provision,
          "€"
        ),
        contractPeriodText:
          mainMobileTariff.contractPeriod > 0
            ? `Mindestvertragslaufzeit ${mainMobileTariff.contractPeriod} Monate`
            : "",
        oldPrice: oldPrice ? humandReadableNumber(oldPrice, "€") : null,
        name: mainMobileTariff.name,
        dataVolume: MagentaNextSummaryPresenter.getVolume(
          mainMobileTariff,
          this.tariffsHasBenefit
        ),
        smartphonePrice: this.tariffsExport.smartphonePrice
          ? humandReadableNumber(
              parseFloat(this.tariffsExport.smartphonePrice.toString()),
              "€"
            )
          : "",
        smartphoneName: this.tariffsExport.smartphoneName,
      };

      const reducedTariffMonthlyPrice = () => {
        const provisionFee = hasProvisionFee
          ? mainMobileTariff.price.provision
          : 0;

        const result =
          mainMobileTariff.price.monthly -
          BENEFIT_VALUE -
          (loyaltyBonus - provisionFee) / 24;

        return humandReadableNumber(result, "€ mtl.", "roundUp");
      };

      if (benefits.isActive && loyaltyBonus > 0) {
        tariffs.loyaltyBonus = {
          name: "Treuebonus",
          value: humandReadableNumber(-loyaltyBonus, "€"),
          description: `<p>${LOYALTY_BONUS_TEXT}</p>`,
          averagePriceDescription: `<p><b>Der rechnerische Durchschnittspreis für den gewählten Tarif ${
            hasProvisionFee ? "(inkl. Bereitstellungspreis)" : ""
          } beträgt unter Berücksichtigung des Treuebonus und MagentaEINS Vorteils für 24 Monate <span class="magenta">${reducedTariffMonthlyPrice()}</span></b></p>`,
        };
      }

      if (hasProvisionFee) {
        tariffs.provisionFee = {
          name: "Bereitstellungspreis berücksichtigen",
          value: humandReadableNumber(this.activeMainCard.price.provision, "€"),
        };
      }
    }
    tariffs.sections = MagentaNextSummaryPresenter.mapCardsSections(
      cards,
      this.tariffsSectionsMap
    );

    tariffs.promotions = {
      name: "Aktionen",
      cards: promotions.reduce(
        (acc, promotion) => [
          ...acc,
          MagentaNextSummaryPresenter.getPromotionItem(promotion),
        ],
        [] as IPromotionItem[]
      ),
    };

    if (benefits.isActive) {
      tariffs.benefits = this.getBenefits();
      tariffs.happyHourBenefitOptionDescription =
        benefits.happyHourBenefitOptionDescription;
    }

    tariffs.summary = {
      ...MagentaNextSummaryPresenter.getSummaryBaseData(
        quantity,
        totalPrice,
        averagePrice,
        hasProvisionFee,
        loyaltyBonus,
        false
      ),
      credit: credit ? humandReadableNumber(credit, "Ersparnis mtl.") : "",
    };

    return tariffs;
  }

  getLegacyTariffsSummary(): ILegacyTariffsSummary {
    const legacyTariffs = {
      title: "Mobilfunk-Tarife im Bestand",
    } as ILegacyTariffsSummary;
    const { cards, quantity, totalPrice, averagePrice } =
      this.legacyTariffsExport;

    legacyTariffs.sections = MagentaNextSummaryPresenter.mapCardsSections(
      cards,
      this.legacyTariffsSectionsMap
    );
    legacyTariffs.summary = {
      ...MagentaNextSummaryPresenter.getSummaryBaseData(
        quantity,
        totalPrice,
        averagePrice
      ),
      credit: "",
    };

    return legacyTariffs;
  }

  export(): ICalculatorExport {
    const calcItems = { calculatorItems: {} } as ICalculatorExport;

    if (
      this.tariffsExport.quantity &&
      typeof this.tariffsExport.totalPrice === "number"
    ) {
      calcItems.calculatorItems.tariffs = this.getTariffsSummary();
    }

    if (
      this.legacyTariffsExport.quantity &&
      typeof this.tariffsExport.totalPrice === "number"
    ) {
      calcItems.calculatorItems.legacyTariffs = this.getLegacyTariffsSummary();
    }

    const disclaimer = {
      headline: "Hinweis",
      // eslint-disable-next-line max-len
      text: "<b>Änderungen und Irrtum vorbehalten.</b> Die Empfehlung ist freibleibend und basiert auf den geltenden Allgemeinen Geschäftsbedingungen von Telekom Deutschland GmbH für das jeweilige Produkt. Die Allgemeinen Geschäftsbedingungen sind in den Telekom Shops, bei den Telekom Partnern oder im Internet unter www.telekom.de/agb erhältlich.",
    };

    return { ...calcItems, disclaimer };
  }
}
