import { createContext, useContext } from "react";

import {
  configuration,
  homeConfigurator,
  offer,
  productsEntity,
  productsRepository,
  factModalEntity,
  customerRequirements,
} from "../../core/entities/PencilSelling/PrivateClient/main";

const rootStoresContext = createContext({
  configurationsStore: configuration,
  offerStore: offer,
  productsStore: productsEntity,
  productsRepositoryStore: productsRepository,
  homeConfiguratorStore: homeConfigurator,
  factModalStore: factModalEntity,
  customerRequirementsStore: customerRequirements,
});

export const useStores = () => useContext(rootStoresContext);
