import type { IOffer } from "core/entities/PencilSelling/Offer_legacy/IOffer";
import type { ICustomPromotionSections } from "core/entities/PencilSelling/ICustomPromotion";
import type { IGetCustomPromotionsUseCase } from "./IGetCustomPromotions";

export class GetCustomPromotionsUseCase implements IGetCustomPromotionsUseCase {
  constructor(private entity: IOffer) {}

  execute(): ICustomPromotionSections {
    return this.entity.getCustomPromotions();
  }
}
