// extracted by mini-css-extract-plugin
var _1 = "toggle-btn-module-active-E6u8x";
var _2 = "toggle-btn-module-alignStart-iGau1";
var _3 = "toggle-btn-module-contentCenter-KOn0h";
var _4 = "toggle-btn-module-contentStart-CvUE2";
var _5 = "toggle-btn-module-disabled-xR2Ie";
var _6 = "toggle-btn-module-icon-MGTxa";
var _7 = "toggle-btn-module-iconWithText-Pks1S";
var _8 = "toggle-btn-module-radioMark-fZPmp";
var _9 = "toggle-btn-module-title-aIuYi";
var _a = "toggle-btn-module-toggleBtn-iDBCB";
export { _1 as "active", _2 as "alignStart", _3 as "contentCenter", _4 as "contentStart", _5 as "disabled", _6 as "icon", _7 as "iconWithText", _8 as "radioMark", _9 as "title", _a as "toggleBtn" }
