import { Cart } from "core/entities/PencilSelling/Cart/Cart";
import { ProductsEntity } from "core/entities/PencilSelling/Products/ProductsEntity";
import { ITariff } from "core/entities/Product/Tariff/ITariff";
import { CardWorldKeys } from "core/repositories/ProductsRepository/DefinitionKeys";
import { createSecondaryCardKey } from "helpers/CreateSeconaryCardKey";
import { getSecondaryCardDataForCart } from "helpers/GetSecondaryCardDataForCart";
import { useCallback } from "react";
import { ConfigurationsEntity } from "core/entities/PencilSelling/Configurations/Configurations";

type UseMobileTariffSelectionSecondaryCardProps = {
  productsStore: ProductsEntity;
  configurationsStore: ConfigurationsEntity;
  linkedSecondaryCardCart: Cart;
  showNextMagentaCardSwitch: boolean;
};

const magentaNextCardKey = "magenta_mobile_plus_card_main_tariff_no_phone";

export const useMobileTariffSelectionNextMagenta = ({
  productsStore,
  configurationsStore,
  linkedSecondaryCardCart,
  showNextMagentaCardSwitch,
}: UseMobileTariffSelectionSecondaryCardProps) => {
  const nextMagentaCardFromCart = linkedSecondaryCardCart
    .getCards()
    .find(
      (card) =>
        card.tariffWorld.key === CardWorldKeys.magenta_mobile_next_card_main
    );

  const { isNextMagentaActive } =
    configurationsStore.getActiveConfiguration().data;

  const addMagentaNextCardToCart = useCallback(
    (linkedTariff: ITariff) => {
      if (nextMagentaCardFromCart) {
        linkedSecondaryCardCart.delete(nextMagentaCardFromCart.key);
      }

      const newMagentaNextCard = productsStore
        .getCards()
        .find((card) => card.key === magentaNextCardKey);

      const isBenefitActive =
        configurationsStore.getActiveConfigurationBenefitIsActive();

      const nextMagentaIsActive =
        configurationsStore.applyNextMagentaForConfiguration(
          configurationsStore.getActiveConfiguration().key,
          linkedTariff
        );

      const magentaNextCardItemData = {
        ...getSecondaryCardDataForCart({
          card: newMagentaNextCard,
          additionalDevices: [],
          promotions: [],
          note: "",
          cardSettings: {
            benefitIsActive: isBenefitActive,
            tariff: linkedTariff,
            nextMagentaIsActive,
          },
        }),
        key: createSecondaryCardKey(newMagentaNextCard.key),
      };

      linkedSecondaryCardCart.add(magentaNextCardItemData);
    },
    [
      configurationsStore,
      linkedSecondaryCardCart,
      nextMagentaCardFromCart,
      productsStore,
    ]
  );

  /*
   * check if secondary cards not exist
   * special handling for next magenta -> plus card doesn´t count
   */
  const secondaryCardsNotExist = (
    applyNextMagenta: boolean,
    tariff?: ITariff | null,
    activeTariff?: ITariff | null
  ): boolean => {
    const secondaryCardsLength = linkedSecondaryCardCart.getCards().length;

    if (tariff && activeTariff && tariff?.key === activeTariff?.key) {
      return !secondaryCardsLength;
    }

    if (applyNextMagenta && isNextMagentaActive && !!nextMagentaCardFromCart) {
      return secondaryCardsLength === 1;
    }

    return !secondaryCardsLength;
  };

  const handleNextMagentaCard = (tariff: ITariff | null) => {
    if (!showNextMagentaCardSwitch) return;

    const nextMagentaIsActive =
      configurationsStore.getActiveConfiguration().data.isNextMagentaActive;

    if (nextMagentaIsActive && tariff) {
      addMagentaNextCardToCart(tariff);
    } else if (!nextMagentaIsActive && nextMagentaCardFromCart) {
      linkedSecondaryCardCart.delete(nextMagentaCardFromCart.key);
    }
  };

  return {
    isNextMagentaActive,
    handleNextMagentaCard,
    secondaryCardsNotExist,
  };
};
