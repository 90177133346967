import React from "react";
import { Button, Heading } from "@dtpk-cc/components";
import BenefitLogoIcon from "../../../../../../assets/images/icons/pencil-selling-tool/tariff-selection/magentaeins-benefit-logo.svg";

import * as stylesToggleBtn from "../../../../../elements/new-design/ToggleMainBtn/toggle-main-btn.module.scss";
import * as styles from "./tariff-selection-benefit.module.scss";
import { trackClick } from "../../../../../helpers/reactTracking";

type TariffSelectionBenefitProps = {
  customClass?: string;
  isActive: boolean;
  onClick: () => void;
  withLoyaltyBonus?: boolean;
  customText?: string;
  trackingContext?: string;
};

const TariffSelectionBenefit = ({
  isActive,
  onClick,
  withLoyaltyBonus = false,
  customClass = "",
  customText = "",
  trackingContext,
}: TariffSelectionBenefitProps) => (
  <Button
    tabIndex={0}
    onClick={() => {
      onClick();
      trackClick("magentaeins-vorteil", trackingContext);
    }}
    variants={Button.Variant.bare}
    customClass={`${styles.magentaBenefit} ${customClass} ${
      isActive ? styles.active : ""
    } ${withLoyaltyBonus ? styles.withLoyaltyBonus : ""}`}
  >
    <BenefitLogoIcon className={styles.logoIcon} />
    <Heading variants={Heading.Variant.quaternary} customClass={styles.text}>
      {customText ||
        (isActive ? "MagentaEINS aktiviert" : "MagentaEINS aktivieren")}
    </Heading>
    <div
      className={`${stylesToggleBtn.toggleButton} ${
        isActive ? stylesToggleBtn.checked : ""
      } ${styles.toggleBtn}`}
    >
      <span className={stylesToggleBtn.toggleButtonSwitcher} />
    </div>
  </Button>
);

export default TariffSelectionBenefit;
