export const BusinessCasesLandlineTooltipData = [
  {
    title: "Festnetz Neukunde",
    content: [
      "Kunden, die in den letzten 3 Monaten keinen Festnetz-Anschluss bei der Telekom hatten. Geschäftsfall „NEU/NEU“.",
    ],
  },
  {
    title: "Wechsel vom Wettbewerber",
    content: [
      "Anbieterwechsel zurück zur Telekom Kunde hat Festnetz und Internet beim Wettbewerber und möchte vom jetzigen Anbieter zur Telekom wechseln.",
    ],
  },
  {
    title: "Breitband-Neukunde",
    content: [
      "Telekom Festnetz Kunde, der in den letzten 3 Monaten keinen Breitbandanschluss bei der Telekom hatte sowie Upgrader von Single Play auf Double/Triple Play. Geschäftsfall „WECHSLER/NEU“.",
    ],
  },
  {
    title: "Bestandskunde MagentaZuhause (DP)",
    content: ["Telekom Festnetz-Kunde mit einem Telekom Breitbandanschluss."],
  },
  {
    title: "Bestandskunde MZ mit MagentaTV (TP)",
    content: [
      "Telekom Festnetz-Kunde mit einem MagentaZuhause Tarif mit MagentaTV inklusive Telekom Breitbandanschluss.",
    ],
  },
];