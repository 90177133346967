import { IBenefit } from "../../Product/IBenefit";
import { Bandwidths, ITariff } from "../../Product/Tariff/ITariff";
import { PortfolioKeys } from "../../Product/IProduct";
import { ExpansionArea } from "../../Applications";
import { Cart as ChildrenCart } from "../Cart/Cart";

export interface ILandlineSettings {
  portfolio: PortfolioKeys;
  fiber: boolean;
  bandwidth: Bandwidths;
  isVario: boolean | null;
  isYoung: boolean | null;
  tariffWorld: string;
  level: string;
  expansionArea: ExpansionArea;
}

export interface IMobileSettings {
  portfolio: PortfolioKeys;
  isVario: boolean | null;
  isYoung: boolean | null;
  tariffWorld: string;
  level: string;
  hasProvisionFee: boolean;
  loyaltyBonusValue: number;
}

export interface ICardSettings {
  tariff?: ITariff;
  benefitIsActive: boolean;
  nextMagentaIsActive?: boolean;
}

export interface IBusinessClientsCardSettings {
  isVario: boolean | null;
  isYoung: boolean | null;
  tariffWorld: string;
  level: string;
  tariff?: ITariff | null;
  inheritedDataVolume?: string;
  portfolio: PortfolioKeys;
  hasProvisionFee: boolean;
}

export type ICartConfigurationSettings =
  | IMobileSettings
  | ILandlineSettings
  | IBusinessClientsCardSettings;

export interface ICartConfigurationData {
  settings: ICartConfigurationSettings | null;
  note: string;
  isBenefitActive: boolean;
  isNextMagentaActive?: boolean;
  benefit: IBenefit | null;
  // If true -> configuration will be omitted in summary screen
  // In private client secondary cards tab if linked mobile configuration is omitted -> mobile tariff won't be involved in total/average price calculation.
  isOmitted: boolean;
}

export enum ICartConfigurationType {
  MOBILE = "mobile",
  LANDLINE = "landline",
  CARD = "card",
  DEVICE = "device",
}

export interface ICartConfiguration {
  type: ICartConfigurationType;
  key: string;
  name: string | null;
  amount: number | null;
  data: ICartConfigurationData | null;
  children: ChildrenCart;
  linkedConfigurationKey: string | null;
}
