import React from "react";
import { observer } from "mobx-react";
import { Checkbox, Heading } from "@dtpk-cc/components";
import TileContainer from "../../TileContainer";
import TileContainerTitle from "../../TileContainer/TileContainerTitle";
import { TileContainerVariants } from "../../TileContainer/ITileContainer";
import * as styles from "./total-price-display-toggle.module.scss";
import { BaseOffer } from "../../../../core/entities/PencilSelling/BaseOffer/BaseOffer";

type PrevContractPriceToggleProps = {
  customClass?: string;
  baseOffer: BaseOffer;
};

const TotalPriceDisplayToggle = ({
  customClass = "",
  baseOffer,
}: PrevContractPriceToggleProps) => (
  <TileContainer
    variant={TileContainerVariants.outline}
    customClass={`dataContainer ${styles.wrapper} ${customClass}`}
  >
    <TileContainerTitle
      title="Anzeige der Teil-/Gesamtsumme"
      variants={[Heading.Variant.quaternary, Heading.Variant.uppercase]}
    />
    <div className={styles.labelWrapper}>
      <Checkbox
        checked={baseOffer.getDisplayConfigurationTotalPrice()}
        onChange={(isDisplayed) =>
          baseOffer.setDisplayConfigurationTotalPrice(isDisplayed)
        }
        tabIndex={0}
        role="checkbox"
        id="display-configuration-total-price"
      >
        <b className="m-r-6">Teilsummen je Konfiguration</b>
        <span>anzeigen</span>
      </Checkbox>
      <Checkbox
        checked={baseOffer.getDisplayOfferTotalPrice()}
        onChange={(isDisplayed) =>
          baseOffer.setDisplayOfferTotalPrice(isDisplayed)
        }
        tabIndex={0}
        role="checkbox"
        id="display-offer-total-price"
      >
        <b className="m-r-6">Gesamtsumme des Angebots</b>
        <span>anzeigen</span>
      </Checkbox>
    </div>
  </TileContainer>
);

export default observer(TotalPriceDisplayToggle);
