import React, { Fragment, useState } from "react";
import { observer } from "mobx-react";
import { Accordion, Heading, Icon, Tabs, TextLink } from "@dtpk-cc/components";
import ActionAddDefault from "@dtpk-cc/components/dist/icons/Action/Add/Default";
import ContinueOutline from "@dtpk-cc/components/dist/icons/Lpg/ContinueOutline";
import {
  AddonsPresenter,
  IAddon,
  ProductGroup,
  SelectionTypes,
} from "../../../core/presenter/PencilSelling/AddonsPresenter";
import {
  Egsp,
  ICartItemData,
  PaymentTypes,
} from "../../../core/entities/PencilSelling/CartItem/ICartItem";
import { Group, PortfolioKeys } from "../../../core/entities/Product/IProduct";
import { trackClick, trackLink } from "../../../helpers/reactTracking";
import AddonItem from "../../../elements/new-design/AddonItem";
import AddonsGroupSelectionModal from "./AddonsGroupSelectionModal";
import AddonsPriceTypeSelectionModal from "./AddonsPriceTypeSelectionModal";
import { IPromotion } from "../../../core/entities/PencilSelling/IPromotion";
import AddonsPromotionSelectionModal from "./AddonsPromotionSelectionModal";

import * as styles from "./addons-selection.module.scss";
import { ConfigurationsEntity } from "../../../core/entities/PencilSelling/Configurations/Configurations";
import { ICustomAddon, ICustomAddonFormData } from "./IAddonsSelection";
import CustomAddonForm from "./CustomAddonForm";

type AddonsSelectionProps = {
  products: IAddon[];
  activeProducts: ICartItemData[];
  isFungible: boolean;
  allowCustomProducts: boolean;
  portfolio: PortfolioKeys;
  addonGroup: Group;
  trackingContext: string;
  businessCaseName: string;
  condition?: string | null;
  configurationsStore: ConfigurationsEntity;
  customAddonContractPeriod: number;
  customAddonFormData: ICustomAddonFormData;
  updateRelatedTariff?: (addonKey: string) => void;
  customAddons?: ICustomAddon[];
  addCustomAddon?: (customAddon: ICustomAddon) => Promise<ICustomAddon> | null;
  removeCustomAddon?: (customAddonKey: string) => void;
};

const AddonsSelection = ({
  activeProducts,
  products,
  condition = null,
  isFungible,
  // TODO For PK Redesign
  allowCustomProducts = false,
  portfolio,
  addonGroup,
  trackingContext,
  businessCaseName,
  configurationsStore,
  customAddonContractPeriod,
  customAddonFormData,
  updateRelatedTariff = () => {},
  customAddons = [],
  addCustomAddon = () => null,
  removeCustomAddon = () => null,
}: AddonsSelectionProps) => {
  const presenter = new AddonsPresenter(products, condition);
  const categories = presenter.formattedCategories;
  const [currentTabCategory, setCurrentTabCategory] = useState(
    categories[0]?.key
  );
  const [isPriceModalOpen, setIsPriceModalOpen] = useState(false);
  const [isGroupModalOpen, setIsGroupModalOpen] = useState(false);
  const [isPromotionsModalOpen, setIsPromotionsModalOpen] = useState(false);
  const [promotionsProduct, setPromotionsProduct] =
    useState<ICartItemData>(null);
  const [productChoices, setProductChoices] = useState<IAddon[]>([]);

  const getEgspText = (item: IAddon) => {
    if ("egsp" in item && item?.egsp && item?.egsp !== Egsp.NONE) {
      return item?.egsp === Egsp.DEFAULT ? "mit EGSP" : "mit EGSP+";
    }
    return "";
  };

  const addProduct = (
    product: IAddon | ICustomAddon,
    paymentType: PaymentTypes
  ) => {
    const isCustom = "isCustom" in product && product?.isCustom;
    const activeGroupItem =
      !isCustom &&
      presenter.activeGroupItem(activeProducts, product.productGroup.key);

    const productEgsp = "egsp" in product ? product?.egsp : "";

    const cartItem = {
      portfolio,
      isCustom,
      name: product.name,
      price: product.price,
      paymentType,
      key: product.key,
      promotions: [],
      quantity: activeGroupItem?.quantity || 1,
      group: Group[product.type] || addonGroup,
      description:
        ((productEgsp || "") === Egsp.DEFAULT || productEgsp === Egsp.PLUS) &&
        paymentType === PaymentTypes.MONTHLY
          ? {
              summary: product.description.summary_egsp,
              text: product.description.text_egsp,
            }
          : product.description,
      additionalDescription: product.additionalDescription,
      contractPeriod: product.contractPeriod,
      isFungible,
      egsp: productEgsp,
      availableFor: product.availableFor,
    };

    if (activeGroupItem) {
      configurationsStore
        .getActiveConfigurationChildren()
        .replace(activeGroupItem.key, cartItem);
    } else {
      configurationsStore.getActiveConfigurationChildren().addToCart(cartItem);
    }
    updateRelatedTariff(product.key);
  };

  const handleProductSelection: (
    product: IAddon,
    payment?: PaymentTypes
  ) => void = (product, payment = null) => {
    const { monthly } = product.price;
    // payment parameter is optional
    const paymentType = payment || presenter.getPaymentType(monthly);

    addProduct(product, paymentType);

    setIsGroupModalOpen(false);
    setIsPriceModalOpen(false);
  };

  const handleProductGroupSelection = (product: IAddon) => {
    handleProductSelection(product);
  };

  const showPaymentTypeChoices = (items: IAddon[]) => {
    setIsPriceModalOpen(true);
    setProductChoices(items);
  };

  const showProductGroupChoices = (items: IAddon[]) => {
    setIsGroupModalOpen(true);
    setProductChoices(items);
  };

  const handleGroupTileClick = (group: ProductGroup) => {
    const type = presenter.getSelectionType(group.key);

    const typeMap = {
      [SelectionTypes.PRODUCTS]: () => showProductGroupChoices(group.products),
      [SelectionTypes.PRICE]: () => showPaymentTypeChoices(group.products),
      [SelectionTypes.NONE]: () => handleProductSelection(group.products[0]),
    };
    typeMap[type]();
  };

  const deleteAddon = (productKey: string, name: string) => {
    configurationsStore.getActiveConfigurationChildren().delete(productKey);
    updateRelatedTariff(productKey);
    trackClick(`${name}.entfernen`, trackingContext);
  };

  const onQuantityChange = (key: string, quantity: number) => {
    configurationsStore
      .getActiveConfigurationChildren()
      .setQuantity(key, quantity);
  };

  const showPromotionChoices = (product: ICartItemData) => {
    setPromotionsProduct(product);
    setIsPromotionsModalOpen(true);
  };

  const addonsProductPromotions = (addonKey) => {
    const addonsProduct = products.find((product) => product.key === addonKey);
    const productPromotions = addonsProduct?.promotions || [];

    return productPromotions.filter((promotion) =>
      promotion.concerned
        ? promotionsProduct?.paymentType === promotion.concerned
        : true
    );
  };

  const handlePromotionSelection = (promotions: IPromotion[]) => {
    setIsPromotionsModalOpen(false);
    configurationsStore
      .getActiveConfigurationChildren()
      .replace(promotionsProduct.key, {
        ...promotionsProduct,
        promotions,
      });
  };

  const getAddonTileTemplate = (productGroup: ProductGroup) => {
    const productGroupAddonInCart = activeProducts.find(
      (cartItem) =>
        !!productGroup.products.find((addon) => addon.key === cartItem.key)
    );
    const isActive = !!productGroupAddonInCart;

    return (
      <AddonItem
        customClass={styles.productGroupItem}
        isFungible={isFungible}
        isActive={isActive}
        contractPeriod={productGroupAddonInCart?.contractPeriod}
        paymentType={productGroupAddonInCart?.paymentType}
        price={productGroupAddonInCart?.price || productGroup.minPrice}
        name={productGroupAddonInCart?.name || productGroup.name}
        isProductGroup={productGroup.products.length > 1}
        additionalPriceSuffix={getEgspText(productGroup.products[0])}
        groupHasPromotions={productGroup.products.some(
          (product) => !!product.promotions.length
        )}
        selectedHasPromotions={
          isActive &&
          presenter.isProductWithPromotions(
            productGroupAddonInCart.key,
            productGroupAddonInCart.paymentType
          )
        }
        onPromotionsClick={() => {
          showPromotionChoices(productGroupAddonInCart);
          trackClick(
            `${productGroupAddonInCart.name}.aktion-hinzufügen/ändern`,
            trackingContext
          );
        }}
        quantity={productGroupAddonInCart?.quantity}
        onQuantityChange={(quantity: number) => {
          onQuantityChange(productGroupAddonInCart.key, quantity);
          trackClick(
            `${productGroupAddonInCart.name}.menge.${quantity}`,
            trackingContext
          );
        }}
        onClick={() => {
          if (isActive) {
            deleteAddon(
              productGroupAddonInCart.key,
              productGroupAddonInCart.name
            );
          } else {
            handleGroupTileClick(productGroup);
            trackClick(`${productGroup.name}`, trackingContext);
          }
        }}
      />
    );
  };
  const filterProductsByCategoryKey = (key: string): IAddon[] =>
    products.filter((product) => product.category.key === key);

  const filterProductsBySubcategoryKey = (key: string | null): IAddon[] => {
    if (key) {
      return products.filter(
        (product) => (product?.subcategory?.key || null) === key
      );
    }
    return [] as IAddon[];
  };

  const isAddonSelectedInCategoryOrSubcategory = (
    filteredProducts: IAddon[]
  ) => {
    const activeProductsMap: { [key: string]: boolean } = activeProducts.reduce(
      (acc, activeProduct) => {
        acc[activeProduct.key] = true;
        return acc;
      },
      {}
    );
    const isAddonSelected = !!filteredProducts.find(
      (addon) => !!activeProductsMap[addon.key]
    );

    return isAddonSelected;
  };

  const selectedCustomAddons = activeProducts.filter(
    (selectedCustomItem) =>
      selectedCustomItem.isCustom &&
      (selectedCustomItem.group === Group.device ||
        selectedCustomItem.group === Group.addon)
  );

  const filteredCustomAddons = customAddons.filter(
    (customAddon) =>
      !selectedCustomAddons.find(
        (selectedCustomAddonItem) =>
          customAddon.key === selectedCustomAddonItem.key
      )
  );

  const customAddonClickHandler = (
    customAddon: ICustomAddon | ICartItemData,
    isActive: boolean
  ) => {
    if (isActive) {
      deleteAddon(customAddon.key, customAddon.name);
    } else {
      addProduct(
        customAddon as ICustomAddon,
        presenter.getPaymentType(customAddon.price.monthly)
      );
      trackClick(`${customAddon.name}.in.den.warenkorb.legen`, trackingContext);
    }
  };

  return (
    <>
      <Tabs
        customClass={styles.navigationWrapper}
        scheme={Tabs.Variant.large}
        onChange={(index) => {
          setCurrentTabCategory(categories[index]?.key);
        }}
      >
        <Tabs.List>
          {categories.map((category) => (
            <Tabs.Tab
              onClick={() => {
                trackClick(`${category.name}`, trackingContext);
              }}
              key={category.key}
              value={category.key}
            >
              {category.name}
              {isAddonSelectedInCategoryOrSubcategory(
                filterProductsByCategoryKey(category.key)
              ) && (
                <span
                  className={`${styles.categoryWithSelectedAddonsMark} ${styles.category}`}
                />
              )}
            </Tabs.Tab>
          ))}
        </Tabs.List>
        {categories.map((category) => (
          <Tabs.Panel
            aria-label={`${category.name} Tabelle`}
            className={styles.tabPanel}
            key={category.key}
            tabIndex={-1}
          >
            {category.key === currentTabCategory && (
              <>
                {!!category.productGroups.length && (
                  <div className={`${styles.productGroupWrapper}`}>
                    {category.productGroups.map((productGroup) => (
                      <Fragment key={productGroup.key}>
                        {getAddonTileTemplate(productGroup)}
                      </Fragment>
                    ))}
                  </div>
                )}
                {!!category.subcategories.length && (
                  <Accordion>
                    {category.subcategories
                      .filter((subCategory) => subCategory.productGroups.length)
                      .map((subcategory) => (
                        <Accordion.Item
                          isExpanded={true}
                          customClass={styles.accordionItem}
                          key={subcategory.key}
                        >
                          {/* Clickable div */}
                          <div className={styles.accordionToggleWrapper}>
                            <Accordion.Item.Toggle
                              customClass={styles.accordionToggle}
                            >
                              <div
                                className={styles.accordionToggleContentWrapper}
                              >
                                <Icon
                                  wrapperProps={{
                                    className: styles.accordionToggleIcon,
                                  }}
                                  icon={ActionAddDefault}
                                  size={Icon.Size.small}
                                />
                                <Heading
                                  variants={Heading.Variant.quaternary}
                                  customClass={styles.accordionToggleText}
                                >
                                  {subcategory.name}
                                  {isAddonSelectedInCategoryOrSubcategory(
                                    filterProductsBySubcategoryKey(
                                      subcategory?.key || null
                                    )
                                  ) && (
                                    <span
                                      className={`${styles.categoryWithSelectedAddonsMark} ${styles.subcategory}`}
                                    />
                                  )}
                                </Heading>
                              </div>
                            </Accordion.Item.Toggle>
                            {subcategory.link && subcategory.link_title && (
                              <TextLink
                                tabIndex={0}
                                customClass={styles.accordionLink}
                                href={subcategory.link}
                                target="_blank"
                                variants={TextLink.Variant.highlight}
                                iconRight={ContinueOutline}
                                onClick={() => {
                                  trackLink(subcategory.link, trackingContext);
                                }}
                              >
                                {subcategory.link_title}
                              </TextLink>
                            )}
                          </div>
                          <Accordion.Panel>
                            <div className={styles.productGroupWrapper}>
                              {subcategory.productGroups.map((productGroup) => (
                                <Fragment key={productGroup.key}>
                                  {getAddonTileTemplate(productGroup)}
                                </Fragment>
                              ))}
                            </div>
                          </Accordion.Panel>
                        </Accordion.Item>
                      ))}
                  </Accordion>
                )}
              </>
            )}
          </Tabs.Panel>
        ))}
      </Tabs>
      {allowCustomProducts && (
        <div
          className={`${styles.customAddonsSection} ${styles.productGroupWrapper}`}
        >
          <CustomAddonForm
            trackingContext={trackingContext}
            addProduct={addProduct}
            addonGroup={addonGroup}
            customClass={styles.productGroupItem}
            customAddonFormData={customAddonFormData}
            customAddonContractPeriod={customAddonContractPeriod}
            addCustomAddon={addCustomAddon}
            condition={condition}
          />
          {[...selectedCustomAddons, ...filteredCustomAddons].map(
            (customAddon, index) => {
              const isActive = !!activeProducts.find(
                (activeProduct) => activeProduct.key === customAddon.key
              );
              return (
                <AddonItem
                  key={`${customAddon.key}-${index}`}
                  customClass={styles.productGroupItem}
                  isActive={isActive}
                  isFungible
                  selectedHasPromotions={false}
                  price={customAddon.price}
                  name={customAddon.name}
                  quantity={
                    isActive && "quantity" in customAddon
                      ? customAddon.quantity
                      : 1
                  }
                  isProductGroup={false}
                  isRemovable={addonGroup === Group.device}
                  onClick={() => customAddonClickHandler(customAddon, isActive)}
                  onQuantityChange={(quantity: number) => {
                    onQuantityChange(customAddon.key, quantity);
                    trackClick(
                      `${customAddon.name}.menge.${quantity}`,
                      trackingContext
                    );
                  }}
                  contractPeriod={customAddon.contractPeriod}
                  paymentType={presenter.getPaymentType(
                    customAddon.price.monthly
                  )}
                  onRemove={() => {
                    removeCustomAddon(customAddon.key);
                    deleteAddon(customAddon.key, customAddon.name);
                    trackClick(
                      `${customAddon.name}.aus.gespeichert.entfernen`,
                      trackingContext
                    );
                  }}
                />
              );
            }
          )}
        </div>
      )}
      {isGroupModalOpen && (
        <AddonsGroupSelectionModal
          getAdditionalPriceSuffix={getEgspText}
          activeProducts={activeProducts}
          groupedProducts={productChoices}
          isOpen={isGroupModalOpen}
          onCancel={() => setIsGroupModalOpen(false)}
          onSubmit={handleProductGroupSelection}
          customClass={styles.modalWrapper}
          trackingContext={trackingContext}
        />
      )}
      {isPriceModalOpen && (
        <AddonsPriceTypeSelectionModal
          product={productChoices.length ? productChoices[0] : null}
          isOpen={isPriceModalOpen}
          activeProductPriceType={
            activeProducts.find(
              (activeProduct) => activeProduct.key === productChoices[0]?.key
            )?.paymentType || ""
          }
          getAdditionalPriceSuffix={getEgspText}
          onCancel={() => setIsPriceModalOpen(false)}
          onSubmit={handleProductSelection}
          customClass={styles.modalWrapper}
          trackingContext={trackingContext}
        />
      )}
      {isPromotionsModalOpen && (
        <AddonsPromotionSelectionModal
          activeProduct={promotionsProduct}
          promotions={addonsProductPromotions(promotionsProduct.key)}
          isOpen={isPromotionsModalOpen}
          onSubmit={(selectedPromotions) => {
            handlePromotionSelection(selectedPromotions);
            trackClick("modal.aktionen.auswahl.übernehmen", "Aktionsangebote");
          }}
          onCancel={() => setIsPromotionsModalOpen(false)}
          businessCaseName={businessCaseName}
          trackingContext={trackingContext}
          customClass={styles.modalWrapper}
        />
      )}
    </>
  );
};

export default observer(AddonsSelection);
