import type { ICard, CardExport } from "core/entities/Product/Card/ICard";
import { CardSections } from "core/entities/Product/Card/ICard";
import { ITariffWorld } from "core/entities/Product/IProduct";
import { ITariff } from "core/entities/Product/Tariff/ITariff";

export interface IActiveSettings {
  tariffWorld: string;
  generation: string;
  size: string;
}

export type MagentaNextCard = ICard | ITariff;

export type ITariffConfigurationSettings = {
  tariffWorlds?: ITariffWorld[];
  generations: string[];
  sizes: string[];
};

const initialActiveSettings = {
  tariffWorld: "",
  generation: "",
  size: "",
};

export class LegacyTariffPresenter {
  private settings: ITariffConfigurationSettings = {
    tariffWorlds: [],
    generations: [],
    sizes: [],
  };

  private activeSettings: IActiveSettings = initialActiveSettings;

  private sectionNames: Partial<Record<CardSections, string>> = {
    [CardSections.MAGENTA_MOBILE_CARD]: "Hauptkarte",
    [CardSections.ADDITIONAL_CARD]: "Zusatzkarte",
    [CardSections.CUSTOM_CARD]: "Fremdkarte",
  };

  private activeSection: CardSections = CardSections.ADDITIONAL_CARD;

  constructor(private cards: CardExport, private activeCards: CardExport) {}

  get mainCards(): ITariff[] {
    return this.cards[CardSections.MAGENTA_MOBILE_CARD];
  }

  get additionalCards(): ICard[] {
    return this.cards[CardSections.ADDITIONAL_CARD];
  }

  get activeMainCards(): ITariff[] {
    return this.activeCards[CardSections.MAGENTA_MOBILE_CARD];
  }

  get activeAdditionalCards(): ICard[] {
    return this.activeCards[CardSections.ADDITIONAL_CARD];
  }

  get activeCustomCards(): ICard[] {
    return this.activeCards[CardSections.CUSTOM_CARD];
  }

  get card(): MagentaNextCard | undefined {
    const { tariffWorld, generation, size } = this.activeSettings;
    const cards = this.cards[this.activeSection] as Array<MagentaNextCard>;
    const matchingCard = cards.find(
      (card) =>
        card.tariffWorld?.key === tariffWorld &&
        card.generation === generation &&
        card.size === size
    );

    return matchingCard;
  }

  getSectionName(sectionId?: CardSections, isPlural?: boolean): string {
    return (this.sectionNames[sectionId] || "Karte") + (isPlural ? "n" : "");
  }

  setCards(cards: CardExport): void {
    this.cards = cards;
  }

  setActiveCards(activeCards: CardExport): void {
    this.activeCards = activeCards;
  }

  setActiveSection(section: CardSections): void {
    this.activeSection = section;
  }

  resetActiveSettings(): void {
    this.activeSettings = initialActiveSettings;
  }

  getSettings(): ITariffConfigurationSettings {
    const cards = this.cards[this.activeSection] as Array<MagentaNextCard>;
    return {
      tariffWorlds: this.getCardWorlds(cards),
      generations: this.getGenerations(cards),
      sizes: this.getSizes(cards),
    };
  }

  getActiveSettings(): IActiveSettings {
    return this.activeSettings;
  }

  setActiveSettings(activeSettings: IActiveSettings) {
    this.activeSettings = activeSettings;
  }

  setActiveSetting(key: keyof IActiveSettings, value: string): void {
    if (key === "tariffWorld") this.activeSettings.generation = "";
    if (key === "tariffWorld" || key === "generation")
      this.activeSettings.size = "";

    this.activeSettings = { ...this.activeSettings, [key]: value };
  }

  createCustomCard(index: number): Partial<ICard> {
    return {
      key: new Date().getTime().toString(),
      name: `Bestandskarte Nr. ${index}`,
      tariffWorld: {
        valency: null,
        name: "",
        key: CardSections.CUSTOM_CARD,
        limit: null,
      },
      price: { monthly: 0, once: 0, originalPrice: null },
      quantity: 1,
    };
  }

  private getCardWorlds(cards: Array<MagentaNextCard>): ITariffWorld[] {
    const tariffWorlds = cards
      .reduce((acc, { tariffWorld }) => {
        const cardWorldExists = acc.some(
          (item) => item.key === tariffWorld?.key
        );

        return cardWorldExists ? acc : [...acc, tariffWorld];
      }, [] as ITariffWorld[])
      .sort((a, b) => a.name.localeCompare(b.name));

    if (tariffWorlds.length === 1)
      this.activeSettings.tariffWorld = tariffWorlds[0].key;

    return tariffWorlds;
  }

  private getGenerations(cards: Array<MagentaNextCard>): string[] {
    const generations = cards
      .reduce((acc, { tariffWorld, generation }) => {
        const matchingTariffWorldKey =
          tariffWorld.key === this.activeSettings.tariffWorld;
        const generationExists = acc.includes(generation);

        if (matchingTariffWorldKey && !generationExists && generation)
          return [...acc, generation];
        return acc;
      }, [] as string[])
      .sort();

    if (generations.length === 1)
      [this.activeSettings.generation] = generations;

    return generations;
  }

  private getSizes(cards: Array<MagentaNextCard>): string[] {
    const sizes = cards
      .reduce((acc, { tariffWorld, generation, size }) => {
        const matchingTariffWorldKey =
          tariffWorld.key === this.activeSettings.tariffWorld;
        const matchingGeneration =
          generation === this.activeSettings.generation;

        const sizeExists = acc.includes(size);

        if (matchingTariffWorldKey && matchingGeneration && !sizeExists)
          return [...acc, size];
        return acc;
      }, [] as string[])
      .sort();

    if (sizes.length === 1) [this.activeSettings.size] = sizes;

    return sizes;
  }
}
