import { PortfolioKeys } from "../../Product/IProduct";
import { Bandwidths, BusinessCasesMobile } from "../../Product/Tariff/ITariff";
import { ExpansionArea } from "../../Applications";
import {
  IBusinessClientsCardSettings,
  ICartConfigurationType,
  ILandlineSettings,
  IMobileSettings,
} from "./IConfigurations";

export const initialLandlineSettings: ILandlineSettings = {
  portfolio: PortfolioKeys.LANDLINE,
  fiber: false,
  bandwidth: Bandwidths.XL,
  isYoung: false,
  isVario: null,
  tariffWorld: "",
  level: "",
  expansionArea: ExpansionArea.telekom,
} as const;

export const initalMobileSettings: IMobileSettings = {
  portfolio: PortfolioKeys.MOBILE,
  isYoung: false,
  isVario: null,
  tariffWorld: "",
  level: "",
  hasProvisionFee: true,
  loyaltyBonusValue: 0,
};

export const initialMobileDataMap = {
  [BusinessCasesMobile.NEUVERTRAG]: {
    isBenefitActive: true,
    isNextMagentaActive: true,
  },
  [BusinessCasesMobile.VERLAENGERUNG]: {
    isBenefitActive: false,
    isNextMagentaActive: false,
  },
};

export const initialBusinessClientsCardSettings: IBusinessClientsCardSettings =
  {
    isYoung: null,
    isVario: false,
    tariffWorld: "",
    level: "",
    tariff: null,
    inheritedDataVolume: null,
    portfolio: PortfolioKeys.MOBILE,
    hasProvisionFee: true,
  };

export const initialBusinessClientsMobileSettings: IMobileSettings = {
  portfolio: PortfolioKeys.MOBILE,
  isYoung: null,
  isVario: false,
  tariffWorld: "",
  level: "",
  hasProvisionFee: true,
  loyaltyBonusValue: 0,
};

export const initialBusinessClientsLandlineSettings: ILandlineSettings = {
  portfolio: PortfolioKeys.LANDLINE,
  fiber: false,
  bandwidth: Bandwidths.XL,
  isYoung: null,
  isVario: false,
  tariffWorld: "",
  level: "",
  expansionArea: ExpansionArea.telekom,
};

export const configurationsDefaultNameMap = {
  [ICartConfigurationType.MOBILE]: "Mobilfunk Nr.",
  [ICartConfigurationType.CARD]: "Zusatzkarten Nr.",
  [ICartConfigurationType.LANDLINE]: "Festnetz Nr.",
  [ICartConfigurationType.DEVICE]: "Geräte",
};
