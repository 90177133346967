import { CustomerType } from "../Customer/ICustomer";

export enum FactModalKeys {
  CUSTOMER_REQUIREMENTS = "customer_requirements",
  BASE_DATA = "base_data",
  MOBILE_TARIFF = "mobile",
  LANDLINE_TARIFF = "landline",
  SECONDARY_CARDS = "cards",
  DEVICES = "devices",
  OFFER_FINALISATION = "finalization",
  SUMMARY = "offer",
}

export type IFactModalsData = {
  [key in FactModalKeys]?: IFactModalItem | null;
};

export interface IFactModalItem {
  image: string | null;
  heading: string | null;
  copy: string | null;
  layout: string;
  uuid: string | null;
  filled: boolean | null;
  active: boolean;
}

export type IFactModalsMap = {
  [key in CustomerType]?: IFactModalStepMap;
};

export type IFactModalStepMap = {
  [key in FactModalKeys]?: IFactModalsMapItem;
};

export interface IFactModalsMapItem {
  uuid: string;
  isOpenedByDefault: boolean;
}
