import React, { ReactNode } from "react";
import { Container, Grid } from "@dtpk-cc/components";
import { CustomerRequirementsBase } from "core/entities/PencilSelling/CustomerRequirementsBase/CustomerRequirementsBase";
import * as styles from "./customer-requirements.module.scss";
import { InfoModalVariants } from "./CustomerRequirementsInfoModal";
import CustomerRequirementsBandwidth from "./CustomerRequirementsBandwidth";
import CustomerRequirementsDevices from "./CustomerRequirementsDevices";

type CustomerRequirementsProps = {
  components: {
    useCase: ReactNode;
    fiber: ReactNode;
    internationalTariff: ReactNode;
    smartphone: ReactNode;
    magentaShape: ReactNode;
    customerSize: ReactNode;
    modal: ReactNode;
  };
  customerRequirementsBase: CustomerRequirementsBase;
  isBusinessClient: boolean;
  openInfoModal: (variant: InfoModalVariants) => void;
  trackingContext: string;
};

const CustomerRequirements = ({
  components,
  customerRequirementsBase,
  isBusinessClient,
  openInfoModal,
  trackingContext,
}: CustomerRequirementsProps) => (
  <Container>
    <Grid customClass={styles.gridPaddingLeft}>
      <Grid.Row customClass={styles.firstRow}>
        <Grid.Col customClass={styles.firstColumn}>
          <div className={styles.bandwidthAndFiberContainer}>
            <Grid.Col customClass={styles.bandwidthSectionContainer}>
              <CustomerRequirementsBandwidth
                isBusinessClient={isBusinessClient}
                openInfoModal={openInfoModal}
                trackingContext={trackingContext}
                customerRequirementsBase={customerRequirementsBase}
              />
              {components.useCase}
            </Grid.Col>
            <Grid.Col customClass={styles.fiberCableSectionContainer}>
              {components.fiber}
            </Grid.Col>
          </div>
        </Grid.Col>
        <Grid.Col l={6} xl={14} customClass={styles.secondColumn}>
          {components.internationalTariff}
          {components.smartphone}
        </Grid.Col>
      </Grid.Row>
      <Grid.Row customClass={styles.secondRow}>
        <Grid.Col l={12} xl={24}>
          <div className={styles.houseHoldSwapFlexDirectionReverse}>
            <div className={styles.houseHoldSwapFlexDirection}>
              <Grid.Col l={6} xl={15}>
                {components.customerSize}
              </Grid.Col>

              <Grid.Col l={6} xl={12}>
                <CustomerRequirementsDevices
                  trackingContext={trackingContext}
                  openInfoModal={openInfoModal}
                  customerRequirementsBase={customerRequirementsBase}
                  isBusinessClient={isBusinessClient}
                />
              </Grid.Col>
            </div>
            <div className={styles.shapeSectionContainer}>
              {components.magentaShape}
            </div>
          </div>
        </Grid.Col>
      </Grid.Row>
    </Grid>
    {components.modal}
  </Container>
);

export default CustomerRequirements;
