import React from "react";
import { CopyText } from "@dtpk-cc/components";
import DropdownMain from "elements/new-design/DropdownMain";
import {
  DevicesAmounts,
  DevicesAmountVariants,
} from "core/entities/PencilSelling/CustomerRequirementsBase/ICustomerRequirementsBase";
import { trackClick } from "helpers/reactTracking";
import { CustomerRequirementsBase } from "core/entities/PencilSelling/CustomerRequirementsBase/CustomerRequirementsBase";
import { getCustomerRequirementsOptionsInterval } from "helpers/CustomerRequirements";
import { observer } from "mobx-react";
import * as styles from "./customer-requirements-devices.module.scss";
import CustomerRequirementsSectionHeader from "../CustomerRequirementsSectionHeader";
import { InfoModalVariants } from "../CustomerRequirementsInfoModal";
import SmartphoneIcon from "../../../../../assets/images/icons/pencil-selling-tool/customer-requirements/smartphone.svg";
import TabletIcon from "../../../../../assets/images/icons/pencil-selling-tool/customer-requirements/tablet.svg";
import LaptopIcon from "../../../../../assets/images/icons/pencil-selling-tool/customer-requirements/laptop.svg";
import SmartWatchIcon from "../../../../../assets/images/icons/pencil-selling-tool/customer-requirements/smartwatch.svg";
import SmartHomeIcon from "../../../../../assets/images/icons/pencil-selling-tool/customer-requirements/smart_home.svg";
import GamingIcon from "../../../../../assets/images/icons/pencil-selling-tool/customer-requirements/gaming.svg";
import MobilityCarIcon from "../../../../../assets/images/icons/pencil-selling-tool/customer-requirements/mobility_car.svg";
import SmartHomeIconBC from "../../../../../assets/images/icons/pencil-selling-tool/customer-requirements/smart_home_bc.svg";

type DevicesItem = {
  identifier: keyof DevicesAmounts;
  icon: React.FunctionComponent<React.SVGAttributes<SVGElement>>;
  title: string;
};

const getDevicesItemsMap = (isBusinessClient: boolean): DevicesItem[] => {
  const items: DevicesItem[] = [
    {
      icon: SmartphoneIcon,
      title: "Smartphone",
      identifier: "smartphones",
    },
    {
      icon: TabletIcon,
      title: "Tablet",
      identifier: "tablets",
    },
    {
      icon: LaptopIcon,
      title: "Laptop",
      identifier: "laptops",
    },
    {
      icon: SmartWatchIcon,
      title: "Smartwatch",
      identifier: "smartwatches",
    },
  ];

  if (isBusinessClient) {
    return [
      ...items,
      {
        icon: MobilityCarIcon,
        title: "Mobilität",
        identifier: "mobility",
      },
      {
        icon: SmartHomeIconBC,
        title: "Smart Home",
        identifier: "smartHome",
      },
    ];
  }

  return [
    ...items,
    {
      icon: GamingIcon,
      title: "Gaming",
      identifier: "gaming",
    },
    {
      icon: SmartHomeIcon,
      title: "Smart Home",
      identifier: "smartHome",
    },
  ];
};

const devicesContentMap = {
  dropDownOptions: getCustomerRequirementsOptionsInterval(4, [5], [""]),
  tabs: [
    { label: "Im Bestand", value: DevicesAmountVariants.IN_STOCK },
    { label: "Wird benötigt", value: DevicesAmountVariants.NEEDED },
  ],
};

type CustomerRequirementsDevicesProps = {
  openInfoModal: (variant: InfoModalVariants) => void;
  isBusinessClient: boolean;
  trackingContext: string;
  customerRequirementsBase: CustomerRequirementsBase;
};

const CustomerRequirementsDevices = ({
  openInfoModal,
  isBusinessClient,
  trackingContext,
  customerRequirementsBase,
}: CustomerRequirementsDevicesProps) => {
  const devicesItems = getDevicesItemsMap(isBusinessClient);
  const devicesInfoModalContent = isBusinessClient
    ? InfoModalVariants.DEVICES_GK
    : InfoModalVariants.DEVICES;
  return (
    <div className={styles.devicesSectionContainer}>
      <CustomerRequirementsSectionHeader
        sectionTitle="geräte"
        onInfoClick={() => openInfoModal(devicesInfoModalContent)}
      />
      <div className={styles.devicesItemsContainer}>
        {devicesItems.map((item) => (
          <div key={item.identifier} className={styles.devicesItemContainer}>
            <div className={styles.devicesItemIconContainer}>
              <item.icon className={styles.devicesItemIcon} />
              <CopyText
                variants={CopyText.Variant.additional}
                customClass={styles.devicesItemTitle}
              >
                {item.title}
              </CopyText>
            </div>
            <DropdownMain
              label="Anzahl"
              items={devicesContentMap.dropDownOptions}
              onSelection={(value) => {
                trackClick(
                  `devices.${item.identifier}.${value || "0"}`,
                  trackingContext
                );
                customerRequirementsBase.setDevicesValue(
                  item.identifier,
                  value
                );
              }}
              value={
                customerRequirementsBase.requirements.devices[
                  item.identifier
                ] || ""
              }
              customClass={styles.devicesItemDropdown}
            />
          </div>
        ))}
      </div>
    </div>
  );
};

export default observer(CustomerRequirementsDevices);
