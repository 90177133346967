// extracted by mini-css-extract-plugin
var _1 = "magenta-next-module-active-TFgMR";
var _2 = "magenta-next-module-additionalCardsWrapper-AoaYX";
var _3 = "magenta-next-module-badge-FQEIB";
var _4 = "magenta-next-module-benefit-hzT8g";
var _5 = "magenta-next-module-benefitPrice-LMjqE";
var _6 = "magenta-next-module-benefitsButton-WSx3C";
var _7 = "magenta-next-module-brandColor-N8bNH";
var _8 = "magenta-next-module-centered-KLdSZ";
var _9 = "magenta-next-module-checkbox-CD9Fu";
var _a = "magenta-next-module-col-OwpWx";
var _b = "magenta-next-module-comparisingMode-Hmg6Q";
var _c = "magenta-next-module-copytext-Iamho";
var _d = "magenta-next-module-equalHeightSummary-xKqlY";
var _e = "magenta-next-module-header-HMN0r";
var _f = "magenta-next-module-headerHintbox-r_uvy";
var _10 = "magenta-next-module-headline-QDnxY";
var _11 = "magenta-next-module-headlineWithDelimiter-KJAQC";
var _12 = "magenta-next-module-hideInPrint-zvNIo";
var _13 = "magenta-next-module-hintbox-gi0JR";
var _14 = "magenta-next-module-hintboxIcon-OdmQX";
var _15 = "magenta-next-module-legacy-kAx1K";
var _16 = "magenta-next-module-linkTile-SR77e";
var _17 = "magenta-next-module-list-a2u0I";
var _18 = "magenta-next-module-listElement-cm5ZX";
var _19 = "magenta-next-module-loyaltyBonus-gcUqA";
var _1a = "magenta-next-module-magentaNext-B9CIb";
var _1b = "magenta-next-module-mainContainer-eAten";
var _1c = "magenta-next-module-noFlexShrink-H3fCb";
var _1d = "magenta-next-module-priceSummary-UBPhh";
var _1e = "magenta-next-module-priceSummaryLeft-HBbXd";
var _1f = "magenta-next-module-priceSummaryRight-nQepX";
var _20 = "magenta-next-module-primaryText-qTm9m";
var _21 = "magenta-next-module-printRow-HgRAG";
var _22 = "magenta-next-module-productTile-xt7os";
var _23 = "magenta-next-module-reset-w6BiL";
var _24 = "magenta-next-module-row-yuCff";
var _25 = "magenta-next-module-scrollChild-wYT81";
var _26 = "magenta-next-module-scrollContainer-zYUFN";
var _27 = "magenta-next-module-separator-DMgOu";
var _28 = "magenta-next-module-stretch-NbIWq";
var _29 = "magenta-next-module-stretchLegacy-S_rUd";
var _2a = "magenta-next-module-tariffConfigurator-J_4nP";
var _2b = "magenta-next-module-test-QTwFo";
var _2c = "magenta-next-module-tile-tig2A";
var _2d = "magenta-next-module-top-VttGe";
var _2e = "magenta-next-module-withDelimiter-yBsFZ";
export { _1 as "active", _2 as "additionalCardsWrapper", _3 as "badge", _4 as "benefit", _5 as "benefitPrice", _6 as "benefitsButton", _7 as "brandColor", _8 as "centered", _9 as "checkbox", _a as "col", _b as "comparisingMode", _c as "copytext", _d as "equalHeightSummary", _e as "header", _f as "headerHintbox", _10 as "headline", _11 as "headlineWithDelimiter", _12 as "hideInPrint", _13 as "hintbox", _14 as "hintboxIcon", _15 as "legacy", _16 as "linkTile", _17 as "list", _18 as "listElement", _19 as "loyaltyBonus", _1a as "magentaNext", _1b as "mainContainer", _1c as "noFlexShrink", _1d as "priceSummary", _1e as "priceSummaryLeft", _1f as "priceSummaryRight", _20 as "primaryText", _21 as "printRow", _22 as "productTile", _23 as "reset", _24 as "row", _25 as "scrollChild", _26 as "scrollContainer", _27 as "separator", _28 as "stretch", _29 as "stretchLegacy", _2a as "tariffConfigurator", _2b as "test", _2c as "tile", _2d as "top", _2e as "withDelimiter" }
