import { useCallback } from "react";
import { BusinessCasesMobile } from "core/entities/Product/Tariff/ITariff";
import { initialMobileDataMap } from "core/entities/PencilSelling/Configurations/const";
import {
  IBusinessClientsCardSettings,
  ICartConfiguration,
  ICartConfigurationType,
} from "../../core/entities/PencilSelling/Configurations/IConfigurations";
import { Cart } from "../../core/entities/PencilSelling/Cart/Cart";
import {
  DefaultSettingsMap,
  getDefaultSettings,
} from "./useGetDefaultSettings";
import { CustomerType } from "../../core/entities/PencilSelling/Customer/ICustomer";
import { BaseOffer } from "../../core/entities/PencilSelling/BaseOffer/BaseOffer";

export const useGetDefaultConfigurationSettings = (offerStore: BaseOffer) => {
  const defaultMobileConfigurationSettings = useCallback(
    (defaultSettings: DefaultSettingsMap) => ({
      type: ICartConfigurationType.MOBILE,
      amount: 1,
      key: "",
      name: null,
      data: {
        settings: {
          ...defaultSettings.mobileSettings,
        },
        note: "",
        benefit: null,
        isOmitted: false,
        ...initialMobileDataMap[
          offerStore.getBusinessCase(
            ICartConfigurationType.MOBILE
          ) as BusinessCasesMobile
        ],
      },
      children: new Cart(),
      linkedConfigurationKey: null,
    }),
    [offerStore]
  );

  const defaultSecondaryCardsConfigurationSettings = useCallback(
    () => ({
      type: ICartConfigurationType.CARD,
      amount: 1,
      key: "",
      name: null,
      data: {
        settings: null,
        note: "",
        isBenefitActive: true,
        benefit: null,
        isOmitted: false,
      },
      children: new Cart(),
      linkedConfigurationKey: null,
    }),
    []
  );

  const defaultLandlineConfigurationSettings = useCallback(
    (defaultSettings: DefaultSettingsMap) => ({
      type: ICartConfigurationType.LANDLINE,
      amount: 1,
      key: "",
      name: null,
      data: {
        settings: {
          ...defaultSettings.landlineSettings,
        },
        note: "",
        isBenefitActive: true,
        benefit: null,
        isOmitted: false,
      },
      children: new Cart(),
      linkedConfigurationKey: null,
    }),
    []
  );
  const defaultDeviceConfigurationSettings = useCallback(
    () => ({
      type: ICartConfigurationType.DEVICE,
      amount: 1,
      key: "",
      name: null,
      data: {
        settings: null,
        isBenefitActive: false,
        benefit: null,
        note: "",
        isOmitted: false,
      },
      children: new Cart(),
      linkedConfigurationKey: null,
    }),
    []
  );

  const defaultBKConfigurationMap: (defaultSettings: DefaultSettingsMap) => {
    [key in ICartConfigurationType]: ICartConfiguration;
  } = useCallback(
    (defaultSettings: DefaultSettingsMap) => ({
      [ICartConfigurationType.MOBILE]:
        defaultMobileConfigurationSettings(defaultSettings),
      [ICartConfigurationType.CARD]: {
        ...defaultSecondaryCardsConfigurationSettings(),
        data: {
          ...defaultSecondaryCardsConfigurationSettings().data,
          settings: {
            ...(defaultSettings.cardSettings as IBusinessClientsCardSettings),
          },
        },
      },
      [ICartConfigurationType.LANDLINE]:
        defaultLandlineConfigurationSettings(defaultSettings),
      [ICartConfigurationType.DEVICE]: defaultDeviceConfigurationSettings(),
    }),
    [
      defaultDeviceConfigurationSettings,
      defaultLandlineConfigurationSettings,
      defaultMobileConfigurationSettings,
      defaultSecondaryCardsConfigurationSettings,
    ]
  );

  const defaultPKConfigurationMap: (defaultSettings: DefaultSettingsMap) => {
    [key in ICartConfigurationType]: ICartConfiguration;
  } = useCallback(
    (defaultSettings: DefaultSettingsMap) => ({
      [ICartConfigurationType.MOBILE]:
        defaultMobileConfigurationSettings(defaultSettings),
      [ICartConfigurationType.CARD]: {
        ...defaultSecondaryCardsConfigurationSettings(),
        data: {
          ...defaultSecondaryCardsConfigurationSettings().data,
          isBenefitActive: false,
        },
      },
      [ICartConfigurationType.LANDLINE]:
        defaultLandlineConfigurationSettings(defaultSettings),
      [ICartConfigurationType.DEVICE]: defaultDeviceConfigurationSettings(),
    }),
    [
      defaultDeviceConfigurationSettings,
      defaultLandlineConfigurationSettings,
      defaultMobileConfigurationSettings,
      defaultSecondaryCardsConfigurationSettings,
    ]
  );

  const getDefaultConfigurationSettings = useCallback(
    (type: ICartConfigurationType): ICartConfiguration => {
      const { customerType } = offerStore.customer.get();
      const defaultSettings = getDefaultSettings(customerType);
      return customerType === CustomerType.PRIVATE
        ? defaultPKConfigurationMap(defaultSettings)[type]
        : defaultBKConfigurationMap(defaultSettings)[type];
    },
    [defaultBKConfigurationMap, defaultPKConfigurationMap, offerStore.customer]
  );

  return { getDefaultConfigurationSettings };
};
