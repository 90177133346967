import React from "react";
import { Button, Icon } from "@dtpk-cc/components";
import TariffSelectionBenefit from "components/new-design/Tariff/TariffSelection/TariffSelectionBenefit";
import YoungTariff from "components/YoungTariff";
import { useStores } from "stores/PrivateClient";
import { observer } from "mobx-react";
import { CustomerType } from "core/entities/PencilSelling/Customer/ICustomer";
import { trackClick } from "helpers/reactTracking";
import * as styles from "./customer-requirements-header-extra-elements.module.scss";
import PdfIcon from "../../../../../../assets/images/icons/pencil-selling-tool/summary/pdf.svg";

type CustomerRequirementsHeaderExtraElementsProps = {
  trackingContext: string;
};

const CustomerRequirementsHeaderExtraElements = ({
  trackingContext,
}: CustomerRequirementsHeaderExtraElementsProps) => {
  const { customerRequirementsStore, offerStore, productsRepositoryStore } =
    useStores();
  const { customerRequirementsBase } = customerRequirementsStore;
  const { setIsLoading, customer } = offerStore.baseOffer;
  const { domainType, customerType } = customer.get();
  const pdfPayload = {
    tool_domain: domainType,
    bge_context: customerType === CustomerType.EMPLOYEE,
    customer_requirements: customerRequirementsStore.export(),
  };
  const pdfState = customerRequirementsStore.areRequirementsModified()
    ? "modified"
    : "not_modified";
  const getCustomerRequirementsPDF = async () => {
    try {
      setIsLoading(true);
      const pdfResponse =
        await productsRepositoryStore.getCustomerRequirementsPDF(pdfPayload);
      if (pdfResponse)
        window.open(pdfResponse, "_blank", "noopener,noreferrer");
    } catch (e) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, no-console
      console.log(e?.message);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className={styles.headerExtraElements}>
      <div className={styles.extraButtonsWrapper}>
        <TariffSelectionBenefit
          isActive={customerRequirementsBase.requirements.isBenefitActive}
          onClick={() => customerRequirementsBase.toggleBenefitActive()}
          trackingContext={trackingContext}
          withLoyaltyBonus={false}
          customText="MagentaEINS"
          customClass={styles.magentaBenefit}
        />
        <YoungTariff
          isActive={customerRequirementsStore.requirements.isYoung}
          onClick={() => customerRequirementsStore.toggleIsYoung()}
          customClass={styles.youngTariff}
          trackingContext={trackingContext}
        />
      </div>
      <div className={styles.buttonsWrapper}>
        <Button
          tabIndex={0}
          onClick={() => {
            trackClick(`pdf_drucken.${pdfState}`, trackingContext);
            getCustomerRequirementsPDF();
          }}
          variants={Button.Variant.icon}
          customClass={`${styles.btn}`}
        >
          <Icon icon={PdfIcon} wrapperProps={{ className: styles.btnIcon }} />
          PDF drucken
        </Button>
      </div>
    </div>
  );
};

export default observer(CustomerRequirementsHeaderExtraElements);
