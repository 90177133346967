import React, { useState, useCallback, useMemo } from "react";
import { TextLink, Tile } from "@dtpk-cc/components";
import { CardConfigModal } from "components/CardConfigModal";
import { LpgContinueSolid } from "@dtpk-cc/components/dist/icons";
import {
  IActiveSettings,
  LegacyTariffPresenter,
  MagentaNextCard,
} from "core/presenter/MagentaNext/LegacyTariffPresenter";
import { CardSections, ICard } from "core/entities/Product/Card/ICard";
import { trackClick, trackLink } from "helpers/reactTracking";
import * as styles from "views/MagentaNext/magenta-next.module.scss";
import { useMagentaNextCalculatorStores } from "stores/MagentaNext";
import { observer } from "mobx-react";
import CardCategory from "components/CardCategory";

type LegacyTariffConfiguratorProps = {
  legacyTariffDetailViewIsVisible?: boolean;
  toggleLegacyTariffDetailedView?: () => void;
};

const LegacyTariffConfigurator = ({
  legacyTariffDetailViewIsVisible,
  toggleLegacyTariffDetailedView,
}: LegacyTariffConfiguratorProps) => {
  const { legacyTariffStore } = useMagentaNextCalculatorStores();

  const presenter = useMemo(
    () =>
      new LegacyTariffPresenter(
        legacyTariffStore.getCards(),
        legacyTariffStore.getActiveCards()
      ),
    [legacyTariffStore]
  );

  const [cardToBeUpdated, setCardToBeUpdated] = useState(presenter.card);
  const [cardToBeConfigurated, setCardToBeConfigurated] = useState(
    presenter.card
  );

  const [sectionName, setSectionName] = useState(presenter.getSectionName());
  const [settings, setSettings] = useState(presenter.getSettings());
  const [activeSettings, setActiveSettings] = useState(
    presenter.getActiveSettings()
  );
  const [isModalOpen, setIsModalOpen] = useState(false);

  const legacyTariffView = legacyTariffDetailViewIsVisible
    ? "Kompaktansicht"
    : "Detailansicht";

  const updateCustomPrice = useCallback(() => {
    legacyTariffStore.setCustomTotalPrice(legacyTariffStore.getTotalPrice());
  }, [legacyTariffStore]);

  const updateCustomQuantity = useCallback(() => {
    legacyTariffStore.setCustomTotalQuantity(
      legacyTariffStore.getTotalQuantity()
    );
  }, [legacyTariffStore]);

  const updatePresenterActiveCards = useCallback(() => {
    presenter.setActiveCards(legacyTariffStore.getActiveCards());
  }, [presenter, legacyTariffStore]);

  const setQuantityAndUpdateCustomValues = useCallback(
    (key: string, quantity: number) => {
      legacyTariffStore.setQuantity(key, quantity);
      updateCustomPrice();
      updateCustomQuantity();
    },
    [legacyTariffStore, updateCustomPrice, updateCustomQuantity]
  );

  const handleDataVolumeChange = (key: string, dataVolume: string) => {
    legacyTariffStore.setCardDataVolume(key, dataVolume);
  };

  const handleCardPriceChange = (key: string, price: number) => {
    legacyTariffStore.setCardPrice(key, price);
    updateCustomPrice();
  };

  function handleNameChange(key: string, name: string) {
    legacyTariffStore.setName(key, name);
  }

  const handleQuantityChange = (key: string, quantity: number) => {
    setQuantityAndUpdateCustomValues(key, quantity);
  };

  const configureModal = (
    cardSection: CardSections,
    cardToUpdate?: MagentaNextCard
  ) => {
    if (!cardToUpdate) presenter.resetActiveSettings();
    presenter.setActiveSection(cardSection);
    setSectionName(presenter.getSectionName(cardSection));
    setActiveSettings(presenter.getActiveSettings());
    setSettings(presenter.getSettings());
    setCardToBeUpdated(cardToUpdate);
    setIsModalOpen(true);
  };

  const setActiveCard = (card: MagentaNextCard) => {
    if (!card) return;

    if (card.tariffWorld.key === CardSections.CUSTOM_CARD) {
      legacyTariffStore.addCustomCard(card as ICard);
    }

    setQuantityAndUpdateCustomValues(card.key, 1);
  };

  const handleAddMainCard = () => {
    configureModal(CardSections.MAGENTA_MOBILE_CARD);
  };

  const handleAddAdditionalCard = () => {
    configureModal(CardSections.ADDITIONAL_CARD);
  };

  const handleAddCustomCard = () => {
    const customCards = legacyTariffStore.getCustomCards();
    const newCard = {
      key: new Date().getTime().toString(),
      name: `Bestandskarte Nr. ${customCards.length + 1}`,
      tariffWorld: {
        valency: null,
        name: "",
        key: CardSections.CUSTOM_CARD,
        limit: null,
      },
      price: { monthly: 0, once: 0, originalPrice: null },
      quantity: 1,
    } as ICard;
    setActiveCard(newCard);
    updatePresenterActiveCards();
  };

  const handleCardUpdate = (card: MagentaNextCard, section: CardSections) => {
    presenter.setActiveSettings({
      tariffWorld: card.tariffWorld.key,
      generation: card.generation,
      size: card.size,
    });
    configureModal(section, card);
  };

  const handleDeleteCard = () => {
    trackClick(`${cardToBeUpdated}.karte-löschen`);
    setQuantityAndUpdateCustomValues(cardToBeUpdated.key, 0);
    setIsModalOpen(false);
  };

  const handleSettingsChange = (key: keyof IActiveSettings, value: string) => {
    presenter.setActiveSetting(key, value);
    setSettings(presenter.getSettings());
    setCardToBeConfigurated(presenter.card);
    setActiveSettings(presenter.getActiveSettings());
  };

  const saveCard = () => {
    if (cardToBeUpdated) legacyTariffStore.setQuantity(cardToBeUpdated.key, 0);
    setActiveCard(cardToBeConfigurated);
  };

  return (
    <div className={`${styles.magentaNext} ${styles.tariffConfigurator}`}>
      {true ? (
        <>
          {/* temporary hidden */}
          {/* {!!presenter.mainCards.length && (
            <CardCategory
              name={presenter.getSectionName(
                CardSections.MAGENTA_MOBILE_CARD,
                true
              )}
              cards={presenter.mainCards}
              addCardBtnText={`${presenter.getSectionName(
                CardSections.MAGENTA_MOBILE_CARD
              )} hinzufügen`}
              onAddCard={handleAddMainCard}
              onChangeCard={(card) =>
                handleCardUpdate(card, CardSections.MAGENTA_MOBILE_CARD)
              }
              onChangeQuantity={handleQuantityChange}
              onlyWithQuantity
            />
          )}
          {!!presenter.additionalCards.length && (
            <CardCategory
              name={presenter.getSectionName(
                CardSections.ADDITIONAL_CARD,
                true
              )}
              cards={presenter.additionalCards}
              addCardBtnText={`${presenter.getSectionName(
                CardSections.ADDITIONAL_CARD
              )} hinzufügen`}
              onAddCard={handleAddAdditionalCard}
              onChangeCard={(card) =>
                handleCardUpdate(card, CardSections.ADDITIONAL_CARD)
              }
              onChangeQuantity={handleQuantityChange}
              onlyWithQuantity
            />
          )} */}
          <CardCategory
            name="Bestandskarten"
            cards={presenter.activeCustomCards}
            addCardBtnText="Bestandskarte hinzufügen"
            isCustom
            withGB
            onChangeName={handleNameChange}
            onAddCard={handleAddCustomCard}
            onChangePrice={handleCardPriceChange}
            onChangeQuantity={handleQuantityChange}
            onDataVolumeChange={handleDataVolumeChange}
            onlyWithQuantity
            printRow
          />
        </>
      ) : (
        <CardCategory
          cards={[
            {
              name: "Mobilfunk-Karten (SIM-only)",
              key: "legacy-summary",
              price: { monthly: legacyTariffStore.getCustomTotalPrice() },
              quantity: legacyTariffStore.getCustomTotalQuantity(),
            } as MagentaNextCard,
          ]}
          isCustom
          onChangePrice={(_key, value) =>
            legacyTariffStore.setCustomTotalPrice(value)
          }
          onChangeQuantity={(_key, value) =>
            legacyTariffStore.setCustomTotalQuantity(value)
          }
        />
      )}

      {isModalOpen && (
        <CardConfigModal
          isOpen={isModalOpen}
          card={cardToBeConfigurated}
          settings={settings}
          activeSettings={activeSettings}
          headline={`${sectionName} ${
            cardToBeUpdated ? "ändern" : "hinzufügen"
          }`}
          buttonText={cardToBeUpdated ? "Speichern" : "Hinzufügen"}
          onChange={(key: keyof IActiveSettings, value: string) => {
            handleSettingsChange(key, value);
            trackClick(`${key}.${value}`);
          }}
          onSubmit={() => {
            saveCard();
            trackClick("karte-hinzufügen");
          }}
          onDelete={cardToBeUpdated ? handleDeleteCard : undefined}
          onCloseModal={() => setIsModalOpen(false)}
        />
      )}
    </div>
  );
};

export default observer(LegacyTariffConfigurator);
