import React from "react";
import { ToggleBtn } from "elements/new-design/ToggleBtn";
import { observer } from "mobx-react";
import { InternationalTariffVariants } from "core/entities/PencilSelling/CustomerRequirementsBase/ICustomerRequirementsBase";
import { trackClick } from "helpers/reactTracking";
import { CustomerRequirementsBase } from "core/entities/PencilSelling/CustomerRequirementsBase/CustomerRequirementsBase";
import * as styles from "./customer-requirements-international-tariff.module.scss";
import CustomerRequirementsSectionHeader from "../CustomerRequirementsSectionHeader";
import { InfoModalVariants } from "../CustomerRequirementsInfoModal";
import CustomerRequirementsRecommendation from "../CustomerRequirementsRecommendation";

const internationalTariffButtons = {
  EU_CH_UK: {
    title: "EU, Schweiz & UK",
    identifier: InternationalTariffVariants.EU_CH_UK,
  },
  TURKEY: {
    title: "Türkei",
    identifier: InternationalTariffVariants.TURKEY,
  },
  USA_CANADA_TURKEY: {
    title: "USA / Kanada & Türkei",
    identifier: InternationalTariffVariants.USA_CANADA_TURKEY,
  },
};

const getInternationalTariffButtons = (isBusinessClient: boolean) => {
  const buttons = [internationalTariffButtons.EU_CH_UK];
  if (isBusinessClient) {
    buttons.push(internationalTariffButtons.USA_CANADA_TURKEY);
  } else {
    buttons.push(internationalTariffButtons.TURKEY);
  }
  return buttons;
};

type CustomerRequirementsInternationalTariffProps = {
  openInfoModal: (variant: InfoModalVariants) => void;
  trackingContext: string;
  customerRequirementsBase: CustomerRequirementsBase;
  recommendation: string;
  isBusinessClient: boolean;
};

const CustomerRequirementsInternationalTariff = ({
  openInfoModal,
  trackingContext,
  customerRequirementsBase,
  recommendation,
  isBusinessClient,
}: CustomerRequirementsInternationalTariffProps) => {
  const buttons = getInternationalTariffButtons(isBusinessClient);
  const internationalTariffInfoModalContent = isBusinessClient
    ? InfoModalVariants.INTERNATIONAL_TARIFF_GK
    : InfoModalVariants.INTERNATIONAL_TARIFF;
  return (
    <div className={styles.internationalTariffComponentContainer}>
      <CustomerRequirementsSectionHeader
        sectionTitle="mobilfunk ausland"
        onInfoClick={() => openInfoModal(internationalTariffInfoModalContent)}
      />
      <div className={styles.internationalTariffContainer}>
        {buttons.map((item) => (
          <ToggleBtn
            key={`international_tariff_${item.identifier}`}
            id={`international_tariff_${item.identifier}`}
            title={item.title}
            onChange={() => {
              trackClick(
                `internationaltariff.${item.identifier}`,
                trackingContext
              );
              customerRequirementsBase.setInternationalTariff(item.identifier);
            }}
            checked={customerRequirementsBase.requirements.internationalTariffs.includes(
              item.identifier
            )}
            inputTestId={`international_tariff_${item.identifier}`}
            customTileClass={styles.internationalTariffTile}
            customTitleClass={styles.internationalTariffTitle}
            placeItems="start"
          />
        ))}
        <CustomerRequirementsRecommendation
          description={recommendation}
          customClass={styles.internationalTariffRecommendationPosition}
        />
      </div>
    </div>
  );
};

export default observer(CustomerRequirementsInternationalTariff);
