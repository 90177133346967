import { makeAutoObservable } from "mobx";
import {
  ICustomer,
  ICustomerEntity,
  CustomerType,
  DomainType,
  IUserGroups,
} from "./ICustomer";
import { CUSTOMER_DATA_DEFAULT, GROSS_PRICE_PREFIX } from "./const";

export class Customer implements ICustomerEntity {
  constructor() {
    makeAutoObservable(this);
  }

  consultingTime = {
    nextAppointmentDate: null,
    nextAppointmentTime: null,
  };

  notice = "";

  customerType: CustomerType = CustomerType.PRIVATE;

  customerPricePrefix = "";

  userGroup: IUserGroups | null = null;

  customerData = CUSTOMER_DATA_DEFAULT;

  set(customer: ICustomer) {
    this.consultingTime = customer.consultingTime;
    this.customerData = customer.customerData;
    this.notice = customer.notice;
    this.customerType = customer.customerType;
    this.customerPricePrefix = customer.customerPricePrefix;
    this.userGroup = customer.userGroup;
  }

  get(): ICustomer {
    return {
      notice: this.notice,
      customerData: this.customerData,
      consultingTime: this.consultingTime,
      customerType: this.customerType,
      customerPricePrefix: this.customerPricePrefix,
      domainType: this.getDomainType(),
      userGroup: this.userGroup,
    };
  }

  reset(): void {
    this.notice = "";
    this.customerData = CUSTOMER_DATA_DEFAULT;
    this.consultingTime = {
      nextAppointmentDate: null,
      nextAppointmentTime: null,
    };
    this.customerPricePrefix = GROSS_PRICE_PREFIX;
    this.customerType = CustomerType.PRIVATE;
    this.userGroup = IUserGroups.DEFAULT;
  }

  private getDomainType(): DomainType {
    if (this.customerType === CustomerType.PRIVATE) {
      return DomainType.PRIVATE;
    }
    // handle BUSINESS and EMPLOYEE as DomainType.BUSINESS
    return DomainType.BUSINESS;
  }
}
