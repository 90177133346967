import { makeAutoObservable } from "mobx";

import { IDescription } from "core/entities/Product/IDescription";
import { IPrice } from "core/entities/Product/IPrice";
import { Group, ITariffWorld, PortfolioKeys } from "../../Product/IProduct";
import {
  ICartItem,
  ICartItemData,
  PaymentTypes,
  IAdditionalDevices,
  ICardData,
} from "./ICartItem";
import { IExtension } from "../../Product/IExtension";
import { IPromotion } from "../IPromotion";

export class CartItem implements ICartItem {
  portfolio: PortfolioKeys;

  name: string;

  egsp?: string;

  price: IPrice;

  key: string;

  quantity: number;

  group: Group;

  description?: IDescription;

  additionalDescription?: string;

  contractPeriod?: number;

  promotions?: IPromotion[];

  additionalDevices?: IAdditionalDevices[];

  paymentType: PaymentTypes;

  isFungible: boolean;

  tariffWorld: ITariffWorld;

  availableFor?: string[];

  cardData: ICardData;

  inheritingCard?: boolean;

  extension?: IExtension;

  isCustom?: boolean;

  nextMagentaIsAvailable?: boolean;

  constructor(product: ICartItemData) {
    this.portfolio = product.portfolio;
    this.name = product.name;
    this.egsp = product.egsp;
    this.key = product.key;
    this.price = product.price;
    this.quantity = product.quantity;
    this.group = product.group;
    this.description = product.description;
    this.additionalDescription = product.additionalDescription;
    this.contractPeriod = product.contractPeriod;
    this.promotions = product.promotions;
    this.additionalDevices = product.additionalDevices;
    this.paymentType = product.paymentType;
    this.isFungible = product.isFungible;
    this.tariffWorld = product.tariffWorld;
    this.availableFor = product.availableFor;
    this.cardData = product.cardData;
    this.inheritingCard = product.inheritingCard;
    this.extension = product.extension;
    this.isCustom = product.isCustom ?? false;
    this.nextMagentaIsAvailable = product.nextMagentaIsAvailable;
    makeAutoObservable(this);
  }

  setQuantity(quantity: number): void {
    if (quantity > 0) this.quantity = quantity;
  }

  getPrice(): IPrice {
    return this.price;
  }

  export(): ICartItemData {
    return {
      portfolio: this.portfolio,
      name: this.name,
      egsp: this.egsp,
      price: this.price,
      key: this.key,
      quantity: this.quantity,
      group: this.group,
      description: this.description,
      additionalDescription: this.additionalDescription,
      contractPeriod: this.contractPeriod,
      promotions: this.promotions,
      additionalDevices: this.additionalDevices,
      paymentType: this.paymentType,
      isFungible: this.isFungible,
      tariffWorld: this.tariffWorld,
      availableFor: this.availableFor,
      cardData: this.cardData,
      inheritingCard: this.inheritingCard,
      extension: this.extension,
      isCustom: this.isCustom,
      nextMagentaIsAvailable: this.nextMagentaIsAvailable,
    };
  }
}
