import React from "react";
import { observer } from "mobx-react";
import { useAddonSelection } from "components/new-design/AddonsSelection/hooks/useAddonSelection";
import { ICartConfigurationType } from "../../../../../core/entities/PencilSelling/Configurations/IConfigurations";
import AddonsSelection from "../../../../../components/new-design/AddonsSelection";
import {
  Group,
  PortfolioKeys,
} from "../../../../../core/entities/Product/IProduct";
import { mobileBusinessCase } from "../../../../../core/entities/Product/BusinessClient/IBaseData";
import { getBusinessCaseName } from "../../../../../helpers/GetBusinessCaseName";
import { PropsWithBusinessCase } from "../../../IPencilSelling";
import { useStores } from "../../../../../stores/BusinessClient";
import { useGetCustomAddonContractPeriod } from "../../../../../hooks/PencilSelling/useGetCustomAddonContractPeriod";
import { CUSTOM_EXTRA_FORM_DATA } from "../../../../../components/new-design/AddonsSelection/const";

export interface MobileTariffAddonsProps extends PropsWithBusinessCase {
  trackingContext: string;
}

const MobileTariffAddons = ({
  businessCase,
  trackingContext,
}: MobileTariffAddonsProps) => {
  const { configurationsStore, offerStore } = useStores();
  const { addons, addonsInCart, activeTariffKey } = useAddonSelection(
    ICartConfigurationType.MOBILE
  );
  const customAddonContractPeriod = useGetCustomAddonContractPeriod(
    offerStore.getBaseOffer()
  );

  return (
    <AddonsSelection
      isFungible
      allowCustomProducts
      key={addons.length}
      products={addons}
      activeProducts={addonsInCart}
      condition={activeTariffKey}
      portfolio={PortfolioKeys.MOBILE}
      addonGroup={Group.addon}
      trackingContext={trackingContext}
      configurationsStore={configurationsStore}
      businessCaseName={getBusinessCaseName(
        businessCase,
        mobileBusinessCase.items
      )}
      customAddonContractPeriod={customAddonContractPeriod}
      customAddonFormData={CUSTOM_EXTRA_FORM_DATA}
    />
  );
};

export default observer(MobileTariffAddons);
