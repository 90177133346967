import {
  NEXT_MAGENTA_ACTIVE_DESCRIPTION,
  NEXT_MAGENTA_NOT_ACTIVE_DESCRIPTION,
  ONLY_NEXT_MAGENTA_WITH_BENEFIT_TARIFF,
} from "constants/const";
import { ICartItemData } from "core/entities/PencilSelling/CartItem/ICartItem";
import { IDescriptionItemNextMagenta } from "core/entities/Product/IDescription";
import {
  BusinessCasesMobile,
  IBusinessCasesTypeValues,
} from "core/entities/Product/Tariff/ITariff";

export const nextMagentaIsApplied = (
  isBenefitActive: boolean,
  isNextMagentaActive: boolean,
  mobileTariffKey?: string
): boolean => {
  if (mobileTariffKey?.includes(ONLY_NEXT_MAGENTA_WITH_BENEFIT_TARIFF)) {
    return isBenefitActive && isNextMagentaActive;
  }

  return isNextMagentaActive;
};

export const getNextMagentaDescriptionItem = (
  cartItem: ICartItemData,
  isBenefitActive: boolean,
  isNextMagentaActive: boolean,
  businessCase: IBusinessCasesTypeValues
): IDescriptionItemNextMagenta | null => {
  const { nextMagentaIsAvailable, key } = cartItem;

  if (
    !nextMagentaIsAvailable ||
    businessCase !== BusinessCasesMobile.NEUVERTRAG
  )
    return null;

  const applyNextMagenta = nextMagentaIsApplied(
    isBenefitActive,
    isNextMagentaActive,
    key
  );

  if (applyNextMagenta) {
    return NEXT_MAGENTA_ACTIVE_DESCRIPTION;
  }

  return NEXT_MAGENTA_NOT_ACTIVE_DESCRIPTION;
};
