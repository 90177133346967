export const getCustomerRequirementsOptionsInterval = (
  intervalEnd: number,
  moreThan: number[],
  initialInterval = [""]
) => {
  let values = initialInterval || [];
  values = values.concat(
    Array.from({ length: intervalEnd }, (_, i) => (i + 1).toString())
  );
  moreThan.forEach((value) => {
    values.push(`≥ ${value}`);
  });
  return values;
};

export const handleArrayChange = <T>(
  array: T[],
  value: T,
  isMultiple: boolean
): T[] => {
  if (isMultiple) {
    return array.includes(value)
      ? array.filter((item) => item !== value)
      : [...array, value];
  }

  return [value];
};
