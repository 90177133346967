import React from "react";
import { Heading } from "@dtpk-cc/components";
import BackDrop from "elements/new-design/ModalWrapper/BackDrop";
import { Endpoints } from "core/repositories/ProductsRepository/IProductsRepository";
import { useStores } from "stores/BusinessClient";
import { ICartConfigurationType } from "core/entities/PencilSelling/Configurations/IConfigurations";
import EmployeeClientIcon from "../../../../../assets/images/icons/pencil-selling-tool/business-client-start-screen/customer-type-employee-client.svg";
import BusinessClientIcon from "../../../../../assets/images/icons/pencil-selling-tool/business-client-start-screen/customer-type-business-client.svg";
import PrivateClientIcon from "../../../../../assets/images/icons/pencil-selling-tool/business-client-start-screen/customer-type-private-client.svg";
import * as styles from "./customer-type-selection-modal.module.scss";
import { CustomerType } from "../../../../core/entities/PencilSelling/Customer/ICustomer";
import { useFetchData } from "../../../../hooks/PencilSelling/BusinessClient/useFetchData";
import CustomerTypeSelectionModalTile from "./CustomerTypeSelectionModalTile";
import CustomerTypeSelectionModalTileDescriptions from "./CustomerTypeSelectionModalTileDescriptions";
import { IFrameworkContractSelectState } from "../../IPencilSelling";
import { useGetProductsWithFrameworkContract } from "../../../../hooks/PencilSelling/BusinessClient/useGetProductsWithFrameworkContract";
import { FRAMEWORK_CONTRACT_DEFAULT_ID_MAP } from "../const";

type CustomerTypeSelectionModalProps = {
  closeModalHandler: () => void;
  setFrameworkContractSelectState: React.Dispatch<
    React.SetStateAction<IFrameworkContractSelectState>
  >;
  addConfigurationHandler: (type: ICartConfigurationType) => void;
};

const tilesMap = [
  {
    title: "Privatkunde",
    type: CustomerType.PRIVATE,
    Icon: PrivateClientIcon,
    requestUrl: Endpoints.pencil_selling,
    descriptions: [
      <>
        Preise werden in <strong>brutto</strong> ausgegeben
      </>,
    ],
  },
  {
    title: "Top Mitarbeiter-Rahmenvertragskunde",
    type: CustomerType.EMPLOYEE,
    Icon: BusinessClientIcon,
    requestUrl: Endpoints.pencil_selling_employee,
    descriptions: [
      <>Keine Vermarktung für Telekom-Partner</>,
      <>
        Preise werden in <strong>brutto</strong> ausgegeben
      </>,
    ],
  },
  {
    title: "Geschäftskunde",
    type: CustomerType.BUSINESS,
    Icon: EmployeeClientIcon,
    requestUrl: Endpoints.pencil_selling_business,
    descriptions: [
      <>
        Preise werden in <strong>netto</strong> ausgegeben
      </>,
    ],
  },
];

const CustomerTypeSelectionModal = ({
  closeModalHandler,
  setFrameworkContractSelectState,
  addConfigurationHandler,
}: CustomerTypeSelectionModalProps) => {
  const {
    offerStore,
    productsStore,
    factModalStore,
    customerRequirementsStore,
  } = useStores();
  const { fetchData } = useFetchData();
  const { setIsLoading } = offerStore.getBaseOffer();
  const { getProductsWithFrameworkContract } =
    useGetProductsWithFrameworkContract();

  const fetchDataHandler = (getProductsUrl: Endpoints) => {
    setIsLoading(true);
    closeModalHandler();
    fetchData(getProductsUrl)
      .then(({ data, summary }) => {
        if (data && "business_discount_reports" in data) {
          const mapperFrameworkContracts = data.business_discount_reports.map(
            (frameworkContractItem) => ({
              ...frameworkContractItem,
              benefitsAreAvailable:
                frameworkContractItem?.benefits_are_available,
              loyaltyBonusIsAvailable:
                frameworkContractItem?.loyalty_bonus_is_available,
            })
          );
          offerStore.setFrameworkContractsList(mapperFrameworkContracts);
          // TODO Refactor ( could be removed ) after upfront promotions merge
          productsStore.setup(summary);
        }
        factModalStore.setupFactModalsData(data.facts);
        customerRequirementsStore.setup(addConfigurationHandler);
      })
      .then(() => {
        const targetFrameworkContract = offerStore
          .getFrameworkContractsList()
          .find(
            (frameworkContract) =>
              frameworkContract.id ===
              FRAMEWORK_CONTRACT_DEFAULT_ID_MAP[
                offerStore.getBaseOffer().customer.get().customerType
              ]
          );
        return getProductsWithFrameworkContract(
          targetFrameworkContract,
          setFrameworkContractSelectState
        );
      })
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.error(error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <>
      <BackDrop customClass={styles.backDrop} withPortal={false}>
        <div
          className={styles.customerTypeSelectionModal}
          role="dialog"
          aria-modal="true"
        >
          <Heading
            tag="h3"
            variants={[Heading.Variant.secondary, Heading.Variant.display]}
            customClass={styles.modalTitle}
          >
            Für wen möchtest du ein Angebot erstellen?
          </Heading>
          <div className={styles.content}>
            {tilesMap.map(({ title, type, Icon, requestUrl, descriptions }) => (
              <CustomerTypeSelectionModalTile
                key={title}
                Icon={Icon}
                title={title}
                type={type}
                requestUrl={requestUrl}
                fetchData={fetchDataHandler}
              >
                <CustomerTypeSelectionModalTileDescriptions
                  descriptions={descriptions}
                />
              </CustomerTypeSelectionModalTile>
            ))}
          </div>
        </div>
      </BackDrop>
    </>
  );
};

export default CustomerTypeSelectionModal;
