import React from "react";
import { Heading, CopyText, Button } from "@dtpk-cc/components";
import ModalWrapper from "../../../elements/new-design/ModalWrapper";
import * as styles from "./user-group-warning-modal.module.scss";
import { PAGE_USER_PROFILE } from "../../../routes/extern-url";

type UserGroupWarningModalProps = {
  isOpen: boolean;
  onClose: () => void;
};

const UserGroupWarningModal = ({
  isOpen,
  onClose,
}: UserGroupWarningModalProps) => (
  <ModalWrapper
    containerCustomClass={styles.modalWrapper}
    backDropCustomClass={styles.mainWrapper}
    isOpen={isOpen}
    onClose={onClose}
  >
    <Heading
      tag="h3"
      customClass="m-b-24"
      variants={[Heading.Variant.tertiary, Heading.Variant.display]}
    >
      Passe dein Profil an.
    </Heading>
    <CopyText>
      Bitte wähle in <b>deinem Profil</b> die passende <b>Benutzergruppe</b>. Du
      kannst sie später noch anpassen, falls sie sich ändern sollte.
    </CopyText>
    <div className={styles.footer}>
      <Button
        tabIndex={0}
        customClass="m-r-24"
        variants={Button.Variant.outline}
        onClick={onClose}
      >
        Nächstes mal
      </Button>
      <Button
        tabIndex={0}
        tag="a"
        rel="noopener noreferrer"
        href={`${window.location.origin}/${PAGE_USER_PROFILE}`}
      >
        Zum Profil
      </Button>
    </div>
  </ModalWrapper>
);

export default UserGroupWarningModal;
