// extracted by mini-css-extract-plugin
var _1 = "customer-requirements-module-bandwidthAndFiberContainer-b9BYo";
var _2 = "customer-requirements-module-bandwidthSectionContainer-dYUnB";
var _3 = "customer-requirements-module-fiberCableSectionContainer-lyDIb";
var _4 = "customer-requirements-module-firstColumn-O1PMs";
var _5 = "customer-requirements-module-firstRow-Cx1uH";
var _6 = "customer-requirements-module-gridPaddingLeft-tvyjS";
var _7 = "customer-requirements-module-houseHoldSwapFlexDirection-af1mi";
var _8 = "customer-requirements-module-houseHoldSwapFlexDirectionReverse-j8xrg";
var _9 = "customer-requirements-module-householdSectionContainer-Fkxyx";
var _a = "customer-requirements-module-secondColumn-o1hNm";
var _b = "customer-requirements-module-secondRow-vIDCh";
var _c = "customer-requirements-module-shapeSectionContainer-ANNVl";
export { _1 as "bandwidthAndFiberContainer", _2 as "bandwidthSectionContainer", _3 as "fiberCableSectionContainer", _4 as "firstColumn", _5 as "firstRow", _6 as "gridPaddingLeft", _7 as "houseHoldSwapFlexDirection", _8 as "houseHoldSwapFlexDirectionReverse", _9 as "householdSectionContainer", _a as "secondColumn", _b as "secondRow", _c as "shapeSectionContainer" }
