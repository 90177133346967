import PageWrapper from "elements/new-design/PageWrapper";
import PageInner from "elements/new-design/PageWrapper/PageInner";
import React, { PropsWithChildren, ReactNode } from "react";
import * as styles from "./customer-requirements-wrapper.module.scss";

type CustomerRequirementsWrapperProps = {
  header: ReactNode;
};

const CustomerRequirementsWrapper = ({
  header,
  children,
}: PropsWithChildren<CustomerRequirementsWrapperProps>) => (
  <PageWrapper>
    {header}
    <PageInner customClass={styles.pageInnerNeedAnalysis}>{children}</PageInner>
  </PageWrapper>
);

export default CustomerRequirementsWrapper;
