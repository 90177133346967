// extracted by mini-css-extract-plugin
var _1 = "tariff-selection-next-magenta-module-active-puCMP";
var _2 = "tariff-selection-next-magenta-module-logoIcon-cvd_S";
var _3 = "tariff-selection-next-magenta-module-nextMagenta-wE7uc";
var _4 = "tariff-selection-next-magenta-module-nextMagentaSubLabel-hJ0e6";
var _5 = "tariff-selection-next-magenta-module-nextMagentaTextContainer-LiCkC";
var _6 = "tariff-selection-next-magenta-module-rollIn-WdYGs";
var _7 = "tariff-selection-next-magenta-module-rollOut-jUXB0";
var _8 = "tariff-selection-next-magenta-module-toggleBtn-XGutI";
export { _1 as "active", _2 as "logoIcon", _3 as "nextMagenta", _4 as "nextMagentaSubLabel", _5 as "nextMagentaTextContainer", _6 as "rollIn", _7 as "rollOut", _8 as "toggleBtn" }
