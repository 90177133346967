import { ExpansionArea } from "core/entities/Applications";
import { PromotionPriceType } from "core/entities/Product/IDiscount";
import { PortfolioKeys } from "core/entities/Product/IProduct";
import { IStreamingServicesKeys } from "core/entities/Product/IStreamingServiceKeys";
import {
  Bandwidths,
  IBusinessCasesTypeValues,
} from "core/entities/Product/Tariff/ITariff";

import { CustomPromotionTypes } from "core/entities/PencilSelling/ICustomPromotion";
import { CardWorldKeys, TariffWorldKeys } from "./DefinitionKeys";
import { IPromotionSectionsKeys } from "../../entities/PencilSelling/IPromotionSectionsKeys";
import { IDescription } from "../../entities/Product/IDescription";
import { IFactModalsData } from "../../entities/PencilSelling/FactModal/IFactModal";
import { IUserGroups } from "../../entities/PencilSelling/Customer/ICustomer";

export interface OfferLinkResponse {
  url: string;
}

export interface QRCodeResponse extends OfferLinkResponse {
  qr_code: string;
}

export interface IResponseFrameWorkContractItem {
  id: number;
  name: string;
  conditions: string | null;
  benefits_are_available?: boolean;
  loyalty_bonus_is_available?: boolean;
}

export interface ProductsResponse {
  products: Record<string, LandlineResponse | MobileResponse>;
  business_discount_reports?: IResponseFrameWorkContractItem[];
  facts: IFactModalsData;
  user_group: IUserGroups;
}
export interface LandlineResponse {
  addons: IAddonResponse[];
  devices: IDeviceResponse[];
  tariffs: ITariffResponse[];
  custom_promotions: ICustomPromotionResponse[];
}

export interface MobileResponse {
  addons: IAddonResponse[];
  cards: ICardResponse[];
  tariffs: ITariffResponse[];
  custom_promotions: ICustomPromotionResponse[];
}

/**
 * Represents the base properties for a product.
 * @interface
 */
export interface IProductBase {
  /**
   * The unique key for the product.
   * @type {string}
   */
  key: string;
  /**
   * The name of the product.
   * @type {string}
   */
  name: string;
  /**
   * The name_alternative of the product.
   * @type {string}
   */
  name_alternative: string;
  /**
   * The length of the contract for the product in months, or null if no contract.
   * @type {number | null}
   */
  contract_period: number | null;
  /**
   * A description of the product, or null if no description.
   * @type {string | null}
   */
  description: string | null;
  /**
   * The pricing information for the product.
   * @type {IPricingResponse}
   */
  pricing: IPricingResponse;
  /**
   * An array of promotion responses associated with the product, or an empty array if no promotions.
   * @type {IPromotionResponse[] | []}
   */
  promotions: IPromotionResponse[] | [];
  /**
   * The product description, if available.
   * @type {IProductDescriptionResponse | undefined}
   */
  product_description?: IProductDescriptionResponse;
  /**
   * The valency of the product this determines the position of the product in display, or null if not applicable.
   * @type {number | null}
   */
  valency: number | null;
}

/**
 * Represents a tariff response object.
 * @interface
 * @extends {IProductBase}
 */
export interface ITariffResponse extends IProductBase {
  /**
   * The tariff world response associated with the tariff.
   * @type {ITariffWorldResponse}
   */
  tariff_world: ITariffWorldResponse;
  /**
   * The bandwidth sizing of the tariff, or null if not applicable.
   * @type {keyof Bandwidths | null}
   */
  sizing: keyof Bandwidths | null;
  /**
   * The bandwidth sizing of the tariff, or null if not applicable.
   * @type {keyof Bandwidths | null}
   */
  sizing_alternative: string | null;
  /**
   * The size suffix of the tariff, or null if not applicable.
   * @type {string | null}
   */
  size_suffix: string | null;
  /**
   * An array of benefit responses associated with the tariff, or an empty array if no benefits.
   * @type {IBenefitResponse[] | []}
   */
  benefits: IBenefitResponse[] | [];
  /**
   * Whether the tariff is a vario tariff or not. For Private Clients it's always 'null', because Private Clients doesn't have this option
   * @type {boolean}
   */
  vario: boolean | null;
  /**
   * Whether the tariff is a young tariff or not. For Business Clients it's always 'null', because Business Clients doesn't have this option
   * @type {boolean}
   */
  young: boolean | null;
  /**
   * The level response associated with the tariff. Each tariff has a level which could be of "no smartphone", "with smartphone" etc.
   * @type {ILevelResponse}
   */
  level: ILevelResponse;
  /**
   * The tariff product description, if available.
   * @type {ITariffProductDescriptionResponse | undefined}
   */
  product_description?: ITariffProductDescriptionResponse;
  /**
   * The speed of the tariff for landline products, or null if not applicable. Only applicable for landline tariff.
   * @type {Speed | null | undefined}
   */
  speed?: Speed | null;
  /**
   * The fiber speed of the tariff for landline products, or null if not applicable. Undefined for mobile tariff.
   * @type {Speed | null | undefined}
   */
  speed_fiber?: Speed | null;
  /**
   * An array containing business keys for tariffs. This limits the visibility of the product to the specified business case keys.
   * @type {IBusinessCasesTypeValues[] | undefined}
   */
  available_for_business_cases?: IBusinessCasesTypeValues[];
  /**
   * Whether the tariff is a fiber tariff or not. Undefined for mobile tariff.
   * @type {boolean | undefined}
   */
  fiber?: boolean;
  /**
   * The areas where the tariff is available. Undefied for mobile tariff.
   * @type {ExpansionArea[]}
   */
  expansion_area: ExpansionArea[];
  /**
   * The data volume of the mobile tariff.  or null if not applicable. Undefined for landline tariff.
   * @type {string | null | undefined}
   */
  data_volume?: string | null;
  /**
   * The data volume for one SIM card of the tariff for mobile products, or null if not applicable. Undefined for landline tariff
   * @type {string | null | undefined}
   */
  data_volume_one?: string | null;
  /**
   * The data volume for next magenta 4.0 for mobile products, or null if not applicable. Undefined for landline tariff
   * @type {string | null | undefined}
   */
  data_volume_next_magenta?: string | null;

  young_streaming?: {
    /**
     * The data volume extra representing the mobile tariff data volume when certain related mobile extras were selected.
     */
    data_volume: string;

    /**
     * List of related to mobile tariff extras selecting of which, will apply data_volume_extra property value to currently selected mobile tariff
     */
    addons: string[];
  };

  /**
   * Whether the tariff is a legacy tariff, applicable only for magenta_calculator. Undefined for pencil_selling landline/mobile tariffs.
   * @type {boolean | null | undefined}
   */
  legacy?: boolean | null;
  /**
   * The generation of the tariff, applicable only for magenta_calculator. Undefined for pencil_selling landline/mobile tariffs.
   * @type {string | null | undefined}
   */
  generation?: string | null;
  /**
   * Product extensions
   * @type {IExtensionResponse[]}
   */
  extensions: IExtensionResponse[];

  /**
   * Tariff streaming services
   * @type {string[]}
   */
  streaming_services?: string[];
}

export interface IExtensionResponse {
  key: string;
  name: string;
  description: string;
  pricing: IPricingResponse;
  product_description?: {
    summary?: string;
    text?: string;
  };
}

/**
 * An interface representing a mobile card response. This is very similiar to the ITariffResponse and could be further abstracted.
 * @interface
 * @extends IProductBase
 */
export interface ICardResponse extends IProductBase {
  /**
   * The bandwidth sizing of the mobile card, or null if not applicable.
   */
  sizing: keyof typeof Bandwidths | null;

  /**
   * The size suffix of the mobile card, or null if not applicable.
   */
  size_suffix: string | null;

  /**
   * The data volume of the mobile card, or null if not applicable.
   */
  data_volume: string | null;

  /**
   * The data volume representing the mobile card when a benefit is activated, or null if not applicable.
   */
  data_volume_one: string | null;

  /**
   * The level of the mobile card.
   */
  level: ILevelResponse;

  /**
   * The product description of the mobile card, or null if not available.
   */
  product_description: IProductDescriptionResponse | null;

  /**
   * The tariff world response associated with the tariff.
   */
  tariff_world: ITariffWorldResponse;

  /**
   * Indicates whether the mobile card is a legacy card, relevant only for magenta_calculator.
   * @default null
   */
  legacy: boolean | null;

  /**
   * The generation of the mobile card, relevant only for magenta_calculator.
   * @default null
   */
  generation: string | null;

  /**
   * The tariff world keys that the card product is available for. Cards can only be selected if the main mobile TariffWorldKey is selected and included in this array.
   */
  available_for: TariffWorldKeys[] | [];

  /**
   * Indicates whether the mobile card is an inheriting card. If true, the mobile card will inherit the data volume of the selected main mobile tariff and disregard it's own data vollume - all inheriting_card should never have it's own data volume.
   */
  inheriting_card?: boolean;

  /**
   * Indicates whether the mobile card has multisim capability. In the card modal selection a multisim @IncrementalButtonGroup component will show up based of this attribute.
   */
  has_multisim?: boolean;
}

/**
 * Represents an addon/device product.
 * @interface OptionProduct
 * @extends IProductBase
 */
interface OptionProduct extends IProductBase {
  /**
   * The category of the addon/device product.
   * @type {ICategoryResponse|undefined}
   */
  category?: ICategoryResponse;

  /**
   * The subcategory of the addon/device product.
   * @type {ISubcategoryResponse|undefined}
   */
  subcategory?: ISubcategoryResponse;

  /**
   * The variant of the addon/device product.
   * @type {IProductVariantResponse|undefined}
   */
  product_variant?: IProductVariantResponse;

  /**
   * The description of the addon/device product.
   * @type {IDeviceProductDescriptionResponse|null}
   */
  product_description: IDeviceProductDescriptionResponse | null;
  /**
   * The tariff world keys / product keys that the addon/device product is available for. This OptionProduct is only selectable if a landline/mobile TariffWorldKey is selected and included in this array. This is mapped to availableFor in productsMapper.
   * @type {TariffWorldKeys[] | string[]}
   */
  available_for: TariffWorldKeys[] | string[];
}

/**
 * An interface representing a device response. It uses OptionProduct
 * @interface
 * @extends OptionProduct
 */
export interface IDeviceResponse extends OptionProduct {
  /** The EGSP of the device. Can be keys of { none: 'kein EGSP', default: 'EGSP', plus: 'EGSP+' }  */
  egsp?: string;
  /** The name of the HNP (Heimnetz) of the device. */
  hnp_name?: string;
}

/**
 * Represents an add-on product that can be added to a tariff or device.
 * @interface
 * @typedef {OptionProduct} IAddonResponse
 * @property {string} key - The unique identifier of the add-on product.
 * @property {string} name - The name of the add-on product.
 * @property {number | null} contract_period - The contract period of the add-on product in months. Null if the add-on product doesn't require a contract.
 * @property {string | null} description - The description of the add-on product. Null if not available.
 * @property {IPricingResponse} pricing - The pricing details of the add-on product.
 * @property {IPromotionResponse[] | []} promotions - The promotions available for the add-on product. Empty array if no promotions available.
 * @property {ICategoryResponse | undefined} category - The category of the add-on product, if available.
 * @property {ISubcategoryResponse | undefined} subcategory - The subcategory of the add-on product, if available.
 * @property {IProductVariantResponse | undefined} product_variant - The product variant of the add-on product, if available.
 * @property {IDeviceProductDescriptionResponse | null} product_description - The description of the add-on product specific to devices, if available. Null if not available.
 * @property {TariffWorldKeys[] | []} available_for - The tariff world(s) the add-on product is available for. Empty array if available for all tariff worlds.
 */

export type IAddonResponse = OptionProduct;

/**
 * Represents a tariff world or card world associated with a tariff or card product.
 *
 * @interface ITariffWorldResponse
 */
interface ITariffWorldResponse {
  /**
   * The href link associated with the tariff world, when a default tariff is to be selected. Remark: a default tariff or young tariff can be of the same tariff world. When no young tariff is selected. No usecases for card worlds, yet.
   *
   * @type {(string | null)}
   */
  link_default: string | null;

  /**
   * The href link associated with the tariff world, when a young tariff is to be selected.
   *
   * @type {(string | null)}
   */
  link_young: string | null;

  /**
   * The display title of the link associated with the tariff world or card world.
   *
   * @type {(string | null)}
   */
  link_title: string | null;

  /**
   * The key associated with the tariff world or card world.
   *
   * @type {(TariffWorldKeys | CardWorldKeys)}
   */
  key: TariffWorldKeys | CardWorldKeys;

  /**
   * The name of the tariff world or card world.
   *
   * @type {string}
   */
  name: string;

  /**
   * The limit associated with the card world. Within a specific card world, the limit is the maximum number of cards of this card world that can be selected. Not applicable for tariff world
   *
   * @type {(number | null)}
   */
  limit: number | null;

  /**
   * This determines the display order, ascending.
   *
   * @type {(number | null)}
   */
  valency: number | null;
}

/**
 * An object representing a level of a tariff or card. Almost every tariff or card should have a level association.
 * @interface
 */
export interface ILevelResponse {
  /**
   * The name of the level. Ohne Smartphone, Mit Smartphone, etc.
   * @type {string}
   */
  name: string;

  /**
   * The key identifying the level.
   * @type {string}
   */
  key: string;

  /**
   * This determines the display order, ascending.
   * @type {number | null}
   */
  valency: number | null;
}

/**
 * An interface that defines a hierarchical option, option can be device or addon.
 * @interface
 */
interface OptionHierachyDefinition {
  /**
   * The key of the hierachy.
   * @type {string}
   */
  key: string;
  /**
   * The name of the hierachy.
   * @type {string}
   */
  name: string;
  /**
   * This determines the display order, ascending.
   * @type {number|null}
   */
  valency: number | null;
}

/**
 * The `ICategoryResponse`, `ISubcategoryResponse`, `IProductVariantResponse`,  interface extends the `OptionHierachyDefinition` interface and adds additional properties. The `OptionHierachyDefinition` interface defines the common properties for all hierarchical objects, including the `key`, `name`, and `valency` properties.
 */

/**
 * An interface representing a category in the product hierarchy
 * @interface
 */
export interface ICategoryResponse extends OptionHierachyDefinition {
  /**
   * The href link for a category.
   *
   * @type {string|null}
   */
  link: string | null;

  /**
   * The link title for a category.
   *
   * @type {string|null}
   */
  link_title: string | null;
}

/**
 * Represents a subcategory of a product category. It is just an alias for ICategorydResponse which itself extends OptionHierachyDefinition.
 * Inherits properties from OptionHierachyDefinition interface.
 */
export type ISubcategoryResponse = ICategoryResponse;

/**
 * Represents a product variant.
 * Inherits properties from OptionHierachyDefinition interface.
 */
export type IProductVariantResponse = OptionHierachyDefinition;

/**
 * Represents a benefit response object.
 *
 * @interface
 * @property {string} key - The unique key of the benefit response.
 * @property {string} name - The name of the benefit response.
 * @property {?BenefitOption} data_volume - The data volume benefit option, or `null` if not applicable.
 * @property {?BenefitOption} discount - The discount benefit option, or `null` if not applicable.
 * @property {?BenefitOption} flatrate - The flat rate benefit option, or `null` if not applicable.
 * @property {?BenefitOption} landline_notes - The flat rate benefit option, or `null` if not applicable.
 * @property {?BenefitOption} mobile_notes - The flat rate benefit option, or `null` if not applicable.
 */
export interface IBenefitResponse {
  key: string;
  name: string;
  data_volume: BenefitOption | null;
  discount: BenefitOption | null;
  flatrate: BenefitOption | null;
  happy_hour: BenefitOption | null;
  landline_notes: BenefitOption | null;
  mobile_notes: BenefitOption | null;
}

export interface ICustomPromotionPricing {
  kind: CustomPromotionTypes;
  value: number | null;
  quantity: number | null;
  from: number | null;
  to: number | null;
}

export interface ICustomPromotionRequest {
  custom_promotion: {
    key: string | null;
    portfolio: IPromotionSectionsKeys;
    name: string;
    description: string;
    pricing: ICustomPromotionPricing;
    type: CustomPromotionTypes;
  };
}

export interface CustomPromotionFetchResponse {
  custom_promotions: {
    [PortfolioKeys.LANDLINE]: ICustomPromotionResponse[];
    [PortfolioKeys.MOBILE]: ICustomPromotionResponse[];
  };
}
export enum CustomAddonsRequestKeys {
  DEVICE = "custom_device",
}

export interface ICustomAddonItemResponse {
  name: string;
  type: string;
  key: string;
  pricing: {
    once: number | null;
    monthly: number | null;
  };
  contract_period: number;
}

export type ICustomAddonItemRequest = {
  [key in CustomAddonsRequestKeys]: ICustomAddonItemResponse;
};

export interface ICustomPromotionResponse {
  key: string;
  name: string;
  portfolio: IPromotionSectionsKeys;
  type: CustomPromotionTypes;
  description?: string;
  concerned?: string | null;
  pricing: ICustomPromotionPricing;
}

/**
 * Represents a benefit option.
 *
 * @typedef {object} BenefitOption
 * @property {PortfolioKeys} portfolio - The portfolio keys associated with the benefit option. Determines if it is for landine or mobile
 * @property {string} concerned - IIRC this is not used anymore
 * @property {?string} description - A description of the benefit option, or `null` if none is available.
 * @property {string} key - The unique key of the benefit option.
 * @property {string} name - The name of the benefit option.
 * @property {?number} value - The numerical value associated with the benefit option or `null` if none is available. This is the value that will be used to display a monthly or once discount.
 */
type BenefitOption = {
  portfolio: PortfolioKeys;
  key: string;
  name: string;
  value?: number;
};

/**
 * Represents a promotion response object.
 *
 * @interface
 * @property {string} key - The unique key of the promotion.
 * @property {string} name - The name of the promotion.
 * @property {?string} additional_description - Additional description for the promotion, or `null` if none is available.
 * @property {string[]} conditions - With conditions, we limit the promotions to only be available and displayed when a matching businesscase is included and it's key is included in one of the conditions.
 * @property {string} description - The description of the promotion.
 * @property {IPromotionPricingResponse} pricing - The pricing details of the promotion.
 * @property {?string} concerned - A product can have monthly and once purchase version, certain promotion only apply to one of them. This property is used to determine which version the promotion applies to. `monthly` or `once`
 */
export interface IPromotionResponse {
  key: string;
  name: string;
  additional_description: string | null;
  summary: IDescription;
  conditions: string[];
  description: string;
  pricings: IPromotionPricingResponse[];
  concerned?: string | null;
  apply_before_framework_contract: boolean | null;
}

/**
 * Represents the pricing details of a promotion.
 *
 * @interface
 * @property {PromotionPriceType} kind - The type of promotion. See  `PromotionPriceType` for possible values.
 * @property {number} price - The price of the promotion.
 * @property {?number} quantity - How many months this promotion will be valid/used, or `null` if none is available.
 * @property {?string} notes - Additional notes for the promotion, or `null` if none is available.
 * @property {?number} from - The starting month when the promotion should occur, or `null` if not applicable.
 * @property {?number} to - The month when the promotion will end, or `null` if not applicable.
 */

export interface IPromotionPricingResponse {
  kind: PromotionPriceType;
  value: number;
  quantity: number | null;
  notes: string | null;
  from: number | null;
  to: number | null;
}

export interface IYoungDiscountResponse {
  from: number;
  to: number;
  price: number;
  available_for_business_cases: IBusinessCasesTypeValues[];
}

/**
 * Represents the pricing details of a product.
 *
 * @interface
 * @property {?number} monthly - The monthly price of the product, or `null` if none is available.
 * @property {?number | null} monthly_price_before_framework_discount - The original monthly price of the product, or `null` if none is available before certain framework contract was applied (relevant for BK application).
 * @property {?number | null} provision_price_before_framework_discount - The original provision price of the product, or `null` if none is available before certain framework contract was applied (relevant for BK application).
 * @property {?number} once - The one-time price of the product, or `null` if none is available.
 * @property {?number} provision - The provision for the product, or `null` if none is available.
 * @property {?number} original_price - The original price of the product, or `null` if none is available. This is the price after the contract_period for a product has ended and but still continues to be bought. This only works on products with a monthly price.
 * @property {?string} suffix - The price suffix of the price, or `null` if none is available. Any string is allowed here, but it is usually used to indicate the payment type/period.
 */
export interface IPricingResponse {
  monthly?: number;
  once?: number;
  provision?: number;
  original_price?: number;
  suffix?: string;
  quantity_discount?: {
    price: number | null;
    required_quantity: number;
  };
  monthly_price_before_framework_discount?: number | null;
  provision_price_before_framework_discount?: number | null;
  framework_discount_percentage?: number | null;
  young_discount?: IYoungDiscountResponse;
}

/**
 * Represents a product description response object.
 *
 * @interface
 * @property {?string} summary - The summary of the product description, or `null` if none is available. It is in HTML format.
 * @property {?string} text - The full text of the product description, or `null` if none is available. It is in HTML format.
 * @property {?string} usps - Unique selling points that gets displayed in tariff tile on tariff selection screen.
 */
export interface IProductDescriptionResponse {
  summary: string | null;
  text: string | null;
  usps: string[];
}

/**
 * Represents a tariff product description response object.
 *
 * @interface
 * @extends {IProductDescriptionResponse}
 * @property {?string} text_fiber - The full text of the product description when fiber is chosen, or `null` if none is available.
 * @property {?string} summary_fiber - The summary of the product description when fiber option is chosen, or `null` if none is available.
 */
export interface ITariffProductDescriptionResponse
  extends IProductDescriptionResponse {
  text_fiber?: string | null;
  summary_fiber?: string | null;
}

/**
 * Represents a device product description response object.
 *
 * @interface
 * @extends {IProductDescriptionResponse}
 * @property {?string} text_egsp - The full text of the product description if user opted for monthly purchase that has EGSP, or `null` if none is available.
 * @property {?string} summary_egsp - The summary of the product description if user opted for monthly purchase that has EGSP, or `null` if none is available.
 */
export interface IDeviceProductDescriptionResponse
  extends IProductDescriptionResponse {
  text_egsp?: string | null;
  summary_egsp?: string | null;
}

/**
 * Represents a speed object containing download and upload speeds.
 *
 * @typedef {Object} Speed
 * @property {?number} download - The download speed in megabits per second, or `null` if none is available.
 * @property {?number} upload - The upload speed in megabits per second, or `null` if none is available.
 */
type Speed = {
  download: number | null;
  upload: number | null;
};

interface IStreamingServiceResponse {
  name: string;
  key: IStreamingServicesKeys;
  product_key?: string;
  active: boolean;
  pricing: {
    monthly: number;
  };
}

interface IStreamingVariantResponse {
  name: string;
  key: string;
  product_key?: string;
  kind: string;
  active: boolean;
  monthly: number;
  tariff_world: ITariffWorldResponse;
  level: ILevelResponse;
  area_of_use: string;
  streaming_services: Record<string, number>;
}

interface IServicesResponse {
  streaming_services: Record<string, IStreamingServiceResponse>;
  streaming_variants: Record<string, IStreamingVariantResponse>;
}

export interface StreamingAnalysisResponse {
  services: IServicesResponse;
}
