import { makeAutoObservable } from "mobx";
import { TariffWorldKeys } from "core/repositories/ProductsRepository/DefinitionKeys";
import { filterNonNumericCharactersAndParseToInt } from "helpers/NumberHelpers";
import { handleArrayChange } from "helpers/CustomerRequirements";
import { ConfigurationsEntity } from "../../Configurations/Configurations";
import {
  HouseholdSize,
  ICustomerRequirementsPC,
  StreamingServiceVariants,
  UseCaseVariants,
} from "./ICustomerRequirementsPC";
import {
  CustomerRequirementsBase,
  initialRequirementsBase,
} from "../../CustomerRequirementsBase/CustomerRequirementsBase";
import {
  BandwidthValues,
  DevicesAmounts,
  InternationalTariffVariants,
} from "../../CustomerRequirementsBase/ICustomerRequirementsBase";
import { ICartConfigurationType } from "../../Configurations/IConfigurations";
import { ICustomerRequirementsExport } from "../../ICustomerRequirements";

const initialRequirements: ICustomerRequirementsPC = {
  useCases: [],
  newSmartphoneDesired: null,
  household: {
    adults: null,
    children: null,
  },
  streaming: {
    activeStreamingServices: [],
    newMagentaTv: null,
    resolutionActive: null,
    televisionNumber: null,
  },
  isYoung: null,
};

const StreamingServicesMegaStream = [
  StreamingServiceVariants.APPLE_TV,
  StreamingServiceVariants.NETFLIX,
  StreamingServiceVariants.DISNEY_PLUS,
];

const StreamingServicesMegaSport = [
  StreamingServiceVariants.WOW,
  StreamingServiceVariants.DAZN,
  StreamingServiceVariants.MAGENTA_SPORT,
];

const StreamingServicesMagentaTvSmart = [
  StreamingServiceVariants.MAGENTA_HD_TV,
  StreamingServiceVariants.RTL_PLUS,
];

const getLandlineStreamingOptions = (fiber: boolean) => {
  const tariffWorld = fiber
    ? TariffWorldKeys.fiber_home_tv
    : TariffWorldKeys.magenta_home_tv;

  return [
    {
      streamingValues: StreamingServicesMegaStream,
      level: "magentatv_megastream",
      tariffWorld,
    },
    {
      streamingValues: StreamingServicesMagentaTvSmart,
      level: "magenta_tv_smart",
      tariffWorld,
    },
  ];
};

const streamingServiceRecommendations = [
  {
    streamingValues: StreamingServicesMegaStream,
    description: "MegaStream",
  },
  {
    streamingValues: StreamingServicesMagentaTvSmart,
    description: "MagentaTV Smart",
    ignoreWhenRecommendationKeyIsPresent: "MegaStream",
  },
  {
    streamingValues: StreamingServicesMegaSport,
    description: "MegaSport",
  },
];

export class CustomerRequirementsPC {
  private configurationsStore: ConfigurationsEntity;

  private customerRequirements: ICustomerRequirementsPC = initialRequirements;

  customerRequirementsBase: CustomerRequirementsBase;

  private initialCustomerRequirements: ICustomerRequirementsPC;

  constructor(configurationsStore: ConfigurationsEntity) {
    this.configurationsStore = configurationsStore;
    this.customerRequirementsBase = new CustomerRequirementsBase(
      configurationsStore
    );
    makeAutoObservable(this);
  }

  get requirements(): ICustomerRequirementsPC {
    return {
      ...this.customerRequirements,
      isYoung:
        this.configurationsStore.getActiveConfigurationSettings()?.isYoung,
    };
  }

  get recommendations() {
    return {
      newSmartphoneDesired: this.requirements.newSmartphoneDesired
        ? "Handyversicherung"
        : "",
      streaming: this.getStreamingRecommendation() || "",
      household: this.getHouseholdRecommendation() || "",
      internationalTariffs: this.customerRequirementsBase.requirements
        .internationalTariffs.length
        ? "MagentaMobil L oder XL"
        : "",
    };
  }

  areRequirementsModified(): boolean {
    return (
      JSON.stringify(this.initialCustomerRequirements) !==
        JSON.stringify(this.requirements) ||
      this.customerRequirementsBase.areRequirementsModified()
    );
  }

  export(standalone = true): ICustomerRequirementsExport {
    if (!this.areRequirementsModified() && standalone) {
      return {
        ...initialRequirements,
        ...initialRequirementsBase,
        recommendations: null,
      };
    }

    if (this.areRequirementsModified() || standalone) {
      return {
        ...this.requirements,
        ...this.customerRequirementsBase.requirements,
        recommendations: {
          ...this.recommendations,
          ...this.customerRequirementsBase.recommendations,
        },
      };
    }

    return null;
  }

  setup(addConfigurationHandler: (type: ICartConfigurationType) => void): void {
    this.initialCustomerRequirements = JSON.parse(
      JSON.stringify(this.requirements)
    ) as ICustomerRequirementsPC;
    this.customerRequirementsBase.setup();
    addConfigurationHandler(ICartConfigurationType.LANDLINE);
  }

  setBandwidth(key: keyof BandwidthValues, bandwidth: string): void {
    this.customerRequirementsBase.setBandwidth(key, bandwidth);
  }

  toggleBenefitActive(): void {
    this.customerRequirementsBase.toggleBenefitActive();
  }

  setHousehold(key: keyof HouseholdSize, size: string) {
    this.customerRequirements.household[key] =
      this.customerRequirements.household[key] === size ? null : size;
  }

  toggleIsYoung(): void {
    const isYoung = !this.requirements.isYoung;

    this.customerRequirementsBase.setCustomerRequirementsForConfigurations(
      [ICartConfigurationType.MOBILE, ICartConfigurationType.LANDLINE],
      (config) => ({
        settings: {
          ...config.data.settings,
          isYoung,
        },
      })
    );
  }

  toggleFiber(value: boolean): void {
    this.customerRequirementsBase.toggleFiber(value);
    this.handleLineConfigurationChange();
  }

  setDevicesValue(key: keyof DevicesAmounts, value: string): void {
    this.customerRequirementsBase.setDevicesValue(key, value || null);
  }

  setInternationalTariff(value: InternationalTariffVariants): void {
    this.customerRequirementsBase.setInternationalTariff(value);
  }

  setSmartphoneConsultation(value: boolean): void {
    this.customerRequirements.newSmartphoneDesired =
      this.customerRequirements.newSmartphoneDesired === value ? null : value;
  }

  setUseCases(value: UseCaseVariants): void {
    this.customerRequirements.useCases = handleArrayChange(
      this.customerRequirements.useCases,
      value,
      true
    );
  }

  setNewMagentaTvWithToggle(value: boolean): void {
    if (this.customerRequirements.streaming.newMagentaTv === value) {
      this.customerRequirements.streaming.newMagentaTv = null;
    } else {
      this.customerRequirements.streaming.newMagentaTv = value;
    }
  }

  handleStreamingServiceChange(value: StreamingServiceVariants): void {
    this.customerRequirements.streaming.activeStreamingServices =
      handleArrayChange(
        this.customerRequirements.streaming.activeStreamingServices,
        value,
        true
      );

    this.handleLineConfigurationChange();
  }

  handleLineConfigurationChange(): void {
    const adjustedLandlineConfig = getLandlineStreamingOptions(
      this.customerRequirementsBase.requirements.fiber
    ).find((option) =>
      option.streamingValues.some((value) =>
        this.customerRequirements.streaming.activeStreamingServices.includes(
          value
        )
      )
    );

    const level = adjustedLandlineConfig?.level || "";
    const tariffWorld = adjustedLandlineConfig?.tariffWorld || "";

    this.customerRequirementsBase.setCustomerRequirementsForConfigurations(
      [ICartConfigurationType.LANDLINE],
      (config) => ({
        settings: {
          ...config.data.settings,
          tariffWorld,
          level,
        },
      })
    );
  }

  toggleResolution(): void {
    this.customerRequirements.streaming.resolutionActive =
      !this.customerRequirements.streaming.resolutionActive;
  }

  handleChangeTelevision(value: string): void {
    this.customerRequirements.streaming.televisionNumber =
      this.customerRequirements.streaming.televisionNumber === value
        ? null
        : value;
  }

  private getStreamingRecommendation(): string {
    const recommendations = streamingServiceRecommendations.filter(
      ({ streamingValues }) =>
        streamingValues.some((value) =>
          this.requirements.streaming.activeStreamingServices.includes(value)
        )
    );

    const filteredRecommendations = recommendations.filter(
      ({ ignoreWhenRecommendationKeyIsPresent }) =>
        !ignoreWhenRecommendationKeyIsPresent ||
        recommendations.every(
          ({ description }) =>
            !description.includes(ignoreWhenRecommendationKeyIsPresent)
        )
    );

    return filteredRecommendations
      .map((recommendation) => recommendation.description)
      .join(" und ");
  }

  private getHouseholdRecommendation(): string {
    const { adults, children } = this.requirements.household;

    const adultsNumber = filterNonNumericCharactersAndParseToInt(adults ?? "");
    const childrenNumber = filterNonNumericCharactersAndParseToInt(
      children ?? ""
    );

    const recommendation: string[] = [];

    if (adultsNumber > 1) {
      recommendation.push("PlusKarte");
    }

    if (childrenNumber > 0) {
      recommendation.push("PlusKarte Kids & Teens");
    }

    return recommendation.join(" und ");
  }
}
