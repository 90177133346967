import React from "react";
import DropdownMain from "elements/new-design/DropdownMain";
import { BandwidthValues } from "core/entities/PencilSelling/CustomerRequirementsBase/ICustomerRequirementsBase";
import { observer } from "mobx-react";
import { trackClick } from "helpers/reactTracking";
import { CustomerRequirementsBase } from "core/entities/PencilSelling/CustomerRequirementsBase/CustomerRequirementsBase";
import InternetSpeedAtTheMomentIcon from "../../../../../assets/images/icons/pencil-selling-tool/customer-requirements/network_at_the_moment.svg";
import InternetSpeedMaxIcon from "../../../../../assets/images/icons/pencil-selling-tool/customer-requirements/network_max.svg";
import CustomerRequirementsSectionHeader from "../CustomerRequirementsSectionHeader";
import { InfoModalVariants } from "../CustomerRequirementsInfoModal";
import CustomerRequirementsRecommendation from "../CustomerRequirementsRecommendation";
import * as styles from "./customer-requirements-bandwidth.module.scss";

const bandwidthContentMap = {
  options: [
    "",
    "Bis 16 MBit/s",
    "50 MBit/s",
    "100 MBit/s",
    "150 MBit/s",
    "250 MBit/s",
    "300 MBit/s",
    "600 MBit/s",
    "1000 MBit/s",
  ],
  items: [
    {
      identifier: "current",
      icon: InternetSpeedAtTheMomentIcon,
    },
    {
      identifier: "max",
      icon: InternetSpeedMaxIcon,
    },
  ],
};

type BandwidthItem = {
  identifier: keyof BandwidthValues;
  icon: React.FunctionComponent<React.SVGAttributes<SVGElement>>;
};

type CustomerRequirementsBandwidthProps = {
  openInfoModal: (variant: InfoModalVariants) => void;
  trackingContext: string;
  customerRequirementsBase: CustomerRequirementsBase;
  isBusinessClient: boolean;
};

const CustomerRequirementsBandwidth = ({
  openInfoModal,
  trackingContext,
  customerRequirementsBase,
  isBusinessClient,
}: CustomerRequirementsBandwidthProps) => {
  const bandwidthInfoModalContent = isBusinessClient
    ? InfoModalVariants.BANDWIDTH_GK
    : InfoModalVariants.BANDWIDTH;
  return (
    <div className={styles.bandwidthContainer}>
      <CustomerRequirementsSectionHeader
        sectionTitle="bandbreite"
        onInfoClick={() => openInfoModal(bandwidthInfoModalContent)}
      />
      <div className={styles.bandwidthItemsContainer}>
        {bandwidthContentMap.items.map((bandwidthItem: BandwidthItem) => (
          <div className={styles.bandwidthItem} key={bandwidthItem.identifier}>
            <bandwidthItem.icon className={styles.bandwidthIcon} />
            <DropdownMain
              label="Auswahl"
              items={bandwidthContentMap.options}
              value={
                customerRequirementsBase.requirements.bandwidth[
                  bandwidthItem.identifier
                ] || ""
              }
              onSelection={(value) => {
                trackClick(
                  `bandwidth.${bandwidthItem.identifier}.${value || "0"}`,
                  trackingContext
                );
                customerRequirementsBase.setBandwidth(
                  bandwidthItem.identifier,
                  value
                );
              }}
            />
          </div>
        ))}
      </div>
      <CustomerRequirementsRecommendation
        description={customerRequirementsBase.recommendations.bandwidth}
        customClass={styles.bandwidthRecommendationPosition}
      />
    </div>
  );
};

export default observer(CustomerRequirementsBandwidth);
