import {
  IConfigurationsSummary,
  IConfigurationSummaryItemSections,
  IConfigurationSummaryTotalMonthlyPrice,
} from "../ISummary";
import {
  ICartConfiguration,
  ICartConfigurationType,
  IMobileSettings,
} from "../../../../core/entities/PencilSelling/Configurations/IConfigurations";
import { Group } from "../../../../core/entities/Product/IProduct";
import {
  CartItemSummaryPresenter,
  IConfigurationItemExport,
} from "../../../../core/presenter/PencilSelling/CartItemSummaryPresenter";
import {
  ICartItemData,
  PaymentTypes,
} from "../../../../core/entities/PencilSelling/CartItem/ICartItem";
import ProductMonthlyPrices, {
  ICartItemStepPricesData,
} from "../../../../helpers/ProductMonthlyPrices";
import { PRODUCT_DEFAULT_CONTRACT_PERIOD } from "../../../../constants/const";
import { IBusinessCasesTypeValues } from "../../../../core/entities/Product/Tariff/ITariff";
import { getCashbackPromotions } from "../../../../helpers/GetCashbackPromotions";
import { getFormattedFloat } from "../../../../helpers/NumberHelpers";

export const getConfigurationSummarySectionsItemsArray = (
  configurationItem: IConfigurationSummaryItemSections
): IConfigurationItemExport[][] =>
  configurationItem.sections.map((section) => section.items);

export const getConfigurationsSummarySectionsItemsMonthlyPriceSteps = (
  configurationsSectionsItems: IConfigurationItemExport[]
): ICartItemStepPricesData[][] =>
  configurationsSectionsItems.map(
    (sectionItem) => sectionItem.additionalRenderData.monthlyPriceStepsData
  );

export const getSectionsItemsTotalOncePrice = (
  sectionItems: IConfigurationItemExport[],
  configurationsAmount: number
): number =>
  Number(
    sectionItems
      .reduce(
        (acc, { additionalRenderData }) =>
          acc + configurationsAmount * additionalRenderData.totalOncePrice,
        0
      )
      .toFixed(2)
  );

export const calculateConfigurationsOnceTotalPrice = (
  configurations: IConfigurationSummaryItemSections[]
): number => {
  const result = configurations.reduce(
    (acc, configurationItem) => acc + +configurationItem.totalPrice.oncePrice,
    0
  );

  return result <= 0 ? 0 : result;
};

export const calculateConfigurationItemOnceTotalPrice = (
  configurationItem: IConfigurationSummaryItemSections
): number | null => {
  const sectionsItems =
    getConfigurationSummarySectionsItemsArray(configurationItem).flat();
  return getSectionsItemsTotalOncePrice(
    sectionsItems,
    configurationItem.amount
  );
};

export const calculateConfigurationsMonthlyTotalPrice = (
  configurationItemsMonthlyPriceSteps: ICartItemStepPricesData[][],
  configurationsAmount = 1
): ICartItemStepPricesData[] => {
  const priceStepsFromValues = configurationItemsMonthlyPriceSteps
    .flat()
    .map((priceStep) => priceStep.from);
  const configurationMonthlyFromValues = Array.from(
    new Set(priceStepsFromValues)
  ).sort((current, next) => current - next);

  const totalMonthlyPriceSteps = configurationMonthlyFromValues.map(
    (fromStepValue) => {
      // Taking array of ICartItemStepPricesData[][] Calculate total sum for all available intervals in configurations
      const updatedPrice = configurationItemsMonthlyPriceSteps.reduce(
        (acc, secondaryPriceStepsArray) => {
          // Get the closest and lowest/equal priceStep item by "form" property value basing on current 'main' "from" value => fromStepValue
          const closestLowestOrEqualPriceStepItem =
            secondaryPriceStepsArray.reduce((prevPriceStep, currentPriceStep) =>
              currentPriceStep.from <= fromStepValue
                ? currentPriceStep
                : prevPriceStep
            );
          return (
            acc + configurationsAmount * closestLowestOrEqualPriceStepItem.price
          );
        },
        0
      );
      return {
        price: updatedPrice,
        from: fromStepValue,
      };
    }
  );
  return totalMonthlyPriceSteps;
};

export const getFormattedConfigurationsTotalMonthlyPrice = (
  priceSteps: ICartItemStepPricesData[]
): IConfigurationSummaryTotalMonthlyPrice => {
  const [mainPrice, ...restPriceSteps] = priceSteps;
  const priceStepsDescriptions = restPriceSteps.map((priceStep) =>
    ProductMonthlyPrices.priceIntervalText(
      priceStep.from,
      priceStep?.price <= 0 ? 0 : priceStep?.price
    )
  );
  const updatedMainPrice = mainPrice?.price
    ? Number(mainPrice?.price.toFixed(2))
    : 0;
  return {
    priceStepsDescriptions,
    mainPrice: updatedMainPrice <= 0 ? 0 : updatedMainPrice,
  };
};

export const getConfigurationItemCalculatedPriceSteps = (
  configurationItem: IConfigurationSummaryItemSections
): ICartItemStepPricesData[] => {
  const sectionsItems =
    getConfigurationSummarySectionsItemsArray(configurationItem).flat();

  const configurationSectionsItemsMonthlyPriceSteps =
    getConfigurationsSummarySectionsItemsMonthlyPriceSteps(sectionsItems);

  const configurationTotalMonthlyPriceSteps =
    calculateConfigurationsMonthlyTotalPrice(
      configurationSectionsItemsMonthlyPriceSteps,
      configurationItem.amount
    );
  return configurationTotalMonthlyPriceSteps;
};

export const getConfigurationsTotalMonthlyPrice = (
  configurations: IConfigurationSummaryItemSections[]
): IConfigurationSummaryTotalMonthlyPrice => {
  const calculatedMonthlyStepPricesArr = configurations.map(
    getConfigurationItemCalculatedPriceSteps
  );
  const configurationsTotalMonthlyPriceSteps =
    calculateConfigurationsMonthlyTotalPrice(calculatedMonthlyStepPricesArr);
  return getFormattedConfigurationsTotalMonthlyPrice(
    configurationsTotalMonthlyPriceSteps
  );
};

export const getConfigurationItemTotalMonthlyPrice = (
  configurationItem: IConfigurationSummaryItemSections
): IConfigurationSummaryTotalMonthlyPrice => {
  const configurationTotalMonthlyPriceSteps =
    getConfigurationItemCalculatedPriceSteps(configurationItem);

  return getFormattedConfigurationsTotalMonthlyPrice(
    configurationTotalMonthlyPriceSteps
  );
};

export const mapTariffAddonsSummary = (
  currentConfigurationItem: ICartConfiguration,
  configurationsSummary: IConfigurationsSummary,
  addons: ICartItemData[],
  configurationType: ICartConfigurationType,
  configurationsSummaryItemIndex: number,
  businessCase: IBusinessCasesTypeValues
) => {
  configurationsSummary[configurationType].configurationItems[
    configurationsSummaryItemIndex
  ].sections.push({
    name: "Extras",
    group: Group.addon,
    items: addons.map((addonItem) => {
      const addonPresenter = new CartItemSummaryPresenter(
        addonItem,
        currentConfigurationItem.data.settings,
        businessCase
      );
      return addonPresenter.configurationExport();
    }),
  });
};

export const mapTotalPricesForPDF = (
  configurationsSummary: IConfigurationsSummary,
  displayConfigurationTotalPrice: boolean,
  displayOfferTotalPrice: boolean
) => {
  const updatedConfigurationsSummary = JSON.parse(
    JSON.stringify(configurationsSummary)
  ) as IConfigurationsSummary;

  if (!displayOfferTotalPrice) {
    updatedConfigurationsSummary.totalPrice = null;
  }

  if (!displayConfigurationTotalPrice) {
    const configurations = [
      ...updatedConfigurationsSummary[ICartConfigurationType.DEVICE]
        .configurationItems,
      ...updatedConfigurationsSummary[ICartConfigurationType.CARD]
        .configurationItems,
      ...updatedConfigurationsSummary[ICartConfigurationType.MOBILE]
        .configurationItems,
      ...updatedConfigurationsSummary[ICartConfigurationType.LANDLINE]
        .configurationItems,
    ];
    configurations.forEach((configurationItem) => {
      configurationItem.totalPrice = null;
    });
  }

  return updatedConfigurationsSummary;
};

export const calculateSecondaryCardsSumAveragePrice = ({
  mobileTariffConfiguration,
  cardsInCart,
  customerPricePrefix,
  businessCase,
}: {
  mobileTariffConfiguration: ICartConfiguration | null;
  cardsInCart: ICartItemData[];
  customerPricePrefix: string;
  businessCase: IBusinessCasesTypeValues;
}): {
  totalPriceData: IConfigurationSummaryTotalMonthlyPrice;
  averagePrice: number;
} => {
  const cartItems = [...cardsInCart];
  const cartItemsExports = cardsInCart.map((cardInCart) =>
    new CartItemSummaryPresenter(
      cardInCart,
      null,
      businessCase,
      customerPricePrefix
    ).configurationExport(null, cardInCart.cardData?.note)
  );

  if (mobileTariffConfiguration) {
    const benefitIsActive = mobileTariffConfiguration.data.isBenefitActive;
    const mobileTariffBenefit = benefitIsActive
      ? mobileTariffConfiguration.data.benefit
      : null;
    const mobileTariffNote = mobileTariffConfiguration.data.note;
    const mobileTariffConfigurationSettings = mobileTariffConfiguration.data
      .settings as IMobileSettings;
    const mobileTariffInCart =
      mobileTariffConfiguration.children.getMobileTariff();
    cartItems.push(mobileTariffInCart);

    const mobileTariffSummaryPresenterExport = new CartItemSummaryPresenter(
      mobileTariffInCart,
      mobileTariffConfigurationSettings,
      businessCase,
      customerPricePrefix
    ).configurationExport(
      mobileTariffBenefit,
      mobileTariffNote,
      mobileTariffConfigurationSettings.loyaltyBonusValue
    );
    cartItemsExports.push(mobileTariffSummaryPresenterExport);
  }

  const configurationSectionsItemsMonthlyPriceSteps =
    getConfigurationsSummarySectionsItemsMonthlyPriceSteps(cartItemsExports);
  const configurationTotalMonthlyPriceSteps =
    calculateConfigurationsMonthlyTotalPrice(
      configurationSectionsItemsMonthlyPriceSteps,
      1
    );
  // Create monthly intervals from price steps
  const priceStepMonthlyIntervals = configurationTotalMonthlyPriceSteps
    .map((monthlyPriceStep, index, arr) => {
      const nextMonthlyPriceStep = arr[index + 1];

      if (nextMonthlyPriceStep) {
        const intervalEnd =
          nextMonthlyPriceStep.from - 1 > PRODUCT_DEFAULT_CONTRACT_PERIOD
            ? PRODUCT_DEFAULT_CONTRACT_PERIOD
            : nextMonthlyPriceStep.from - 1;
        return ProductMonthlyPrices.createMonthlyIntervalItemsArr(
          monthlyPriceStep.from,
          intervalEnd,
          monthlyPriceStep.price
        );
      }

      return ProductMonthlyPrices.createMonthlyIntervalItemsArr(
        monthlyPriceStep.from,
        PRODUCT_DEFAULT_CONTRACT_PERIOD,
        monthlyPriceStep.price
      );
    })
    .flat();
  const baseAveragePrice = ProductMonthlyPrices.getAverageMonthlyPrice(
    priceStepMonthlyIntervals,
    PRODUCT_DEFAULT_CONTRACT_PERIOD
  );
  const cashbackPromotionsAveragePrice =
    CartItemSummaryPresenter.getCashbackPromotionsAveragePrice(
      getCashbackPromotions(cartItems),
      PRODUCT_DEFAULT_CONTRACT_PERIOD
    );
  const formattedSumPriceSteps = getFormattedConfigurationsTotalMonthlyPrice(
    ProductMonthlyPrices.createMonthlyPriceSteps(priceStepMonthlyIntervals)
  );
  // TODO Temporary hidden
  // Get reduced once/provision price from mobile tariff and secondary cards
  // const reducedProvisionTotalPrice =
  //   cartItemsExports.reduce(
  //     (acc, exportData) =>
  //       acc + exportData.additionalRenderData.reducedOncePrice,
  //     0
  //   ) / PRODUCT_DEFAULT_CONTRACT_PERIOD;

  return {
    totalPriceData: formattedSumPriceSteps,
    averagePrice: cartItems.length
      ? (baseAveragePrice + cashbackPromotionsAveragePrice) /
        // TODO Temporary hidden
        // + reducedProvisionTotalPrice) /
        cartItems.length
      : 0,
  };
};

export const calculateMobilePortfolioSummaryAveragePrice = (
  mobilePortfolioConfigurations: IConfigurationSummaryItemSections[]
): number | null => {
  let configurationsTotalAmount = 0;
  let totalPrice = 0;

  mobilePortfolioConfigurations.forEach((configurationItem) => {
    const cartItems =
      getConfigurationSummarySectionsItemsArray(configurationItem).flat(2);

    cartItems
      .filter(
        (cartItem) =>
          cartItem.additionalRenderData.paymentType === PaymentTypes.MONTHLY
      )
      .forEach(
        ({
          additionalRenderData: {
            baseMonthlyIntervalItems,
            cashbackPromotions,
          },
        }) => {
          const cashbackPromotionsAveragePrice =
            CartItemSummaryPresenter.getCashbackPromotionsAveragePrice(
              cashbackPromotions,
              PRODUCT_DEFAULT_CONTRACT_PERIOD
            );
          let updatedMonthlyIntervals = [...baseMonthlyIntervalItems];

          if (
            baseMonthlyIntervalItems.length > PRODUCT_DEFAULT_CONTRACT_PERIOD
          ) {
            updatedMonthlyIntervals = updatedMonthlyIntervals.slice(
              0,
              PRODUCT_DEFAULT_CONTRACT_PERIOD
            );
          }

          if (
            baseMonthlyIntervalItems.length < PRODUCT_DEFAULT_CONTRACT_PERIOD
          ) {
            const lastPriceInterval =
              baseMonthlyIntervalItems[baseMonthlyIntervalItems.length - 1];

            for (
              let i = lastPriceInterval.month;
              i < PRODUCT_DEFAULT_CONTRACT_PERIOD;
              i += 1
            ) {
              updatedMonthlyIntervals.push({
                ...lastPriceInterval,
                month: i,
              });
            }
          }

          const mainAveragePrice = ProductMonthlyPrices.getAverageMonthlyPrice(
            updatedMonthlyIntervals,
            PRODUCT_DEFAULT_CONTRACT_PERIOD
          );

          totalPrice =
            (totalPrice * 100 +
              (mainAveragePrice + cashbackPromotionsAveragePrice) *
                configurationItem.amount *
                100) /
            100;
        }
      );

    configurationsTotalAmount +=
      configurationItem.amount *
      configurationItem.sections
        .filter((section) => section.group !== Group.addon)
        .reduce(
          (acc, section) => acc + section.items.length,
          0
      );
  });
    

  return configurationsTotalAmount > 1
    ? getFormattedFloat(totalPrice / configurationsTotalAmount)
    : null;
};
