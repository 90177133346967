import React from "react";
import ModalWrapper from "elements/new-design/ModalWrapper";
import { CopyText, Heading } from "@dtpk-cc/components";
import { BusinessCasesLandlineTooltipData } from "core/entities/Product/BaseData/const";

import * as styles from "./business-case-landline-info-modal.module.scss";

type BusinessCaseLandlineInfoModalProps = {
  onClose: () => void;
  isOpen: boolean;
};

const BusinessCaseLandlineInfoModal = ({
  onClose,
  isOpen,
}: BusinessCaseLandlineInfoModalProps) => (
  <ModalWrapper
    containerCustomClass={styles.businessCaseLandlineInfo}
    isOpen={isOpen}
    onClose={onClose}
  >
    <Heading
      tag="h3"
      variants={[Heading.Variant.secondary, Heading.Variant.display]}
      customClass={styles.mainTitle}
    >
      Geschäftsfälle Festnetz
    </Heading>
    <Heading tag="h3" variants={[Heading.Variant.quaternary]}>
      Der ausgewählte Geschäftsfall zeigt in der weiteren Angebotserstellung{" "}
      <b>passende</b> Aktionen und Produkte an.
    </Heading>
    {BusinessCasesLandlineTooltipData.map((tooltip) => (
      <React.Fragment key={tooltip.title}>
        <Heading
          tag="h3"
          customClass="m-t-24"
          variants={[Heading.Variant.quaternary, Heading.Variant.highlight]}
        >
          {tooltip.title}
        </Heading>
        {tooltip.content.map((copy, index) => (
          <CopyText key={index.toString()}>{copy}</CopyText>
        ))}
      </React.Fragment>
    ))}
  </ModalWrapper>
);

export default BusinessCaseLandlineInfoModal;
