import React, { useState } from "react";
import CustomerRequirementsFiber from "components/new-design/CustomerRequirements/CustomerRequirementsFiber";
import CustomerRequirementsInfoModal, {
  InfoModalVariants,
} from "components/new-design/CustomerRequirements/CustomerRequirementsInfoModal";
import CustomerRequirements from "components/new-design/CustomerRequirements";
import { trackClick } from "helpers/reactTracking";
import CustomerRequirementsWrapper from "components/new-design/CustomerRequirements/CustomerRequirementsWrapper";
import CustomerRequirementsHeader from "components/new-design/CustomerRequirements/CustomerRequirementsHeader";
import { observer } from "mobx-react";
import { useStores } from "stores/BusinessClient";
import CustomerRequirementsInternationalTariff from "components/new-design/CustomerRequirements/CustomerRequirementsInternationalTariff";
import CustomerRequirementsHeaderExtraElements from "./CustomerRequirementsHeaderExtraElements";
import CustomerRequirementsUseCases from "./CustomerRequirementsUseCases";
import CustomerRequirementsSmartphone from "./CustomerRequirementsSmartphone";
import CustomerRequirementsBusinessSize from "./CustomerRequirementsBusinessSize";
import CustomerRequirementsSpecialistQuestions from "./CustomerRequirementsSpecialistQuestions";

const trackingContext = "umfeldanalyse.geschaeftskunde";

const CustomerRequirementsBC = () => {
  const { customerRequirementsStore } = useStores();

  const isBusinessClient = true;

  const [infoModalOpen, setInfoModalOpen] = useState<boolean>(false);
  const [activeModalVariant, setActiveModalVariant] =
    useState<InfoModalVariants>(InfoModalVariants.DEFAULT);

  const openInfoModal = (variant: InfoModalVariants) => {
    trackClick(`modales.${variant}.geöffnet`, trackingContext);
    setInfoModalOpen(true);
    setActiveModalVariant(variant);
  };

  const onCloseInfoModal = () => {
    trackClick(`modales.${activeModalVariant}.schließen`, trackingContext);
    setInfoModalOpen(false);
    setActiveModalVariant(InfoModalVariants.DEFAULT);
  };

  const useCaseComponent = (
    <CustomerRequirementsUseCases trackingContext={trackingContext} />
  );

  const fiberCableComponent = (
    <CustomerRequirementsFiber
      fiber={
        customerRequirementsStore.customerRequirementsBase.requirements.fiber
      }
      toggleFiber={(value) =>
        customerRequirementsStore.customerRequirementsBase.toggleFiber(value)
      }
      trackingContext={trackingContext}
      isBusinessClient={isBusinessClient}
    />
  );

  const internationalTariffComponent = (
    <CustomerRequirementsInternationalTariff
      trackingContext={trackingContext}
      openInfoModal={openInfoModal}
      isBusinessClient={isBusinessClient}
      customerRequirementsBase={
        customerRequirementsStore.customerRequirementsBase
      }
      recommendation={
        customerRequirementsStore.recommendations.internationalTariffs
      }
    />
  );

  const smartphoneConsultationComponent = (
    <CustomerRequirementsSmartphone trackingContext={trackingContext} />
  );

  const businessSizeComponent = (
    <CustomerRequirementsBusinessSize
      openInfoModal={openInfoModal}
      trackingContext={trackingContext}
    />
  );

  const SpecialistQuestionsComponent = (
    <CustomerRequirementsSpecialistQuestions
      openInfoModal={openInfoModal}
      trackingContext={trackingContext}
    />
  );

  const modalComponent = (
    <CustomerRequirementsInfoModal
      isOpen={infoModalOpen}
      onClose={onCloseInfoModal}
      variant={activeModalVariant}
    />
  );

  return (
    <CustomerRequirementsWrapper
      header={
        <CustomerRequirementsHeader>
          <CustomerRequirementsHeaderExtraElements
            trackingContext={trackingContext}
            openInfoModal={openInfoModal}
          />
        </CustomerRequirementsHeader>
      }
    >
      <CustomerRequirements
        components={{
          useCase: useCaseComponent,
          fiber: fiberCableComponent,
          internationalTariff: internationalTariffComponent,
          smartphone: smartphoneConsultationComponent,
          customerSize: businessSizeComponent,
          modal: modalComponent,
          magentaShape: SpecialistQuestionsComponent,
        }}
        customerRequirementsBase={
          customerRequirementsStore.customerRequirementsBase
        }
        isBusinessClient={isBusinessClient}
        openInfoModal={openInfoModal}
        trackingContext={trackingContext}
      />
    </CustomerRequirementsWrapper>
  );
};

export default observer(CustomerRequirementsBC);
